import { decorate, observable, action } from "mobx";
import commonStore from "./commonStore";
import stores from ".";
import ProfileForm from "./form/profile";
import {
  updateProfile,
  updateAccountToBusiness,
  getProfile,
  uploadAvatar,
  changePassword,
  changeNotificationPreferences,
  getNotificationPreferences,
  getPaymentMethods,
  setDefaultPaymentMethod,
  addPaymentMethod,
  deletePaymentMethod,
  getStatisticalSummary,
  getUserTWProfile,
  updateSubscribeTWDegist,
  updateGUProfile
} from "../services/domainServices/userDomain.service";
import PreferencesForm from "./form/preferences";
import axios from "axios";
import Cookie from "js-cookie";
import AccountSettingsForm from "./form/accountSettings";
import NotificationPreferencesForm from "./form/notificationPreferencesForm";
import {
  getSubscriptionInfo,
  getAvailableSubscriptionList,
  purchaseSubscription
} from "../services/domainServices/subscriptionDomain.service";
import { countPendingTransaction } from "../services/domainServices/transactionDomain.service";
import AddCardForm from "./form/addCard";
import NumberOfEmployeeSearchForm from "./form/numberOfEmployeeSearchForm";
import { appRole } from "../constants/app.constant";

class UserStore {
  currentUser;
  currentUserTW;
  timeZone;
  paymentMethods = [];
  subscriptionInfo;
  availableSubscriptionList;
  pendingTransaction = 0;
  dismissedRemindPendingTransaction = false;
  statisticalSummary = {};
  isNewRegister = false;
  dismissedGuildHint = false;
  currentUserIdLocalStorage = "";
  firstTimeToFeatureLocalStorage = false;

  constructor() {
    this.profileForm = new ProfileForm();
    this.preferencesForm = new PreferencesForm();
    this.accountSettingsForm = new AccountSettingsForm();
    this.notificationPreferencesForm = new NotificationPreferencesForm();
    this.addCardForm = new AddCardForm();
    this.numberOfEmployeeSearchForm = new NumberOfEmployeeSearchForm();
  }

  hasPaymentMethod() {
    return this.paymentMethods.length > 0;
  }

  toggleShowCurrentPassword = () => {
    if (this.accountSettingsForm.$("current_password").get("type") === "text") {
      this.accountSettingsForm.$("current_password").set("type", "password");
    } else {
      this.accountSettingsForm.$("current_password").set("type", "text");
    }
  };

  toggleShowNewPassword = () => {
    if (this.accountSettingsForm.$("new_password").get("type") === "text") {
      this.accountSettingsForm.$("new_password").set("type", "password");
    } else {
      this.accountSettingsForm.$("new_password").set("type", "text");
    }
  };

  toggleShowRetypeNewPassword = () => {
    if (
      this.accountSettingsForm.$("retype_new_password").get("type") === "text"
    ) {
      this.accountSettingsForm.$("retype_new_password").set("type", "password");
    } else {
      this.accountSettingsForm.$("retype_new_password").set("type", "text");
    }
  };

  changePassword(data) {
    return changePassword(data);
  }

  getNotificationPreferences() {
    return getNotificationPreferences().then(
      action(data => {
        this.notificationPreferencesForm.set(data);
        if (data.guild_hint) this.dismissedGuildHint = data.guild_hint;
      })
    );
  }

  changeNotificationPreferences(data) {
    return changeNotificationPreferences(data);
  }

  setUserToken(data, rememberMe) {
    if (data.role === "user_console") {
      this.logout();
      Cookie.set("__user_credential_console", JSON.stringify(data), {
        expires: 14
      });
      window.location.href = "/admin";
    } else {
      Cookie.set("__user_credential", JSON.stringify(data), {
        expires: rememberMe ? 14 : 1
      });
    }
  }

  async getCurrentUser() {
    const userCredential =
      stores.authStore.userCredential || Cookie.getJSON("__user_credential");
    if (userCredential) {
      axios.defaults.headers.common["Authorization"] = `${
        userCredential.token_type
      } ${userCredential.access_token}`;
      axios.defaults.headers.common["X-MEC-AUTHORIZATION"] = `${
        userCredential.token_type
      } ${userCredential.access_token}`;

      return getProfile()
        .then(
          action(data => {
            this.currentUser = data;
            if (
              appRole.general !== data.user.role &&
              appRole.job_seeker !== data.user.role
            ) {
              this.getStatisticalSummary();
              countPendingTransaction().then(
                action(data => {
                  this.pendingTransaction = data.count;
                })
              );
            }

            this.getNotificationPreferences();

            const { location } = this.currentUser.profile;
            if (location && location.state) {
              commonStore.getCitiesByState(location.state).then(data => {
                if (!data.find(item => item === location.city)) {
                  data.push(location.city);
                }
                this.profileForm.$("location.city").set(
                  "extra",
                  data.map(item => ({
                    label: item,
                    value: item
                  }))
                );
              });
            }

            if (
              appRole.general !== data.user.role &&
              appRole.job_seeker !== data.user.role
            ) {
              const { car_types, business_type } = this.currentUser.preferences;
              if (car_types && car_types.includes("Other")) {
                this.preferencesForm
                  .$("other_car_types")
                  .set("extra", { show: true });
              }
              if (business_type === "Other") {
                this.preferencesForm
                  .$("other_business_type")
                  .set("extra", { show: true });
              }
              this.timeZone =
                location && location.time_zone && location.time_zone.id;
              this.getPaymentMethods();
            }

            if (appRole.admin !== userCredential.role) {
              return getUserTWProfile()
                .then(
                  action(resp => {
                    this.currentUserTW = resp.data;
                    // return getUserTWInfo({
                    //   seasonId: resp.data.season.id,
                    //   twStatus: resp.data.twStatus ? true : false
                    // })
                    //   .then(
                    //     action(data => {
                    //       console.log("getUserTWInfo data ", data);
                    //       this.currentUser = {
                    //         ...resp.data,
                    //         ...data.data
                    //       };
                    //     })
                    //   )
                    //   .catch(err => {
                    //     console.log("err ", err);
                    //   });
                  })
                )
                .catch(err => {
                  if (err) this.logout();
                })
                .finally(() => commonStore.setAppLoaded());
            }
          })
        )
        .catch(err => {
          console.log("err ", err);
          if (err) this.logout();
        })
        .finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }

  setIsNewRegister = value => {
    this.isNewRegister = value;
  };

  dismissGuildHint = () => {
    this.dismissedGuildHint = true;
  };

  getStatisticalSummary = () => {
    getStatisticalSummary().then(
      action(data => {
        this.statisticalSummary = data;
      })
    );
  };

  dismissRemindPendingTransaction = () => {
    this.dismissedRemindPendingTransaction = true;
  };

  logout() {
    this.profileForm.reset();
    this.preferencesForm.reset();
    this.currentUser = null;
    this.currentUserTW = null;
    this.subscriptionInfo = null;
    this.availableSubscriptionList = null;
    this.dismissedRemindPendingTransaction = false;
    this.dismissedGuildHint = false;
    this.pendingTransaction = 0;
    this.paymentMethods = [];
    Cookie.remove("__user_credential");
    this.currentUserIdLocalStorage = localStorage.getItem("userIdForFirstTime");
   
    localStorage.clear();
    localStorage.setItem(
      "userIdForFirstTime",
      this.currentUserIdLocalStorage
    );
    window.location.href = "/app/#/auth/login-page";
  }

  updateProfile(profile) {
    if (
      profile.profile &&
      profile.profile.location &&
      profile.profile.location.other_city
    ) {
      profile.profile.location.city = profile.profile.location.other_city;
      const extra = this.profileForm.$("location.city").extra;
      if (!extra.find(item => item.value === profile.profile.location.city)) {
        extra.push({
          label: profile.profile.location.city,
          value: profile.profile.location.city
        });
        this.profileForm.$("location.city").set("extra", extra);
      }
      this.profileForm
        .$("location.other_city")
        .set(profile.profile.location.city);
      this.profileForm.$("location.other_city").set("extra", {
        show: false
      });
      this.profileForm.$("location.other_city").reset();
    }
    return updateProfile(profile);
  }

  updateAccountToBusiness(profile) {
    if (
      profile.profile &&
      profile.profile.location &&
      profile.profile.location.other_city
    ) {
      profile.profile.location.city = profile.profile.location.other_city;
      const extra = this.profileForm.$("location.city").extra;
      if (!extra.find(item => item.value === profile.profile.location.city)) {
        extra.push({
          label: profile.profile.location.city,
          value: profile.profile.location.city
        });
        this.profileForm.$("location.city").set("extra", extra);
      }
      this.profileForm
        .$("location.other_city")
        .set(profile.profile.location.city);
      this.profileForm.$("location.other_city").set("extra", {
        show: false
      });
      this.profileForm.$("location.other_city").reset();
    }
    return updateAccountToBusiness(profile);
  }

  uploadAvatar(file) {
    return uploadAvatar(file);
  }

  getProfile() {
    return this.getCurrentUser();
  }

  getPaymentMethods() {
    return getPaymentMethods().then(
      action(data => {
        this.paymentMethods = data;
      })
    );
  }

  setDefaultPaymentMethod(id) {
    return setDefaultPaymentMethod(id);
  }

  addPaymentMethod(id) {
    return addPaymentMethod({ payment_method_id: id });
  }

  deletePaymentMethod(id) {
    return deletePaymentMethod(id);
  }

  getSubscriptionInfo = () => {
    return getSubscriptionInfo().then(
      action(data => {
        this.subscriptionInfo = data;
      })
    );
  };

  getAvailableSubscriptionList = () => {
    return getAvailableSubscriptionList().then(
      action(data => {
        this.availableSubscriptionList = data;
        return data;
      })
    );
  };

  purchaseSubscription = data => {
    return purchaseSubscription(data);
  };

  updateSubscribeTWDegist = (id, params) => {
    return updateSubscribeTWDegist(id, params);
  };

  updateGUProfile = (id, body) => {
    return updateGUProfile(id, body);
  };
}

decorate(UserStore, {
  currentUser: observable,
  currentUserTW: observable,
  timeZone: observable,
  paymentMethods: observable,
  subscriptionInfo: observable,
  availableSubscriptionList: observable,
  pendingTransaction: observable,
  dismissedRemindPendingTransaction: observable,
  statisticalSummary: observable,
  isNewRegister: observable,
  setUserToken: action,
  getCurrentUser: action,
  logout: action,
  getProfile: action,
  getNotificationPreferences: action,
  getPaymentMethods: action,
  getSubscriptionInfo: action,
  getAvailableSubscriptionList: action,
  dismissRemindPendingTransaction: action,
  getStatisticalSummary: action,
  setIsNewRegister: action,
  updateSubscribeTWDegist: observable,
  updateGUProfile: action
});

export default new UserStore();
