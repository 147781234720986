import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";
import { observer, inject } from "mobx-react";
import notificationService from "../services/notificationService";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden"
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
    this.props.authStore.resetPasswordForm.reset();
    this.props.authStore.resetPasswordForm.showErrors(false);
  }

  handleToggleShowPw = () => {
    this.props.authStore.toggleShowResetPassword();
  };

  handleToggleShowConfirmPw = () => {
    this.props.authStore.toggleShowConfirmResetPassword();
  };

  get token() {
    const queries = new URLSearchParams(this.props.location.search);
    return queries.get("token");
  }

  submit = e => {
    e.preventDefault();
    const { resetPasswordForm, resetPassword } = this.props.authStore;
    if (!resetPasswordForm.isValid) {
      return resetPasswordForm.showErrors(true);
    }
    resetPasswordForm.submit({
      onSuccess: form => {
        const data = form.values();
        resetPassword({ ...data, token: this.token })
          .then(() => {
            notificationService.success("RESET_PASSWORD.SUCCESS");
            this.props.history.push("/auth/login-page");
          })
          .catch(res => {
            const err = res.response;
            notificationService.error(err && err.data && err.data.message);
          });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { resetPasswordForm } = this.props.authStore;
    const queries = new URLSearchParams(this.props.location.search);
    return (
      <div className={classes.container}>
        <form onSubmit={this.submit}>
          <Card
            profile
            className={
              classes.customCardClass + " " + classes[this.state.cardAnimaton]
            }
          >
            <CardBody profile>
              <h4 className={classes.cardTitle}>Reset your password</h4>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                          onClick={this.handleToggleShowPw}
                        >
                          {this.props.authStore.resetPasswordForm
                            .$("password")
                            .get("type") === "password" ? (
                            <RemoveRedEye
                              className={classes.inputAdornmentIcon}
                            />
                          ) : (
                            <VisibilityOff
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </InputAdornment>
                      ),
                      ...resetPasswordForm.$("password").bind()
                    }}
                    error={!!resetPasswordForm.$("password").error}
                    helpText={
                      resetPasswordForm.$("password").error || undefined
                    }
                    labelText="Password"
                  />
                  <div className={classes.passwordHint}>
                    Password must contain at least 8 characters, one lower case
                    letter, one upper case letter, one number and one of the
                    following symbols: !, @, #, $, %, &, *
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <span style={{ display: "none" }}>
                    {resetPasswordForm.$("confirmPassword").error}
                  </span>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                          onClick={this.handleToggleShowConfirmPw}
                        >
                          {this.props.authStore.resetPasswordForm
                            .$("confirmPassword")
                            .get("type") === "password" ? (
                            <RemoveRedEye
                              className={classes.inputAdornmentIcon}
                            />
                          ) : (
                            <VisibilityOff
                              className={classes.inputAdornmentIcon}
                            />
                          )}
                        </InputAdornment>
                      ),
                      ...resetPasswordForm.$("confirmPassword").bind()
                    }}
                    error={!!resetPasswordForm.$("confirmPassword").error}
                    helpText={
                      resetPasswordForm.$("confirmPassword").error &&
                      resetPasswordForm.$("confirmPassword").value !==
                        resetPasswordForm.$("password").value
                        ? "Passwords don't match"
                        : resetPasswordForm.$("confirmPassword").error ||
                          undefined
                    }
                    labelText="Confirm password"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <div>
                <Button color="primary" size="lg" round block type="submit">
                  Submit
                </Button>
                <div className={classes.bottomText}>
                  Back to{" "}
                  <Link className={classes.registerLink} to="/auth/login-page">
                    Login
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </form>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(lockScreenPageStyle)(
  inject("authStore")(withRouter(observer(ResetPassword)))
);
