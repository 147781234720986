import {
  updateProfileApi,
  updateAccountToBusinessApi,
  getProfileApi,
  uploadAvatarApi,
  changePasswordApi,
  changeNotificationPreferencesApi,
  getNotificationPreferencesApi,
  getPaymentMethodsApi,
  addPaymentMethodApi,
  deletePaymentMethodApi,
  setDefaultPaymentMethodApi,
  getStatisticalSummaryApi,
  getUserTWProfileAPI,
  getUserTWInfoAPI,
  updateSubscribeTWDegistApi,
  updateGUProfileApi
} from "../dataServices/userData.service";

const updateProfile = data => {
  return updateProfileApi(data);
};

const updateAccountToBusiness = data => {
  return updateAccountToBusinessApi(data);
};

const getProfile = () => {
  return getProfileApi();
};

const getUserTWProfile = () => {
  return getUserTWProfileAPI();
};

const getUserTWInfo = data => {
  return getUserTWInfoAPI(data);
};

const uploadAvatar = file => {
  return uploadAvatarApi(file);
};

const changePassword = data => {
  return changePasswordApi(data);
};

const changeNotificationPreferences = data => {
  return changeNotificationPreferencesApi(data);
};

const getNotificationPreferences = () => {
  return getNotificationPreferencesApi();
};

const getPaymentMethods = () => {
  return getPaymentMethodsApi();
};

const addPaymentMethod = data => {
  return addPaymentMethodApi(data);
};

const deletePaymentMethod = id => {
  return deletePaymentMethodApi(id);
};

const setDefaultPaymentMethod = id => {
  return setDefaultPaymentMethodApi(id);
};

const getStatisticalSummary = () => getStatisticalSummaryApi();

const updateSubscribeTWDegist = (id, params) =>
  updateSubscribeTWDegistApi(id, params);

const updateGUProfile = (id, body) => updateGUProfileApi(id, body);

export {
  updateProfile,
  updateAccountToBusiness,
  getProfile,
  uploadAvatar,
  changePassword,
  changeNotificationPreferences,
  getNotificationPreferences,
  getPaymentMethods,
  addPaymentMethod,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  getStatisticalSummary,
  getUserTWProfile,
  getUserTWInfo,
  updateSubscribeTWDegist,
  updateGUProfile
};
