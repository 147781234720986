import React from "react";
import Datetime from "react-datetime";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import {
  MenuItem,
  FormLabel,
  Radio,
  FormControlLabel,
  Checkbox,
  Table,
  TableCell,
  TableRow,
  TableBody,
  InputLabel,
  FormControl
} from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import IntegrationReactSelect from "../../../components/CustomSelect/IntegrationReactSelect";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import commonStore from "../../../stores/commonStore";
import _ from 'lodash'
import { getCompleteLocation } from "../../../services/dataServices/authData.service";

const paytype = [
  { name: "Hourly Rate", value: "hourly" },
  { name: "Flat Rate/Commission", value: "flat" },
  { name: "Hourly + Commission/Performance Bonus", value: "bonus" }
];

const bringTools = [
  { name: "Yes", value: "yes" },
  { name: "No", value: "no" },
  { name: "N/A", value: "n/a" }
];

const isSaturdayWorkOptions = [
  { name: "Yes", value: "yes" },
  { name: "No", value: "no" },
  { name: "Some week-ends", value: "somewk" }
];

const isSaturdayWorkOptionsTemporary = [
  { name: "Yes", value: "yes" },
  { name: "No", value: "no" }
];

const styles = {
  dateTimeContainer: {
    "& .rdtPicker": {
      marginTop: "20px!important"
    }
  }
};

class RequestFormUI extends React.Component {
  endDateRef;

  state = {
    stToRerender: null,
    location: { city: '', state: '' }
  };

  componentDidMount() {
    if (
      this.props.requestForm.$("car_types").value &&
      this.props.requestForm.$("car_types").value.length === 0
    ) {
      this.props.requestForm.$("car_types").set(this.props.profileCarTypes);
    }
  }
  validEndDate = currentDate => {
    const startDateValue = this.props.requestForm.$("start_date").value;
    if (startDateValue) {
      const startDate = moment(startDateValue);
      return (
        currentDate.isSameOrAfter(startDate, "day") &&
        currentDate.isSameOrAfter(moment(), "day")
      );
    }
    return currentDate.isSameOrAfter(moment(), "day");
  };

  validStartDate = currentDate => {
    const endDateValue = this.props.requestForm.$("end_date").value;
    if (endDateValue) {
      const endDate = moment(endDateValue);
      return (
        currentDate.isSameOrBefore(endDate, "day") &&
        currentDate.isSameOrAfter(moment(), "day")
      );
    }
    return currentDate.isSameOrAfter(moment(), "day");
  };

  onBlurStartDate = e => {
    if (e && this.endDateRef) {
      this.setState({ stToRerender: "rerender" }, this.endDateRef.openCalendar);
    }
  };

  temporaryForm = ({ classes, requestForm }) => {
    return (
      <>
        <GridItem xs={6}>
          <div className={classes.dateTimeContainer}>
            <span style={{ display: "none" }}>
              {requestForm.$("start_date").error}
            </span>
            <Datetime
              {...requestForm.$("start_date").bind()}
              onBlur={this.onBlurStartDate}
              isValidDate={this.validStartDate}
              timeFormat={false}
              closeOnSelect={true}
              renderInput={props => (
                <CustomInput
                  labelText="Start date"
                  error={!!requestForm.$("start_date").error}
                  helpText={requestForm.$("start_date").error || undefined}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    ...props,
                    disabled: requestForm.$("start_date").disabled,
                    onClick: e => {
                      if (requestForm.$("start_date").disabled) {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              )}
            />
          </div>
        </GridItem>
        <GridItem xs={6} />
        <GridItem xs={12}>
          <GridContainer alignItems="center">
            <GridItem xs={6}>
              <div className={classes.dateTimeContainer}>
                <span style={{ display: "none" }}>
                  {requestForm.$("end_date").error}
                </span>
                <Datetime
                  ref={el => (this.endDateRef = el)}
                  {...requestForm.$("end_date").bind()}
                  isValidDate={this.validEndDate}
                  timeFormat={false}
                  closeOnSelect={true}
                  renderInput={props => (
                    <CustomInput
                      labelText="End date"
                      error={!!requestForm.$("end_date").error}
                      helpText={requestForm.$("end_date").error || undefined}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        ...props,
                        disabled: requestForm.$("end_date").disabled,
                        onClick: e => {
                          if (requestForm.$("end_date").disabled) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                  )}
                />
              </div>
            </GridItem>
            <GridItem xs={6}>
              Number of days: {requestForm.$("numberOfDays").value}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Is Saturday a work day?
              </FormLabel>
            </GridItem>
            {isSaturdayWorkOptionsTemporary.map(opt => (
              <GridItem xs={12} sm={4} key={opt.value}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          requestForm.$("expect_work_on_weekends").value ===
                          opt.value
                        }
                        onChange={
                          requestForm.$("expect_work_on_weekends").onChange
                        }
                        value={opt.value}
                        name="radio button enabled"
                        aria-label={opt.name}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        disabled={
                          requestForm.$("expect_work_on_weekends").disabled
                        }
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={opt.name}
                  />
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
        <GridItem xs={6}>
          <CustomSelect
            labelText="How many hours in a work day?"
            error={!!requestForm.$("work_day_hours").error}
            helpText={requestForm.$("work_day_hours").error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              MenuProps: {
                className: classes.selectMenu
              },
              classes: {
                select: classes.select
              },
              inputProps: {
                name: "select-in-modal",
                id: "select-in-modal"
              },
              ...requestForm.$("work_day_hours").bind()
            }}
            items={requestForm.$("work_day_hours").extra}
            itemRenderer={item => (
              <MenuItem
                key={item}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item}
              >
                {item}
              </MenuItem>
            )}
          />
        </GridItem>
        <GridItem xs={6}>
          <GridContainer>
            <GridItem xs={8}>
              <div className={classes.dateTimeContainer}>
                <span style={{ display: "none" }}>
                  {requestForm.$("work_start_time").error}
                </span>
                <Datetime
                  {...requestForm.$("work_start_time").bind()}
                  dateFormat={false}
                  timeFormat={`h:mm`}
                  timeConstraints={{
                    hours: { min: 0, max: 12 }
                  }}
                  renderInput={props => (
                    <CustomInput
                      labelText="Work start time"
                      error={!!requestForm.$("work_start_time").error}
                      helpText={
                        requestForm.$("work_start_time").error || undefined
                      }
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        ...props,
                        disabled: requestForm.$("work_start_time").disabled,
                        onClick: e => {
                          if (requestForm.$("work_start_time").disabled) {
                            e.preventDefault();
                          }
                        }
                      }}
                    />
                  )}
                />
              </div>
            </GridItem>
            <GridItem xs={4}>
              <CustomSelect
                labelText=""
                formControlProps={{
                  fullWidth: true
                }}
                selectProps={{
                  MenuProps: {
                    className: classes.selectMenu
                  },
                  classes: {
                    select: classes.select
                  },
                  inputProps: {
                    name: "select-in-modal",
                    id: "select-in-modal"
                  },
                  ...requestForm.$("work_start_time_a").bind()
                }}
                items={requestForm.$("work_start_time_a").extra}
                itemRenderer={item => (
                  <MenuItem
                    key={item}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                )}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} />
        <GridItem xs={3}>
          <CustomInput
            labelText="Rate ($/hr)"
            error={!!requestForm.$("rate").error}
            helpText={requestForm.$("rate").error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={requestForm.$("rate").bind()}
          />
        </GridItem>
        <GridItem xs={9} />
      </>
    );
  };

  fullTimeAndPartTimeForm = props => {
    const {
      handleUpdateKeyOffer,
      selectedKeyOffers = [],
      benefitOffer,
      classes,
      requestForm,
      editMode
    } = props;
    return (
      <>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Is employee expected to work week-ends?
              </FormLabel>
            </GridItem>
            {isSaturdayWorkOptions.map(opt => (
              <GridItem xs={12} sm={2} key={opt.value}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          requestForm.$("expect_work_on_weekends").value ===
                          opt.value
                        }
                        onChange={
                          requestForm.$("expect_work_on_weekends").onChange
                        }
                        value={opt.value}
                        name="radio button enabled"
                        aria-label={opt.name}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        disabled={
                          requestForm.$("expect_work_on_weekends").disabled
                        }
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={opt.name}
                  />
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
        <GridItem xs={6} />
        <GridItem xs={12}>
          <GridContainer>
            {requestForm.$("job_type").value === "full_time" ? (
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    classes.labelLeftHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  Annual Income Potential Range?
                </FormLabel>
              </GridItem>
            ) : (
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    classes.labelLeftHorizontal +
                    " " +
                    classes.labelHorizontalRadioCheckbox
                  }
                >
                  Hourly Pay Range?
                </FormLabel>
              </GridItem>
            )}

            <GridItem xs={12} sm={2}>
              <CustomInput
                labelText={
                  requestForm.$("job_type").value === "full_time"
                    ? "From $ K/yr *"
                    : "From $ /hr *"
                }
                error={!!requestForm.$("rate_from").error}
                helpText={requestForm.$("rate_from").error || undefined}
                inputProps={requestForm.$("rate_from").bind()}
              />
            </GridItem>
            <GridItem xs={12} sm={2}>
              <CustomInput
                labelText={
                  requestForm.$("job_type").value === "full_time"
                    ? "To $ K/yr *"
                    : "To $ /hr *"
                }
                error={!!requestForm.$("rate_to").error}
                helpText={requestForm.$("rate_to").error || undefined}
                inputProps={requestForm.$("rate_to").bind()}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={6} />
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Pay type?
              </FormLabel>
            </GridItem>
            {paytype.map(opt => (
              <GridItem xs={12} sm={2} key={opt.value}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={requestForm.$("paytype").value === opt.value}
                        onChange={requestForm.$("paytype").onChange}
                        value={opt.value}
                        name="radio button enabled"
                        aria-label={opt.name}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        disabled={requestForm.$("paytype").disabled}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={opt.name}
                  />
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Key Benefits Offered:
              </FormLabel>
            </GridItem>
            <GridItem>
              <Table style={{ marginTop: "10px" }} aria-labelledby="tableTitle">
                <TableBody>
                  {benefitOffer.map(item => (
                    <TableRow key={item}>
                      <TableCell>
                        <Checkbox
                          disabled={!editMode}
                          tabIndex={-1}
                          onClick={e => handleUpdateKeyOffer(item)}
                          defaultChecked={
                            selectedKeyOffers &&
                            selectedKeyOffers.includes(item)
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      </TableCell>
                      <TableCell>{item}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </GridItem>
      </>
    );
  };
  searchLocation = _.debounce(async function (e) {
    const res = await getCompleteLocation(e)
    const options = _.get(res.data, 'data', []).map(e => {
      return { value: e, label: e }
    })
    this.setState({ stateReactSelect: options })
  }, 750);
  componentDidMount() {
    const { requestForm } = this.props;
    if (requestForm) {
      this.setState({
        location: {
          city: requestForm.$("geolocation.city").value,
          state: requestForm.$("geolocation.state").value
        }
      })
    }
  }

  render() {
    const {
      requestForm,
      classes,
      carTypes,
      functions,
      autotechLevels,
      jobTypes,
      bodyTechTypes,
      handleUpdateKeyOffer,
      selectedKeyOffers = [],
      benefitOffer,
      handleUpdateLocation,
      location,
      editMode
    } = this.props;
    const { state } = commonStore;
    const stateReactSelect = state.map(item => ({ label: item, value: item }));

    const carTypesReactSelect = carTypes.map(item => ({
      label: item,
      value: item
    }));

    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomSelect
            labelText="Job type *"
            error={!!requestForm.$("job_type").error}
            helpText={requestForm.$("job_type").error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              MenuProps: {
                className: classes.selectMenu
              },
              classes: {
                select: classes.select
              },
              inputProps: {
                name: "select-in-modal",
                id: "select-in-modal"
              },
              ...requestForm.$("job_type").bind()
            }}
            items={jobTypes}
            itemRenderer={item => (
              <MenuItem
                key={item.value}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                disabled={
                  item.disabled ||
                  requestForm.$("job_type").value === item.value
                }
                value={item.value}
              >
                {item.name}
              </MenuItem>
            )}
          />
        </GridItem>
        <GridItem xs={12}>
          <CustomSelect
            labelText="Function *"
            error={!!requestForm.$("function").error}
            helpText={requestForm.$("function").error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            selectProps={{
              MenuProps: {
                className: classes.selectMenu
              },
              classes: {
                select: classes.select
              },
              inputProps: {
                name: "select-in-modal",
                id: "select-in-modal"
              },
              ...requestForm.$("function").bind()
            }}
            items={functions}
            itemRenderer={item => (
              <MenuItem
                key={item.value}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            )}
          />
        </GridItem>
        {requestForm.$("function").value === "other" && (
          <GridItem xs={12}>
            <CustomInput
              labelText="Other function *"
              error={!!requestForm.$("other_function").error}
              helpText={requestForm.$("other_function").error || undefined}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={requestForm.$("other_function").bind()}
            />
          </GridItem>
        )}
        <GridItem xs={12}>
          {requestForm.$("autotech_level").extra &&
            requestForm.$("autotech_level").extra.isShow && (
              <CustomSelect
                labelText="Level *"
                error={!!requestForm.$("autotech_level").error}
                helpText={requestForm.$("autotech_level").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                selectProps={{
                  MenuProps: {
                    className: classes.selectMenu
                  },
                  classes: {
                    select: classes.select
                  },
                  inputProps: {
                    name: "select-in-modal",
                    id: "select-in-modal"
                  },
                  ...requestForm.$("autotech_level").bind()
                }}
                items={autotechLevels}
                itemRenderer={item => (
                  <MenuItem
                    key={item}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                )}
              />
            )}
        </GridItem>
        <GridItem xs={12}>
          {requestForm.$("bodytech_type").extra &&
            requestForm.$("bodytech_type").extra.isShow && (
              <CustomSelect
                labelText="Type *"
                error={!!requestForm.$("bodytech_type").error}
                helpText={requestForm.$("bodytech_type").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                selectProps={{
                  MenuProps: {
                    className: classes.selectMenu
                  },
                  classes: {
                    select: classes.select
                  },
                  inputProps: {
                    name: "select-in-modal",
                    id: "select-in-modal"
                  },
                  ...requestForm.$("bodytech_type").bind()
                }}
                items={bodyTechTypes}
                itemRenderer={item => (
                  <MenuItem
                    key={item}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                )}
              />
            )}
        </GridItem>
        <GridItem xs={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Years of Experience *
            </InputLabel>
            <Input inputProps={requestForm.$("years_of_experience").bind()} />
            <FormHelperText id={"-text"} className={classes.labelRootError}>
              {requestForm.$("years_of_experience").error || undefined}
            </FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem xs={9} />
        <GridItem xs={12} sm={12} md={12} lg={10} style={{ margin: "20px 0" }}>
          <InputLabel>Job Location City</InputLabel>
          <IntegrationReactSelect
            // label={"City"}
            id="city-select"
            // error={!!requestForm.$("geolocation.city").error}
            // helpText={requestForm.$("geolocation.city").error || undefined}
            // options={requestForm.$("geolocation.city").extra}
            options={this.state.stateReactSelect}
            // value={requestForm.$("geolocation.city").value}
            value={{ label: this.state.location.city, value: this.state.location.city }}
            // onChange={requestForm.$("geolocation.city").onChange}
            onChange={(e) => {
              let city
              let state
              if (!e) {
                city = ""
                state = ""
              } else {
                const locationList = e.value.split(',').reverse()
                city = locationList[1].trim()
                state = locationList[0].trim()
              }
              this.setState({
                location: {
                  city: city,
                  state: state
                }
              })
              handleUpdateLocation({ city: city, state: state })
            }}
            handleInputChange={e => {
              if (e.trim() === '') return
              this.searchLocation(e)
            }}
            placeholder={"Select City"}
            editMode={editMode}
            isClearable={editMode}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <InputLabel>Job Location State</InputLabel>
          <IntegrationReactSelect
            // label={"State"}
            id="state-select"
            // error={!!requestForm.$("geolocation.state").error}
            // helpText={requestForm.$("geolocation.state").error || undefined}
            // options={stateReactSelect}
            options={this.state.stateReactSelect}
            // value={requestForm.$("geolocation.state").value}
            value={{ label: this.state.location.state, value: this.state.location.state }}
            // onChange={requestForm.$("geolocation.state").onChange}
            onChange={(e) => {
              let city
              let state
              if (!e) {
                city = ""
                state = ""
              } else {
                const locationList = e.value.split(',').reverse()
                city = locationList[1].trim()
                state = locationList[0].trim()
              }
              this.setState({
                location: {
                  city: city,
                  state: state
                }
              })
              handleUpdateLocation({ city: city, state: state })
            }}
            handleInputChange={e => {
              if (e.trim() === '') return
              this.searchLocation(e)
            }}
            placeholder={"Select State"}
            editMode={editMode}
            isClearable={editMode}
            selectProps={{ ...requestForm.$("geolocation.state").bind() }}
          />
        </GridItem>
        <GridItem xs={12}>
          {requestForm.$("car_types").disabled ? (
            <CustomSelect
              labelText="Car Types"
              formControlProps={{
                fullWidth: true
              }}
              selectProps={{
                MenuProps: {
                  className: classes.selectMenu
                },
                classes: {
                  select: classes.select
                },
                inputProps: {
                  name: "select-in-modal",
                  id: "select-in-modal"
                },
                multiple: true,
                ...requestForm.$("car_types").bind()
              }}
              items={carTypes}
              itemRenderer={item => (
                <MenuItem
                  key={item}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item}
                >
                  {item}
                </MenuItem>
              )}
            />
          ) : (
            <IntegrationReactSelect
              id="car-type-select"
              label={"Car type *"}
              multiple
              options={carTypesReactSelect}
              value={requestForm.$("car_types").value}
              onChange={requestForm.$("car_types").onChange}
              placeholder={"Select car type"}
            />
          )}
        </GridItem>
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={3}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Must Bring Tools?
              </FormLabel>
            </GridItem>
            {bringTools.map(opt => (
              <GridItem xs={12} sm={2} key={opt.value}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          requestForm.$("must_bring_tools").value === opt.value
                        }
                        onChange={requestForm.$("must_bring_tools").onChange}
                        value={opt.value}
                        name="radio button enabled"
                        aria-label={opt.name}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                        disabled={requestForm.$("must_bring_tools").disabled}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={opt.name}
                  />
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
        {requestForm.$("job_type").value === "temporary"
          ? this.temporaryForm({ classes, requestForm })
          : this.fullTimeAndPartTimeForm({
            handleUpdateKeyOffer,
            selectedKeyOffers,
            benefitOffer,
            classes,
            requestForm,
            editMode
          })}
        <GridItem xs={12}>
          <CustomInput
            labelText="Qualifications, Comments &  Additional Benefits *"
            error={!!requestForm.$("additional_comment").error}
            helpText={requestForm.$("additional_comment").error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              ...requestForm.$("additional_comment").bind(),
              multiline: true
            }}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles({
  ...customInputStyle,
  ...extendedFormsStyle,
  ...regularFormsStyle,
  ...styles
})(observer(RequestFormUI));
