import { Form } from "mobx-react-form";
import plugins from "./plugins";
import * as moment from "moment";
import { getDiffDays } from "services/utils";
import stores from "..";

const rateFrom = {
  name: "rate_from",
  placeholder: "Enter rate",
  label: "From $ K/yr",
  rules: "required|numeric|min:0|max:999",
  hooks: {
    onChange: field => {
      field.validate();
    }
  }
};

const rateTo = {
  name: "rate_to",
  placeholder: "Enter rate",
  label: "To $ K/yr",
  rules: "required|numeric|min:0|max:999",
  hooks: {
    onChange: field => {
      field.validate();
    }
  }
};

const startDate = {
  name: "start_date",
  placeholder: "Choose start date",
  rules: "required",
  hooks: {
    onChange: field => {
      const startDate = moment(field.value);
      const endDate = moment(this.$("end_date").value);
      this.$("numberOfDays").set(
        getDiffDays(
          endDate,
          startDate,
          this.$("expect_work_on_weekends").value === "yes"
        )
      );
      field.validate();
    }
  }
};

const endDate = {
  name: "end_date",
  placeholder: "Choose end date",
  rules: "required",
  hooks: {
    onChange: field => {
      const endDate = moment(field.value);
      const startDate = moment(this.$("start_date").value);
      this.$("numberOfDays").set(
        getDiffDays(
          endDate,
          startDate,
          this.$("expect_work_on_weekends").value === "yes"
        )
      );
      field.validate();
    }
  }
};

const workStartTime = {
  name: "work_start_time",
  placeholder: "Choose work start time",
  rules: "required",
  hooks: {
    onChange: field => {
      field.validate();
    }
  }
};

const rate = {
  name: "rate",
  placeholder: "Enter rate",
  label: "Rate",
  rules: "required|numeric|min:0",
  hooks: {
    onChange: field => {
      field.validate();
    }
  }
};

export default class RequestForm extends Form {
  plugins() {
    return plugins;
  }

  setup() {
    return {
      fields: [
        { name: "id", value: "" },
        {
          name: "function",
          placeholder: "Enter function",
          rules: "required",
          value: "automotive_foreman",
          hooks: {
            onChange: field => {
              field.validate();
              if (field.value === "automotive_tech") {
                this.$("autotech_level").set("rules", "required");
                this.$("autotech_level").set("extra", {
                  isShow: true
                });
                this.$("bodytech_type").reset();
                this.$("bodytech_type").resetValidation();
                this.$("bodytech_type").set("extra", null);
                this.$("bodytech_type").set("rules", null);
              } else if (field.value === "body_technician") {
                this.$("bodytech_type").set("rules", "required");
                this.$("bodytech_type").set("extra", {
                  isShow: true
                });
                this.$("autotech_level").reset();
                this.$("autotech_level").resetValidation();
                this.$("autotech_level").set("extra", null);
                this.$("autotech_level").set("rules", null);
              } else {
                this.$("autotech_level").reset();
                this.$("bodytech_type").reset();
                this.$("autotech_level").set("extra", null);
                this.$("bodytech_type").set("extra", null);
                this.$("autotech_level").set("rules", null);
                this.$("bodytech_type").set("rules", null);
                this.$("bodytech_type").resetValidation();
                this.$("autotech_level").resetValidation();
              }
            }
          }
        },
        {
          name: "other_function",
          placeholder: "Other function",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "autotech_level",
          placeholder: "Enter level",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "bodytech_type",
          placeholder: "Enter type",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "job_type",
          placeholder: "Enter job type",
          rules: "required",
          value: "full_time",
          hooks: {
            onChange: field => {
              field.validate();
              const { value } = field;
              this.reset();
              this.$("autotech_level").resetValidation();
              this.$("autotech_level").set("extra", null);
              this.$("autotech_level").set("rules", null);
              this.$("bodytech_type").resetValidation();
              this.$("bodytech_type").set("extra", null);
              this.$("bodytech_type").set("rules", null);
              field.value = value;
              this.$("must_bring_tools").value = "yes";
              this.$("work_day_hours").value = 8;
              if (field.value === "temporary") {
                !this.has("rate") && this.add(rate);
                !this.has("end_date") && this.add(endDate);
                !this.has("work_start_time") && this.add(workStartTime);
                !this.has("start_date") && this.add(startDate);
              } else {
                !this.has("rate_from") && this.add(rateFrom);
                !this.has("rate_to") && this.add(rateTo);
              }
            }
          }
        },
        {
          name: "years_of_experience",
          placeholder: "Enter years of experience",
          type: "number",
          label: "Years of Experience",
          hooks: {
            onChange: field => {
              field.validate();
            }
          },
          rules: "required|integer|min:0"
        },
        {
          name: "geolocation",
          label: "Geolocation",
          fields: [
            {
              name: "state",
              //   rules: "required",
              placeholder: "Select State",
              handlers: {
                onChange: field => value => {
                  field.set(value);
                }
              },
              hooks: {
                onChange: field => {
                  if (!field.value) {
                    this.$("geolocation.state").reset();
                  }
                  this.$("geolocation.city").set("extra", []);
                  if (this.$("geolocation.city").value) {
                    this.$("geolocation.city").reset();
                  }

                  const { value } = field;
                  if (value && value.value) {
                    stores.commonStore
                      .getCitiesByState(value.value)
                      .then(data => {
                        this.$("geolocation.city").set(
                          "extra",
                          data.map(item => ({
                            label: item,
                            value: item
                          }))
                        );
                      });
                  }
                  field.validate();
                }
              }
            },
            {
              name: "city",
              //   rules: "required",
              placeholder: "Select City",
              handlers: {
                onChange: field => value => {
                  field.set(value);
                }
              },
              hooks: {
                onChange: field => {
                  field.validate();
                }
              },
              extra: []
            }
          ]
        },

        {
          name: "car_types",
          value: []
        },
        {
          name: "must_bring_tools",
          type: "radio",
          value: "yes"
        },
        {
          name: "expect_work_on_weekends",
          type: "radio",
          value: "no",
          hooks: {
            onChange: field => {
              if (this.$("job_type").value === "temporary") {
                const startDate = moment(this.$("start_date").value);
                const endDate = moment(this.$("end_date").value);
                this.$("numberOfDays").set(
                  getDiffDays(endDate, startDate, field.value === "yes")
                );
              }
              field.validate();
            }
          }
        },
        {
          name: "work_day_hours",
          value: 8,
          extra: new Array(24).fill(1).map((value, idx) => idx + 1),
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "start_date",
          placeholder: "Choose start date",
          rules: "required",
          hooks: {
            onChange: field => {
              const startDate = moment(field.value);
              const endDate = moment(this.$("end_date").value);
              this.$("numberOfDays").set(
                getDiffDays(
                  endDate,
                  startDate,
                  this.$("expect_work_on_weekends").value === "yes"
                )
              );
              field.validate();
            }
          }
        },
        {
          name: "end_date",
          placeholder: "Choose end date",
          rules: "required",
          hooks: {
            onChange: field => {
              const endDate = moment(field.value);
              const startDate = moment(this.$("start_date").value);
              this.$("numberOfDays").set(
                getDiffDays(
                  endDate,
                  startDate,
                  this.$("expect_work_on_weekends").value === "yes"
                )
              );
              field.validate();
            }
          }
        },
        {
          name: "numberOfDays",
          type: "radio",
          value: ""
        },
        {
          name: "work_start_time",
          placeholder: "Choose work start time",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "work_start_time_a",
          value: "AM",
          extra: ["AM", "PM"]
        },
        {
          name: "rate",
          placeholder: "Enter rate",
          label: "Rate",
          rules: "required|numeric|min:0",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "rate_from",
          placeholder: "Enter rate",
          rules: "required|numeric|min:0|max:999",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "rate_to",
          placeholder: "Enter rate",
          rules: "required|numeric|min:0|max:999",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "paytype",
          type: "radio",
          value: "flat"
        },
        {
          name: "additional_comment",
          placeholder: "Enter additional comment",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "status",
          rules: "required"
        }
      ]
    };
  }
}
