import {
  cardTitle,
  container,
  whiteColor,
  blackColor,
  hexToRgb,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const lockScreenPageStyle = theme => ({
  cardTitle,
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  customCardClass: {
    width: "400px",
    margin: "60px auto 0",
    padding: '15px 10px',
    color: whiteColor,
    display: "block",
    transform: "translate3d(" + hexToRgb(blackColor) + ")",
    transition: "all 300ms linear"
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardAvatar: {
    maxWidth: "90px",
    maxHeight: "90px",
    marginTop: "-45px"
  },
  customCardFooterClass: {
    border: "none",
    paddingTop: "0"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  bottomText: {
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: '400',
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  notifyText: {
    marginTop: '15px',
    fontSize: '16px',
    fontWeight: '400',
    color: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
  }, 
  passwordHint: {
    color: primaryColor[0]
  },
});

export default lockScreenPageStyle;
