// @material-ui/core components
import { Link } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import otpPageStyle from "assets/jss/material-dashboard-pro-react/views/otpPageStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import notificationService from "../services/notificationService";

const OnBoardingOtp = props => {
  const [otp, setOtp] = useState();
  const [counter, setCounter] = useState(59);
  const [hour, setHour] = useState(4);
  const [disabled, setDisabled] = useState(true);
  const [expriedTimeOtp, setExpriedTimeOtp] = useState(false);
  const { history } = props;

  const handleChangeOtp = otp => {
    setOtp(otp);
    checkDisabledButtonSubmit(otp);
  };
  function checkDisabledButtonSubmit(otp) {
    if (otp.length > 5) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      if (hour > 0) {
        setTimeout(() => setHour(hour - 1), 1000);
        setTimeout(() => setCounter(59), 1000);
      } else {
        setExpriedTimeOtp(true);
        setDisabled(true);
      }
    }
    return () => clearInterval(timer);
  }, [counter]);

  const { classes } = props;
  const { verifyOtp, sendOtp } = props.authStore;
  // const isEmailFormat = localStorage.getItem("isEmailFormat");

  let contents = "Enter Code sent to the email you entered";
  const handleSubmitOtp = () => {
    const otpEntryId = localStorage.getItem("otpEntryId");
    const user = JSON.parse(localStorage.getItem("user"));
    verifyOtp({
      otpEntryId: otpEntryId,
      otp: otp,
      mobileNumber: user.mobileNumber,
      email: user.email
    })
      .then(res => {
        if (!res.userId) {
          throw res;
        }
        notificationService.success("Verified successfully. Please login.");
        history.push("/auth/login-page");
      })
      .catch(err => {
        console.log("handleSubmitOtp error: ", err);
        // notificationService.error("OTP is not correct!");
      });
  };

  const resendOtp = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    sendOtp({
      mobileNumber: user.mobileNumber,
      email: user.email
    }).then(sendOtpRes => {
      localStorage.setItem("otpEntryId", sendOtpRes.otpEntryId);
    });
    resetOtpTimer();
  };

  const resetOtpTimer = () => {
    setDisabled(false);
    setExpriedTimeOtp(false);
    setHour(4);
    setCounter(59);
  };
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={4}>
          <Card>
            <CardBody>
              <GridItem>
                <img
                  className={classes.icon}
                  src={require("./../assets/img/letters.png")}
                  alt=""
                />
                <div className={classes.cardTitle}>{contents}</div>
                <div className={classes.justifyContentCenter}>
                  <div className={classes.timer}>
                    0{hour}:{counter < 10 && "0"}
                    {counter}
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <OtpInput
                  value={otp}
                  isDisabled={expriedTimeOtp}
                  onChange={handleChangeOtp}
                  numInputs={6}
                  inputStyle={{
                    fontSize: "24px",
                    width: "100%",
                    height: "36px",
                    margin: "4px",
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    outline: "none",
                    borderColor: "#000a46",
                    borderBottomColor: "#ff9800",
                    fontWeight: "bold"
                  }}
                  containerStyle={{
                    margin: "20px auto",
                    padding: "10px"
                  }}
                  isInputNum
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  size="sm"
                  fullWidth
                  round
                  color="primary"
                  onClick={handleSubmitOtp}
                  disabled={disabled}
                >
                  Submit
                </Button>
              </GridItem>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <div>
                <div className={classes.bottomText}>
                  Didn't receive a Code ? &nbsp;
                  <Link
                    onClick={resendOtp}
                    className={classes.sendAgainLink}
                    style={{ pointerEvents: expriedTimeOtp ? "" : "none" }}
                  >
                    Resend
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const OnBoardingOtpPage = inject(stores => ({
  authStore: stores.authStore
}))(OnBoardingOtp);

export default withStyles(otpPageStyle)(observer(OnBoardingOtpPage));
