import React from "react";
import { withRouter } from 'react-router-dom';
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';

// react component for creating dynamic tables
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Tooltip, Divider } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Help from "@material-ui/icons/Help";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Loading from "components/Loading/Loading";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import notificationService from "services/notificationService";
import { getFieldFromListByKey } from 'services/utils';
import PaymentModal from "components/PaymentModal";
import { getDiffDays } from "../../services/utils";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  dflex: {
    display: 'flex',
  },
  ml5: {
    marginLeft: '5px'
  },
  mr5: {
    marginRight: '5px'
  },
  tableCell: {
      fontSize: '1rem',
      padding: '4px 20px'
  },
  ml10: {
    marginLeft: '10px'
  },
  mbt10: {
    marginBottom: '10px',
    marginTop: '10px'
  },
  label: {
    textAlign: 'left',
    fontWeight: 'bold'
  },
  value: {
    textAlign: 'left'
  },
  tooltip: {
    zIndex: 20000
  },
  actionsCol: {
    width: '220px'
  }
};


const rows = [
  { id: 'listing_id', numeric: false, disablePadding: false, label: 'Listing ID' },
  // { id: 'name', numeric: false, disablePadding: false, label: 'Employee name' },
  { id: 'function', numeric: false, disablePadding: false, label: 'Function' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
  { id: 'start_date', numeric: false, disablePadding: false, label: 'Date available' },
  { id: 'end_date', numeric: false, disablePadding: false, label: 'Date applied' },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

class Applications extends React.Component {
  state = {
    confirmRejectModal: null,
    confirmAcceptModal: null,
    paymentModal: null
  }

  componentDidMount() {
    this.props.userStore.getPaymentMethods();
    this.getApplicationsList();
  }

  getApplicationsList = () => {
    const { getApplicationsList, pagination } = this.props.applicationsStore;
    const { pageSize, page, sort, order } = pagination;
    getApplicationsList(pageSize, page, sort, order);
  }

  handleChangePage = (e, page) => {
    this.props.applicationsStore.changePageOfPagination(page);
    this.getApplicationsList();
  }

  handleChangePageSize = (e) => {
    this.props.applicationsStore.changePageSizeOfPagination(e.target.value);
    this.getApplicationsList();
  }

  handleSort = sort => () => {
    this.props.applicationsStore.changeSortOfPagination(sort);
    this.getApplicationsList();
  }

  acceptApplication = id => () => {
    if (this.props.userStore.hasPaymentMethod()) {
      this.handleAcceptApplication(id)();
    } else {
      this.setState({
        paymentModal: <PaymentModal addPaymentMethod={this.addPaymentMethod} closePaymentModal={this.closePaymentModal} selectPayment={this.handleAcceptApplication(id)} />
      });
    }
  }

  handleAcceptApplication = id => () => {
    const { acceptApplication } = this.props.applicationsStore;
    acceptApplication(id).then(({ data, ok }) => {
      this.closeConfirmAcceptModal();
      this.closePaymentModal();
      if (ok) {
        notificationService.success('APPLICATION.PURCHASE_SUCCESS');
        return this.getApplicationsList();
      }
      if (data && data.payment_method_id && data.client_secret) {
        this.props.stripe.handleCardPayment(data.client_secret, {
          payment_method: data.payment_method_id,
        }).then((result) => {
          if (result.error) {
            // Display error.message in your UI.
            notificationService.error(result.error.message);
          } else {
            notificationService.success('APPLICATION.PURCHASE_SUCCESS');
            return this.getApplicationsList();
          }
        })
      } else {
        this.setState({
          paymentModal: <PaymentModal addPaymentMethod={this.addPaymentMethod} closePaymentModal={this.closePaymentModal} selectPayment={this.selectPayment(data.transaction)} />
        });
      }
    });
  }

  addPaymentMethod = (id) => {
    return this.props.userStore.addPaymentMethod(id).then(() => {
      notificationService.success('APPLICATION.ADD_CARD_SUCCESS');
      this.props.userStore.getPaymentMethods();
      return Promise.resolve();
    });
  }

  selectPayment = transactionId => (card) => {
    this.props.transactionStore.repayTransaction(transactionId, { payment_method_id: card.id }).then(() => {
      notificationService.success('APPLICATION.PURCHASE_SUCCESS');
      this.closePaymentModal();
    })
  }

  closePaymentModal = () => {
    this.setState({ paymentModal: null });
  }

  showConfirmAcceptModal = (row) => (e) => {
    e.stopPropagation();
    const { classes } = this.props;
    const { functions, autotechLevels } = this.props.commonStore;
    const { timeZone } = this.props.userStore;
    const { employee_request, id } = row;
    const startDateMoment = moment(employee_request.start_date).tz(timeZone);
    const endDateMoment = moment(employee_request.end_date).tz(timeZone);
    const diffDays = getDiffDays(endDateMoment, startDateMoment, employee_request.expect_work_on_weekends);
    const { getPlatformFee } = this.props.commonStore;
    const { getTransactionFee } = this.props.commonStore;
    const transactionFee = getTransactionFee();
    const isTempoJob = employee_request.job_type === 'temporary' ? true : false;
    let platformFee = getPlatformFee(employee_request.rate, diffDays, employee_request.work_day_hours);
    this.setState({
      confirmAcceptModal: <SweetAlert
        warning
        title=""
        style={{ 
          display: "revert", 
          width: '55%', fontSize: '0.7rem', 
          top: '15%', maxHeight: '80%', left: '25%', 
          marginLeft: 'auto !important', 
          marginRight: 'auto !important', 
          marginTop: 'auto !important', 
          overflowY: 'auto' }}
        onConfirm={this.acceptApplication(id)}
        onCancel={this.closeConfirmAcceptModal}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
        cancelBtnCssClass={
          classes.button + " " + classes.danger
        }
        confirmBtnText="Yes"
        cancelBtnText="No"
        showCancel
      >
        <h3>Want to Contact This Applicant?</h3>
        <GridContainer>
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Function:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {getFieldFromListByKey(functions, 'name', 'value', employee_request.function)}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Level/Type:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {getFieldFromListByKey(autotechLevels, 'name', 'value', employee_request.autotech_level)}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Years of Experience:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {employee_request.years_of_experience}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}></GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={3} className={classes.label}>
                Car Types:
            </GridItem>
              <GridItem xs={9} className={classes.value}>
                {employee_request.car_types && employee_request.car_types.join(', ')}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Must Bring Tools:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {employee_request.must_bring_tools ? 'Yes' : 'No'}
              </GridItem>
            </GridContainer>
          </GridItem>
          {/* <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Work process:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {getFieldFromListByKey(workProcesses, 'name', 'id', employee_request.work_process)}
              </GridItem>
            </GridContainer>
          </GridItem> */}
          <GridItem xs={6} display={employee_request.job_type === 'temporary'}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Start date:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {timeZone && moment(employee_request.start_date).tz(timeZone).format('MMM DD YYYY')}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={6} display={employee_request.job_type === 'temporary'}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                End date:
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {timeZone && moment(employee_request.end_date).tz(timeZone).format('MMM DD YYYY')}
              </GridItem>
            </GridContainer>
          </GridItem>
            {employee_request.job_type === 'temporary' && <GridItem xs={6}>
                <GridContainer>
                    <GridItem xs={6} className={classes.label}>
                        Work start time:
                    </GridItem>
                    <GridItem xs={6} className={classes.value}>
                        {`${employee_request.work_start_time.h} ${employee_request.work_start_time.a}`}
                    </GridItem>
                </GridContainer>
            </GridItem>}  
          <GridItem xs={6}>
            <GridContainer>
              <GridItem xs={6} className={classes.label}>
                Rate ($/hr):
            </GridItem>
              <GridItem xs={6} className={classes.value}>
                {employee_request.rate}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <GridContainer>
              <GridItem xs={3} className={classes.label}>
                Special Instructions / Requirements:
            </GridItem>
              <GridItem xs={9} className={classes.value}>
                {employee_request.instructions}
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <Divider className={classes.mbt10} />
          </GridItem>
            {
                isTempoJob ?
                    <GridItem xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.5rem' }}>
                            <h5>Estimated Pay to Employee: ${employee_request.rate * 8 * diffDays} </h5>
                            <Tooltip classes={{
                                popper: classes.tooltip
                            }} title="This is calculated based on an employee working 8hrs/day for the number of days indicated. This amount may vary if the employee works fewer or longer hours per day. You pay the employee directly at your business after he completes his work."><Help style={styles.ml10} /></Tooltip>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '0.5rem' }}>
                            <h5>Platform Fee to hire this candidate: ${platformFee} </h5>
                            <Tooltip classes={{
                                popper: classes.tooltip
                            }} title="This is the fee we collect to develop and maintain the platform as well as market and solicit candidates to join and get connected with you. Our business can’t survive without it, the same way your business can’t survive by offering free car repairs. We appreciate your support. Note that this fee will be refunded in case of a “No Show” and may be partially refunded if you cancel after the 1st day of work."><Help style={styles.ml10} /></Tooltip>
                        </div>
                    </GridItem> 
                    :
                    <GridItem xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <h5>You will be charged a ${transactionFee} transaction fee to see this applicant's contact information and s/he will be notified of your interest in interviewing him/her </h5>
                        </div>
                    </GridItem>
            }
          
          <GridItem container xs={12}>
            <label style={{fontSize: '1.2rem'}}>By clicking Yes, you accept the Mechanics & Body Shops Marketplace Terms and Conditions of Use</label>
          </GridItem>
        </GridContainer>
      </SweetAlert>
    })
  }

  closeConfirmAcceptModal = () => {
    this.setState({
      confirmAcceptModal: null
    })
  }

  rejectApplication = (id) => () => {
    this.props.applicationsStore.rejectApplication(id).then(() => {
      this.closeConfirmRejectModal();
      this.getApplicationsList();
      notificationService.success('APPLICATION.REJECTED_APPLICATION');
    });
  }

  showConfirmRejectModal = (id) => (e) => {
    e.stopPropagation();
    this.setState({
      confirmRejectModal: <SweetAlert
        warning
        title=""
        onConfirm={this.rejectApplication(id)}
        onCancel={this.closeConfirmRejectModal}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Yes"
        cancelBtnText="No"
        showCancel
      >
        Are you sure to reject this application ?
  </SweetAlert>
    })
  }

  closeConfirmRejectModal = () => {
    this.setState({
      confirmRejectModal: null
    })
  }

  setCurrentApplication = app => () => {
    this.props.history.push(`/applications/${app.id}`);
  }

  render() {
    const { classes } = this.props;
    const { applicationsPagination, pagination, isLoading } = this.props.applicationsStore;
    const { functions } = this.props.commonStore;
    const { timeZone } = this.props.userStore;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Applications</h4>
            </CardHeader>
            <CardBody>
              <div style={{ overflow: 'auto' }}>
                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle">
                  <TableHead>
                      <TableRow>
                        {rows.map(
                        row => (
                            <TableCell
                            key={row.id}
                            className={row.id === 'action' ? classes.actionsCol : classes.tableCell}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={pagination.sort === row.id ? pagination.order : false}
                            >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                active={pagination.sort === row.id}
                                direction={pagination.order}
                                onClick={this.handleSort(row.id)}
                                >
                                {row.label}
                                </TableSortLabel>
                            </Tooltip>
                            </TableCell>
                        ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {applicationsPagination.items.map(n => {
                      return (
                        <React.Fragment
                          key={n.id}
                        >
                          <Tooltip title=''>
                            <TableRow
                              hover
                              tabIndex={-1}
                              onClick={this.setCurrentApplication(n)}
                            >
                              <TableCell
                                className={classes.tableCell}
                              >
                                {n.listing_id}
                              </TableCell>
                              {/*<TableCell*/}
                              {/*  className={classes.tableCell}*/}
                              
                              {/*>*/}
                              {/*  {`${n.job_seeker.profile.first_name || ''} ${n.job_seeker.profile.last_name || ''}`}*/}
                              {/*</TableCell>*/}
                              <TableCell
                                className={classes.tableCell}

                              >
                                {getFieldFromListByKey(functions, 'name', 'value', n.employee_request.function)}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}

                              >
                                ${n.employee_request.rate}/hr
                              </TableCell>
                                {n.job_type === 'temporary' ?
                                    <TableCell className={classes.tableCell}>
                                        {`${timeZone && moment(n.employee_request.start_date).tz(timeZone).format('MMM DD YYYY')} to ${timeZone && moment(n.employee_request.end_date).tz(timeZone).format('MMM DD YYYY')}`}
                                    </TableCell> : <TableCell />}
                              <TableCell
                                className={classes.tableCell}
                              >
                                {moment(n.created_at).format('MMM DD YYYY')}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                <div className={classes.dflex}>
                                  <Button color="primary" size='sm' className={classes.mr5} onClick={this.showConfirmAcceptModal(n)}>Reveal/Hire</Button>
                                  <Button size='sm' className={classes.ml5} onClick={this.showConfirmRejectModal(n.id)}>Reject</Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          </Tooltip>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>}
                {this.state.confirmRejectModal}
                {this.state.confirmAcceptModal}
                {this.state.paymentModal}
              </div>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={applicationsPagination.totalItem}
                rowsPerPage={pagination.pageSize}
                page={pagination.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangePageSize}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const EnhancedApplications = injectStripe(withStyles({ ...extendedFormsStyle, ...styles })(inject('applicationsStore', 'commonStore', 'userStore', 'transactionStore')(withRouter(observer(Applications)))));

class WrapperApplications extends React.Component {
  render() {
    const { appConfig } = this.props.commonStore;
    return <StripeProvider apiKey={appConfig && appConfig.stripe.api_key}>
      <Elements><EnhancedApplications /></Elements>
    </StripeProvider>
  }
}

export default inject('commonStore')(observer(WrapperApplications));