import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class FeedbackForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "can_contact",
                    type: "radio",
                },
                {
                    name: "recommendation_rate",
                    value: 0
                },
                {
                    name: "easy_of_use_rate",
                    value: 0
                },
                {
                    name: "comment",
                    value: ''
                },
                {
                    name: 'submitting',
                    value: false
                }
            ]
        };
    }
}
