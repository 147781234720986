import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withStyles } from '@material-ui/core';
import Rating from 'react-rating';

import FiberManualRecordOutlined from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const styles = {
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    icon: {
        color: '#ff9800'
    }
};
class ServiceAdvisorProfile extends React.Component {
    render() {
        const { classes, skills } = this.props;
        let { skills_level, software, other_software } = skills;
        if (!skills_level) skills_level = {};
        return <React.Fragment>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years Of Combined Experience as a Service Writer/Advisor and Manager:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_combined_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years Of Experience As a Technician/Mechanic:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_technician_mechanic_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <div className={classes.label}>Skills:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Job Estimation & Pricing:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.job_estimation_and_pricing}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Parts Ordering:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.parts_ordering}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Working with Suppliers:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.working_with_suppliers}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Scheduling Jobs & Workload:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.scheduling_jobs}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Interfacing with Technicians:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.interfacing_with_technician}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Tracking & Managing Customer Satisfaction:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.tracking_managing_customer_satisfaction}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Customer Support:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.customer_support}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Advising Customers On Services Needed:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.advising_customers}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Computer Skills:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.computer}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Operating POS:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.operating_pos}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Shop Marketing:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.shop_marketing}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Negotiations:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.negotiations}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Organization:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.organization}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.label}>Proficiency With Shop Management Software:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>ADP:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.adp}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>AllData:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.all_data}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>CDK:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.cdk}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Identifix:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.identifix}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Mitchell1:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.mitchell1}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Reynold & Reynolds:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.reynold_reynolds}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>ShopKey:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.shop_key}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Other:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.other}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Other Software:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{other_software}</p>
                </GridItem>
            </GridContainer>
        </React.Fragment>;
    }
}

export default withStyles(styles)(ServiceAdvisorProfile);