import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { observer } from 'mobx-react';

const styles = {
    passwordHint: {
        color: '#F8981F'
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

class Account extends React.Component {
    render() {

        const { form, classes, submit, toggleShowCurrentPassword, toggleShowNewPassword,toggleShowRetypeNewPassword, submitting } = this.props;
        return <form onSubmit={submit}>
            <GridItem xs={12} sm={12}>
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.inputAdornment}
                                onClick={toggleShowCurrentPassword}
                            >
                                {form.$("current_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                    <VisibilityOff className={classes.inputAdornmentIcon} />}
                            </InputAdornment>
                        ),
                        ...form.$('current_password').bind()
                    }}
                    error={!!form.$('current_password').error}
                    helpText={form.$('current_password').error || undefined}
                    labelText='Current Password'
                />
            </GridItem>
            <GridItem xs={12} sm={12}>
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.inputAdornment}
                                onClick={toggleShowNewPassword}
                            >
                                {form.$("new_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                    <VisibilityOff className={classes.inputAdornmentIcon} />}
                            </InputAdornment>
                        ),
                        ...form.$('new_password').bind()
                    }}
                    error={!!form.$('new_password').error}
                    helpText={form.$('new_password').error || undefined}
                    labelText='New password'
                />
                <div className={classes.passwordHint}>Password must contain at least 8 characters, one lower case letter, one upper case letter, one number and one of the following symbols: !, @, #, $, %, &, *</div>
            </GridItem>
            <GridItem xs={12} sm={12}>
                <CustomInput
                    formControlProps={{
                        fullWidth: true,
                        className: classes.customFormControlClasses
                    }}
                    inputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                className={classes.inputAdornment}
                                onClick={toggleShowRetypeNewPassword}
                            >
                                {form.$("retype_new_password").get("type") === "password" ? <RemoveRedEye className={classes.inputAdornmentIcon} /> :
                                    <VisibilityOff className={classes.inputAdornmentIcon} />}
                            </InputAdornment>
                        ),
                        ...form.$('retype_new_password').bind()
                    }}
                    error={!!form.$('retype_new_password').error}
                    helpText={form.$('retype_new_password').error || undefined}
                    labelText='Retype new password'
                />
            </GridItem>
            <GridItem xs={12} sm={12} className={classes.btnWrapper}>
                <Button color="primary" round type="submit"  loading={submitting} disabled={submitting}>
                    Submit
              </Button>
            </GridItem>
        </form>
    }
}

export default withStyles(styles)(observer(Account));