import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class LoginForm extends Form {
  plugins() {
    return plugins;
  }

  setup() {
    return {
      fields: [
        {
          name: "emailOrPhone",
          placeholder: "Enter your email",
          rules: "required",
          // hooks: {
          //   onChange: field => {
          //     field.validate();
          //   }
          // }
        },
        {
          name: "password",
          placeholder: "Enter your password",
          rules: "required",
          type: "password",
          // hooks: {
          //   onChange: field => {
          //     field.validate();
          //   }
          // }
        },
        {
          name: "rememberMe",
          type: "checkbox"
        }
      ]
    };
  }
}
