import {
  acceptApplicationApi,
  getApplicationByIdApi,
  getApplicationsListApi,
  rejectApplicationApi
} from "../dataServices/applicationsData.service";

const getApplicationsList = (pageSize, page, sort, order) => {
  return getApplicationsListApi(pageSize, page, sort, order);
};

const getApplicationById = id => {
  return getApplicationByIdApi(id);
};

const acceptApplication = id => {
  return acceptApplicationApi(id);
};

const rejectApplication = id => {
  return rejectApplicationApi(id);

};
export {getApplicationsList, acceptApplication, rejectApplication, getApplicationById};
