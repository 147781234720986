import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./postImageItemSlider.css";

const modal = {
  position: "fixed",
  zIndex: 2,
  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh",
  overflow: "auto",
  backgroundColor: "rgba(0, 0, 0, 0.5)"
};

const close = {
  position: "absolute",
  top: 15,
  right: 35,
  color: "#f1f1f1",
  fontSize: 40,
  fontWeight: "bold",
  cursor: "pointer"
};

const modalContent = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "80%",
  height: "100%",
  margin: "auto"
};

const carouselWrapper = {
  maxWidth: "600px",
  margin: "20px auto",
  padding: " 0 20px"
};

const imageWrap = {
  width: "100%",
  margin: "auto",
  height: "100%"
};

const contentImage = {
  width: "100%",
  height: "100% !important",
  objectFit: "contain !important"
};

export const PostImageItemSlider = ({
  onClose,
  children,
  listImageSlider,
  indexImageStart
}) => {
  return (
    <div style={modal}>
      <span style={close} onClick={onClose}>
        &times;
      </span>
      <div style={modalContent}>
        <div style={carouselWrapper}>
          <Carousel
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            selectedItem={indexImageStart}
            showIndicators={false}
          >
            {listImageSlider.map((img, index) => (
              <div key={index} style={imageWrap}>
                <img src={img} className={contentImage} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
