import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

const featuresPageStyle = theme => ({
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    },
    overflow: "hidden",
    padding: `0 ${theme.spacing.unit * 3}px`
  },
  cardTitle: {
    ...cardTitle,
    fontSize: "20px",
    fontWeight: "700",
    color: "rgba(7, 86, 126, 1)",
    height: 30
  },
  cardContent: {
    height: 157,
    "@media (max-width: 992px)": {
      height: 200
    },
    "@media (max-width: 960px)": {
      height: 115
    }
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },

  justifyContentCenter: {
    justifyContent: "center !important"
  },
  marginBottom: {
    marginBottom: "0px"
  },
  marginTop: {
    marginTop: "30px"
  },
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: `0 ${theme.spacing.unit * 3}px`
  },
  paper: {
    margin: `${theme.spacing.unit}px auto`,
    padding: theme.spacing.unit * 2,
    borderRadius: 15
  },
  displayFlex: {
    display: "flex"
  },
  mrtopItem: {
    "@media (max-width: 960px)": {
      marginTop: 50
    }
  },

  itemWrap: {
    position: "relative",
    height: 350,
    "@media (max-width: 992px)": {
      height: 400
    },
    "@media (max-width: 960px)": {
      height: 300
    }
  },

  itemFeature: {
    position: "absolute",
    borderRadius: "50%",
    backgroundColor: "white",
    width: 115,
    height: 115,
    textAlign: "center",
    top: "-55px",
    left: "50%",
    transform: "translateX(-50%)",
    boxShadow: "-1px 4px 7px 0px #aaaaaa"
  },

  btnChoose: {
    cursor: "pointer",
    color: "#f8971c",
    padding: "14px 33px",
    background: "none",
    border: "1px solid",
    borderRadius: "28px",
    fontWeight: 700,
    fontSize: 15,
    "&:hover": {
      backgroundColor: "#f8971c",
      color: "white"
    }
  },
  btnBackLogin: {
    cursor: "pointer",
    color: "#f8971c",
    padding: "14px 33px",
    background: "none",
    border: "1px solid",
    borderRadius: "28px",
    fontWeight: 700,
    fontSize: 15,
    "&:hover": {
      backgroundColor: "#f8971c",
      color: "white"
    }
  },
  btnContinueJob: {
    cursor: "pointer",
    color: "#f8971c",
    padding: "14px 33px",
    background: "none",
    border: "1px solid",
    borderRadius: "28px",
    fontWeight: 700,
    fontSize: 15,
    "&:hover": {
      backgroundColor: "#f8971c",
      color: "white"
    },
    marginRight: "10px",
  },
  btnBackBU: {
    cursor: "pointer",
    color: "blue",
    padding: "14px 33px",
    background: "none",
    border: "1px solid",
    borderRadius: "28px",
    fontWeight: 700,
    fontSize: 15,
    "&:hover": {
      backgroundColor: "blue",
      color: "white"
    },
    marginLeft: "10px",
  }
});

export default featuresPageStyle;
