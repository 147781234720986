import requests from "../http";
import { convertPagingResponse } from "../utils";

const getNotificationListApi = (pageSize, page) =>
  requests
    .get(`/api/notifications?page=${++page}&page_size=${pageSize}`)
    .then(data => convertPagingResponse(data));

const getNotificationApi = id => requests.get(`/api/notifications/${id}`);

const markAsReadNotificationApi = id =>
  requests.post(`/api/notifications/mark_as_read?${id ? `id=${id}` : ""}`);

export {
  getNotificationListApi,
  getNotificationApi,
  markAsReadNotificationApi
};
