import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  console.log("style", props.style);
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  return (
    <footer
      className={classes.footer}
      style={{
        borderTop: props.style ? "none" : "1px solid #e7e7e7"
      }}
    >
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="/" className={block}>
                {"Home"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/#about-us-content" className={block}>
                {"About Us"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/#support-content" className={block}>
                {"Support"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/#contact-us-content" className={block}>
                {"Contact Us"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/news" className={block}>
                {"News"}
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>@Copyright 2022-Present Skill-Loan Inc.</p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
