import {
  container,
  cardTitle,
  blackColor,
  hexToRgb,
  grayColor,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";

import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";

const registerPageStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonStyle,
  cardTitle: {
    ...cardTitle,
    textAlign: "center"
  },
  container: {
    ...container,
    position: "relative",
    zIndex: "3"
    // paddingTop: "23vh"
  },
  cardSignup: {
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 6px 30px 5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.12), 0 8px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2)",
    marginBottom: "100px",
    padding: "40px 0px",
    marginTop: "15vh"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  form: {
    padding: "0 20px",
    position: "relative"
  },
  socialTitle: {
    fontSize: "18px"
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
    cursor: "pointer"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  passwordHint: {
    color: primaryColor[0]
  },
  customFormControlClasses: {
    // margin: "0 12px"
  },
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  bottomText: {
    marginTop: "5px",
    fontSize: "14px",
    fontWeight: "400",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  registerLink: {
    color: primaryColor[0]
  },
  errorField: {
    color: "#f44336",
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Inter, Helvetica, Helvetica Neue, Arial, sans-serif",
    lineHeight: "1em"
  },
  errorFieldInput: {
    color: "#f44336",
    margin: 0,
    marginTop: "-8px",
    fontSize: "0.75rem",
    textAlign: "left",
    minHeight: "1em",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, Inter, Helvetica, Helvetica Neue, Arial, sans-serif",
    lineHeight: "1em"
  }
};

export default registerPageStyle;
