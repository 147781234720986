import React from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import PaymentInfo from '../views/Settings/components/PaymentInfo/PaymentInfo';

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';

class PaymentModal extends React.Component {
    state = {
        submitting: false
    }
    addPaymentMethod = (data) => {
        this.setState({ submitting: true });
        return this.props.addPaymentMethod(data).then(() => {
            this.setState({ submitting: false });
        })
    }
    render() {
        let { closePaymentModal, selectPayment, isTempoJob } = this.props;
        console.log('PaymentModal isTempoJob', isTempoJob)
        if (isTempoJob === undefined) isTempoJob = true;
        const { paymentMethods, addCardForm } = this.props.userStore;
        return <SweetAlert
            title=""
            onConfirm={() => { }}
            confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
            }
            style={{ display: "block", marginTop: "-300px", width: '800px', left: 'calc(50% - 150px)' }}
            confirmBtnText="Yes"
            cancelBtnText="Cancel"
            showConfirm={false}
        >
            <PaymentInfo addPaymentMethod={this.addPaymentMethod} cardList={paymentMethods}
                selectPayment={selectPayment} submitting={this.state.submitting} isSelectPayment={true} addCardForm={addCardForm} prevText='Cancel' handlePrevFunc={closePaymentModal} showChargeHintText={isTempoJob}/>
        </SweetAlert>
    }
}

export default inject('userStore')(withStyles(sweetAlertStyle)(observer(PaymentModal)));