import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withStyles } from '@material-ui/core';
import Rating from 'react-rating';

import FiberManualRecordOutlined from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const styles = {
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    mb30: {
        marginBottom: '30px'
    },
    icon: {
        color: '#ff9800'
    }
};
class AutoBodyTechnicianProfile extends React.Component {
    render() {
        const { classes, skills } = this.props;
        const { skills_level } = skills;
        return <React.Fragment>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years of Body Technician Experience:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_body_tech_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Type Of Work:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.type_of_technician.join(', ')}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <div className={classes.label}>Skills:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Assembling:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.assembling}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Prepping:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.prepping}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Finishing:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.finishing}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Painting:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.painting}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Classic Restoration:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.classicRestoration}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Expertise in Car Types:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.expertise_car_types.join(', ') || '--'}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Expertise in Engine Types:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.expertise_engine_types.join(', ') || '--'}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Certifications:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.certifications.join(', ')}</p>
                </GridItem>
            </GridContainer>
        </React.Fragment>
    }
}

export default withStyles(styles)(AutoBodyTechnicianProfile);