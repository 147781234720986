export const formatCurrency = (
  amount,
  localization = "en-US",
  currency = "USD"
) => {
  return new Intl.NumberFormat(localization, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0
  }).format(amount);
};

export const getNumberDateOfRange = (fromDate, toDate) => {
  const from = new Date(fromDate).getTime();
  const to = new Date(toDate).getTime();
  return Math.floor((to - from) / (24 * 3600 * 1000));
};
