import React, { useRef } from "react";
import { ReactComponent as NotiIcon } from "../../assets/img/icons/notification-bell.svg";
import { ReactComponent as NotiRepoIcon } from "../../assets/img/icons/notification-svgrepo.svg";
import "./notification.css";

import userNotificationStore from "../../stores/userNotificationStore";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

const Dropdown = props => {
  const dropdown_toggle_el = useRef(null);
  const dropdown_content_el = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [dataNoti, setDataNoti] = useState([]);

  const itemUnread = dataNoti && dataNoti.filter(item => item.read === false);

  useEffect(() => {
    document.addEventListener("mousedown", e => {
      if (
        dropdown_content_el.current &&
        !dropdown_content_el.current.contains(e.target)
      ) {
        setIsActive(false);
      }
    });
  }, [dropdown_content_el]);

  useEffect(() => {
    getUserNotificationList();
  }, []);

  const getUserNotificationList = async () => {
    const { pageSize, page } = userNotificationStore.pagination;
    await userNotificationStore.getUserNotificationList(pageSize, page);
    userNotificationStore.userNotificationPagination &&
      userNotificationStore.userNotificationPagination.items &&
      setDataNoti(userNotificationStore.userNotificationPagination.items);
  };

  const handleItemClick = async (e, id) => {
    e.preventDefault();
    await userNotificationStore.markAsReadUserNotification(id);
    getUserNotificationList();
  };

  const handleDropdownClick = () => {
    setIsActive(!isActive);
  };
  const handleViewAllClick = () => {
    setIsActive(false);
  };
  const handleChangeTabIndex = name => {
    if (name === "all") {
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
  };
  const renderTimeNotification = time => {
    if (moment().diff(time, "seconds") < 60) {
      return moment().diff(time, "seconds") + " " + "seconds ago";
    }
    if (moment().diff(time, "minutes") < 60) {
      return moment().diff(time, "minutes") + " " + "minutes ago";
    }
    if (moment().diff(time, "hours") < 24) {
      return moment().diff(time, "hours") + " " + "hours ago";
    }
    if (moment().diff(time, "days") < 7) {
      return moment().diff(time, "days") + " " + "days ago";
    }
    if (moment().diff(time, "weeks") <= 4) {
      return moment().diff(time, "weeks") + " " + "weeks ago";
    }
    if (moment().diff(time, "months") < 12) {
      return moment().diff(time, "months") + " " + "months ago";
    }
    return moment().diff(time, "years") + " " + "years ago";
  };

  // const clickOutsideRef = (content_ref, toggle_ref) => {
  //   document.addEventListener("mousedown", e => {
  //     // user click toggle
  //     if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
  //       content_ref.current.classList.add("active");
  //     } else {
  //       // user click outside toggle and content
  //       if (content_ref.current && !content_ref.current.contains(e.target)) {
  //         content_ref.current.classList.remove("active");
  //       }
  //     }
  //   });
  // };
  // useEffect(() => {
  //   clickOutsideRef(dropdown_content_el, dropdown_toggle_el);
  // }, [dropdown_content_el, dropdown_toggle_el]);

  return (
    <div className="dropdown">
      <button
        onClick={() => handleDropdownClick()}
        className="dropdown__toggle"
      >
        <div
          className="notification-icon"
          style={{
            backgroundColor: props.backgroundColorWhite ? "#ffffff" : "aaaaaa75"
          }}
        >
          <NotiIcon />
        </div>
        {itemUnread && itemUnread.length !== 0 && (
          <span className="dropdown__toggle-badge">{itemUnread.length}</span>
        )}
        {props.customToggle ? props.customToggle() : ""}
      </button>
      <div
        ref={dropdown_content_el}
        className={isActive ? "dropdown__content active" : "dropdown__content"}
      >
        <h4
          style={{
            fontWeight: "bold",
            padding: "10px 0px 5px 20px",
            fontSize: 23
          }}
        >
          Notifications
        </h4>
        <div style={{ padding: "5px 20px" }}>
          <div
            className={
              tabIndex === 0 ? "notification-btn active" : "notification-btn"
            }
          >
            <button onClick={() => handleChangeTabIndex("all")}>All</button>
          </div>
          <div
            className={
              tabIndex === 1 ? "notification-btn active" : "notification-btn"
            }
            style={{ marginLeft: 10 }}
          >
            <button onClick={() => handleChangeTabIndex("notread")}>
              Unread
            </button>
          </div>
        </div>
        {tabIndex === 0 ? (
          <div className="wrap-notification-item">
            {dataNoti ? (
              dataNoti.map((item, index) => (
                <div
                  className="notification-item"
                  key={index}
                  onClick={e => !item.read && handleItemClick(e, item.id)}
                >
                  <div style={{ marginLeft: 10, paddingRight: 10 }}>
                    <NotiRepoIcon />
                  </div>
                  <div style={{ width: "100%", padding: "0 10px" }}>
                    {/* <span className="notification-item-title">
                      {item.title}
                    </span>
                    <br /> */}
                    {item.type &&
                    ["USER_QUESTION_FLAG", "USER_ANSWER_FLAG"].includes(
                      item.type
                    ) ? (
                      <>
                        <span className="notification-item-body">
                          {item.title}
                        </span>
                        <br />
                        <span className="notification-item-body">
                          {item.metadata.other.content.length > 25
                            ? `
                        "${item.metadata.other.content.slice(0, 25)}..."`
                            : `"${item.metadata.other.content}"`}
                        </span>
                        <br />
                      </>
                    ) : (
                      <span className="notification-item-body">
                        {item.body}
                      </span>
                    )}

                    <p className="notification-item-time">
                      {renderTimeNotification(item.date_created)}
                    </p>
                  </div>

                  {!item.read && <span className="dot" />}
                </div>
              ))
            ) : (
              <div>
                <p className="no-notification">
                  You don't have any notifications
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="wrap-notification-item">
            {itemUnread ? (
              itemUnread.map((item, index) => (
                <div
                  className="notification-item"
                  key={index}
                  onClick={e => handleItemClick(e, item.id)}
                >
                  <div style={{ marginLeft: 10, paddingRight: 10 }}>
                    <NotiRepoIcon />
                  </div>
                  <div style={{ width: "100%", padding: "0 10px" }}>
                    {/* <span className="notification-item-title">
                      {item.title}
                    </span>
                    <br /> */}
                    {item.type &&
                    ["USER_QUESTION_FLAG", "USER_ANSWER_FLAG"].includes(
                      item.type
                    ) ? (
                      <>
                        <span className="notification-item-body">
                          {item.title}
                        </span>
                        <br />
                        <span className="notification-item-body">
                          {item.metadata.other.content.length > 25
                            ? `
                        "${item.metadata.other.content.slice(0, 25)}..."`
                            : `"${item.metadata.other.content}"`}
                        </span>
                        <br />
                      </>
                    ) : (
                      <span className="notification-item-body">
                        {item.body}
                      </span>
                    )}
                    <p className="notification-item-time">
                      {renderTimeNotification(item.date_created)}
                    </p>
                  </div>
                  <span className="dot" />
                </div>
              ))
            ) : (
              <div>
                <p className="no-notification">
                  You don't have any notifications
                </p>
              </div>
            )}
          </div>
        )}
        {dataNoti && (
          <div
            className="dropdown__footer"
            onClick={() => handleViewAllClick()}
          >
            <Link to="/tech-wizard/notification">View All</Link>
          </div>
        )}
      </div>
    </div>
  );
};

const Notification = props => {
  return (
    <Dropdown
      backgroundColorWhite={props.backgroundColorWhite}
      icon="bx bx-bell"
      badge="12"
    />
  );
};

export default Notification;
