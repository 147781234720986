import axios from "axios";
import config from "../../config";
import { toQueryString } from "../utils";

const getPostsAPI = data => {
  const {
    twSeasonId,
    twCategoryId,
    twMakeId,
    ownerUserId,
    title,
    content,
    year,
    userId,
    sort,
    order,
    page,
    page_size,
    status
  } = data;

  const query = toQueryString({
    sort,
    order,
    page,
    page_size,
    status
  });

  return axios.post(
    `${config.API_ROOT}/api/v2/tw_question_allow/list?${query}`,
    {
      twSeasonId,
      twCategoryId,
      twMakeId,
      title,
      content,
      year,
      userId,
      ownerUserId
    }
  );
};

const getPostsAuthAPI = data => {
  const {
    twSeasonId,
    twCategoryId,
    twMakeId,
    ownerUserId,
    title,
    content,
    year,
    userId,
    sort,
    order,
    page,
    page_size,
    status
  } = data;

  const query = toQueryString({
    sort,
    order,
    page,
    page_size,
    status
  });

  return axios.post(`${config.API_ROOT}/api/v2/tw_question/list?${query}`, {
    twSeasonId,
    twCategoryId,
    twMakeId,
    title,
    content,
    year,
    userId,
    ownerUserId
  });
};

const getPostsByIdAPI = (id, ownerUserId) => {
  return axios.get(
    `${
      config.API_ROOT
    }/api/v2/tw_question_allow/detail/${id}?ownerUserId=${ownerUserId}`
  );
};

const getAnswersByIdAPI = id => {
  return axios.get(`${config.API_ROOT}/api/v2/tw_answer/${id}`);
};

const getAnswersByPostIdAuthAPI = id => {
  const body = {
    twQuestionId: id
  };
  return axios.post(`${config.API_ROOT}/api/v2/tw_answer/list`, body);
};

const getAnswersByPostIdAPI = id => {
  const body = {
    twQuestionId: id
  };
  return axios.post(`${config.API_ROOT}/api/v2/tw_answer/list_allow`, body);
};

const getCategoriesAPI = () => {
  return axios.get(`${config.API_ROOT}/api/v2/tw_category/list`);
};

const getMakesAPI = () => {
  return axios.get(`${config.API_ROOT}/api/v2/tw_make/list`);
};

const addAnswerAPI = (twSeasonId, twQuestionId, content) => {
  const body = { twSeasonId, twQuestionId, content };
  return axios.post(`${config.API_ROOT}/api/v2/tw_answer/create`, body);
};
const updateAnswerAPI = (answerId, content) => {
  return axios.put(`${config.API_ROOT}/api/v2/tw_answer/update/${answerId}`, {
    content: content
  });
};

const addPostAPI = body => {
  return axios.post(`${config.API_ROOT}/api/v2/tw_question/create`, body);
};
const updatePostAPI = (postId, body) => {
  return axios.put(
    `${config.API_ROOT}/api/v2/tw_question/update/${postId}`,
    body
  );
};

const toggleLikeAPI = body => {
  return axios.post(`${config.API_ROOT}/api/v2/tw_like/create`, body);
};

const updateViewQuestionAPI = body => {
  return axios.post(`${config.API_ROOT}/api/v2/tw_view/create`, body);
};
const uploadImageCreatePostApi = (postId, formData) => {
  return axios.put(
    `${config.API_ROOT}/api/v2/tw_question/upload-images/${postId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const disableQuestionAPI = (postId, body) => {
  return axios.put(
    `${config.API_ROOT}/api/v2/tw_question/disable/${postId}`,
    body
  );
};

const getSeasonAPI = () => {
  return axios.get(`${config.API_ROOT}/api/v2/tw_season/detail`);
};

export {
  getPostsAPI,
  getPostsAuthAPI,
  getCategoriesAPI,
  getMakesAPI,
  getPostsByIdAPI,
  getAnswersByPostIdAPI,
  getAnswersByPostIdAuthAPI,
  addAnswerAPI,
  updateAnswerAPI,
  addPostAPI,
  updatePostAPI,
  toggleLikeAPI,
  updateViewQuestionAPI,
  uploadImageCreatePostApi,
  disableQuestionAPI,
  getAnswersByIdAPI,
  getSeasonAPI
};
