import moment from "moment-business-days";
import _ from 'lodash'
import { getCompleteLocation } from "../dataServices/authData.service";

export const convertPagingResponse = data => {
  const pagination = {
    page: data.page - 1,
    items: data.items,
    pageSize: data.page_size,
    totalPage: data.total_page,
    totalItem: data.total_item
  };
  return pagination;
};

export const convertDisplayText = (str, splitChar) => {
  if (!str) return "";
  if (str === "hired") return "Revealed/ Hired";
  const arr = str.split(splitChar);
  arr[0] = upperFirstLetter(arr[0]);
  return arr.join(" ");
};

export const upperFirstLetter = str => {
  if (!str) return "";
  const arr = str.split("");
  arr[0] = arr[0].toUpperCase();
  return arr.join("");
};

export const getFieldFromListByKey = (list, field, key, value) => {
  const selectedItem = list.find(item => item[key] === value);
  if (selectedItem) {
    return selectedItem[field];
  }
  return value;
};

export const checkParagraphNotContainsStrings = (para, strings) => {
  let valid = true;
  strings.forEach(str => {
    const reg = new RegExp(str);
    if (reg.test(para)) {
      valid = false;
    }
  });
  return valid;
};

export const getDiffDays = (endDate, startDate, saturdayWorking) => {
  if (!endDate.isValid() || !startDate.isValid()) return 0;
  const workingWeekdays = [1, 2, 3, 4, 5];

  if (saturdayWorking) {
    workingWeekdays.push(6);
  }

  moment.updateLocale("us", {
    workingWeekdays
  });

  const localeEndDate = moment(endDate.format("DD/MM/YYYY"), "DD/MM/YYYY")
    .add(1, "day")
    .locale("us", { workingWeekdays });

  const localeStartDate = moment(
    startDate.format("DD/MM/YYYY"),
    "DD/MM/YYYY"
  ).locale("us", { workingWeekdays });

  const businessDiffDay = localeEndDate.businessDiff(localeStartDate);

  return businessDiffDay;
};

export const downloadURI = (uri, name) => {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const toQueryString = (obj, prefix) => {
  var str = [],
    p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? toQueryString(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};
export const searchLocation = _.debounce(async function (e, func) {
  const res = await getCompleteLocation(e)
  // const r = _.get(res.data, 'data', [])
  // const rr = r.map(e => {
  //   return { value: e, label: e }
  // })
  func(res)
}, 750);
