import React from "react";
import { withRouter } from 'react-router-dom';

// react component for creating dynamic tables
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Tooltip } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { getFieldFromListByKey } from 'services/utils';
import Loading from "components/Loading/Loading";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  mb20: {
    marginBottom: '20px'
  },
  tableCell: {
    fontSize: '1rem',
    padding: '4px 20px'
  }
};


const rows = [
  { id: 'listing_id', numeric: false, disablePadding: false, label: 'Listing ID' },
  { id: 'name', numeric: false, disablePadding: false, label: 'Employee name' },
  { id: 'function', numeric: false, disablePadding: false, label: 'Function' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
  { id: 'start_date', numeric: false, disablePadding: false, label: 'Start date' },
  { id: 'end_date', numeric: false, disablePadding: false, label: 'End date' },
  { id: 'date_established', numeric: false, disablePadding: false, label: 'Date hired' },
];

class HiredEmployees extends React.Component {

  constructor(props) {
    super(props);
    this.props.employeesStore.setPageViewing('hired');
  }

  componentDidMount() {
    this.getHiredEmployeesList();
  }

  getHiredEmployeesList = () => {
    const { getHiredEmployeesList, pagination } = this.props.employeesStore;
    const { pageSize, page, sort, order } = pagination;
    getHiredEmployeesList(pageSize, page, sort, order);
  }

  handleChangePage = (e, page) => {
    this.props.employeesStore.changePageOfPagination(page);
    this.getHiredEmployeesList();
  }

  handleChangePageSize = (e) => {
    this.props.employeesStore.changePageSizeOfPagination(e.target.value);
    this.getHiredEmployeesList();
  }

  handleSort = sort => () => {
    this.props.employeesStore.changeSortOfPagination(sort);
    this.getHiredEmployeesList();
  }

  setCurrentEmployee = emp => () => {
    this.props.history.push(`/employees-hired/${emp.id}`);
  }

  render() {
    const { classes } = this.props;
    const { employeesPagination, pagination, isLoading } = this.props.employeesStore;
    const { functions } = this.props.commonStore;
    const { timeZone } = this.props.userStore;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Revealed/Hired</h4>
            </CardHeader>
            <CardBody>
              <div style={{ overflow: 'auto' }}>
                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle" padding="default">
                  <TableHead>
                    <TableRow>
                        {rows.map(
                        row => (
                            <TableCell
                            key={row.id}
                            className={classes.tableCell}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={pagination.sort === row.id ? pagination.order : false}
                            >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                active={pagination.sort === row.id}
                                direction={pagination.order}
                                onClick={this.handleSort(row.id)}
                                >
                                {row.label}
                                </TableSortLabel>
                            </Tooltip>
                            </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employeesPagination.items.map(n => {
                      return (
                        <React.Fragment
                          key={n.id}
                        >
                          <Tooltip title=''>
                            <TableRow
                              hover
                              tabIndex={-1}
                              onClick={this.setCurrentEmployee(n)}
                            >
                              <TableCell>{n.listing_id}</TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {`${n.job_seeker.profile.first_name || ''} ${n.job_seeker.profile.last_name || ''}`}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {n.details && (n.details.function === 'other' ? `Other - ${n.details.other_function}` : getFieldFromListByKey(functions, 'name', 'value', n.details.function))}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                ${n.details && n.details.rate}/hr
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {timeZone && moment(n.details && n.details.start_date).tz(timeZone).format('MMM DD YYYY')}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {timeZone && moment(n.details && n.details.end_date).tz(timeZone).format('MMM DD YYYY')}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {timeZone && moment(n.created_at).tz(timeZone).format('MMM DD YYYY')}
                              </TableCell>
                            </TableRow>
                          </Tooltip>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>}
              </div>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={employeesPagination.totalItem}
                rowsPerPage={pagination.pageSize}
                page={pagination.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangePageSize}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('employeesStore', 'commonStore', 'userStore')(withRouter(observer(HiredEmployees))));
