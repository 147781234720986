import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FormLabel, Radio, FormControlLabel } from "@material-ui/core";
import { observer } from 'mobx-react';
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const styles = {
    ...extendedFormsStyle,
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    mb15: {
        marginBottom: '15px'
    },
    labelSettings: {
        color: 'rgba(0, 0, 0, 0.87)'
    }
}

const locationDistances = [{ name: 'Up to 10 miles', value: 10 }, { name: 'Up to 25 miles [Default]', value: 25 }, { name: 'Up to 50 miles', value: 50 }]

const methods = [{ name: 'By email only', value: 'email' }, { name: 'By SMS/text only', value: 'sms' }, { name: 'By SMS & email [Default]', value: 'both' }, { name: 'No Notifications', value: 'none' }]


class NotificationPreferences extends React.Component {

    checkChangeNotificationMethod = (e) => {
        if (e.target.value !== 'none') {
            this.props.form.$('notification_preferences.sending_method').onChange(e);
        } else {
            this.props.showConfirmNoNotificationModal();
        }
    }

    render() {

        const { form, classes, submit, submitting } = this.props;
        return <form onSubmit={submit}>
            <h4>I want to receive notifications from following Job Seekers:</h4>
            <GridItem xs={12}>
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel
                            className={
                                classes.labelLeftHorizontal +
                                " " +
                                classes.labelHorizontalRadioCheckbox +
                                " " + classes.labelSettings
                            }
                        >
                            Location from my business:
                        </FormLabel>
                    </GridItem>
                    {locationDistances.map(opt => <GridItem xs={12} sm={3} key={opt.value}>
                        <div
                            className={
                                classes.checkboxAndRadio +
                                " " +
                                classes.checkboxAndRadioHorizontal
                            }
                        >
                            <FormControlLabel
                                control={
                                    <Radio
                                        checked={+form.$('notification_preferences.max_distance').value === opt.value}
                                        onChange={form.$('notification_preferences.max_distance').onChange}
                                        value={opt.value}
                                        name="radio button enabled"
                                        aria-label={opt.name}
                                        icon={
                                            <FiberManualRecord
                                                className={classes.radioUnchecked}
                                            />
                                        }
                                        checkedIcon={
                                            <FiberManualRecord
                                                className={classes.radioChecked}
                                            />
                                        }
                                        classes={{
                                            checked: classes.radio,
                                            root: classes.radioRoot
                                        }}
                                    />
                                }
                                classes={{
                                    label: classes.label +
                                        " " + classes.labelSettings
                                }}
                                label={opt.name}
                            />
                        </div>
                    </GridItem>)}
                </GridContainer>
            </GridItem>
            <GridItem xs={12} className={classes.mb15}>
                <GridContainer>
                    <GridItem xs={12} sm={3}>
                        <FormLabel
                            className={
                                classes.labelLeftHorizontal +
                                " " +
                                classes.labelHorizontalRadioCheckbox +
                                " " + classes.labelSettings
                            }
                        >
                            How
            </FormLabel>
                    </GridItem>
                    <GridItem sm={9}><GridContainer>
                        {methods.map(opt => <GridItem xs={12} sm={3} key={opt.value}>
                            <div
                                className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={form.$('notification_preferences.sending_method').value === opt.value}
                                            onChange={this.checkChangeNotificationMethod}
                                            value={opt.value}
                                            name="radio button enabled"
                                            aria-label={opt.name}
                                            icon={
                                                <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                />
                                            }
                                            checkedIcon={
                                                <FiberManualRecord
                                                    className={classes.radioChecked}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label +
                                            " " + classes.labelSettings
                                    }}
                                    label={opt.name}
                                />
                            </div>
                        </GridItem>)}
                    </GridContainer></GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem sm={3}></GridItem>
                    <GridItem sm={9}><p>* You can always change your Notification preferences from the Settings menu</p></GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} className={classes.btnWrapper}>
                <Button color="primary" round type="submit" loading={submitting} disabled={submitting}>
                    Submit
              </Button>
            </GridItem>
        </form>
    }
}

export default withStyles(styles)(observer(NotificationPreferences));