import config from "../config";

export const appRole = {
  job_seeker: "job_seeker",
  business: "business_owner",
  admin: "user_console",
  general: "user_general"
};

const constants = {
  role: [appRole.job_seeker, appRole.business, appRole.general],
  pagination: {
    page: 1,
    page_size: 10,
    order: -1,
    pageRangeDisplayed: 5,
    itemsPerPage: 10
  },
  dateTimeFormat: "MM/DD/YYYY LT",
  dateTimeFormatNoHM: "MM/DD/YYYY",
  loginType: {
    GG: 'google',
    FB: 'facebook',
    IND: 'indeed'
  }
};

export const appBreadcrumbs = {
  posts: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "All Posts",
      url: "",
      active: true
    }
  ],
  createPost: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Create Post",
      url: "",
      active: true
    }
  ],
  editPost: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Edit Post",
      url: "",
      active: true
    }
  ],
  postDetail: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Post Detail",
      url: "",
      active: true
    }
  ],
  listNotification: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Notification",
      url: "",
      active: true
    }
  ],
  settingAccount: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Account Setting",
      url: "",
      active: true
    }
  ],
  updateGUProfile: [
    {
      label: "Tech Wizard",
      url: "/tech-wizard",
      active: false
    },
    {
      label: "Update Profile",
      url: "",
      active: true
    }
  ]
};

export default constants;
