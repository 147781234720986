import React from "react";
import { inject, observer } from 'mobx-react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Help from "@material-ui/icons/Help";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { FormControl, InputLabel, Select, MenuItem, InputAdornment } from "@material-ui/core";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Tooltip from '@material-ui/core/Tooltip';
import IntegrationReactSelect from "../../../components/CustomSelect/IntegrationReactSelect";
import bestWorkplaceBadge from "../../../assets/img/best_workplace_badge_7.png";
import _ from 'lodash'
import { getCompleteLocation } from "../../../services/dataServices/authData.service";
// import { searchLocation } from "../../../services/utils";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};

const filterConfig = {
  matchFrom: 'start'
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
    this.state = { location: { city: '', state: '' } }
  }

  isValidated() {
    this.avatarRef.current.handleSubmit();
    this.props.userStore.profileForm.showErrors(true);
    return this.props.userStore.profileForm.isValid;
  }
  searchLocation = _.debounce(async function (e) {
    const res = await getCompleteLocation(e)
    const options = _.get(res.data, 'data', []).map(e => {
      return { value: e, label: e }
    })
    this.setState({ stateReactSelect: options })
  }, 750);
  componentDidMount() {
    const { userStore } = this.props;
    userStore.getProfile();
    this.setState({
      location: {
        city: userStore.currentUser.profile.location.city,
        state: userStore.currentUser.profile.location.state
      }
    })
  }
  render() {
    const { classes, commonStore, handleUpdateLocation } = this.props;
    const { profileForm } = this.props.userStore;
    const { state, salesRep } = commonStore;
    const profile = this.props.userStore.profileForm.values();
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Let's start with the basic information
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          {
            profile.best_workplace ?
              <div className="picture-container">
                <img src={bestWorkplaceBadge} alt="Best Workplace" style={{ marginLeft: '20px' }} />
              </div>
              :
              <div className="picture-container">
              </div>
          }
          <PictureUpload ref={this.avatarRef} defaultImage={profileForm.$('avatar').value} handleUpload={profileForm.$('avatar').onChange} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            labelText='First Name*'
            error={!!profileForm.$('first_name').error}
            helpText={profileForm.$('first_name').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('first_name').bind()
            }
          />
          <CustomInput
            labelText='Last Name*'
            error={!!profileForm.$('last_name').error}
            helpText={profileForm.$('last_name').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('last_name').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Email*'
            error={!!profileForm.$('email').error}
            helpText={profileForm.$('email').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('email').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Mobile Phone*'
            error={!!profileForm.$('mobile_number').error}
            helpText={profileForm.$('mobile_number').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="We request your mobile number for security purpose only.  We use it to authenticate users and to deter hackers. We don't share your mobile number with anyone and we only use it with your prior permission.">
                      <Help className={classes.inputAdornmentIcon} />
                    </Tooltip>
                  </InputAdornment>
                ),
                ...profileForm.$('mobile_number').bind()
              }
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <IntegrationReactSelect
            label={'City*'}
            id="city-select"
            // error={!!profileForm.$('city').error}
            // helpText={profileForm.$('city').error || undefined}
            // options={profileForm.$('location.city').extra}
            options={this.state.stateReactSelect}
            // value={profileForm.$('city').value}
            value={{ label: this.state.location.city, value: this.state.location.city }}
            onChange={(e) => {
              let city
              let state
              if (!e) {
                city = ""
                state = ""
              } else {
                const locationList = e.value.split(',').reverse()
                city = locationList[1].trim()
                state = locationList[0].trim()
              }
              this.setState({
                location: {
                  city: city,
                  state: state
                }
              })
              handleUpdateLocation({ city: city, state: state })
            }}
            placeholder={'Select City'}
            handleInputChange={e => {
              if (e.trim() === '') return
              this.searchLocation(e)
            }}
            filterConfig={filterConfig} />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <IntegrationReactSelect
            label={'State*'}
            id="state-select"
            // error={!!profileForm.$('state').error}
            // helpText={profileForm.$('state').error || undefined}
            // options={stateReactSelect}
            options={this.state.stateReactSelect}
            // value={profileForm.$('city').value}
            value={{ label: this.state.location.state, value: this.state.location.state }}
            onChange={(e) => {
              let city
              let state
              if (!e) {
                city = ""
                state = ""
              } else {
                const locationList = e.value.split(',').reverse()
                city = locationList[1].trim()
                state = locationList[0].trim()
              }
              this.setState({
                location: {
                  city: city,
                  state: state
                }
              })
              handleUpdateLocation({ city: city, state: state })
            }}
            handleInputChange={e => {
              if (e.trim() === '') return
              this.searchLocation(e)
            }}
            placeholder={'Select State'}
            filterConfig={filterConfig} />
        </GridItem>
        {profileForm.$('location.other_city').extra.show && <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Other City'
            error={!!profileForm.$('location.other_city').error}
            helpText={profileForm.$('location.other_city').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('location.other_city').bind()
            }
          />
        </GridItem>}
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Address*'
            error={!!profileForm.$('location.address_line1').error}
            helpText={profileForm.$('location.address_line1').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('location.address_line1').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Zip Code'
            error={!!profileForm.$('location.zip_code').error}
            helpText={profileForm.$('location.zip_code').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('location.zip_code').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
          >
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
              style={{ fontWeight: 400, fontSize: '1rem' }}
            >
              Assigned Sales Rep
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
              value={profileForm.$('sales_rep').value}
              onChange={profileForm.$('sales_rep').onChange}
            >
              {salesRep.map(item =>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item.code}
                  key={item.code}
                >
                  {item.name}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </GridItem>
        {profileForm.$('other_sales_rep').extra.show && <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Other Sales Rep'
            error={!!profileForm.$('other_sales_rep').error}
            helpText={profileForm.$('other_sales_rep').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              profileForm.$('other_sales_rep').bind()
            }
          />
        </GridItem>}
      </GridContainer>
    );
  }
}

export default withStyles({ ...extendedFormsStyle, ...style })(inject('userStore', 'commonStore')(observer(Profile)));
