import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class ReplyForm extends Form {
    plugins() {
        return plugins;
    }

    options() {
        return {
            showErrorsOnReset: true,
            validateOnChange: true,
            validateOnBlur: false
        };
    }

    setup() {
        return {
            fields: [
                {
                    name: "comment",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }, {
                    name: 'submitting',
                    value: false
                }
            ]
        };
    }
}
