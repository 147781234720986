import axios from "axios";
import Cookie from "js-cookie";
import config from "../../config";
import * as _ from "lodash";
import notificationService from "../notificationService";
import authStore from "../../stores/authStore";
import stores from "../../stores";
import { sendOtpApi } from "../dataServices/authData.service";

const API_ROOT = config.API_ROOT;

// const encode = encodeURIComponent;

const handleErrors = err => {
  if (err && err.response && err.response.status === 401) {
    authStore.logout();
    window.location.href = "/app/#/auth/login-page";
  }
  const errMessage =
    (err.response && err.response.data && err.response.data.message) ||
    "Something went wrong!";
  notificationService.error(errMessage);
  return Promise.reject(errMessage);
};

const responseBody = res => {
  const { data, statusCode, statusMessage = "", message = "" } = res;
  if (
    (statusCode && statusCode === "10003") ||
    (data.statusCode && data.statusCode === "10003")
  ) {
    return Promise.reject({
      response: { status: 401, data: { message: data.statusMessage } }
    });
  }

  if (
    (statusCode && statusCode === "10004") ||
    (data.statusCode && data.statusCode === "10004")
  ) {
    const { data: user } = data;
    localStorage.setItem("user", JSON.stringify(user));
    return sendOtpApi({
      mobileNumber: user.mobileNumber,
      email: user.email
    }).then(res => {
      if (res.statusCode !== "0000") {
        throw res;
      }
      localStorage.setItem("otpEntryId", res.data.otpEntryId);
      window.location.href = `/app/#/auth/verify-otp`;
      notificationService.error(statusMessage || data.statusMessage || message);
    });
  }

  if (data && data.statusCode && data.statusCode !== "0000") {
    notificationService.error(data.statusMessage || message);
    return Promise.reject({
      response: {
        status: data.statusCode,
        data: { message: data.statusMessage }
      }
    });
  }

  return data;
};

const http = axios.create({
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  }
});

http.interceptors.request.use(request => {
  const token =
    stores.authStore.userCredential || Cookie.getJSON("__user_credential");
  if (token) {
    request.headers = {
      Authorization: `${token.token_type} ${token.access_token}`
    };
  }
  return request;
});

const requests = {
  del: (url, body) =>
    http
      .delete(`${API_ROOT}${url}`, { data: body })
      .then(responseBody)
      .catch(handleErrors),
  get: url =>
    http
      .get(`${API_ROOT}${url}`)
      .then(responseBody)
      .catch(handleErrors),
  put: (url, body) =>
    http
      .put(`${API_ROOT}${url}`, body)
      .then(responseBody)
      .catch(handleErrors),
  patch: (url, body) =>
    http
      .patch(`${API_ROOT}${url}`, body)
      .then(responseBody)
      .catch(handleErrors),
  post: (url, body) =>
    http
      .post(`${API_ROOT}${url}`, body)
      .then(responseBody)
      .catch(handleErrors),
  sendWithFiles: (url, method, bodyData, files) => {
    let formData = new FormData();
    bodyData = _.omitBy(bodyData, dataItem => _.isNil(dataItem));

    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        for (let j = 0; j < files[i].files.length; j++) {
          formData.append(
            files[i].name,
            files[i].files[j],
            files[i].files[j] ? files[i].files[j].name : "__upload_file"
          );
        }
      }
    }
    for (let [key, value] of Object.entries(bodyData)) {
      appendRecursive(formData, value, key);
    }
    return http[method](`${API_ROOT}${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(responseBody)
      .catch(handleErrors);
  }
};

const appendRecursive = (fData, data, prop) => {
  if ("object" === typeof data) {
    for (let [key, value] of Object.entries(fData)) {
      appendRecursive(fData, value, `${key}`);
    }
  } else {
    fData.append(prop, data);
  }
};

export default requests;
