import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";

// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Popper,
  Hidden,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@material-ui/core";

// @material-ui/icons
import Notifications from "@material-ui/icons/Notifications";
import Person from "@material-ui/icons/Person";
import Help from "@material-ui/icons/Help";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";
import { observer, inject } from "mobx-react";
import { Divider } from "@material-ui/core";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    openPersonal: false,
    openHelp: false
  };
  componentDidMount() {
    this.props.userNotificationStore.getNavbarUserNotification();
  }
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickPersonal = () => {
    this.setState({ openPersonal: !this.state.openPersonal });
  };
  handleClosePersonal = () => {
    this.setState({ openPersonal: false });
  };

  handleClickHelp = () => {
    this.setState({ openHelp: !this.state.openHelp });
  };
  handleCloseHelp = () => {
    this.setState({ openHelp: false });
  };

  handleClickNotification = notification => () => {
    this.handleClose();
    const link = this.props.userNotificationStore.getNofiticationLink(
      notification
    );
    link && this.props.history.push(link);
  };

  navigateToNotificationPage = () => {
    this.props.history.push("/notifications");
  };

  navigateToProfile = () => {
    this.props.history.push("/update-profile");
    this.handleClosePersonal();
  };

  navigateToSettings = () => {
    this.props.history.push("/settings");
    this.handleClosePersonal();
  };

  logout = () => {
    const { history } = this.props;
    this.props.userStore.logout();
    history.push("/auth/login-page");
    this.handleClosePersonal();
  };

  showHelpModal = idx => () => {
    this.handleCloseHelp();
    this.props.showHelpModal(idx);
  };

  showFeedbackModal = () => {
    this.handleCloseHelp();
    this.props.showFeedbackModal();
  };

  showTermConditionsModal = () => {
    this.handleCloseHelp();
    this.props.showTermConditionsModal();
  };

  showPrivacyPolicyModal = () => {
    this.handleCloseHelp();
    this.props.showPrivacyPolicyModal();
  };

  render() {
    const { classes } = this.props;
    const { open, openPersonal, openHelp } = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    const { navbarUserNotification } = this.props.userNotificationStore;
    const newNotificationCount =
      navbarUserNotification &&
      navbarUserNotification.filter(noti => !noti.read).length;
    return (
      <div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Help"
            aria-owns={openHelp ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickHelp}
            className={classes.buttonLink}
            muiClasses={{
              label: ""
            }}
            buttonRef={node => {
              this.anchorHelpEl = node;
            }}
          >
            <Help className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClickHelp} className={classes.linkText}>
                {"Help"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openHelp}
            anchorEl={this.anchorHelpEl}
            transition
            placement="bottom-start"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent"
              }
            }}
            className={classNames({
              [classes.popperClose]: !openHelp,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <div className={classes.notificationsWrapper}>
                    <ClickAwayListener onClickAway={this.handleCloseHelp}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={this.showHelpModal(0)}
                          className={dropdownItem}
                        >
                          {"Getting Started"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.showHelpModal(1)}
                          className={dropdownItem}
                        >
                          {"FAQs"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.showHelpModal(2)}
                          className={dropdownItem}
                        >
                          {"Contact Us"}
                        </MenuItem>
                        <Divider className={classes.divider} />
                        <MenuItem
                          onClick={this.showTermConditionsModal}
                          className={dropdownItem}
                        >
                          {"Terms & Conditions of Use"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.showPrivacyPolicyModal}
                          className={dropdownItem}
                        >
                          {"Privacy Policy"}
                        </MenuItem>
                        <Divider className={classes.divider} />
                        <MenuItem
                          onClick={this.showFeedbackModal}
                          className={dropdownItem}
                        >
                          {"Send feedback"}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.buttonLink}
            muiClasses={{
              label: ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Notifications
              className={classes.headerLinksSvg + " " + classes.links}
            />
            {newNotificationCount > 0 && (
              <span className={classes.notifications}>
                {newNotificationCount}
              </span>
            )}
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {"Notification"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            placement="bottom-start"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent"
              }
            }}
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <div className={classes.notificationsWrapper}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList role="menu">
                        {navbarUserNotification.length > 0 ? (
                          navbarUserNotification.map(notify => (
                            <MenuItem
                              key={notify.id}
                              onClick={this.handleClickNotification(notify)}
                              className={
                                dropdownItem +
                                " " +
                                classes.notificationDropdownItem +
                                " " +
                                (!notify.read && classes.notRead)
                              }
                            >
                              <GridContainer
                                className={classes.notificationItemWrapper}
                              >
                                {/* <GridItem xs={3}>
                                <CardAvatar plain className={classes.avatar}>
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img src={notify.avatar} alt="Notify avatar" />
                                  </a>
                                </CardAvatar>
                              </GridItem> */}
                                <GridItem xs={12}>
                                  <div className={classes.notifyTitle}>
                                    {notify.title}
                                  </div>
                                  <div>{notify.body}</div>
                                  <span>
                                    {moment(notify.date_created).format(
                                      "MMM DD YYYY"
                                    )}
                                  </span>
                                </GridItem>
                              </GridContainer>
                            </MenuItem>
                          ))
                        ) : (
                          <div className={classes.noNotification}>
                            No notifications.
                          </div>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                  {navbarUserNotification.length > 0 && (
                    <div className={classes.viewAllWrapper}>
                      <Link to={"/notifications"}>{"See all"}</Link>
                    </div>
                  )}
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            color="transparent"
            justIcon
            aria-label="Personal"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClickPersonal}
            className={classes.buttonLink}
            muiClasses={{
              label: ""
            }}
            buttonRef={node => {
              this.anchorPersonalEl = node;
            }}
          >
            <Person className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span
                onClick={this.handleClickPersonal}
                className={classes.linkText}
              >
                {"Personal"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={openPersonal}
            anchorEl={this.anchorPersonalEl}
            transition
            placement="bottom-start"
            modifiers={{
              preventOverflow: {
                enabled: true,
                boundariesElement: "scrollParent"
              }
            }}
            className={classNames({
              [classes.popperClose]: !openPersonal,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <div className={classes.notificationsWrapper}>
                    <ClickAwayListener onClickAway={this.handleClosePersonal}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={this.navigateToProfile}
                          className={dropdownItem}
                        >
                          {"My Profile"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.navigateToSettings}
                          className={dropdownItem}
                        >
                          {"Settings"}
                        </MenuItem>
                        <MenuItem
                          onClick={this.logout}
                          className={dropdownItem}
                        >
                          {"Logout"}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(adminNavbarLinksStyle)(
    inject("userStore", "userNotificationStore")(
      withRouter(observer(HeaderLinks))
    )
  )
);
