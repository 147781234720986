import React, { useRef } from "react";
import { observer, inject } from "mobx-react";
import { NavLink, useParams, withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import PageLayout from "../../layouts/pageLayout/pageLayout";
import Posts from "../../common/components/posts/posts";
import RecentPosts from "../../common/components/recentPosts/recentPosts";
import Button from "../../components/CustomButtons/Button";
import PostItem from "../../common/components/postItem/postItem";
import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  whiteColor,
  blackColor
} from "../../assets/jss/material-dashboard-pro-react";
import { ReactComponent as IconRule } from "../../assets/img/icons/icon_rule.svg";
import { ReactComponent as IconClose } from "../../assets/img/icons/icon_close.svg";
import { ReactComponent as IconAvatarDefault } from "../../assets/img/icons/icon_default_avatar.svg";
import { ReactComponent as IconAvatar } from "../../assets/img/icons/icon_avatar.svg";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import { appBreadcrumbs } from "../../constants/app.constant";
import Welcome from "../../common/components/welcome/welcome";

const styles = theme => ({
  container,
  recentPosts: {
    background: whiteColor
  },
  postTitle: {
    color: textPrimaryColor,
    fontWeight: 700,
    textTransform: "capitalize",
    border: `1px solid ${textPrimaryColor}`,
    marginBottom: 4
  },
  postContent: {
    padding: 8,
    color: textPrimaryColor,
    "& ul": {
      margin: 0,
      padding: 0
    },
    "& li": {
      display: "list-item"
    }
  },
  postActionBtnLabel: {
    fontSize: 16,
    textTransform: "Capitalize",
    marginLeft: 8,
    fontWeight: 700
  },
  gameRule: {
    padding: "16px 20px",
    background: whiteColor
  },
  pageDetailWrapper: {
    background: blackColor,
    padding: "6px 0"
  },
  pageDetailHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      color: whiteColor,
      margin: "0 20px 0 0",
      fontSize: 16,
      fontWeight: 400
    }
  },
  btnClose: {
    color: `${whiteColor} !important`,
    border: `1px solid ${whiteColor} !important`,
    "& svg": {
      width: 10,
      height: 10
    }
  },
  btnCloseLabel: {
    fontSize: 16,
    textTransform: "Capitalize",
    marginLeft: 10,
    fontWeight: 400
  },
  numberOfAnswers: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 20
  },
  noContent: {
    textAlign: "center",
    fontSize: 16,
    margin: "30px 0",
    fontWeight: 500
  },
  commentForm: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "16px 0"
  },
  avatar: {
    display: "flex",
    flex: `0 0 40px`,
    width: 40,
    height: 40,
    margin: `0 12px`,
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      ...image,
      width: "auto"
    }
  },
  formContainer: {
    flex: `1 0 auto`
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& textarea": {
      flex: "1 0 auto",
      resize: "none",
      height: 80,
      padding: 10,
      fontSize: 16,
      lineHeight: "19px",
      color: blackColor,
      fontFamily: "Helvetica",
      borderRadius: 5,
      border: "1px solid #E9EAEB",
      overflow: "auto"
    },
    "& button": {
      flex: "0 0 110px",
      marginLeft: 12
    }
  },
  answers: {
    padding: `12px 20px 8px`,
    background: whiteColor
  }
});

class PostDetail extends React.Component {
  constructor(props) {
    super(props);
    // console.log("props ", props);
    this.state = {
      show: true,
      widthScreen: window.innerWidth
    };
    this.onComment = this.onComment.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const pageDidMount = async () => {
      await this.props.postsStore.getPostByIdFromApi(id);
      const { currentPost, currentPostSeasonId } = this.props.postsStore;
      if (currentPost && currentPost.id) {
        await this.props.postsStore.getAnswersByPostIdFromApi(id);
        await this.props.postsStore.updateViewQuestionFromApi({
          twSeasonId: currentPostSeasonId,
          twQuestionId: id
        });
      }
    };
    pageDidMount();
    window.addEventListener("resize", () => {
      this.setState({ widthScreen: window.innerWidth });
    });
  }

  componentWillUnmount() {
    this.props.postsStore.resetCurrentPost();
    this.props.postsStore.resetCurrentAnswers();
    this.props.postsStore.updateCurrentPostStatus(false);
  }

  componentDidUpdate = prevProps => {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      const { id } = this.props.match.params;
      this.props.postsStore.getPostByIdFromApi(id);
      this.props.postsStore.getAnswersByPostIdFromApi(id);
    }
  };

  onComment = e => {
    this.props.postsStore.updateCurrentComment(e.target.value);
  };

  onSubmitFormComment = (e, questionId, seasonId) => {
    e.preventDefault();
    this.props.postsStore.addAnswerFromApi(seasonId, questionId);
  };

  commentForm = (questionId, seasonId) => {
    const { classes, userStore } = this.props;
    const { currentUserTW } = userStore;
    const { currentComment } = this.props.postsStore;

    return (
      <div className={classes.commentForm}>
        <div className={classes.avatar}>
          {currentUserTW && currentUserTW.profile.avatar ? (
            <>
              <img
                className={classes.logoImage}
                src={currentUserTW.profile.avatar}
              />
            </>
          ) : (
            <IconAvatarDefault />
          )}
        </div>
        <div className={classes.formContainer}>
          <form
            className={classes.form}
            onSubmit={e => {
              if (currentUserTW) {
                this.onSubmitFormComment(e, questionId, seasonId);
              } else {
                this.props.commonStore.updateModalRequireLogin(true);
              }
            }}
          >
            <textarea
              value={currentComment}
              placeholder="Write your answer"
              onChange={e => this.onComment(e)}
            />
            <Button type="submit" color="primary">
              Send
            </Button>
          </form>
        </div>
      </div>
    );
  };

  render() {
    const { classes, userStore } = this.props;
    const {
      currentPost,
      currentAnswers,
      currentPostStatus
    } = this.props.postsStore;
    const infoQuestion = {
      category:
        currentPost &&
        currentPost.tw_category_id &&
        currentPost.tw_category_id.name,
      make:
        currentPost && currentPost.tw_make_id && currentPost.tw_make_id.name,
      year: currentPost && currentPost.year
    };
    const { widthScreen } = this.state;
    const isMobileScreen = widthScreen <= 980;

    const PageTitle = () => {
      return (
        <div className={classes.pageDetailWrapper}>
          <div className={classes.container}>
            <div className={classes.pageDetailHeading}>
              <p>
                How do you diagnose a communication network? Automotive
                Technician/Mechanic...
              </p>
              <Button
                className={classes.btnClose}
                color="primary"
                size={"sm"}
                outline={true}
                onClick={() => this.setState({ show: false })}
              >
                <IconClose />
                <span className={classes.btnCloseLabel}>Close</span>
              </Button>
            </div>
          </div>
        </div>
      );
    };

    const GameRule = () => {
      return (
        <div className={classes.gameRule}>
          <Button
            className={classes.btnGameRule}
            color="primary"
            size={"sm"}
            fullWidth={true}
            outline={true}
            onClick={() => this.props.commonStore.updateModalGameRules(true)}
          >
            <IconRule />
            <span className={classes.postActionBtnLabel}>Game Rules</span>
          </Button>
        </div>
      );
    };

    return (
      <PageLayout
        pageTitle={this.state.show ? <PageTitle /> : null}
        sidebar={!isMobileScreen && [<Welcome />, <RecentPosts />]}
      >
        {isMobileScreen && <Welcome />}

        <Breadcrumbs items={appBreadcrumbs.postDetail} />

        {currentPostStatus && currentPost ? (
          <>
            <PostItem
              item={currentPost}
              infoQuestion={infoQuestion}
              isQuestion={true}
            />
            <Typography color="textPrimary" className={classes.numberOfAnswers}>
              {currentAnswers ? currentAnswers.length : 0} answers
            </Typography>
            {currentAnswers && currentAnswers.length > 0 ? (
              <>
                <div className={classes.answers}>
                  <Posts
                    items={currentAnswers ? currentAnswers : []}
                    isQuestion={false}
                  />
                </div>
                {this.commentForm(currentPost.id, currentPost.tw_season_id)}
              </>
            ) : (
              <>
                <Typography color="textPrimary" className={classes.noContent}>
                  No answer
                </Typography>
                {this.commentForm(currentPost.id, currentPost.tw_season_id)}
              </>
            )}
          </>
        ) : (
          <Typography color="textPrimary" className={classes.noContent}>
            No question found!
          </Typography>
        )}
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(PostDetail))
  )
);
