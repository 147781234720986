import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "../../../components/CustomButtons/Button";

import Posts from "../../../common/components/posts/posts";
import PageLayout from "../../../layouts/pageLayout/pageLayout";

import Welcome from "../../../common/components/welcome/welcome";
import SearchForm from "../../../common/components/searchForm/searchForm";
import FilterGroup from "../../../common/components/filterGroup/filterGroup";

import { container } from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  container,
  pageTitle: {
    fontSize: 32,
    lineHeight: "39px",
    margin: `${theme.spacing.unit * 3}px 0 0`,
    fontWeight: "bold",
    color: "#000"
  },
  pageSubTitle: {
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  viewAllPost: {
    fontWeight: "normal",
    textTransform: "none",
    margin: 0,
    fontSize: 16,
    padding: `0 16px`
  },
  titleBar: {
    marginBottom: 16
  }
});

class TechWizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widthScreen: window.innerWidth
    };
  }

  componentDidMount() {
    this.props.postsStore.getPostsFromApi();
    this.props.userStore.getCurrentUser();
    window.addEventListener("resize", () => {
      this.setState({ widthScreen: window.innerWidth });
    });
  }

  renderPageTitle() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography className={classes.pageTitle}>Tech Wizard</Typography>
      </div>
    );
  }

  renderTitleBar() {
    const { classes, history } = this.props;

    const PageSubTitle = () => {
      return (
        <Typography className={classes.pageSubTitle}>
          Most Recent Posts
        </Typography>
      );
    };

    return (
      <div className={classes.titleBar}>
        <Grid container alignItems={"flex-start"} justify={"space-between"}>
          <Grid item xs={12} md={6}>
            <PageSubTitle />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justify={"flex-end"}>
              <Button
                className={classes.viewAllPost}
                color="primary"
                size={"sm"}
                simple={true}
                onClick={() => {
                  history.push("/tech-wizard/posts");
                }}
              >
                View all
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {
    const { posts } = this.props.postsStore;
    const { widthScreen } = this.state;
    const isMobileScreen = widthScreen <= 980;

    return (
      <PageLayout
        pageTitle={this.renderPageTitle()}
        sidebar={
          !isMobileScreen && [
            <Welcome />,
            <SearchForm />,
            <FilterGroup
              redirectUrl={"/tech-wizard/posts"}
              showFilterActions={false}
            />
          ]
        }
      >
        {isMobileScreen && (
          <>
            <Welcome />
            <FilterGroup
              redirectUrl={"/tech-wizard/posts"}
              showFilterActions={false}
            />
          </>
        )}
        {this.renderTitleBar()}
        <Posts items={posts} isQuestion={true} />
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(TechWizard))
  )
);
