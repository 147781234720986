import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class NotificationPreferencesForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "notification_preferences",
                    fields: [
                        {
                            name: "max_distance",
                            type: "radio",
                        },
                        {
                            name: "sending_method",
                            type: "radio",
                        }
                    ]
                }
            ]
        };
    }
}
