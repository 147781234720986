import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class PreferencesForm extends Form {
    plugins() {
        return plugins;
    }
    options() {
        return {
            showErrorsOnReset: true,
            validateOnChange: true,
            validateOnBlur: false
        };
    }

    setup() {
        return {
            fields: [
                {
                    name: "business_name",
                    placeholder: "Enter business name",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "business_id",
                    disabled: true,
                    value: ""
                },
                {
                    name: "phone_number",
                    rules: "telephone",
                    placeholder: "Enter your business phone"
                },
                {
                    name: "business_type",
                    placeholder: "Select your business type",
                    hooks: {
                        onChange: field => {
                            if (field.value === "Other") {
                                this.$("other_business_type").set("extra", {
                                    show: true
                                });
                            } else {
                                this.$("other_business_type").set("extra", {
                                    show: false
                                });
                                this.$("other_business_type").reset();
                            }
                        }
                    }
                },
                {
                    name: "other_business_type",
                    extra: { show: false },
                },
                {
                    name: "car_types",
                    placeholder: "Select your car type",
                    value: [],
                    hooks: {
                        onChange: field => {
                            if (field.value && field.value.find(item => item.value === "Other")) {
                                this.$("other_car_types").set("extra", {
                                    show: true
                                });
                            } else {
                                this.$("other_car_types").set("extra", {
                                    show: false
                                });
                                this.$("other_car_types").reset();
                            }
                        }
                    }
                },
                {
                    name: "other_car_types",
                    extra: { show: false },
                    value: []
                }
            ]
        };
    }
}
