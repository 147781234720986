import React from 'react';
import Datetime from "react-datetime";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { MenuItem, FormLabel, Radio, FormControlLabel } from "@material-ui/core";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import SweetAlert from "react-bootstrap-sweetalert";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import Tooltip from '@material-ui/core/Tooltip';
import Help from "@material-ui/icons/Help";
import Button from "components/CustomButtons/Button.jsx";

import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const bringTools = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]

const isSaturdayWorkOptions = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]

const styles = {
    dateTimeContainer: {
        "& .rdtPicker": {
            marginTop: '20px!important'
        }
    },
    ml10: {
        marginLeft: '10px'
    },
    mr10: {
        marginRight: '10px'
    },
    mbt10: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        height: 'calc(80vh - 130px)',
        overflow: 'auto'
    },
    tooltip: {
        zIndex: 20000
    }
}

class HireEmployeeFormUI extends React.Component {

    validEndDate = (currentDate) => {
        const startDateValue = this.props.hireEmployeeForm.$('start_date').value;
        if (startDateValue) {
            const startDate = moment(startDateValue);
            return currentDate.isSameOrAfter(startDate, 'day') && currentDate.isSameOrBefore(this.props.endDateJobSeeker, 'day');
        }
        return true;
    };

    validStartDate = (currentDate) => {
        const endDateValue = this.props.hireEmployeeForm.$('end_date').value;
        if (endDateValue) {
            const endDate = moment(endDateValue);
            return currentDate.isSameOrBefore(endDate, 'day') && currentDate.isSameOrAfter(this.props.startDateJobSeeker, 'day');
        }
        return true;
    };

    render() {
        const { hireEmployeeForm, classes, onConfirm, onCancel } = this.props;

        return <SweetAlert
            title=""
            onConfirm={onConfirm}
            onCancel={onCancel}
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel={false}
            showConfirm={false}
        >
            <h3>Hire this employee?</h3>
            <GridContainer className={classes.wrapper}>
                <GridItem xs={6}>
                    <div className={classes.dateTimeContainer}>
                        <span style={{ display: 'none' }}>{hireEmployeeForm.$('start_date').error}</span>
                        <Datetime
                            {...hireEmployeeForm.$('start_date').bind()}
                            isValidDate={this.validStartDate}
                            timeFormat={false}
                            renderInput={(props) => <CustomInput
                                labelText='Start date'
                                error={!!hireEmployeeForm.$('start_date').error}
                                helpText={hireEmployeeForm.$('start_date').error || undefined}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={
                                    {
                                        ...props,
                                        disabled: hireEmployeeForm.$('start_date').disabled,
                                        onClick: (e) => {
                                            if (hireEmployeeForm.$('start_date').disabled) {
                                                e.preventDefault();
                                            }
                                        }
                                    }
                                }
                            />}
                        />
                    </div>
                </GridItem>
                <GridItem xs={6}>
                    <div className={classes.dateTimeContainer}>
                        <span style={{ display: 'none' }}>{hireEmployeeForm.$('end_date').error}</span>
                        <Datetime
                            {...hireEmployeeForm.$('end_date').bind()}
                            isValidDate={this.validEndDate}
                            timeFormat={false}
                            renderInput={(props) => <CustomInput
                                labelText='End date'
                                error={!!hireEmployeeForm.$('end_date').error}
                                helpText={hireEmployeeForm.$('end_date').error || undefined}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={
                                    {
                                        ...props,
                                        disabled: hireEmployeeForm.$('end_date').disabled,
                                        onClick: (e) => {
                                            if (hireEmployeeForm.$('end_date').disabled) {
                                                e.preventDefault();
                                            }
                                        }
                                    }
                                }
                            />}
                        />
                    </div>
                </GridItem>
                <GridItem xs={6}>
                    <GridContainer>
                        <GridItem xs={12} sm={4}>
                            <FormLabel
                                className={
                                    classes.labelLeftHorizontal +
                                    " " +
                                    classes.labelHorizontalRadioCheckbox
                                }
                            >
                                Is Saturday a work day?
            </FormLabel>
                        </GridItem>
                        {isSaturdayWorkOptions.map(opt => <GridItem xs={12} sm={4} key={opt.value}>
                            <div
                                className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={hireEmployeeForm.$('expect_work_on_weekends').value === opt.value}
                                            onChange={hireEmployeeForm.$('expect_work_on_weekends').onChange}
                                            value={opt.value}
                                            name="radio button enabled"
                                            aria-label={opt.name}
                                            icon={
                                                <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                />
                                            }
                                            checkedIcon={
                                                <FiberManualRecord
                                                    className={classes.radioChecked}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label={opt.name}
                                />
                            </div>
                        </GridItem>)}
                    </GridContainer>
                </GridItem>
                <GridItem xs={6}>
                    <CustomSelect
                        labelText='How many hours in a work day?'
                        error={!!hireEmployeeForm.$('work_day_hours').error}
                        helpText={hireEmployeeForm.$('work_day_hours').error || undefined}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        selectProps={{
                            MenuProps: {
                                className: classes.selectMenu
                            },
                            classes: {
                                select: classes.select
                            },
                            inputProps: {
                                name: "select-in-modal",
                                id: "select-in-modal"
                            },
                            ...hireEmployeeForm.$('work_day_hours').bind()
                        }}
                        items={hireEmployeeForm.$('work_day_hours').extra}
                        itemRenderer={item => (
                            <MenuItem
                                key={item}
                                classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                }}
                                value={item}
                            >
                                {item}
                            </MenuItem>)}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <GridContainer>
                        <GridItem xs={8}>
                            <div className={classes.dateTimeContainer}>
                                <span style={{ display: 'none' }}>{hireEmployeeForm.$('work_start_time').error}</span>
                                <Datetime
                                    {...hireEmployeeForm.$('work_start_time').bind()}
                                    dateFormat={false}
                                    timeFormat={`h:mm`}
                                    timeConstraints={{
                                        hours: { min: 0, max: 12 }
                                    }}
                                    renderInput={(props) => <CustomInput
                                        labelText='Work start time'
                                        error={!!hireEmployeeForm.$('work_start_time').error}
                                        helpText={hireEmployeeForm.$('work_start_time').error || undefined}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={
                                            props
                                        }
                                    />}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={4}>
                            <CustomSelect
                                labelText=""
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                selectProps={{
                                    MenuProps: {
                                        className: classes.selectMenu
                                    },
                                    classes: {
                                        select: classes.select
                                    },
                                    inputProps: {
                                        name: "select-in-modal",
                                        id: "select-in-modal",
                                    },
                                    ...hireEmployeeForm.$('work_start_time_a').bind()
                                }}
                                items={hireEmployeeForm.$('work_start_time_a').extra}
                                itemRenderer={item => (
                                    <MenuItem
                                        key={item}
                                        classes={{
                                            root: classes.selectMenuItem,
                                            selected: classes.selectMenuItemSelected
                                        }}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>)}
                            />
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={6}>
                    <CustomInput
                        labelText='Rate ($/hr)'
                        error={!!hireEmployeeForm.$('rate').error}
                        helpText={hireEmployeeForm.$('rate').error || undefined}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={
                            hireEmployeeForm.$('rate').bind()
                        }
                    />
                </GridItem>
                <GridItem xs={12}>
                    <GridContainer>
                        <GridItem xs={12} sm={4}>
                            <FormLabel
                                className={
                                    classes.labelLeftHorizontal +
                                    " " +
                                    classes.labelHorizontalRadioCheckbox
                                }
                            >
                                Must Bring Tools?
            </FormLabel>
                        </GridItem>
                        {bringTools.map(opt => <GridItem xs={12} sm={4} key={opt.value}>
                            <div
                                className={
                                    classes.checkboxAndRadio +
                                    " " +
                                    classes.checkboxAndRadioHorizontal
                                }
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={hireEmployeeForm.$('must_bring_tools').value === opt.value}
                                            onChange={hireEmployeeForm.$('must_bring_tools').onChange}
                                            value={opt.value}
                                            name="radio button enabled"
                                            aria-label={opt.name}
                                            icon={
                                                <FiberManualRecord
                                                    className={classes.radioUnchecked}
                                                />
                                            }
                                            checkedIcon={
                                                <FiberManualRecord
                                                    className={classes.radioChecked}
                                                />
                                            }
                                            classes={{
                                                checked: classes.radio,
                                                root: classes.radioRoot
                                            }}
                                        />
                                    }
                                    classes={{
                                        label: classes.label
                                    }}
                                    label={opt.name}
                                />
                            </div>
                        </GridItem>)}
                    </GridContainer>
                </GridItem>
                <GridItem xs={12}>
                    <CustomInput
                        labelText='Special Instructions/Requirements *'
                        error={!!hireEmployeeForm.$('instructions').error}
                        helpText={hireEmployeeForm.$('instructions').error || undefined}
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={
                            hireEmployeeForm.$('instructions').bind()
                        }
                    />
                </GridItem>
                <GridItem xs={12}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5>Estimated Pay to Employee: ${hireEmployeeForm.$('estimatedPay').value} </h5>
                        <Tooltip classes={{
                            popper: classes.tooltip
                        }} title="This is calculated based on an employee working 8hrs/day for the number of days indicated. This amount may vary if the employee works fewer or longer hours per day. You pay the employee directly at your business after he completes his work."><Help style={styles.ml10} /></Tooltip>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h5>Platform Fee to hire this candidate: ${hireEmployeeForm.$('feePlatform').value} </h5>
                        <Tooltip classes={{
                            popper: classes.tooltip
                        }} title="This is the fee we collect to develop and maintain the platform as well as market and solicit candidates to join and get connected with you. Our business can’t survive without it, the same way your business can’t survive by offering free car repairs. We appreciate your support. Note that this fee will be refunded in case of a “No Show” and may be partially refunded if you cancel after the 1st day of work."><Help style={styles.ml10} /></Tooltip>
                    </div>
                </GridItem>

                <GridItem container xs={12}>
                    <label>By clicking Yes, you accept the Mechanics & Body Shops Marketplace Terms and Conditions of Use. You will not be charged the platform fee until the employee confirms</label>
                </GridItem>
            </GridContainer>
            <div>
                <Button className={classes.mr10} round onClick={onCancel}>NO</Button>
                <Button className={classes.ml10} color="primary" round onClick={onConfirm} loading={hireEmployeeForm.$('submitting').value} disabled={hireEmployeeForm.$('submitting').value}>YES</Button>
            </div>
        </SweetAlert >
    }
}

export default withStyles({ ...customInputStyle, ...extendedFormsStyle, ...regularFormsStyle, ...sweetAlertStyle, ...styles })(observer(HireEmployeeFormUI));