import { decorate, observable, action } from "mobx";
import {
  getNotificationList,
  getNotification,
  markAsReadNotification
} from "../services/domainServices/notificationDomain.service";
import * as _ from "lodash";

const initialUserNotificationPagination = {
  page: 1,
  items: [],
  pageSize: 20,
  totalPage: 0,
  totalItem: 0
};

const initialPagination = {
  pageSize: 20,
  page: 0,
  sort: "",
  order: ""
};

const notificationType = {
  NEW_EMPLOYEE_AVAILABLE: {
    prefix: "/employees-available/",
    metadata: ""
  },
  // NEW_JOB_AVAILABLE: "NEW_JOB_AVAILABLE",
  HIRING_REQUEST_SENT: {
    prefix: "/employees-pending/",
    metadata: "employee_request"
  },
  HIRING_REQUEST_ACCEPTED: {
    prefix: "/employees-hired/",
    metadata: "job_history"
  },
  HIRING_REQUEST_REJECTED: {
    prefix: "/employees-pending/",
    metadata: "employee_request"
  },
  // HIRING_REQUEST_EXPIRED: "/requests/sent",
  APPLYING_REQUEST_SENT: {
    prefix: "/applications/",
    metadata: "applying_request"
  },
  // APPLYING_REQUEST_ACCEPTED: "/applications",
  // APPLYING_REQUEST_REJECTED: "/applications",
  APPLYING_REQUEST_EXPIRED: {
    prefix: "/applications/",
    metadata: "applying_request"
  },
  // JOB_REQUEST_CANCELLED: "JOB_REQUEST_CANCELLED",
  // JOB_REQUEST_EXPIRED: "JOB_REQUEST_EXPIRED",
  // EMPLOYEE_REQUEST_CLOSED: "EMPLOYEE_REQUEST_CLOSED",
  EMPLOYEE_REQUEST_EXPIRED: {
    prefix: "/requests/sent/",
    metadata: "employee_request"
  }
};

class UserNotificationStore {
  userNotificationPagination = _.cloneDeep(initialUserNotificationPagination);

  navbarUserNotification = [];

  pagination = _.cloneDeep(initialPagination);

  currentUserNotification;

  reset() {
    this.userNotificationPagination = _.cloneDeep(
      initialUserNotificationPagination
    );
    this.pagination = _.cloneDeep(initialPagination);
    this.currentUserNotification = null;
  }

  setCurrentUserNotification = noti => {
    this.currentUserNotification = noti;
  };

  changePageOfPagination = page => {
    this.pagination.page = page;
  };

  changePageSizeOfPagination = pageSize => {
    this.pagination.pageSize = pageSize;
  };

  getUserNotificationList = (pageSize, page) => {
    return getNotificationList(pageSize, page).then(
      action(data => {
        this.userNotificationPagination = data;
      })
    );
  };

  getNotification = id => {
    return getNotification(id).then(
      action(data => {
        this.setCurrentUserNotification(data);
      })
    );
  };

  markAsReadUserNotification = id => {
    return markAsReadNotification(id);
  };

  setFlagReadNotification = flag => {
    return flag;
  };

  getNavbarUserNotification = () => {
    return getNotificationList(10, 0).then(
      action(data => {
        this.navbarUserNotification = data.items;
      })
    );
  };

  getNofiticationLink = notification => {
    this.markAsReadUserNotification(notification.id).then(() => {
      this.getNavbarUserNotification();
    });
    const type = notificationType[notification.type];
    let link = "";
    if (type) {
      link = `${type.prefix}${notification.metadata[type.metadata] || ""}`;
    }
    return link;
  };
}

decorate(UserNotificationStore, {
  userNotificationPagination: observable,
  pagination: observable,
  currentUserNotification: observable,
  navbarUserNotification: observable,
  changePageOfPagination: action,
  changePageSizeOfPagination: action,
  setCurrentUserNotification: action,
  getNotification: action,
  getUserNotificationList: action,
  markAsReadUserNotification: action,
  getNavbarUserNotification: action,
  reset: action
});

export default new UserNotificationStore();
