import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import {
  image,
  container,
  primaryColor,
  grayColor,
  textPrimaryColor,
  whiteColor,
  blackColor
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  container,
  recentPosts: {
    background: whiteColor,
    border: `1px solid ${grayColor[8]}`,
    padding: `16px 20px`,
    "@media screen and (max-width: 980px)": {
      display: "none !important"
    }
  },

  postTitle: {
    color: textPrimaryColor,
    fontWeight: 700,
    fontSize: 16,
    textTransform: "capitalize",
    marginBottom: 4,
    margin: `${theme.spacing.unit * 1}px 0`
  },
  postContent: {
    color: textPrimaryColor,
    "& ul": {
      margin: 0,
      padding: 0
    },
    "& li": {
      display: "block",
      position: "relative",
      padding: `${theme.spacing.unit * 1.5}px 0 ${theme.spacing.unit *
        1.5}px 16px`,
      borderBottom: `1px solid ${grayColor[8]}`,
      fontWeight: 400,
      fontSize: 16,
      color: blackColor,
      cursor: "pointer",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "20px",
        left: 2,
        width: 6,
        height: 6,
        borderRadius: "50%",
        background: textPrimaryColor
      },
      "&:last-child": {
        border: "none"
      }
    }
  }
});

class RecentPosts extends React.Component {
  constructor(props) {
    super(props);
    this.props.postsStore.getRecentPostsFromApi();
  }

  componentDidMount() {}

  componentWillUnmount() {}

  toPostDetail = id => {
    const { history } = this.props;
    const paramsId = this.props.match.params.id;
    if (id !== paramsId) {
      history.push(`/tech-wizard/post/${id}`);
    }
  };

  render() {
    const { classes } = this.props;
    const { recentPosts } = this.props.postsStore;

    return (
      <div className={classes.recentPosts}>
        <Typography className={classes.postTitle} color="textPrimary">
          Recent post
        </Typography>
        <div className={classes.postContent}>
          <ul>
            {recentPosts.map((item, key) => (
              <li key={key} onClick={() => this.toPostDetail(item.id)}>
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(RecentPosts))
  )
);
