import React from "react";
import ReactToPrint from "react-to-print";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectStripe } from "react-stripe-elements";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

// core components
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Loading from "components/Loading/Loading";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import Check from "@material-ui/icons/Check";
import amex from "assets/img/payment/amex.png";
import diners from "assets/img/payment/diners.png";
import discover from "assets/img/payment/discover.png";
import jcb from "assets/img/payment/jcb.png";
import masterCard from "assets/img/payment/master-card.svg";
import visa from "assets/img/payment/visa.png";
import unionpay from "assets/img/payment/unionpay.png";
import unknownCard from "assets/img/payment/unknown-card.png";

import { observer, inject } from "mobx-react";
import pricingSubscriptionPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingSubscriptionPageStyle.jsx";
import PaymentInfo from "./PaymentInfo/PaymentInfo";
import notificationService from "../../../services/notificationService";
import { downloadURI } from "../../../services/utils";

class Subscription extends React.Component {
  agreementRef;

  state = {
    step: "overall",
    subscription: null,
    payment: null,
    agree: false,
    employeeNumber: 1
  };

  subcribe = () => {
    this.setState({ step: "agreement_content" });
    this.props.getAvailableSubscriptionList().then(data => {
      if (data[0]) {
        this.props.getContentAgreement(data[0].agreement);
        this.setState({ subscription: data[0] });
        this.props.numberOfEmployeeSearchForm.$(
          "initial_payment_per_search"
        ).value = data[0].first_price;
        this.props.numberOfEmployeeSearchForm.$("total_initial_charge").value =
          data[0].first_price;
      }
    });
  };

  chooseOption = () => {
    this.setState({ step: "choose_option" });
  };

  choosePayment = () => {
    this.setState({ step: "choose_payment" });
  };

  selectPayment = payment => {
    this.setState({ payment });
    this.props.showConfirmSubscriptionModal(
      this.purchaseSubscription,
      this.props.numberOfEmployeeSearchForm.$("total_initial_charge").value
    );
  };

  setStep = step => () => {
    this.setState({ step });
  };

  purchaseSubscription = () => {
    this.props.closeConfirmSubscriptionModal();
    const { numberOfEmployeeSearchForm } = this.props;
    this.props
      .purchaseSubscription({
        subscription_id: this.state.subscription.id,
        payment_method_id: this.state.payment.id,
        employee_searches: numberOfEmployeeSearchForm.$(
          "number_of_employee_search"
        ).value,
        sales_rep: numberOfEmployeeSearchForm.$("sales_rep").value,
        other_sales_rep: numberOfEmployeeSearchForm.$("other_sales_rep").value
      })
      .then(data => {
        if (data.ok) {
          this.setState({
            step: "overall",
            subscription: null,
            payment: null
          });
          this.props.getSubscriptionInfo();
          notificationService.success("SETTINGS.SUBSCRIPTION_PURCHASE_SUCCESS");
          this.props.showSuccessfullSubscriptionModal();
        } else {
          if (data.status === "requires_action") {
            this.props.stripe
              .handleCardPayment(data.data.client_secret, {
                payment_method: this.state.payment.id
              })
              .then(result => {
                if (result.error) {
                  this.setState({
                    step: "overall",
                    subscription: null,
                    payment: null,
                    agree: false
                  });
                  // Display error.message in your UI.
                  notificationService.error(result.error.message);
                } else {
                  // The payment has succeeded. Display a success message.
                  this.setState({
                    step: "overall",
                    subscription: null,
                    payment: null,
                    agree: false
                  });
                  this.props.getSubscriptionInfo();
                  notificationService.success(
                    "SETTINGS.SUBSCRIPTION_PURCHASE_SUCCESS"
                  );
                  this.props.showSuccessfullSubscriptionModal();
                }
              });
          } else {
            this.setState({
              step: "overall",
              subscription: null,
              payment: null,
              agree: false
            });
            notificationService.error("COMMON.UNKNOWN_ERROR");
          }
        }
      });
  };

  getImageCard = brand => {
    let img;
    switch (brand) {
      case "amex":
        img = amex;
        break;
      case "diners":
        img = diners;
        break;
      case "discover":
        img = discover;
        break;
      case "jcb":
        img = jcb;
        break;
      case "mastercard":
        img = masterCard;
        break;
      case "unionpay":
        img = unionpay;
        break;
      case "visa":
        img = visa;
        break;
      case "unknown":
        img = unknownCard;
        break;

      default:
        break;
    }
    return img;
  };

  createMarkup = text => {
    return { __html: text };
  };

  changeAgreement = e => {
    this.setState({
      agree: e.target.checked
    });
  };

  downloadAgreement = () => {
    if (this.props.contentAgreement.file) {
      downloadURI(this.props.contentAgreement.file, "Agreement");
    }
  };

  onRedirectUpdateProfile = () => {
    const { history } = this.props;
    history.push("/update-profile?callback=settings?tab=subscription");
  };

  render() {
    const {
      classes,
      subscriptionInfo,
      addPaymentMethod,
      cardList,
      addCardForm,
      contentAgreement,
      numberOfEmployeeSearchForm,
      setDefaultCard,
      salesRep,
      userStore
    } = this.props;

    console.log(userStore.currentUser.preferences.business_name, "ssss");

    if (subscriptionInfo) {
      const { is_premium } = subscriptionInfo;
      return (
        <GridItem
          xs={12}
          sm={12}
          className={classes.justifyContent + " " + classes.dflex}
        >
          {this.state.step === "overall" && (
            <div>
              {is_premium ? (
                <div className={classes.overallWrapper}>
                  <h3>Thank you for signing up for our Recruiting Service</h3>
                  <div>
                    <Button color="primary" round onClick={this.subcribe}>
                      Add New Search
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {userStore.currentUser.preferences.business_name &&
                    userStore.currentUser.preferences.business_name.trim() !==
                      "" && (
                      <Button
                        color="primary"
                        size="lg"
                        round
                        onClick={this.subcribe}
                      >
                        Recruiting Services Sign-up
                      </Button>
                    )}
                  {(!userStore.currentUser.preferences.business_name ||
                    (userStore.currentUser.preferences.business_name &&
                      userStore.currentUser.preferences.business_name.trim() ===
                        "")) && (
                    <Button
                      color="primary"
                      size="lg"
                      round
                      onClick={() => this.onRedirectUpdateProfile()}
                    >
                      Recruiting Services Sign-up
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
          {this.state.step === "agreement_content" && (
            <GridItem xs={12}>
              {contentAgreement ? (
                <React.Fragment>
                  <div className={classes.agreementContentWrapper}>
                    <div
                      ref={el => (this.agreementRef = el)}
                      className={classes.agreementContent}
                      dangerouslySetInnerHTML={this.createMarkup(
                        contentAgreement.content
                      )}
                    />
                  </div>
                  <div className={classes.mb20}>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={this.changeAgreement}
                          checked={this.state.agree}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={
                        "I agree to the terms of the Recruiting Services Agreement"
                      }
                    />
                  </div>
                  <GridContainer>
                    <Button
                      className={classes.mra}
                      round
                      onClick={this.setStep("overall")}
                    >
                      Previous
                    </Button>
                    {/* <Button className={classes.ml10 + ' ' + classes.mr10} color="info" round onClick={this.downloadAgreement}>Download</Button> */}
                    <ReactToPrint
                      trigger={() => (
                        <Button
                          className={classes.ml10 + " " + classes.mr10}
                          color="rose"
                          round
                        >
                          Print
                        </Button>
                      )}
                      content={() => this.agreementRef}
                    />
                    <Button
                      className={classes.ml10}
                      color="primary"
                      round
                      disabled={!this.state.agree}
                      onClick={this.chooseOption}
                    >
                      Next
                    </Button>
                  </GridContainer>
                </React.Fragment>
              ) : (
                <Loading />
              )}
            </GridItem>
          )}
          {this.state.step === "choose_option" && (
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <GridContainer>
                    <GridItem xs={8} className={classes.labelText}>
                      Initial Payment Per Search:
                    </GridItem>
                    <GridItem xs={4} className={classes.labelText}>
                      $
                      {
                        numberOfEmployeeSearchForm.$(
                          "initial_payment_per_search"
                        ).value
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="baseline">
                    <GridItem xs={8} className={classes.labelText}>
                      Number of Employee Searches:
                    </GridItem>
                    <GridItem xs={4} className={classes.labelText}>
                      <CustomInput
                        labelText=""
                        error={
                          !!numberOfEmployeeSearchForm.$(
                            "number_of_employee_search"
                          ).error
                        }
                        helpText={
                          numberOfEmployeeSearchForm.$(
                            "number_of_employee_search"
                          ).error || undefined
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={numberOfEmployeeSearchForm
                          .$("number_of_employee_search")
                          .bind()}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={8} className={classes.labelText}>
                      Total Initial Charge:
                    </GridItem>
                    <GridItem xs={4} className={classes.labelText}>
                      $
                      {
                        numberOfEmployeeSearchForm.$("total_initial_charge")
                          .value
                      }
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="center">
                    <GridItem xs={8} className={classes.labelText}>
                      Assigned sales rep:
                    </GridItem>
                    <GridItem xs={4}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                            >
                              Choose option
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu
                              }}
                              classes={{
                                select: classes.select
                              }}
                              inputProps={{
                                name: "simpleSelect",
                                id: "simple-select"
                              }}
                              value={
                                numberOfEmployeeSearchForm.$("sales_rep").value
                              }
                              onChange={
                                numberOfEmployeeSearchForm.$("sales_rep")
                                  .onChange
                              }
                            >
                              {salesRep.map(item => (
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={item.code}
                                  key={item.code}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer alignItems="center">
                    <GridItem xs={8} className={classes.labelText} />
                    {numberOfEmployeeSearchForm.$("other_sales_rep").extra
                      .show && (
                      <GridItem xs={4} className={classes.labelText}>
                        <CustomInput
                          labelText="Other Sales Rep"
                          error={
                            !!numberOfEmployeeSearchForm.$("other_sales_rep")
                              .error
                          }
                          helpText={
                            numberOfEmployeeSearchForm.$("other_sales_rep")
                              .error || undefined
                          }
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={numberOfEmployeeSearchForm
                            .$("other_sales_rep")
                            .bind()}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <GridContainer justify="flex-end">
                    <Button
                      className={classes.mra}
                      round
                      onClick={this.setStep("agreement_content")}
                    >
                      Previous
                    </Button>
                    <GridItem>
                      <Button
                        className={classes.ml10}
                        color="primary"
                        round
                        disabled={
                          !!numberOfEmployeeSearchForm.$(
                            "number_of_employee_search"
                          ).error
                        }
                        onClick={this.choosePayment}
                      >
                        Next
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
          {this.state.step === "choose_payment" && (
            <GridItem xs={12}>
              <PaymentInfo
                addPaymentMethod={addPaymentMethod}
                cardList={cardList}
                selectPayment={this.selectPayment}
                isSelectPayment={true}
                addCardForm={addCardForm}
                setDefaultCard={setDefaultCard}
                previousStep={this.setStep("choose_option")}
              />
            </GridItem>
          )}
        </GridItem>
      );
    }
    return null;
  }
}

export default withRouter(
  injectStripe(
    withStyles(pricingSubscriptionPageStyle)(
      inject("userStore")(observer(Subscription))
    )
  )
);
