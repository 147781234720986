import React from 'react';

import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";

import amex from "assets/img/payment/amex.png";
import diners from "assets/img/payment/diners.png";
import discover from "assets/img/payment/discover.png";
import jcb from "assets/img/payment/jcb.png";
import masterCard from "assets/img/payment/master-card.svg";
import visa from "assets/img/payment/visa.png";
import unionpay from "assets/img/payment/unionpay.png";
import unknownCard from "assets/img/payment/unknown-card.png";

import { withStyles } from '@material-ui/core';


const styles = {
    cardWrapper: {
        marginBottom: '20px',
        display: 'flex'
    },
    imgWrapper: {
        padding: '0 15px',
    },
    image: {
        height: '40px',
        width: '50px'
    },
    cardNumber: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    name: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    expireDate: {
        fontSize: '16px',
        marginBottom: '10px'
    },
    defaultCardText: {
        fontSize: '14px'
    },
    btnGroup: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    deleteIcon: {
        marginRight: '0!important'
    },
    mb10: {
        marginBottom: '10px'
    }
}

class PaymentCard extends React.Component {

    setDefaultCard = () => {
        this.props.setDefaultCard(this.props.data.id);
    }

    deleteCard = () => {
        this.props.deleteCard(this.props.data.id);
    }

    selectPayment = () => {
        this.props.selectPayment(this.props.data);
    }

    getImageCard = (brand) => {
        let img;
        switch (brand) {
            case 'amex':
                img = amex;
                break;
            case 'diners': img = diners; break
            case 'discover': img = discover; break
            case 'jcb': img = jcb; break
            case 'mastercard': img = masterCard; break
            case 'unionpay': img = unionpay; break
            case 'visa': img = visa; break
            case 'unknown': img = unknownCard; break


            default: break;
        }
        return img;
    }

    render() {
        const { data, classes, isSelectPayment } = this.props;
        return <div className={classes.mb10}>
            <div className={classes.cardWrapper}>
                <div>
                    <img className={classes.image} src={this.getImageCard(data.card.brand)} alt="card" />
                </div>
                <GridItem xs={12} sm={12}>
                    <div className={classes.cardNumber}>&bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; {data && data.card && data.card.last4} {data.card.exp_month}/{data.card.exp_year}</div>
                    {/* <div className={classes.name}>{data && data.billing_details && data.billing_details.name}</div>
                    <div className={classes.expireDate}>{data && data.card && `Expire ${data.card.exp_month}/${data.card.exp_year}`}</div> */}
                    {!isSelectPayment && <div>
                        {data.is_default && <h5 className={classes.defaultCardText}>Future payments will use this card.</h5>}

                    </div>}
                </GridItem>
            </div>
            <div>
                {!isSelectPayment && <GridItem className={classes.btnGroup}>
                    {!data.is_default ? <Button color="primary" size="sm" round onClick={this.setDefaultCard}>
                        Pay with this card
    </Button> : <Button color="info" size="sm" disabled round>Default card</Button>}
                    <Button color="rose" size="sm" round onClick={this.deleteCard}>
                        <Close className={classes.deleteIcon} />
                    </Button>
                </GridItem>}
                {
                    isSelectPayment && <GridItem className={classes.btnGroup}>
                        <Button color="primary" size="sm" round onClick={this.selectPayment}>
                            Pay with this card
                    </Button>
                        {!data.is_default ? <Button color="primary" size="sm" round onClick={this.setDefaultCard}>
                            Set default card
                        </Button> : <Button color="info" size="sm" disabled round>Default card</Button>}
                    </GridItem>
                }
            </div>
        </div>;
    }
}

export default withStyles(styles)(PaymentCard);