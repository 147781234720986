import { createMuiTheme } from "@material-ui/core/styles";
import {
  textPrimaryColor,
  textSecondaryColor,
  hexToRgb
} from "../assets/jss/material-dashboard-pro-react";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Inter",
      "Helvetica",
      "Helvetica Neue",
      "Arial",
      "sans-serif"
    ].join(", ")
  },
  palette: {
    text: {
      primary: textPrimaryColor[0],
      secondary: textSecondaryColor[0],
      disabled: `rgba(${hexToRgb(textPrimaryColor[0])}, 0.38)`,
      hint: `rgba(${hexToRgb(textPrimaryColor[0])}, 0.38)`
    }
  }
});

export default theme;
