import React from "react";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  Popper,
  Hidden,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@material-ui/core";

import Button from "../../../../components/CustomButtons/Button";
import Notification from "../../../../components/Notification/Notification";

import logo from "../../../../assets/img/app-logo.png";
import { ReactComponent as IconDropDown } from "../../../../assets/img/icons/icon_arrow_down_profile.svg";
import { ReactComponent as IconAvatar } from "../../../../assets/img/icons/icon_avatar.svg";

import {
  image,
  container,
  primaryColor,
  noSidePadding
} from "../../../../assets/jss/material-dashboard-pro-react";

export const styles = theme => ({
  iconArrow: {
    marginLeft: theme.spacing.unit * 0.5,
    width: `${8}px !important`,
    transition: "all 1s ease"
  },
  open: {
    // transform: `rotate(180deg)`
  },
  header: {
    backgroundColor: "#E2E7EB",
    padding: "15px 0",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)"
  },
  container: container,
  logo: {
    flex: 1
  },
  main: {
    margin: 0,
    fontSize: "1em"
  },
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  navbarBtn: {
    marginLeft: 16,
    minWidth: 100,
    fontSize: 16,
    textTransform: "capitalize",
    fontWeight: 700,
    fontStyle: "normal",
    fontFamily: "Inter"
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  btnUser: {
    ...noSidePadding,
    "& svg": {
      width: "100%",
      height: "100%"
    }
  },
  avatarThumbnail: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    overflow: "hidden",
    "& img": {
      ...image,
      width: "100%",
      height: "100%"
    }
  },
  btnLabel: {
    marginLeft: 8,
    marginRight: 4,
    textTransform: "capitalize"
  }
});

class HeaderGeneral extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // console.log("this.props ", this.props);
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  goToProfile = () => {
    const { classes, history, userStore, style } = this.props;
  };

  logout = () => {
    const { classes, history, userStore } = this.props;
    this.props.authStore.logout();
    this.handleClose();
  };

  handleClickSetting = () => {
    const { history } = this.props;

    history.push("/tech-wizard/setting-account");
  };

  handleClickUpgradeToBusinessAccount = () => {
    const { history } = this.props;
    history.push("/create-business-account");
  };

  handleClickUpdateGUProfile = () => {
    const { history } = this.props;
    history.push("/tech-wizard/update-profile");
  };

  handleClickGoToJobsDashboard = () => {
    const { history } = this.props;
    history.push("/employees/employees-available");
  };

  render() {
    const { classes, history, userStore, location } = this.props;
    const { currentUserTW } = this.props.userStore;
    console.log("currentUserTW", currentUserTW);
    const { anchorEl, open } = this.state;
    const id = open ? "simple-popper" : null;
    const sorts = [
      // {
      //   value: "profile",
      //   label: "Profile",
      //   onClick: this.goToProfile
      // },

      {
        value: "setting",
        label: "Setting",
        onClick: this.handleClickSetting
      },
      {
        value: "logout",
        label: "Log out",
        onClick: this.logout
      }
    ];
    const sortsWithGeneral = [
      // {
      //   value: "profile",
      //   label: "Profile",
      //   onClick: this.goToProfile
      // },
      {
        value: " upgradeToBusinessAccount",
        label: " Upgrade to Business Account",
        onClick: this.handleClickUpgradeToBusinessAccount
      },
      {
        value: "setting",
        label: "Account Setting",
        onClick: this.handleClickSetting
      },
      {
        value: "updateGUProfile",
        label: "Profile",
        onClick: this.handleClickUpdateGUProfile
      },
      {
        value: "logout",
        label: "Log out",
        onClick: this.logout
      }
    ];

    const sortsWithBusiness = [
      // {
      //   value: "profile",
      //   label: "Profile",
      //   onClick: this.goToProfile
      // },
      {
        value: "goToJobsDashboard",
        label: "Go to Jobs Dashboard",
        onClick: this.handleClickGoToJobsDashboard
      },
      {
        value: "setting",
        label: "Setting",
        onClick: this.handleClickSetting
      },
      {
        value: "logout",
        label: "Log out",
        onClick: this.logout
      }
    ];

    return (
      <AppBar
        position="absolute"
        style={{
          zIndex: 1,
          background: this.props.style ? "none" : "#E2E7EB",
          display: location.pathname.includes("/unsubcribe") ? "none" : ""
        }}
        color="default"
        className={classes.appBar}
      >
        <div
          className={classes.header}
          style={{ background: this.props.style ? "none" : "#E2E7EB" }}
        >
          <div className={classes.container}>
            <Grid container wrap="nowrap">
              <Grid item xs={12} md={6}>
                <div className={classes.logo}>
                  <Typography className={classes.main}>
                    <a href="/">
                      <img className={classes.logoImage} src={logo} />
                    </a>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className={classes.navbar}>
                  {!userStore.currentUserTW ? (
                    <>
                      <Button
                        className={classes.navbarBtn}
                        color="primary"
                        outline
                        size={"sm"}
                        onClick={() => {
                          history.push("/auth/login-page");
                        }}
                      >
                        Log in
                      </Button>
                      <Button
                        className={classes.navbarBtn}
                        color="primary"
                        variant="outlined"
                        size={"sm"}
                        onClick={() => {
                          history.push("/auth/register-page/general");
                        }}
                      >
                        Sign Up
                      </Button>
                    </>
                  ) : (
                    <>
                      <div style={{ margin: "10px 15px 0 0" }}>
                        <Notification backgroundColorWhite={this.props.style} />
                      </div>

                      <Button
                        aria-describedby={id}
                        simple={true}
                        onClick={this.handleClick}
                        color={"primary"}
                        className={classes.btnUser}
                      >
                        <div className={classes.avatarThumbnail}>
                          {currentUserTW && currentUserTW.profile.avatar ? (
                            <img
                              className={classes.logoImage}
                              src={currentUserTW.profile.avatar}
                            />
                          ) : (
                            <IconAvatar />
                          )}
                        </div>
                        <span className={classes.btnLabel}>
                          {currentUserTW.profile.user_name ||
                            currentUserTW.profile.first_name}
                        </span>
                        <IconDropDown
                          className={classNames({
                            [classes.iconArrow]: true,
                            [classes.open]: open
                          })}
                        />
                      </Button>
                      <Popper
                        style={{ zIndex: 2 }}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        transition
                        placement={"bottom-end"}
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper>
                              <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList
                                  role="menu"
                                  className={classes.menuSort}
                                >
                                  {(sorts.length > 0 ||
                                    sortsWithGeneral.length > 0 ||
                                    sortsWithBusiness > 0) &&
                                    (
                                      (currentUserTW.role === "user_general" &&
                                        sortsWithGeneral) ||
                                      (currentUserTW.role ===
                                        "business_owner" &&
                                        sortsWithBusiness) ||
                                      sorts
                                    ).map((item, key) => (
                                      <MenuItem
                                        key={key}
                                        onClick={() => {
                                          item.onClick();
                                        }}
                                        className={classes.dropdownItem}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* <FeedBack
            isOpen={this.showCreatePosts}
            handleCloseDialog={handleCloseModal}
          /> */}
      </AppBar>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "authStore")(observer(HeaderGeneral))
  )
);
