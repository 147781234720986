export default {
  "APPLICATION.PURCHASE_SUCCESS": "Payment Sent",
  "APPLICATION.ADD_CARD_SUCCESS": "Card Added",
  "APPLICATION.REJECTED_APPLICATION": "Application Rejected",
  "EMPLOYEE.SENT_HIRE": "Job Request Sent",
  "EMPLOYEE.REPORTED": "Reported employee",
  "EMPLOYEE.SENT_REVIEW": "Review Sent",
  "EMPLOYEE.CANCELLED_HIRE": "Cancelled hire employee",
  "EMPLOYEE.ADD_CARD_SUCCESS": "Card Added",
  "REGISTER.SIGNUP_SUCCESS": "You Signed up Successfully",
  "REGISTER.SIGNUP_FAILED": "Mobile number or email address already exists",
  "REQUEST.NUMBER_WORK_DAY_MUST_GREATER_THAN_ZERO":
    "Number of work days must be greater than 0",
  "REQUEST.SENT_REQUEST": "Request Sent",
  "REQUEST.SAVED_REQUEST": "Request Saved",
  "REQUEST.CLOSED_REQUEST": "Request Closed",
  "REVIEW.SENT_REPLY": "Reply Sent",
  "REVIEW.UPDATED_REVIEW": "Review Updated",
  "REVIEW.SENT_REVIEW": "Review Sent",
  "SETTINGS.SUBSCRIPTION_PURCHASE_SUCCESS": "Payment Sent",
  "SETTINGS.CHANGE_PASSWORD_SUCCESS": "Password Changed",
  "SETTINGS.CHANGE_NOTIFICATION_SUCCESS": "Changes Saved",
  "SETTINGS.SET_DEFAULT_CARD_SUCCESS": "Changes Saved",
  "SETTINGS.DELETE_CARD_SUCCESS": "Card Deleted",
  "SETTINGS.ADD_CARD_SUCCESS": "Card Added",
  "TRANSACTION.PURCHASE_SUCCESS": "Payment Sent",
  "TRANSACTION.ADD_CARD_SUCCESS": "Card Added",
  "PROFILE.UPDATED_PROFILE": "Profile Updated",
  "FORGOT_PASSWORD.SENT_REQUEST": "Request Sent",
  "LOGIN.SUCCESS": "Login Successful!",
  "LOGIN.JOBSEEKER_SUCCESS":
    "We apologize but the Web App. is not yet available.  Please download the IOS or Android mobile App. and log in.",
  "RESET_PASSWORD.SUCCESS": "Pasword Reset",
  "COMMON.UNKNOWN_ERROR":
    "Something went wrong. We apologize for this issue. Please try again later."
};
