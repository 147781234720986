import {
  drawerWidth,
  transition,
  containerFluid,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const appStyle = theme => ({
  ...buttonStyle,
  ...customCheckboxRadioSwitch,
  ...extendedFormsStyle,
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  mb15: {
    marginBottom: '15px'
  },
  labelSettings: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "20px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container: { ...containerFluid },
  map: {
    marginTop: "70px"
  },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  hintWrapper: {
    textAlign: 'center'
  },
  radioWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  formWrapper: {
    padding: '10px 20px',
    textAlign: 'initial'
  },
  rateIcon: {
    color: primaryColor[0],
    fontSize: '40px'
  },
  floatRight: {
    float: 'right'
  },
  ml10: {
    marginLeft: '10px'
  },
  mr10: {
    marginRight: '10px'
  },
  mra: {
    marginRight: 'auto'
  }
});

export default appStyle;
