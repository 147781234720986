import {
  getNotificationListApi,
  getNotificationApi,
  markAsReadNotificationApi
} from "../dataServices/notificationData.service";

const getNotificationList = async (type, status, pageSize, page) =>
  getNotificationListApi(type, status, pageSize, page);

const getNotification = id => getNotificationApi(id);

const markAsReadNotification = id => markAsReadNotificationApi(id);

export { getNotificationList, getNotification, markAsReadNotification };
