import AvailableEmployees from "../views/Employees/AvailableEmployees.jsx";
import Applications from "../views/Applications/Applications.jsx";
import ApplicationDetails from "../views/Applications/ApplicationDetails.jsx";
import Settings from "../views/Settings/Settings.jsx";
import UpdateProfile from "../views/UpdateProfile/UpdateProfile.jsx";

import History from "@material-ui/icons/History";
import GetApp from "@material-ui/icons/GetApp";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import RateReview from "@material-ui/icons/RateReview";
import WifiTethering from "@material-ui/icons/WifiTethering";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Group from "@material-ui/icons/Group";

import NewRequest from "../views/RequestEmployee/NewRequest.jsx";
import RequestsSaved from "../views/RequestEmployee/RequestsSaved.jsx";
import EditRequest from "../views/RequestEmployee/EditRequest.jsx";
import RequestsSent from "../views/RequestEmployee/RequestsSent.jsx";
import Notifications from "../views/Notifications.jsx";
import PendingEmployees from "../views/Employees/PendingEmployees.jsx";
import HiredEmployees from "../views/Employees/HiredEmployees.jsx";
import AvailableEmployeeDetails from "../views/Employees/EmployeeDetails/AvailableEmployeeDetails.jsx";
import PendingEmployeeDetails from "../views/Employees/EmployeeDetails/PendingEmployeeDetails.jsx";
import HiredEmployeeDetails from "../views/Employees/EmployeeDetails/HiredEmployeeDetails.jsx";
import ReceivedReviews from "../views/Reviews/ReceivedReviews.jsx";
import SentReviews from "../views/Reviews/SentReviews.jsx";
import TransactionList from "../views/Transaction/TransactionList.jsx";
import WriteReview from "../views/Reviews/WriteReview.jsx";
import TechWizard from "../views/TechWizard/techWizard/TechWizard";
import { appRole } from "../constants/app.constant.js";

import RecentPostsPage from "../views/PostDetail/postDetail";
import PostsPage from "../views/Posts/posts";
import CreatePost from "../views/Posts/createPost";
import FeaturesInformation from "../views/FeaturesInformation.jsx";
import EditPost from "../views/Posts/editPost.jsx";
import ListNotification from "../components/Notification/listNotification.jsx";
import CreateBusinessInfo from "../views/CreateBusinessAccount/CreateBusinessInfo.jsx";
import SettingAccount from "../views/SettingAccount/SettingAccount.jsx";
import Unsubscribe from "../views/Unsubscribe/Unsubscribe.jsx";
import LoginPage from "../views/LoginPage.jsx";
import GeneralRegister from "../views/Register/GeneralRegister.jsx";
import UpdateGUProfile from "../views/UpdateGUProfile/UpdateGUProfile.jsx";
import RecruitingServiceSignUp from "../views/RecruitingServiceSignUp.js";
import DeleteAccount from "../views/DeleteAccount/DeleteAccount.jsx";

const authRoutes = [
  {
    path: "/",
    regex: /^\/$/,
    name: "Homepage",
    component: AvailableEmployees,
    views: [],
    profileUpdated: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/tech-wizard/posts",
    regex: /^\/tech-wizard\/posts/,
    name: "Posts Page",
    component: PostsPage,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/post/:id",
    regex: /^\/tech-wizard\/post\/.+$/,
    name: "Post Detail Page",
    component: RecentPostsPage,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/create-post",
    regex: /^\/tech-wizard\/create-post*$/,
    name: "Create Post Page",
    component: CreatePost,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/edit-post/:id",
    regex: /^\/tech-wizard\/edit-post\/.+$/,
    name: "Edit Post Page",
    component: EditPost,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/notification",
    regex: /^\/tech-wizard\/notification/,
    name: "Notification Page",
    component: ListNotification,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/setting-account",
    regex: /^\/tech-wizard\/setting-account$/,
    name: "Account Setting",
    component: SettingAccount,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/update-profile",
    regex: /^\/tech-wizard\/update-profile$/,
    name: "Update Profile",
    profileUpdated: true,
    component: UpdateGUProfile,
    layout: "general",
    views: [],
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/tech-wizard/unsubcribe/:userid/:email",
    regex: /^\/tech-wizard\/unsubcribe\/.+$/,
    name: "Unsubcribe Page",
    component: Unsubscribe,
    layout: "general",
    views: [],
    profileUpdated: false,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/employees",
    regex: /^\/employees/,
    collapse: true,
    name: "Employees Available",
    icon: SupervisorAccount,
    state: "employeesAvailableCollapse",
    showInSidebar: true,
    roles: [appRole.admin, appRole.business],
    profileUpdated: false,
    views: [
      {
        path: "/employees/employees-available",
        regex: /^\/employees\/employees-available$/,
        name: "Browse Employees",
        mini: "",
        component: AvailableEmployees,
        profileUpdated: true
      },
      {
        path: "/employees/employees-pending",
        regex: /^\/employees\/employeesemployees-pending$/,
        name: "Employees Pending",
        mini: "",
        component: PendingEmployees,
        profileUpdated: true,
        hasBadge: true,
        badgeField: "pending"
      }
    ]
  },
  {
    path: "/request",
    regex: /^\/request/,
    collapse: true,
    name: "Request Employee",
    icon: WifiTethering,
    state: "requestEmployeeCollapse",
    showInSidebar: true,
    roles: [appRole.admin, appRole.business],
    profileUpdated: true,
    views: [
      {
        path: "/request/new",
        regex: /^\/request\/new$/,
        name: "New Request",
        mini: "",
        component: NewRequest,
        profileUpdated: true
      },
      {
        path: "/request/saved",
        regex: /^\/requests\/saved$/,
        name: "Requests Saved",
        mini: "",
        component: RequestsSaved,
        profileUpdated: true
      },
      {
        path: "/request/sent",
        regex: /^\/requests\/sent$/,
        name: "Requests Sent",
        mini: "",
        component: RequestsSent,
        profileUpdated: true
      }
    ]
  },
  {
    path: "/applications",
    regex: /^\/applications$/,
    name: "Applications Received",
    icon: GetApp,
    component: Applications,
    showInSidebar: true,
    roles: [appRole.admin, appRole.business],
    profileUpdated: true,
    hasBadge: true,
    badgeField: "applications"
  },
  {
    path: "/employees-hired",
    regex: /^\/employees-hired$/,
    name: "Revealed/Hired",
    icon: AssignmentInd,
    component: HiredEmployees,
    profileUpdated: true,
    showInSidebar: true,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/transaction-history",
    regex: /^\/transaction-history$/,
    name: "Transaction History",
    icon: History,
    component: TransactionList,
    roles: [appRole.admin, appRole.business],
    profileUpdated: true
  },
  {
    name: "Reviews",
    path: "/reviews",
    regex: /^\/reviews/,
    icon: RateReview,
    collapse: true,
    state: "reviewsCollapse",
    showInSidebar: true,
    roles: [appRole.admin, appRole.business],
    profileUpdated: true,
    views: [
      {
        path: "/reviews/new",
        regex: /^\/reviews\/new$/,
        name: "Write Review",
        mini: "",
        component: WriteReview,
        profileUpdated: true
      },
      {
        path: "/reviews/sent",
        regex: /^\/reviews\/sent$/,
        name: "Reviews Sent",
        mini: "",
        component: SentReviews,
        profileUpdated: true
      },
      {
        path: "/reviews/received",
        regex: /^\/reviews\/received$/,
        name: "Reviews Received",
        mini: "",
        component: ReceivedReviews,
        profileUpdated: true
      }
    ]
  },
  {
    path: "/settings",
    regex: /^\/settings$/,
    name: "Settings",
    component: Settings,
    roles: [appRole.admin, appRole.business],
    profileUpdated: true
  },
  {
    path: "/update-profile",
    regex: /^\/update-profile$/,
    name: "Update Profile",
    component: UpdateProfile,
    roles: [appRole.admin, appRole.business],
    profileUpdated: false
  },
  {
    path: "/employees-available/:id",
    regex: /^\/employees-available\/.+$/,
    name: "Employee Details",
    component: AvailableEmployeeDetails,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/employees-pending/:id",
    regex: /^\/employees-pending\/.+$/,
    name: "Employee Details",
    component: PendingEmployeeDetails,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/employees-hired/:id",
    regex: /^\/employees-hired\/.+$/,
    name: "Employee Details",
    component: HiredEmployeeDetails,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  // {
  //   path: "/request/new",
  //   regex: /^\/request\/new$/,
  //   name: "New Request",
  //   component: NewRequest,
  //   profileUpdated: true,
  //   showInSidebar: false,
  //   roles: [appRole.admin, appRole.business]
  // },
  {
    path: "/requests/saved/:id",
    regex: /^\/requests\/saved\/.+$/,
    name: "Edit Request",
    component: EditRequest,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/requests/sent/:id",
    regex: /^\/requests\/sent\/.+$/,
    name: "Edit Request",
    component: EditRequest,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/applications/:id",
    regex: /^\/applications\/.+$/,
    name: "Application Detail",
    component: ApplicationDetails,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/notifications",
    regex: /^\/notifications$/,
    name: "Notifications",
    component: Notifications,
    profileUpdated: true,
    showInSidebar: false,
    roles: [appRole.admin, appRole.business]
  },
  {
    path: "/features",
    regex: /^\/features$/,
    name: "Features",
    profileUpdated: true,
    showInSidebar: false,
    component: FeaturesInformation,
    roles: [appRole.general],
    views: []
  },
  {
    path: "/features/recruiting-service-sign-up", // /^\/tech-wizard\/posts/,
    regex: /^\/features\/recruiting-service-sign-up$/,
    name: "Recruiting Services Sign Up",
    profileUpdated: true,
    showInSidebar: false,
    component: RecruitingServiceSignUp,
    roles: [appRole.general, appRole.job_seeker]
  },
  {
    path: "/tech-wizard",
    regex: /^\/tech-wizard/,
    name: "Tech Wizard Forum",
    component: TechWizard,
    icon: Group,
    showInSidebar: true,
    profileUpdated: false,
    otherBound: true,
    roles: [appRole.general, appRole.job_seeker, appRole.business],
    views: []
  },

  {
    path: "/create-business-account",
    regex: /^\/create-business-account$/,
    name: "Create Business Account",
    profileUpdated: true,
    showInSidebar: false,
    component: CreateBusinessInfo,
    roles: [appRole.general, appRole.admin, appRole.business]
  },
  {
    path: "/delete-account",
    regex: /^\/delete-account$/,
    name: "Delete Account",
    profileUpdated: true,
    showInSidebar: false,
    component: DeleteAccount,
    roles: [appRole.general, appRole.job_seeker, appRole.business]
  },
  {
    path: "/auth/login-page",
    regex: /^\/auth\/login-page$/,
    name: "Login Page",
    profileUpdated: true,
    showInSidebar: false,
    component: LoginPage,
    roles: [
      appRole.general,
      appRole.admin,
      appRole.business,
      appRole.job_seeker
    ]
  },
  {
    path: "/auth/register-page/general",
    regex: /^\/auth\/register-page\/general$/,
    name: "Register Page",
    profileUpdated: true,
    showInSidebar: false,
    component: GeneralRegister,
    roles: [
      appRole.general,
      appRole.admin,
      appRole.business,
      appRole.job_seeker
    ]
  }
];

export default authRoutes;
