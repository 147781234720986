import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AuthNavbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footer/Footer";

import routes from "../routes/unauthRoutes";

import style from "../assets/jss/material-dashboard-pro-react/layouts/authStyle";

import registration from "../assets/img/background/bg-registration.png";

class AuthLayout extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "unset";
  }

  getRoutes = () => {
    const { path, component } = this.props;
    const { views } = this.props;
    if (views && views.length > 0) {
      return views.map((item, key) => (
        <Route path={item.path} component={item.component} exact key={key} />
      ));
    } else {
      return <Route path={path} component={component} exact />;
    }
  };

  getBgImage = () => {
    return registration;
  };

  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  render() {
    const { classes, regex, path, location, ...rest } = this.props;

    if (regex && regex.test(location.pathname)) {
      return (
        <>
          <div className={classes.wrapper} ref="wrapper">
            <AuthNavbar brandText={this.getActiveRoute(routes)} {...rest} />
            <div
              className={classes.fullPage}
              style={{ backgroundImage: "url(" + this.getBgImage() + ")" }}
            >
              <Switch>{this.getRoutes()}</Switch>
              <Footer white />
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(style)(inject("commonStore", "userStore")(observer(AuthLayout)))
);
