import { decorate, observable, action, computed } from "mobx";
import RequestForm from "./form/request";
import {
  getRequestsList,
  createRequest,
  updateRequest,
  getRequestById,
  closeRequest,
  deleteListings
} from "../services/domainServices/requestsDomain.service";
import * as moment from "moment";
import * as _ from "lodash";
import { getDiffDays } from "services/utils";
import stores from ".";
import commonStore from "./commonStore";

const initialRequestsPagination = {
  page: 1,
  items: [],
  pageSize: 20,
  totalPage: 0,
  totalItem: 0
};

const initialPagination = {
  pageSize: 20,
  page: 0,
  sort: "start_date",
  order: "desc"
};

class RequestsEmployeeStore {
  requestsPagination = _.cloneDeep(initialRequestsPagination);
  pageViewing;

  savedPaginationParams = _.cloneDeep(initialPagination);
  sentPaginationParams = _.cloneDeep(initialPagination);

  selectedRequest = { _id: "" };
  isLoading = false;
  checked = false;

  constructor() {
    this.requestForm = new RequestForm();
  }

  get pagination() {
    let ret;
    switch (this.pageViewing) {
      case "saved":
        ret = this.savedPaginationParams;
        break;
      case "sent":
        ret = this.sentPaginationParams;
        break;
      default:
        break;
    }
    return ret;
  }

  reset() {
    this.selectedRequest = { _id: "" };
    this.requestForm = new RequestForm();
  }

  setPageViewing(page) {
    this.pageViewing = page;
  }

  getRequestsSavedList = (pageSize, page, sort, order) => {
    this.isLoading = true;
    return getRequestsList("saved", pageSize, page, sort, order).then(
      action(data => {
        this.isLoading = false;
        this.requestsPagination = {
          ...data,
          items: data.items.map((item, index) => ({
            ...item,
            number: index + 1 + page * pageSize,
            checked: false
          }))
        };
      })
    );
  };

  getRequestsSentList = (pageSize, page, sort, order) => {
    this.isLoading = true;
    return getRequestsList(
      "sent,expired,closed",
      pageSize,
      page,
      sort,
      order
    ).then(
      action(data => {
        this.isLoading = false;
        this.requestsPagination = {
          ...data,
          items: data.items.map((item, index) => ({
            ...item,
            number: index + 1 + page * pageSize,
            checked: false
          }))
        };
      })
    );
  };

  changePageOfPagination = page => {
    switch (this.pageViewing) {
      case "saved":
        this.savedPaginationParams.page = page;
        break;
      case "sent":
        this.sentPaginationParams.page = page;
        break;
      default:
        break;
    }
  };

  changePageSizeOfPagination = pageSize => {
    switch (this.pageViewing) {
      case "saved":
        this.savedPaginationParams.pageSize = pageSize;
        break;
      case "sent":
        this.sentPaginationParams.pageSize = pageSize;
        break;
      default:
        break;
    }
  };

  changeSortOfPagination = sort => {
    let order = "desc";
    switch (this.pageViewing) {
      case "saved":
        if (
          this.savedPaginationParams.sort === sort &&
          this.savedPaginationParams.order === "desc"
        ) {
          order = "asc";
        }
        this.savedPaginationParams.order = order;
        this.savedPaginationParams.sort = sort;
        break;
      case "sent":
        if (
          this.sentPaginationParams.sort === sort &&
          this.sentPaginationParams.order === "desc"
        ) {
          order = "asc";
        }
        this.sentPaginationParams.order = order;
        this.sentPaginationParams.sort = sort;
        break;
      default:
        break;
    }
  };

  createRequest(request) {
    return createRequest(request);
  }

  updateRequest(id, request) {
    return updateRequest(id, request);
  }

  closeRequest(id) {
    return closeRequest(id);
  }

  getRequestById = id => {
    return getRequestById(id).then(
      action(data => {
        this.selectRequestEmployee(data);
      })
    );
  };

  selectRequestEmployee = request => {
    this.selectedRequest = request;
    if (
      request.must_bring_tools !== null &&
      request.must_bring_tools !== undefined
    ) {
      request.must_bring_tools = request.must_bring_tools ? "yes" : "no";
    } else {
      request.must_bring_tools = "n/a";
    }
    request.end_date = moment(request.end_date).tz(stores.userStore.timeZone);
    request.start_date = moment(request.start_date).tz(
      stores.userStore.timeZone
    );
    if (request.job_type === "temporary") {
      request.work_start_time = moment(request.work_start_time.h, "h:mm");
      request.work_start_time_a = request.work_start_time.a;
      request.numberOfDays = getDiffDays(
        request.end_date,
        request.start_date,
        request.expect_work_on_weekends === "yes"
      );
    }
    request.expect_work_on_weekends =
      request.expect_work_on_weekends === null
        ? "somewk"
        : request.expect_work_on_weekends
        ? "yes"
        : "no";

    this.requestForm.set(request);
    if (request.function === "automotive_tech") {
      this.requestForm.$("autotech_level").set("rules", "required");
      this.requestForm.$("autotech_level").set("extra", { isShow: true });
    }
    if (request.function === "body_technician") {
      this.requestForm.$("bodytech_type").set("rules", "required");
      this.requestForm.$("bodytech_type").set("extra", { isShow: true });
    }
    const { geolocation } = request;
    if (geolocation && geolocation.state) {
      commonStore.getCitiesByState(geolocation.state).then(data => {
        if (!data.find(item => item === geolocation.city)) {
          data.push(geolocation.city);
        }
        this.requestForm.$("geolocation.city").set(
          "extra",
          data.map(item => ({
            label: item,
            value: item
          }))
        );
      });
    }
    this.requestForm.validate();
  };

  handleAllCheckboxItems = checked => {
    this.checked = checked;
    this.requestsPagination = {
      ...this.requestsPagination,
      items: this.requestsPagination.items.map(item => ({
        ...item,
        checked: checked
      }))
    };
  };

  handleCheckboxItem = id => {
    let itemListing = this.requestsPagination.items.find(
      listing => listing.id === id
    );
    itemListing = {
      ...itemListing,
      checked: !itemListing.checked
    };
    this.requestsPagination = {
      ...this.requestsPagination,
      items: this.requestsPagination.items.map(item => {
        if (item.id === id) return itemListing;
        else return item;
      })
    };
    const checkedArray = this.requestsPagination.items.filter(
      item => item.checked === true
    );
    if (checkedArray.length === this.requestsPagination.items.length) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  };

  deleteListings = payload => deleteListings(payload);
}

decorate(RequestsEmployeeStore, {
  requestsPagination: observable,
  pagination: computed,
  selectedRequest: observable,
  requestForm: observable,
  isLoading: observable,
  checked: observable,
  pageViewing: observable,
  getRequestById: action,
  selectRequestEmployee: action,
  getRequestsSavedList: action,
  getRequestsSentList: action,
  createRequest: action,
  updateRequest: action,
  closeRequest: action,
  changePageOfPagination: action,
  changePageSizeOfPagination: action,
  changeSortOfPagination: action,
  reset: action,
  setPageViewing: action
});

export default new RequestsEmployeeStore();
