import {
    getReviewListApi,
    getReviewApi,
    updateReviewApi,
    createReviewApi,
    createReplyApi,
    getHiredEmployeeListApi
} from '../dataServices/reviewData.service';

const getReviewList = (type, pageSize, page) => getReviewListApi(type, pageSize, page);

const getReview = id => getReviewApi(id);

const updateReview = (id, data) => updateReviewApi(id, data);

const createReview = data => createReviewApi(data);

const createReply = (id, data) => createReplyApi(id, data);

const getHiredEmployeeList = () => getHiredEmployeeListApi();

export {
    getReview,
    getReviewList,
    updateReview,
    createReview,
    createReply,
    getHiredEmployeeList
}