import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
  primaryColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const otpPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  checkRoot: {
    padding: "0",
    marginRight: "5px"
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "19px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  bottomText: {
    marginTop: "30px",
    fontSize: "16px",
    fontWeight: "400",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  sendAgainLink: {
    color: primaryColor[0],
    cursor: "pointer"
  },

  timer: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "400",
    color: "rgba(7, 86, 126, 1)"
  },
  icon: {
    marginBottom: "30px",
    display: "block",
    margin: "0 auto"
  },
  submitOtp: {
    width: "30%"
  }
});

export default otpPageStyle;
