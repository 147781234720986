import React from "react";
import { inject, observer } from "mobx-react";
import authRoutes from "../../routes/authRoutes";
import GeneralLayout from "../../layouts/general/generalLayout";
import BusinessLayout from "../../layouts/Business";
import { appRole } from "../../constants/app.constant";

class AuthorizedRoute extends React.Component {
  componentDidMount() {
    const { userStore, history } = this.props;
    if (!userStore.currentUser && !userStore.currentUserTW) {
      history.push("/auth/login-page");
    }
  }
  renderTechWizard(pathname) {
    return pathname === "/tech-wizard" || pathname === "/tech-wizard/" ? (
      <></>
    ) : (
      // <Error wrongUrl={false} />
      <></>
    );
  }
  render() {
    const { userStore } = this.props;
    const { pathname } = this.props.location;

    return (
      <>
        {userStore.currentUserTW &&
          userStore.currentUserTW.role &&
          (pathname.includes("tech-wizard") ||
            pathname.includes("features") ||
            pathname.includes("create-business-account") ||
            pathname.includes("delete-account") ||
            pathname.includes("/auth/login-page") ||
            pathname.includes("/auth/register-page/general")) &&
          authRoutes.map((prop, key) => {
            if (prop.roles.includes(userStore.currentUserTW.role)) {
              return <GeneralLayout {...prop} key={key} />;
            } else {
              return null;
            }
          })}

        {userStore.currentUser &&
        userStore.currentUser.user &&
        !pathname.includes("tech-wizard") &&
        userStore.currentUser.user.role === appRole.business ? (
          <BusinessLayout
            routes={authRoutes.filter(item =>
              item.roles.includes(userStore.currentUser.user.role)
            )}
          />
        ) : (
          this.renderTechWizard(pathname)
        )}
      </>
    );
  }
}

export default inject("userStore")(observer(AuthorizedRoute));
