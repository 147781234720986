import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Rating from 'react-rating';
import SweetAlert from "react-bootstrap-sweetalert";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";
import AccountCircle from "@material-ui/icons/AccountCircle";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { withStyles } from '@material-ui/core';

// images
import { inject, observer } from 'mobx-react';
import Loading from '../../../components/Loading/Loading';
import ForemanProfile from './components/ForemanProfile';
import ServiceAdvisorProfile from './components/ServiceAdvisorProfile';
import AutoBodyTechnicianProfile from './components/AutoBodyTechnicianProfile';
import CollisionServiceAdvisorProfile from './components/CollisionServiceAdvisorProfile';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import notificationService from '../../../services/notificationService';
import { convertDisplayText } from 'services/utils';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { getFieldFromListByKey, getDiffDays } from '../../../services/utils';
import BackPreviousPageButton from '../../../components/BackPreviousPageButton';
import BreachOfBusiness from "components/Navbars/BreachOfBusiness";
import Reviews from './components/Reviews';

const styles = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    container: {
        padding: '10px 20px'
    },
    avatar: {
        marginTop: 0
    },
    ml10: {
        marginLeft: '10px'
    },
    mt15: {
        marginTop: '15px'
    },
    checkboxLabelControl: {
        marginLeft: '5px'
    },
    wrapperPaymentImg: {
        height: '80px'
    },
    paymentImg: {
        height: '100%',
        width: '100%'
    },
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    fullHeight: {
        height: '100%'
    },
    buttonLink: {
        display: 'inline',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        fontSize: '14px',
        color: '#F8981F',
        padding: 0,
        fontWeight: 'bold',
        textDecoration: 'underline',
        '&:focus, &:hover': {
            color: '#bcbb47'
        }
    },
    mb30: {
        marginBottom: '30px'
    },
    showReviewBtn: {
        border: 'none',
        padding: '0',
        background: 'none',
        fontSize: '.875rem',
        fontWeight: 'bold',
        marginLeft: '10px',
        '&:hover': {
            textDecoration: 'underline',
            color: '#00acc1',
            cursor: 'pointer'
        }
    },
    rateIcon: {
        color: '#F8981F'
    }
}

class PendingEmployeeDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            howItWorksModal: null,
            confirmCancelModal: null,
            breachOfBusinessModal: null,
            reviewsModal: null
        }
        this.props.employeesStore.reset();
    }


    componentDidMount() {
        this.props.employeesStore.getPendingEmployeeById(this.employeeId);
    }

    get employeeId() {
        return this.props.match.params.id;
    }

    toggleHowItWorksModal = () => {
        if (!this.state.howItWorksModal) {
            this.setState({
                howItWorksModal: (
                    <SweetAlert
                        title="How it works"
                        onConfirm={this.toggleHowItWorksModal}
                        onCancel={this.toggleHowItWorksModal}
                        confirmBtnCssClass={
                            this.props.classes.button + " " + this.props.classes.success
                        }
                        style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <ul>
                                <li>Click on “Interested” to find out your estimated cost and platform fee.</li>
                                <li> Click on “Hire” to pay the platform fee and instruct the candidate to go to your shop on the available dates and time your shop opens. The candidate may send you a confirmation message. You may also be able to contact the candidate directly. You will pay the employee directly for the agreed rate and hours worked.</li>
                                <li>If the employee does not show up, click on “NO SHOW” and you will receive a full refund on your fee.</li>
                                <li>If you don’t want the employee to come back after the 1st day, click “CANCEL” and receive a partial credit on your fee paid. It’s only fair. Learn about our <button className={this.props.classes.buttonLink} onClick={this.showBreachOfBusinessModal}>Breach of Contract</button> terms by clicking <button className={this.props.classes.buttonLink} onClick={this.showBreachOfBusinessModal}>here</button>.</li>
                            </ul>
                        </div>
                    </SweetAlert>
                )
            });
        } else {
            this.setState({ howItWorksModal: null });
        }
    }

    showBreachOfBusinessModal = () => {
        this.toggleHowItWorksModal();
        this.setState({
            breachOfBusinessModal: <BreachOfBusiness onSave={this.onSaveBreachOfBusiness} onPrint={this.onPrintBreachOfBusiness} onCancel={this.closeBreachOfBusinessModal} data={this.props.commonStore.contentBreachOfBusiness} />
        })
    }

    closeBreachOfBusinessModal = () => {
        this.setState({
            breachOfBusinessModal: null
        })
    }

    onSaveBreachOfBusiness = () => { }

    onPrintBreachOfBusiness = () => { }


    showConfirmCancelModal = () => {
        this.setState({
            confirmCancelModal: <SweetAlert
                warning
                title=""
                onConfirm={this.confirmCancel}
                onCancel={this.closeConfirmCancelModal}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
            >
                Are you sure you want to cancel the employee ?
      </SweetAlert>
        })
    }

    closeConfirmCancelModal = () => {
        this.setState({
            confirmCancelModal: null
        })
    }

    confirmCancel = () => {
        const { currentEmployee, cancelHireEmployee } = this.props.employeesStore;
        cancelHireEmployee(currentEmployee.id).then(() => {
            this.closeConfirmCancelModal();
            notificationService.success('EMPLOYEE.CANCELLED_HIRE');
            this.props.history.push('/employees-pending');
        })
    }

    renderDetailEmployee = (type, emp) => {
        const { toolSet } = this.props.commonStore;
        switch (type) {
            case 'automotive_tech':
            case 'automotive_foreman':
                return <GridContainer>
                    <GridItem container xs={12}>
                        <ForemanProfile skills={emp.job_seeker.preferences.skills.automotive_tech_foreman} toolSet={toolSet} />
                    </GridItem>
                </GridContainer>
            case 'body_technician':
                return <GridContainer>
                    <GridItem container xs={12}>
                        <AutoBodyTechnicianProfile skills={emp.job_seeker.preferences.skills.body_technician} />
                    </GridItem>
                </GridContainer>;
            case 'service_manager':
            case 'service_advisor':
                return <GridContainer>
                    <GridItem container xs={12}>
                        <ServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.service_advisor_manager} />
                    </GridItem>
                </GridContainer>
            case 'collision_service_advisor':
                return <GridContainer>
                    <GridItem container xs={12}>
                        <CollisionServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.collision_service_advisor} />
                    </GridItem>
                </GridContainer>;
            case 'poster_helper':
            case 'other':
            default:
                break;
        }
    }

    showReviewsModal = () => {
        const { getReviews, currentEmployee } = this.props.employeesStore;
        getReviews(currentEmployee.job_seeker.id).then(res => {
            this.setState({
                reviewsModal: <Reviews handleClose={this.closeReviewsModal} reviews={res.items}/>
            })
        });
    }

    closeReviewsModal = () => {
        this.setState({
            reviewsModal: null
        })
    }

    render() {
        const { classes } = this.props;
        const { currentEmployee } = this.props.employeesStore;
        const { functions } = this.props.commonStore;
        const { timeZone } = this.props.userStore;

        if (currentEmployee) {
            const { job_seeker } = currentEmployee;
            return <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.mt15}>
                        <BackPreviousPageButton />
                    </div>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AccountCircle />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Basic Information</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className={classes.container}>
                                <GridItem xs={10}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <GridContainer>
                                                {/* <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Full name:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{`${job_seeker.profile.first_name} ${job_seeker.profile.last_name}`}</p>
                                                </GridItem> */}
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Job Seeker Id:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{job_seeker.job_seeker_id}</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Location:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{`${job_seeker.profile.location.city}, ${job_seeker.profile.location.state}, ${job_seeker.profile.location.zip_code}`}</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Distance from shop:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{Math.ceil(currentEmployee.distance)} miles</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Date available:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{timeZone && moment(currentEmployee.hiring_requirements.start_date).tz(timeZone).format('MMM DD YYYY')} to {timeZone && moment(currentEmployee.hiring_requirements.end_date).tz(timeZone).format('MMM DD YYYY')} ({getDiffDays(moment(currentEmployee.hiring_requirements.end_date).tz(timeZone), moment(currentEmployee.hiring_requirements.start_date).tz(timeZone), currentEmployee.hiring_requirements.expect_work_on_weekends)} days)</p>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <GridContainer>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Function:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{currentEmployee.job_request.function === 'other' ? `Other - ${currentEmployee.job_request.other_function}` : getFieldFromListByKey(functions, 'name', 'value', currentEmployee.job_request.function)}</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Rate:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>${currentEmployee.hiring_requirements.rate}/hr</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <div className={classes.label}>Willing to travel:</div>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.willing_to_travel} miles</p>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={2}>
                                    <GridContainer className={classes.fullHeight} direction="column" justify="space-between">
                                        <Button color="info" fullWidth onClick={this.toggleHowItWorksModal}>
                                            How it works
                                        </Button>
                                        <Button fullWidth color="danger" onClick={this.showConfirmCancelModal}>
                                            Cancel
                                        </Button>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Divider className={classes.mbt10} />
                                        </GridItem>
                                        <GridItem xs={10}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <GridContainer>
                                                        <GridItem xs={4}>
                                                            <div className={classes.label}>Listing #:</div>
                                                        </GridItem>
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>{currentEmployee.listing_id}</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={6}>
                                                    <GridContainer>
                                                        <GridItem xs={4}>
                                                            <div className={classes.label}>Status:</div>
                                                        </GridItem>
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>{convertDisplayText(currentEmployee.status, '_')}</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <Divider className={classes.mbt10} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem container xs={6}>
                                            <h3 className={classes.label}>Profile</h3>
                                        </GridItem>
                                        <GridItem container xs={6} justify="flex-end" alignItems="center">
                                            <Rating
                                                initialRating={currentEmployee.job_seeker.profile.rating}
                                                readonly
                                                fractions={2}
                                                emptySymbol={<StarBorder  className={classes.rateIcon} />}
                                                fullSymbol={<StarRate  className={classes.rateIcon} />}
                                            /> <b>({currentEmployee.job_seeker.profile.rating} / 5) </b><button className={classes.showReviewBtn} onClick={this.showReviewsModal}>Reviews ({(currentEmployee.job_seeker.profile.rating_raw && currentEmployee.job_seeker.profile.rating_raw.count) || 0})</button>
                                        </GridItem>
                                        <GridItem container xs={6} ></GridItem>
                                        <GridItem container xs={6} justify="flex-end" alignItems="center">
                                            <div>
                                                <b>No shows: {currentEmployee.job_seeker.preferences.no_shows}</b>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Years of Auto Repair Industry Experience:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.years_of_experience}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Training Courses Completed:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.completed_training_courses.join(', ')}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Highest Level Of Education:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.education_level}</p>
                                        </GridItem>
                                    </GridContainer>
                                    {
                                        this.renderDetailEmployee(currentEmployee.job_request.function, currentEmployee)
                                    }
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Other Information:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.other_information}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>DMV record with less than 1 minor infraction:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.clean_dmv_record ? 'Yes' : `No (${currentEmployee.job_seeker.preferences.reason_no_dmv_record})`}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer className={classes.mb30}>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Job Seeker Authorization to conduct background check:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.authorization_to_dmv ? 'Yes' : `No`}</p>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {this.state.howItWorksModal}
                                {this.state.confirmCancelModal}
                                {this.state.breachOfBusinessModal}
                                {this.state.reviewsModal}
                                <BackPreviousPageButton />
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }
        return <Loading />
    }
}

export default withStyles(styles)(inject('employeesStore', 'commonStore', 'userStore')(observer(PendingEmployeeDetails)));