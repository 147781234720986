import {
  container,
  defaultFont,
  cardTitle,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  blackColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const pricingSubscriptionPageStyle = theme => ({
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  checkboxLabelControl: {
    margin: "0"
  },
  checkboxLabel: {
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.35)"
  },
  checkRoot: {
    padding: "0",
    marginRight: '5px'
  },
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  title: {
    ...defaultFont,
    color: whiteColor,
    marginTop: "5vh",
    marginBottom: "30px",
    textAlign: "center"
  },
  description: {
    fontSize: "18px",
    color: whiteColor,
    textAlign: "center"
  },
  cardTitlePrimary: {
    ...cardTitle,
    color: primaryColor[0] + " !important"
  },
  cardCategory: {
    color: grayColor[0],
    marginTop: "0px",
    fontSize: '16px',
    fontWeight: '400',
    marginBottom: '10px',
    lineHeight: '16px'
  },
  cardCategoryWhite: {
    color: whiteColor,
    marginTop: "10px"
  },
  price: {
    color: "rgba(" + hexToRgb(primaryColor[0]) + ", 0.76)",
    margin: "15px auto 15px",
    width: "150px",
    height: "150px",
    border: "1px solid " + grayColor[11],
    borderRadius: "50%",
    "& svg": {
      width: "70px",
      height: "70px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "70px",
      fontSize: "70px"
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '26px'
  },
  marginTop30: {
    marginTop: "30px"
  },
  cardContainer: {
    borderRadius: '3px'
  },
  btnWrapper: {
    margin: '0 5px',
    display: 'block'
  },
  dflex: {
    display: 'flex'
  },
  justifyContent: {
    justifyContent: 'center'
  },
  overallWrapper: {
    textAlign: 'center'
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    background: 'none',
    fontSize: '14px',
    color: '#F8981F',
    padding: 0,
    '&:focus, &:hover': {
      color: '#bcbb47'
    }
  },
  mra: {
    marginRight: 'auto'
  },
  ml10: {
    marginLeft: '10px'
  },
  mr10: {
    marginRight: '10px'
  },
  mb20: {
    marginBottom: '20px'
  },
  cardWrapper: {
    marginBottom: '20px',
    display: 'flex'
  },
  imgWrapper: {
    padding: '0 15px',
  },
  image: {
    height: '40px',
    width: '50px'
  },
  cardNumber: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  name: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  expireDate: {
    fontSize: '16px',
    marginBottom: '10px'
  },
  labelText: {
    fontWeight: 'bold',
    marginBottom: '10px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  agreementContentWrapper: {
    maxHeight: '500px',
    borderRadius: '3px',
    border: '1px solid #546e7a',
    overflowY: 'auto',
    marginBottom: '20px'
  },
  agreementContent: {
    margin: '20px',
  }
});

export default pricingSubscriptionPageStyle;
