import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Button from "../../../components/CustomButtons/Button";
import FilterItem from "../filterItem/filterItem";

import { whiteColor } from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  filterGroup: {
    background: "transparent",
    ["@media(max-width: 980px)"]: {
      margin: "10px 0 !important"
    }
  },

  filterGroupTitle: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Inter",
    marginBottom: 12
  },
  filterGroupActions: {
    padding: 20,
    background: whiteColor,
    marginTop: -1,
    position: "relative",
    border: `1px solid #eee`,
    borderTop: "none"
  },
  filterGroupBtns: {
    textTransform: "capitalize",
    margin: 0,
    fontSize: 16,
    fontWeight: 700,
    minHeight: 44
  }
});

class FilterGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  handleChange = () => {};

  render() {
    const { classes, showFilterActions, redirectUrl } = this.props;
    const {
      category,
      year,
      make,
      clearParams,
      getPostsFromApi,
      setCurrentPage
    } = this.props.postsStore;

    return (
      <div className={classes.filterGroup}>
        <Typography className={classes.filterGroupTitle} color="textPrimary">
          Filter by
        </Typography>
        <FilterItem filterItem={category} redirectUrl={redirectUrl} />
        <FilterItem filterItem={year} redirectUrl={redirectUrl} />
        <FilterItem filterItem={make} redirectUrl={redirectUrl} />
        {showFilterActions && (
          <div className={classes.filterGroupActions}>
            <Grid
              container
              alignItems={"center"}
              justify={"space-between"}
              spacing={16}
            >
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.filterGroupBtns}
                  color="primary"
                  size={"sm"}
                  outline
                  fullWidth={true}
                  onClick={() => {
                    clearParams();
                    getPostsFromApi();
                  }}
                >
                  Clear Filter
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  className={classes.filterGroupBtns}
                  color="primary"
                  size={"sm"}
                  fullWidth={true}
                  onClick={() => (setCurrentPage(0), getPostsFromApi())}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

FilterGroup.propTypes = {
  showFilterActions: PropTypes.bool
};

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(FilterGroup))
  )
);
