import requests from "../http";
import { convertPagingResponse } from "../utils";

const getReviewListApi = (type, pageSize, page) =>
    requests
        .get(`/api/reviews?type=${type}&page=${++page}&page_size=${pageSize}`)
        .then(data => convertPagingResponse(data));

const getReviewApi = id => requests.get(`/api/reviews/${id}`);

const createReviewApi = data => {
    delete data.id;
    delete data.submitting;
    const dataPost = {
        ...data,
        receiver: data.receiver.value
    };
    return requests.post(`/api/reviews`, dataPost);
};

const updateReviewApi = (id, data) => {
    delete data.id;
    delete data.submitting;
    const dataPost = {
        ...data,
        receiver: data.receiver.value
    };
    return requests.put(`/api/reviews/${id}`, dataPost);
};

const createReplyApi = (id, data) => {
    delete data.submitting;
    return requests.post(`/api/reviews/${id}/reply`, data);
};

const getHiredEmployeeListApi = () =>
    requests.get(`/api/reviews/search`);

export {
    getReviewListApi,
    getReviewApi,
    updateReviewApi,
    createReviewApi,
    createReplyApi,
    getHiredEmployeeListApi
};
