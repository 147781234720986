import {
    getAvailableEmployeesListApi,
    getAvailableEmployeeByIdApi,
    getPendingEmployeesListApi,
    getPendingEmployeeByIdApi,
    hireEmployeeApi,
    getHiredEmployeesListApi,
    getHiredEmployeeByIdApi,
    reportNoShowEmployeeApi,
    cancelHireEmployeeApi,
    getReviewsApi
} from "../dataServices/employeesData.service";

const getAvailableEmployeesList = (
    jobType,
    func,
    pageSize,
    page,
    sort,
    order
) => {
    return getAvailableEmployeesListApi(
        jobType,
        func,
        pageSize,
        page,
        sort,
        order
    );
};

const getAvailableEmployeeById = id => {
    return getAvailableEmployeeByIdApi(id);
};

const getPendingEmployeesList = (pageSize, page, sort, order) => {
    return getPendingEmployeesListApi(pageSize, page, sort, order);
};

const getPendingEmployeeById = id => {
    return getPendingEmployeeByIdApi(id);
};

const getHiredEmployeesList = (pageSize, page, sort, order) => {
    return getHiredEmployeesListApi(pageSize, page, sort, order);
};

const getHiredEmployeeById = id => {
    return getHiredEmployeeByIdApi(id);
};

const hireEmployee = (id, data) => {
    return hireEmployeeApi(id, data);
};

const cancelHireEmployee = id => {
    return cancelHireEmployeeApi(id);
};

const reportNoShowEmployee = id => {
    return reportNoShowEmployeeApi(id);
};

const getReviews = id => {
    return getReviewsApi(id);
};

export {
    getAvailableEmployeesList,
    getAvailableEmployeeById,
    hireEmployee,
    getPendingEmployeesList,
    getPendingEmployeeById,
    getHiredEmployeeById,
    getHiredEmployeesList,
    reportNoShowEmployee,
    cancelHireEmployee,
    getReviews
};
