// @material-ui/icons
import Image from "@material-ui/icons/Image";
import JobSeekerRegister from "../views/Register/JobSeekerRegister";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import OnBoardingOtp from "../views/OnBoardingOtp";
import GeneralRegister from "../views/Register/GeneralRegister";
import TechWizard from "../views/TechWizard/techWizard/TechWizard";
import LoginPage from "../views/LoginPage";
import PostsPage from "../views/Posts/posts";
import RecentPostsPage from "../views/PostDetail/postDetail";
import CreatePost from "../views/Posts/createPost";
import Unsubscribe from "../views/Unsubscribe/Unsubscribe";

const unauthRoutes = [
  {
    path: "/auth",
    regex: /^\/auth/,
    collapse: true,
    name: "Pages Auth",
    icon: Image,
    state: "pageCollapse",
    layout: "auth",
    component: null,
    views: [
      {
        path: "/auth/login-page",
        name: "Login Page",
        mini: "L",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/auth/register-page/general",
        name: "Register Page",
        mini: "R",
        component: GeneralRegister,
        layout: "/auth"
      },
      {
        path: "/auth/forgot-password",
        name: "Forgot Password",
        mini: "F",
        component: ForgotPassword,
        layout: "/auth"
      },
      {
        path: "/auth/reset-password",
        name: "Reset Password",
        mini: "R",
        component: ResetPassword,
        layout: "/auth"
      },
      {
        path: "/auth/verify-otp",
        name: "Verify Otp",
        mini: "V",
        component: OnBoardingOtp,
        layout: "/auth"
      },
      {
        path: "/auth/tech-wizard",
        name: "Tech Wizard",
        component: TechWizard,
        layout: "/auth"
      }
    ]
  },
  {
    path: "/sample",
    regex: /^\/sample/,
    collapse: true,
    name: "Pages",
    icon: Image,
    state: "pageCollapse",
    layout: "auth",
    component: JobSeekerRegister,
    views: []
  },
  {
    path: "/tech-wizard",
    regex: /^\/tech-wizard/,
    name: "Homepage Tech Wizard",
    component: TechWizard,
    layout: "general",
    profileUpdated: false,
    role: [],
    views: []
  },
  {
    path: "/tech-wizard/posts",
    regex: /^\/tech-wizard\/posts/,
    name: "Posts Page",
    component: PostsPage,
    layout: "general",
    views: [],
    profileUpdated: false
  },
  {
    path: "/tech-wizard/post/:id",
    regex: /^\/tech-wizard\/post\/.+$/,
    name: "Post Detail Page",
    component: RecentPostsPage,
    layout: "general",
    views: [],
    profileUpdated: false
  },
  {
    path: "/tech-wizard/create-post",
    regex: /^\/tech-wizard\/create-post*$/,
    name: "Create Post Page",
    component: CreatePost,
    layout: "general",
    views: [],
    profileUpdated: false
  },
  {
    path: "/tech-wizard/unsubcribe/:userid/:email",
    regex: /^\/tech-wizard\/unsubcribe\/.+$/,
    name: "Unsubcribe Page",
    component: Unsubscribe,
    layout: "general",
    views: [],
    profileUpdated: false
  }
];
export default unauthRoutes;
