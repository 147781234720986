import React from "react";
import { withRouter } from 'react-router-dom';
import Rating from 'react-rating';

// react component for creating dynamic tables
import { inject, observer } from 'mobx-react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import IntegrationReactSelect from "components/CustomSelect/IntegrationReactSelect";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import notificationService from "../../services/notificationService";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  rateIcon: {
    color: '#F8981F',
    fontSize: '40px'
  },
  mb20: {
    marginBottom: '20px'
  },
  mla: {
    marginLeft: 'auto'
  },
  mt10: {
    marginTop: '10px'
  },
  plr15: {
    padding: '0 15px'
  },
  textCenter: {
    textAlign: 'center'
  }
};

class WriteReview extends React.Component {

  constructor(props) {
    super(props);
    this.props.reviewStore.resetForm();
  }

  componentDidMount() {
    this.props.reviewStore.getHiredEmployeeList();
  }

  submitReview = () => {
    const { reviewForm, createReview } = this.props.reviewStore;
    reviewForm.submit({
      onSuccess: (form) => {
        const data = form.values();
        reviewForm.$('submitting').set(true);
        createReview(data).then(() => {
          notificationService.success('REVIEW.SENT_REVIEW');
          reviewForm.$('submitting').set(false);
          this.props.history.push('/reviews/sent');
        }).catch(() => {
          reviewForm.$('submitting').set(false);
        });
      },
      onError: (form) => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { reviewForm, hiredEmployeeList } = this.props.reviewStore;
    const { timeZone } = this.props.userStore;

    const empList = hiredEmployeeList && hiredEmployeeList.map(emp => ({ label: `${emp.first_name} ${emp.last_name} - ${moment(emp.start_date).tz(timeZone).format('MMM DD YYYY')} to ${moment(emp.end_date).tz(timeZone).format('MMM DD YYYY')}(#${emp.listing_id})`, value: emp.id, listing_id: emp.listing_id }))
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon className={classes.header}>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Write Reviews</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.formWrapper}>
                <div className={classes.mb20}>
                  <IntegrationReactSelect
                    id="emp-select"
                    label={'Employee *'}
                    error={!!reviewForm.$('receiver').error}
                    helpText={reviewForm.$('receiver').error || undefined}
                    options={empList}
                    value={reviewForm.$('receiver').value}
                    onChange={reviewForm.$('receiver').onChange}
                    placeholder={'Select employee'}
                    isClearable={false}
                    />
                </div>
                <div className={classes.textCenter}>
                  <label>How satisfied were you overall of the employee's skills, performance, punctuality and team work?</label>
                  <div className={classes.mt10}>
                    <Rating
                      initialRating={reviewForm.$('rating').value}
                      emptySymbol={<StarBorder className={classes.rateIcon} />}
                      fullSymbol={<StarRate className={classes.rateIcon} />}
                      onChange={reviewForm.$('rating').onChange}
                    />
                  </div>
                </div>
                <div>
                  <CustomInput
                    labelText='Type your review *'
                    formControlProps={{
                      fullWidth: true
                    }}
                    error={!!reviewForm.$('comment').error}
                    helpText={reviewForm.$('comment').error || undefined}
                    inputProps={
                      {
                        multiline: true,
                        rows: 4,
                        ...reviewForm.$('comment').bind()
                      }
                    }
                  />
                </div>
              </div>
              <GridContainer justify="flex-end">
                <div className={classes.plr15}>
                  <Button color="primary" round onClick={this.submitReview} loading={reviewForm.$('submitting').value} disabled={reviewForm.$('submitting').value}>Create Review</Button>
                </div>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(inject('reviewStore', 'commonStore', 'userStore')(withRouter(observer(WriteReview))));
