import requests from "../http";

const getSubscriptionInfoApi = () =>
    requests
        .get(`/api/subscription/info`);

const getAvailableSubscriptionListApi = () => requests.get('/api/subscription/available');

const purchaseSubscriptionApi = data => requests.post('/api/subscription/subscribe', data);

export {
    getSubscriptionInfoApi,
    getAvailableSubscriptionListApi,
    purchaseSubscriptionApi
};
