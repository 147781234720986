import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import { appBreadcrumbs } from "../../constants/app.constant";
import PageLayout from "../../layouts/pageLayout/pageLayout";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { InputAdornment } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IntegrationReactSelect from "../../components/CustomSelect/IntegrationReactSelect";
import "./updateGUProfile.css";
import commonStore from "../../stores/commonStore";
import { Help } from "@material-ui/icons";
import Button from "../../components/CustomButtons/Button";
import notificationService from "../../services/notificationService";

const styles = theme => ({
  containerWrap: {
    display: "flex",
    flexDirection: "column"
  },
  container: {
    background: "white",
    alignSelf: "center",
    padding: 24,
    borderRadius: 10,
    width: "60%"
  },
  infoText: {
    textAlign: "center",
    fontWeight: "300"
  },
  btnSave: {
    display: "flex",
    marginTop: 20,
    justifyContent: "flex-end"
  },
  disabled: {
    color: "rgb(93 93 93 / 75%)",
    "&:disabled": {
      color: "rgb(93 93 93 / 75%)"
    },
    "&:hover": {
      cursor: "not-allowed"
    }
  }
});

class UpdateGUProfile extends React.Component {
  constructor(props) {
    super(props);
    this.avatarRef = React.createRef();
  }

  state = {
    isSubscribe: false
  };

  componentDidMount() {
    const { userStore } = this.props;
    userStore.getProfile();
    if (userStore.currentUser) {
      userStore.profileForm
        .$("avatar")
        .set(userStore.currentUser.profile.avatar || "");
      userStore.profileForm
        .$("first_name")
        .set(userStore.currentUser.profile.first_name || "");
      userStore.profileForm
        .$("last_name")
        .set(userStore.currentUser.profile.last_name || "");
      userStore.profileForm
        .$("user_name")
        .set(userStore.currentUser.profile.user_name || "");
      userStore.profileForm
        .$("email")
        .set(userStore.currentUser.profile.email || "");
      userStore.profileForm
        .$("mobile_number")
        .set(userStore.currentUser.profile.mobile_number || "");
      userStore.profileForm
        .$("location.state")
        .set(userStore.currentUser.profile.location.state || "");
      userStore.profileForm
        .$("location.city")
        .set(userStore.currentUser.profile.location.city || "");
      userStore.profileForm
        .$("location.zip_code")
        .set(userStore.currentUser.profile.location.zip_code || "");

      userStore.profileForm.validate();
    }
    const fields = ["user_name", "email"];
    fields.forEach(field => {
      if (userStore.profileForm.$(field)) {
        userStore.profileForm.$(field).set("disabled", true);
      }
    });
  }

  handleClickSave = () => {
    const {
      profileForm,
      updateGUProfile,
      currentUserTW
    } = this.props.userStore;
    this.avatarRef.current.handleSubmit();
    profileForm.submit({
      onSuccess: async form => {
        const data = {
          ...form.values()
        };
        if (typeof data.avatar === "string") {
          const body = {
            profile: {
              first_name: data.first_name || "",
              last_name: data.last_name || "",
              // email: data.email || "",
              mobile_number: data.mobile_number || null,
              location: {
                state:
                  data.location.state.value ||
                  profileForm.$("location.state").value ||
                  null,
                city:
                  data.location.city.value ||
                  profileForm.$("location.state").value ||
                  null,
                zip_code: data.location.zip_code || null
              }
            }
          };
          await updateGUProfile(currentUserTW.userId, body)
            .then(() => {
              this.props.userStore.getProfile();

              notificationService.success("Updated!");
            })
            .catch(() => notificationService.error("Update Profile Fail!"));
        } else {
          const avatar = await this.props.userStore.uploadAvatar(data.avatar);
          const body = {
            profile: {
              avatar: avatar,
              first_name: data.first_name || "",
              last_name: data.last_name || "",
              // email: data.email || "",
              mobile_number: data.mobile_number || null,
              location: {
                state:
                  data.location.state.value ||
                  profileForm.$("location.state").value ||
                  null,
                city:
                  data.location.city.value ||
                  profileForm.$("location.state").value ||
                  null,
                zip_code: data.location.zip_code || null
              }
            }
          };
          await updateGUProfile(currentUserTW.userId, body)
            .then(() => {
              this.props.userStore.getProfile();

              notificationService.success("Updated!");
            })
            .catch(() => notificationService.error("Update Profile Fail!"));
        }
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { profileForm } = this.props.userStore;
    const { state } = commonStore;
    const stateReactSelect = state.map(item => ({ label: item, value: item }));

    return (
      <PageLayout pageTitle={false} sidebar={[]}>
        <div className={classes.containerWrap}>
          <Breadcrumbs items={appBreadcrumbs.updateGUProfile} />
          <GridContainer justify="center" className={classes.container}>
            <GridItem xs={12} sm={12}>
              <h4 className={classes.infoText}>Update Profile</h4>
            </GridItem>
            <GridItem xs={12} sm={4}>
              {/* {profile.best_workplace ? (
                <div className="picture-container">
                  <img
                    src={bestWorkplaceBadge}
                    alt="Best Workplace"
                    style={{ marginLeft: "20px" }}
                  />
                </div>
              ) : (
                <div className="picture-container" />
              )} */}
              <PictureUpload
                ref={this.avatarRef}
                defaultImage={profileForm.$("avatar").value}
                handleUpload={profileForm.$("avatar").onChange}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="First Name*"
                error={!!profileForm.$("first_name").error}
                helpText={profileForm.$("first_name").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={profileForm.$("first_name").bind()}
              />
              <CustomInput
                labelText="Last Name*"
                error={!!profileForm.$("last_name").error}
                helpText={profileForm.$("last_name").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={profileForm.$("last_name").bind()}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <CustomInput
                labelText="Alias*"
                error={!!profileForm.$("user_name").error}
                helpText={profileForm.$("user_name").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={profileForm.$("user_name").bind()}
                classes={{
                  root: classes.disabled,
                  disabled: classes.disabled
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={10}>
              <CustomInput
                labelText="Email*"
                error={!!profileForm.$("email").error}
                helpText={profileForm.$("email").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={profileForm.$("email").bind()}
                classes={{
                  root: classes.disabled,
                  disabled: classes.disabled
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <CustomInput
                labelText="Mobile Phone"
                error={!!profileForm.$("mobile_number").error}
                helpText={profileForm.$("mobile_number").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="We request your mobile number for security purpose only.  We use it to authenticate users and to deter hackers. We don't share your mobile number with anyone and we only use it with your prior permission.">
                        <Help className={classes.inputAdornmentIcon} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  ...(profileForm.$("mobile_number").bind() || "")
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <IntegrationReactSelect
                label={"State*"}
                id="state-select"
                error={!!profileForm.$("location.state").error}
                helpText={profileForm.$("location.state").error || undefined}
                options={stateReactSelect}
                value={profileForm.$("location.state").value}
                onChange={profileForm.$("location.state").onChange}
                placeholder={"Select State"}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <IntegrationReactSelect
                label={"City*"}
                id="city-select"
                error={!!profileForm.$("location.city").error}
                helpText={profileForm.$("location.city").error || undefined}
                options={profileForm.$("location.city").extra}
                value={profileForm.$("location.city").value}
                onChange={profileForm.$("location.city").onChange}
                placeholder={"Select City"}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={10}>
              <CustomInput
                labelText="Zipcode"
                error={!!profileForm.$("location.zip_code").error}
                helpText={profileForm.$("location.zip_code").error || undefined}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={profileForm.$("location.zip_code").bind()}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={10}
              className={classes.btnSave}
            >
              <Button
                type="submit"
                color={"primary"}
                onClick={this.handleClickSave}
              >
                Save
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(UpdateGUProfile))
  )
);
