import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import "./deleteAccount.css";
import { Button } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput";
import { deleteAccountApi } from "../../services/dataServices/userData.service";
import notificationService from "../../services/notificationService";
const styles = () => ({
  pageSubTitle: {
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  titleBar: {
    marginBottom: 16
  },
  contentField: {
    paddingTop: 12
  },
  contentText: {
    width: "100%"
  },
  gridItem: {
    backgroundColor: "#fff",
    margin: "25px 0px",
    padding: "25px !important"
  }
});
class DeleteAccount extends React.Component {
  state = {
    whyLeaving: "",
    experience: ""
  };
  render() {
    const { classes, history } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} className={classes.gridItem}>
          <Typography variant="h5">
            Are you sure you want to delete your account?
          </Typography>
          <Typography style={{ marginBottom: "24px" }}>
            All your data will be removed. You will no longer be able to apply
            to any program
          </Typography>
          <CustomInput
            labelText="Tell us why you’re leaving?*"
            formControlProps={{
              fullWidth: true
            }}
            value={this.state.whyLeaving}
            inputProps={{
              rows: 8,
              onChange: e => this.setState({ whyLeaving: e.target.value })
            }}
          />
          <CustomInput
            labelText="What could we have done to make your experience better?*"
            formControlProps={{
              fullWidth: true
            }}
            value={this.state.experience}
            inputProps={{
              rows: 8,
              onChange: e => this.setState({ experience: e.target.value })
            }}
          />
          <div className={classes.formActions} style={{ marginTop: "24px" }}>
            <Button
              color={"secondary"}
              disabled={!this.state.whyLeaving || !this.state.experience}
              onClick={() => {
                deleteAccountApi({
                  reason: this.state.whyLeaving,
                  feedback: this.state.experience
                }).then(() => {
                  history.push("/auth/login-page");
                  notificationService.success("Delete account successfully!");
                  this.props.userStore.logout();
                });
              }}
            >
              Delete my account
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    );
  }
}
export default inject("userStore")(
  withStyles(styles)(withRouter(observer(DeleteAccount)))
);
