import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Button from "../../../components/CustomButtons/Button";
import appConstants from "../../../constants/app.constant";
import { ReactComponent as IconRequireLogin } from "../../../assets/img/icons/icon_require_login.svg";
import { ReactComponent as IconCloseGrey } from "../../../assets/img/icons/icon_close_grey.svg";

import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor,
  noSidePadding,
  hexToRgb,
  successColor
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 430,
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none"
  },
  closeIcon: {
    position: "absolute",
    top: 13,
    right: 16,
    width: 18,
    height: 18,
    textAlign: "center",
    cursor: "pointer"
  },
  iconHeader: {
    textAlign: "center",
    margin: "10px 0"
  },
  modalTitle: {
    margin: "10px auto 10px",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: 20,
    lineHeight: "26px",
    fontWeight: 700,
    color: textActiveColor
  },
  modalContent: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    color: blackColor,
    textAlign: "center",
    margin: "10px auto 16px"
  },
  modalActions: {
    margin: "16px auto",
    textAlign: "center",
    "& button": {
      textTransform: "capitalize",
      fontSize: 16
    }
  }
});

class ModalRequireLogin extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, open, history } = this.props;

    return (
      <div className={classes.modalRequireLogin}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={() => this.props.commonStore.updateModalRequireLogin(false)}
        >
          <div className={classes.paper}>
            <div
              className={classes.closeIcon}
              onClick={() =>
                this.props.commonStore.updateModalRequireLogin(false)
              }
            >
              <IconCloseGrey />
            </div>
            <div className={classes.iconHeader}>
              <IconRequireLogin />
            </div>
            <Typography className={classes.modalTitle}>Login</Typography>
            <Typography className={classes.modalContent}>
              Create an account or login to save your comments, get notified of
              replies and play the game.
            </Typography>
            <div className={classes.modalActions}>
              <Button
                color="primary"
                onClick={() => {
                  history.push("/auth/login-page");
                  this.props.commonStore.updateModalRequireLogin(false);
                }}
              >
                Log In
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(ModalRequireLogin))
  )
);
