import React from "react";
import { withRouter } from "react-router-dom";

// core components
import Wizard from "components/Wizard/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Profile from "./components/Profile.jsx";
import Preferences from "./components/Preferences.jsx";
import { inject, observer } from "mobx-react";
import notificationService from "../../services/notificationService";

class CreateBusinessInfo extends React.Component {
  state = {
    submitting: false
  };
  componentDidMount() {
    const { userStore } = this.props;
    if (userStore.currentUser) {
      userStore.preferencesForm.set(userStore.currentUser.preferences);
      userStore.profileForm.set(userStore.currentUser.profile);
      userStore.preferencesForm.validate();
      userStore.profileForm.validate();
    }
  }

  handleUpdateLocation = ({ city, state }) => {
    this.setState({ city, state })
  }

  updateProfile = () => {
    if (
      this.props.userStore.profileForm.isValid &&
      this.props.userStore.preferencesForm.isValid
    ) {
      const { city, state } = this.state;
      const profile = this.props.userStore.profileForm.values();
      const preferences = this.props.userStore.preferencesForm.values();
      const avatarFile = profile.avatar || "";
      delete profile.avatar;
      const data = {
        profile,
        preferences
      };
      data.profile.location.state = state;
      data.profile.location.city = city;
      this.setState({ submitting: true });
      if (typeof avatarFile === "string") {
        return this.props.userStore.updateAccountToBusiness(data).then(
          () => {
            notificationService.success("PROFILE.UPDATED_PROFILE");
            this.setState({ submitting: false });
            this.props.userStore.logout();
          },
          err => {
            if (err === "Phone number already exists") {
              this.props.userStore.profileForm
                .$("mobile_number")
                .invalidate("Mobile number is taken");
            }
            this.setState({ submitting: false });
          }
        );
      } else {
        this.props.userStore.uploadAvatar(avatarFile).then(res => {
          data.profile.avatar = res.avatar;
          return this.props.userStore.updateProfile(data).then(
            () => {
              notificationService.success("PROFILE.UPDATED_PROFILE");
              this.setState({ submitting: false });
              this.props.userStore.logout();
            },
            err => {
              if (err === "Phone number already exists") {
                this.props.userStore.profileForm
                  .$("mobile_number")
                  .invalidate("Mobile number is taken");
              }
              this.setState({ submitting: false });
            }
          );
        });
      }
    }
  };

  render() {
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <Wizard
            color={"primary"}
            validate
            steps={[
              {
                stepName: "Basic Information",
                stepComponent: Profile,
                stepId: "profile",
                handleUpdateLocation: this.handleUpdateLocation,
                cityLocation: this.state.city,
                stateLocation: this.state.state
              },
              {
                stepName: "About Your Business",
                stepComponent: Preferences,
                stepId: "preferences"
              }
            ]}
            title="Create Business Account"
            subtitle=""
            finishButtonClick={this.updateProfile}
            finishButtonText="Done"
            previousButtonText="Back"
            submitting={this.state.submitting}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default inject("userStore")(withRouter(observer(CreateBusinessInfo)));
