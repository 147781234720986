import { decorate, observable, action } from "mobx";
import {
    getTransactionList,
    getTransaction,
    repayTransaction,
    confirmTransaction,
    saveAsCSV,
} from "../services/domainServices/transactionDomain.service";
import * as _ from "lodash";

const initialTransactionPagination = {
    page: 1,
    items: [],
    pageSize: 20,
    totalPage: 0,
    totalItem: 0
};

const initialPagination = {
    pageSize: 20,
    page: 0,
    sort: "",
    order: "asc",
    type: "all",
    status: "all",
    fromDate: '',
    toDate: ''
};

class TransactionStore {
    transactionPagination = _.cloneDeep(initialTransactionPagination);

    pagination = _.cloneDeep(initialPagination);

    currentTransaction;
    isLoading = false;
    reset() {
        this.transactionPagination = _.cloneDeep(initialTransactionPagination);
        this.pagination = _.cloneDeep(initialPagination);
        this.currentTransaction = null;
    }

    setCurrentTransaction = tran => {
        this.currentTransaction = tran;
    };

    changePageOfPagination = page => {
        this.pagination.page = page;
    };

    changePageSizeOfPagination = pageSize => {
        this.pagination.pageSize = pageSize;
    };

    changeTypePagination = type => {
        this.pagination.type = type;
    };

    changeFromDatePagination = date => {
        this.pagination.fromDate = date;
    };

    changeToDatePagination = date => {
        this.pagination.toDate = date;
    };

    changeStatusPagination = status => {
        this.pagination.status = status;
    };

    changeSortOfPagination = sort => {
        let order = "desc";

        if (this.pagination.sort === sort && this.pagination.order === "desc") {
            order = "asc";
        }
        this.pagination.order = order;
        this.pagination.sort = sort;
    };

    getTransactionList = (type, status, pageSize, page, fromDate, toDate) => {
        this.isLoading = true;
        return getTransactionList(type, status, pageSize, page, fromDate, toDate).then(
            action(data => {
                this.isLoading = false;
                this.transactionPagination = data;
            })
        );
    };

    getTransaction = id => {
        return getTransaction(id).then(
            action(data => {
                this.setCurrentTransaction(data);
            })
        );
    };

    repayTransaction = (id, data) => {
        return repayTransaction(id, data);
    }

    confirmTransaction = (id) => {
        return confirmTransaction(id);
    }

    download = (type, status, pageSize, page, fromDate, toDate) => {
        saveAsCSV(type, status, pageSize, page, fromDate, toDate)
            .then(data => {
                const blob = new Blob([data], {
                    type: 'text/csv'
                });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'transactions.csv';
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
    }
}

decorate(TransactionStore, {
    transactionPagination: observable,
    pagination: observable,
    currentTransaction: observable,
    isLoading: observable,
    changePageOfPagination: action,
    changePageSizeOfPagination: action,
    changeTypePagination: action,
    changeStatusPagination: action,
    setCurrentTransaction: action,
    getTransaction: action,
    getTransactionList: action,
    changeFromDatePagination: action,
    changeToDatePagination: action,
    reset: action,
    download: action,
});

export default new TransactionStore();
