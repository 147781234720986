import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import {
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@material-ui/core";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import constants, { appBreadcrumbs } from "../../constants/app.constant";
import Posts from "../../common/components/posts/posts";
import PageLayout from "../../layouts/pageLayout/pageLayout";
import FilterGroup from "../../common/components/filterGroup/filterGroup";
import SearchForm from "../../common/components/searchForm/searchForm";
import {
  container,
  textActiveColor,
  noSidePadding
} from "../../assets/jss/material-dashboard-pro-react";
import Pagination from "../../components/Pagination/Pagination";
import { TWPagination } from "../../components/TW_Pagination/TWPagination";
import Welcome from "../../common/components/welcome/welcome";

const styles = theme => ({
  container,
  pageTitle: {
    fontSize: 32,
    lineHeight: "39px",
    margin: `32px 0 16px`,
    fontWeight: "bold",
    color: "#000"
  },
  pageSubTitle: {
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  titleBar: {
    marginBottom: 16
  },
  typography: {
    // margin: theme.spacing.unit * 2
  },
  root: {
    width: 500
  },
  menuSort: {
    padding: 0
  },
  dropdownItem: {
    minWidth: 180,
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 1.5}px`
  },
  btnSort: {
    ...noSidePadding,
    paddingTop: 0,
    marginTop: 0,
    color: `${textActiveColor} !important`,
    textTransform: "capitalize"
  },
  iconSort: {
    marginRight: theme.spacing.unit * 0.5
  },
  iconArrow: {
    marginLeft: theme.spacing.unit * 0.5,
    width: `${8}px !important`,
    transition: "all 0.2s ease"
  },
  open: {
    transform: `rotate(180deg)`
  },
  breadcrumbs: {
    marginTop: `${theme.spacing.unit * 2}px`,
    marginBottom: `-${theme.spacing.unit * 2}px`
  },
  textCenter: {
    textAlign: "center"
  }
});

class TechWizard extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    totalPost: 0,
    widthScreen: window.innerWidth
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.postsStore.getPostsFromApi();
    // const pageDidMount = async () => {
    //   await this.props.postsStore.getPostsFromApi();
    //   const { totalPost } = this.props.postsStore;
    //   this.setState({
    //     totalPost: totalPost
    //   });
    // };
    // pageDidMount();
    window.addEventListener("resize", () => {
      this.setState({ widthScreen: window.innerWidth });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //     const { totalPost } = this.props.postsStore;
    //
    //     if (prevState.totalPost !== totalPost) {
    //       this.setState({
    //         totalPost: totalPost
    //       });
    //       this.handlePageClick({ selected: 0 });
    //     }
  }

  componentWillUnmount() {
    this.props.postsStore.resetParams();
  }

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handlePageClick = page => {
    this.props.postsStore.updateParams({
      page: page.selected + 1
    });
    this.props.postsStore.setCurrentPage(page.selected);
    this.props.postsStore.getPostsFromApi();
  };

  renderPageNumber = () => {
    const { currentPage } = this.props.postsStore;
    const pageNumber = [];
    const { page_size } = this.props.postsStore.params;
    for (
      let index = 1;
      index <= Math.ceil(this.state.totalPost / page_size);
      index++
    ) {
      const element = {
        active: currentPage === index ? true : false,
        text: index
      };
      pageNumber.push(element);
    }
    return pageNumber;
  };

  render() {
    const { anchorEl, open, widthScreen } = this.state;
    const id = open ? "simple-popper" : null;
    const { classes, history } = this.props;
    const isMobileScreen = widthScreen <= 980;
    const {
      posts,
      sorts,
      params,
      updateParams,
      getPostsFromApi,
      totalPost,
      currentPage
    } = this.props.postsStore;

    const PageTitle = () => {
      return (
        <div
          className={classNames({
            [classes.container]: true,
            [classes.breadcrumbs]: true
          })}
        >
          <Breadcrumbs items={appBreadcrumbs.createPost} />
        </div>
      );
    };

    const PageSubTitle = () => {
      return <Typography className={classes.pageSubTitle}>All post</Typography>;
    };

    return (
      <PageLayout
        pageTitle={<PageTitle />}
        sidebar={
          !isMobileScreen && [
            <Welcome />,
            <SearchForm />,
            <FilterGroup redirectUrl={""} showFilterActions={true} />
          ]
        }
      >
        {isMobileScreen && (
          <>
            <Welcome />
            <SearchForm />
            <FilterGroup redirectUrl={""} showFilterActions={true} />
          </>
        )}

        <div className={classes.titleBar}>
          <Grid container alignItems={"flex-start"} justify={"space-between"}>
            <Grid item xs={12} md={6}>
              <Breadcrumbs items={appBreadcrumbs.posts} />
              <PageSubTitle />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container justify={"flex-end"}>
                {/* <Button
                  aria-describedby={id}
                  simple={true}
                  onClick={this.handleClick}
                  color={"primary"}
                  className={classes.btnSort}
                >
                  <IconSort className={classes.iconSort} /> Sort by:
                  {sorts.filter(item => item.value === params.sort).length >
                    0 &&
                    sorts.filter(item => item.value === params.sort)[0].label}
                  <IconArrow
                    className={classNames({
                      [classes.iconArrow]: true,
                      [classes.open]: open
                    })}
                  />
                </Button> */}
                <Popper
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  transition
                  placement={"bottom-end"}
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={this.handleClose}>
                          <MenuList role="menu" className={classes.menuSort}>
                            {sorts.length > 0 &&
                              sorts.map((item, key) => (
                                <MenuItem
                                  key={key}
                                  onClick={() => {
                                    updateParams({ sort: item.value });
                                    getPostsFromApi();
                                    this.handleClose();
                                  }}
                                  className={classes.dropdownItem}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Posts items={posts} isQuestion={true} />

        <div className={classes.textCenter}>
          <TWPagination
            onPageChange={this.handlePageClick}
            page={currentPage}
            total={totalPost}
            page_size={constants.pagination.page_size}
          />

          {/* <Pagination
            handlePageClick={this.handlePageClick}
            pages={this.renderPageNumber()}
          /> */}
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(TechWizard))
  )
);
