import React from "react";
import Rating from 'react-rating';
import { FormLabel, Radio, FormControlLabel, withStyles } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";

import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import { observer } from "mobx-react";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";

const canContact = [{ name: 'Yes', value: 'yes' }, { name: 'No', value: 'no' }]

class FeedbackFromUI extends React.Component {
    render() {
        const { classes, feedbackForm, onConfirm, onCancel } = this.props;
        return <SweetAlert
            title=""
            onConfirm={onConfirm}
            onCancel={onCancel}
            cancelBtnCssClass={
                classes.button + " " + classes.floatRight + " " + classes.primary
            }
            confirmBtnCssClass={
                classes.button + " " + classes.floatRight
            }
            confirmBtnText="Cancel"
            cancelBtnText="Send"
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            showCancel={false}
            showConfirm={false}
        >
            <div className={classes.formWrapper}>
                <div>
                    <h4>Would you recommend Mechanics Marketplace to Others ?</h4>
                    <label>Please rate on a scale from 1 (you strongly disagree) to 5 (you strongly agree)</label>
                    <div>
                        <Rating
                            initialRating={feedbackForm.$('recommendation_rate').value}
                            emptySymbol={<StarBorder className={classes.rateIcon} />}
                            fullSymbol={<StarRate className={classes.rateIcon} />}
                            onChange={feedbackForm.$('recommendation_rate').onChange}
                        />
                    </div>
                </div>
                <div>
                    <h4>Do you find the Mechanics Marketplace application easy to use ?</h4>
                    <label>Please rate on a scale from 1 (you strongly disagree) to 5 (you strongly agree)</label>
                    <div>
                        <Rating
                            initialRating={feedbackForm.$('easy_of_use_rate').value}
                            emptySymbol={<StarBorder className={classes.rateIcon} />}
                            fullSymbol={<StarRate className={classes.rateIcon} />}
                            onChange={feedbackForm.$('easy_of_use_rate').onChange}
                        />
                    </div>
                </div>
                <div>
                    <h4>What features or improvements would you like to see added to the Mechanics Marketplace app ?</h4>
                    <CustomInput
                        labelText='Type your opinions'
                        formControlProps={{
                            fullWidth: true
                        }}
                        inputProps={
                            {
                                multiline: true,
                                rows: 4,
                                ...feedbackForm.$('comment').bind()
                            }
                        }
                    />
                </div>
                <GridContainer>
                    <GridItem xs={12} sm={12}>
                        <FormLabel
                            className={
                                classes.labelLeftHorizontal +
                                " " +
                                classes.labelHorizontalRadioCheckbox
                            }
                        >
                            Can we contact you to discuss issues you may have ?
          </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <GridContainer className={classes.radioWrapper}>
                            {canContact.map(opt => <GridItem xs={12} sm={2} key={opt.value}>
                                <div
                                    className={
                                        classes.checkboxAndRadio +
                                        " " +
                                        classes.checkboxAndRadioHorizontal
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={feedbackForm.$('can_contact').value === opt.value}
                                                onChange={feedbackForm.$('can_contact').onChange}
                                                value={opt.value}
                                                name="radio button enabled"
                                                aria-label={opt.name}
                                                icon={
                                                    <FiberManualRecord
                                                        className={classes.radioUnchecked}
                                                    />
                                                }
                                                checkedIcon={
                                                    <FiberManualRecord
                                                        className={classes.radioChecked}
                                                    />
                                                }
                                                classes={{
                                                    checked: classes.radio,
                                                    root: classes.radioRoot
                                                }}
                                            />
                                        }
                                        classes={{
                                            label: classes.label
                                        }}
                                        label={opt.name}
                                    />
                                </div>
                            </GridItem>)}
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                <Button className={classes.mr10} round onClick={onCancel}>Cancel</Button>
                <Button  className={classes.ml10} color="primary" round onClick={onConfirm} loading={feedbackForm.$('submitting').value} disabled={feedbackForm.$('submitting').value}>Send</Button>
            </div>
        </SweetAlert>
    }
}

export default withStyles(appStyle)(observer(FeedbackFromUI));