import requests from "../http";
import { convertPagingResponse } from "../utils";

const getTransactionListApi = (type, status, pageSize, page, fromDate, toDate) =>
    requests
        .get(`/api/transactions?type=${type === "all" ? "" : type}&status=${status === "all" ? "" : status}&page=${++page}&page_size=${pageSize}&from_date=${fromDate}&to_date=${toDate}`)
        .then(data => convertPagingResponse(data));

const download = (type, status, pageSize, page, fromDate, toDate) =>
    requests
        .get(`/api/transactions/download?type=${type === "all" ? "" : type}&status=${status === "all" ? "" : status}&page=${++page}&page_size=${pageSize}&from_date=${fromDate}&to_date=${toDate}`);

const getTransactionApi = id => requests.get(`/api/transactions/${id}`);

const repayTransactionApi = (id, data) => requests.post(`/api/transactions/${id}/repay`, data);

const confirmTransactionApi = (id) => requests.post(`/api/transactions/${id}/confirm`);

const countPendingTransactionApi = () => requests.get(`/api/transactions/count_pending`);

export {
    getTransactionApi,
    getTransactionListApi,
    repayTransactionApi,
    confirmTransactionApi,
    countPendingTransactionApi,
    download
};
