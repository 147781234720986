import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class ReviewForm extends Form {
    plugins() {
        return plugins;
    }

    options() {
        return {
            showErrorsOnReset: true,
            validateOnChange: true,
            validateOnBlur: false
        };
    }

    setup() {
        return {
            fields: [
                { name: "id", value: "" },
                {
                    name: "receiver",
                    rules: "required",
                    handlers: {
                        onChange: field => value => {
                            field.set(value);
                            this.$('listing_id').set(value.listing_id);
                        }
                    },
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                { name: "listing_id", value: '' },
                {
                    name: "rating",
                    value: 0
                },
                {
                    name: "comment",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }, {
                    name: 'submitting',
                    value: false
                }
            ]
        };
    }
}
