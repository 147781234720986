import React from "react";

import Help from "@material-ui/icons/Help";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from '@material-ui/core/Tooltip';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import { inject, observer } from "mobx-react";
import IntegrationReactSelect from "components/CustomSelect/IntegrationReactSelect";
import IntegrationReactCreatable from "components/CustomSelect/IntegrationReactCreatable";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  mt10: {
    marginTop: '10px'
  },
  ...customSelectStyle
};

class Preferences extends React.Component {
  isValidated() {
    this.props.userStore.preferencesForm.showErrors(true);
    return this.props.userStore.preferencesForm.isValid;
  }
  sendState() {
    return {};
  }
  render() {
    const { classes, commonStore } = this.props;
    const { preferencesForm } = this.props.userStore;
    const { businessTypes, carTypes } = commonStore;
    const carTypesReactSelect = carTypes.map(item => ({ label: item, value: item }));

    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <h4 className={classes.infoText}>Now tell job seekers a little about your business</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Business name*'
            error={!!preferencesForm.$('business_name').error}
            helpText={preferencesForm.$('business_name').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              preferencesForm.$('business_name').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Business ID'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              preferencesForm.$('business_id').bind()
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Business Phone'
            error={!!preferencesForm.$('phone_number').error}
            helpText={preferencesForm.$('phone_number').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Provide a number where job seekers can contact you.">
                      <Help className={classes.inputAdornmentIcon} />
                    </Tooltip>
                  </InputAdornment>
                ),
                ...preferencesForm.$('phone_number').bind()
              }
            }
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={10}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="business-type-select">
              Business Type
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={preferencesForm.$('business_type').value}
              onChange={preferencesForm.$('business_type').onChange}
              inputProps={{
                name: "businessTypeSelect",
                id: "business-type-select"
              }}
            >
              {businessTypes.map(item =>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value={item}
                  key={item}
                >
                  {item}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </GridItem>
        {preferencesForm.$('other_business_type').extra.show && <GridItem xs={12} sm={12} md={12} lg={10}>
          <CustomInput
            labelText='Other Business Type'
            error={!!preferencesForm.$('other_business_type').error}
            helpText={preferencesForm.$('other_business_type').error || undefined}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={
              preferencesForm.$('other_business_type').bind()
            }
          />
        </GridItem>}

        <GridItem xs={12} sm={12} md={12} lg={10}>
          <IntegrationReactSelect
            id="car-type-select"
            label={'Car Types'}
            multiple
            options={carTypesReactSelect}
            value={preferencesForm.$('car_types').value}
            onChange={preferencesForm.$('car_types').onChange}
            placeholder={'Select Car Type'} />
        </GridItem>
        {preferencesForm.$('other_car_types').extra.show && <GridItem xs={12} sm={12} md={12} lg={10} className={classes.mt10}>
          <IntegrationReactCreatable
            id="car-type-select"
            label={'Other Car Type'}
            multiple
            value={preferencesForm.$('other_car_types').value}
            onChange={preferencesForm.$('other_car_types').onChange}
            placeholder={'Type Other Car Type'} />
        </GridItem>}
      </GridContainer>
    );
  }
}

export default withStyles(style)(inject('userStore', 'commonStore')(observer(Preferences)));
