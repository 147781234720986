import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import featuresPageStyle from "assets/jss/material-dashboard-pro-react/views/featuresPageStyle.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect } from "react";
import HeaderGeneral from "../layouts/general/components/header/HeaderGeneral";
import registration from "assets/img/background/bg-registration.png";

import Footer from "../components/Footer/Footer.jsx";
import { inject } from "mobx-react";

const styles = theme => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: `0 ${theme.spacing.unit * 3}px`
  },
  paper: {
    margin: `${theme.spacing.unit}px auto`,
    padding: theme.spacing.unit * 3,
    borderRadius: "15 !important"
  }
});

const FeaturesInformation = props => {
  const { classes, history, location } = props;

  useEffect(() => {
    document.body.style.backgroundColor = "rgb(14 21 25 )";
    return () => {
      document.body.style.backgroundImage = "none";
      document.body.style.backgroundColor = "#eeeeee";
    };
  }, []);


  const redirectToTW = () => {
    history.push("/tech-wizard");
  };

  const redirectToCreateBusiness = () => {
    history.push("/create-business-account");
  };

  return (
    <>
      <HeaderGeneral style={"none"} />
      <div
        className={classes.container}
        style={{
          marginTop: "150px"
        }}
      >
        <GridContainer justify="center" style={{ marginTop: 75 }}>
          <GridItem xs={4} sm={12} md={4} className={classes.mrtopItem}>
            <Paper className={classes.paper}>
              <Grid
                container
                wrap="nowrap"
                spacing={32}
                className={classes.itemWrap}
              >
                <Grid item className={classes.itemFeature}>
                  <img src={require("./../assets/img/business.png")} />
                </Grid>
                <Grid
                  item
                  style={{ textAlign: "center", position: "absolute", top: 65 }}
                >
                  <Typography className={classes.cardTitle}>
                    Create a Business Account
                  </Typography>
                  <Typography
                    style={{ padding: "25px 0" }}
                    className={classes.cardContent}
                  >
                    Post jobs for free, reach out to available temporary
                    employees and use all the free resources available including
                    Tech Wizard.
                  </Typography>
                  <button
                    onClick={redirectToCreateBusiness}
                    className={classes.btnChoose}
                  >
                    CHOOSE
                  </button>
                </Grid>
              </Grid>
            </Paper>
          </GridItem>
          <GridItem xs={4} sm={12} md={4} className={classes.mrtopItem}>
            <Paper className={classes.paper}>
              <Grid
                container
                wrap="nowrap"
                spacing={32}
                alignItems="center"
                className={classes.itemWrap}
              >
                <Grid item className={classes.itemFeature}>
                  <img src={require("./../assets/img/job_seekers.png")} />
                </Grid>
                <Grid
                  item
                  style={{ textAlign: "center", position: "absolute", top: 65 }}
                >
                  <Typography className={classes.cardTitle}>
                    Job Seekers
                  </Typography>
                  <Typography
                    style={{ padding: "25px 0" }}
                    className={classes.cardContent}
                  >
                    Click{" "}
                    <a
                      style={{
                        textDecorationLine: "underline",
                        textUnderlineOffset: 2
                      }}
                      href="https://mechanicsmarketplace.zohorecruit.com/recruit/Portal.na"
                    >
                      here
                    </a>{" "}
                    to go to our jobs page. For a list of all jobs near you,
                    download the Mechanics Marketplace mobile app for a
                    convenient way to find jobs based on your location, complete
                    a skills profile in lieu of a resume and request temporary
                    jobs. The Tech Wizard functionality is there too.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </GridItem>
          <GridItem xs={4} sm={12} md={4} className={classes.mrtopItem}>
            <Paper className={classes.paper}>
              <Grid
                container
                wrap="nowrap"
                spacing={32}
                className={classes.itemWrap}
              >
                <Grid item className={classes.itemFeature}>
                  <img
                    src={require("./../assets/img/general_account.png")}
                    style={{ marginTop: 7 }}
                  />
                </Grid>
                <Grid
                  item
                  style={{ textAlign: "center", position: "absolute", top: 65 }}
                >
                  <Typography className={classes.cardTitle}>
                    Create a General User Account
                  </Typography>
                  <Typography
                    style={{ padding: "25px 0" }}
                    className={classes.cardContent}
                  >
                    Not hiring and not looking for a job? Click on this box to
                    finalize your General User Account and gain full access to
                    all the free resources and functions including Tech Wizard.
                  </Typography>
                  <button onClick={redirectToTW} className={classes.btnChoose}>
                    CHOOSE
                  </button>
                </Grid>
              </Grid>
            </Paper>
          </GridItem>
        </GridContainer>
        <div style={{ marginTop: 75, color: "#eeeeee" }}>
          <Footer style={"borderTopNone"} />
        </div>
      </div>
    </>
  );
};

export default withStyles(featuresPageStyle, styles)(
  inject("userStore")(FeaturesInformation)
);
