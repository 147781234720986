import requests from "../http";
import axios from "axios";
import config from "../../config";
import { appRole } from "../../constants/app.constant";

const registerBusinessApi = data => {
  const { first_name, last_name, business_name, username, password } = data;
  return requests.post(`/api/auth/signup`, {
    first_name,
    last_name,
    business_name,
    username,
    password,
    role: "business_owner"
  });
};

const registerJobSeekerApi = data => {
  const { first_name, last_name, username, password } = data;
  return requests.post(`/api/auth/signup`, {
    first_name,
    last_name,
    username,
    password,
    role: "job_seeker"
  });
};

const registerGeneralApi = data => {
  const isBu = window.location.href.includes("type=bu");
  if (isBu) {
    return requests.post(`/api/v2/auth/signup`, {
      ...data,
      role: appRole.business,
      avatar: ""
    });
  } else {
    return requests.post(`/api/v2/auth/signup`, {
      ...data,
      role: appRole.general,
      avatar: ""
    });
  }
};

const loginApi = (data, isBu) => {
  const urlLogin = isBu ? "/api/v2/auth/login?type=bu" : "/api/v2/auth/login";
  return requests.post(urlLogin, {
    username: data.emailOrPhone,
    password: data.password
  });
};

async function login3rdApi(data, isBu) {
  const urlLogin = isBu
    ? "https://mechanicsmarketplace.com/api/v2/auth/3rd-login?type=bu"
    : "https://mechanicsmarketplace.com/api/v2/auth/3rd-login";
  return axios.post(urlLogin, data);
}

async function getCompleteLocation(data) {
  const urlLogin =
    "https://mechanicsmarketplace.com/api/listings/autocomplete/location?address=" +
    data;
  return axios.get(urlLogin);
}
async function loginIndeedApi(code) {
  try {
    const response = await axios.post(
      "https://apis.indeed.com/oauth/v2/tokens",
      new URLSearchParams({
        code: code,
        client_id:
          "e10705d873f93c8c521acc0598c920c71c93559367c052f890d64a358313dfb9",
        client_secret:
          "sAONoQVHCOPvrt2X4MVpqGoBZIWeBmTMGmnid81jsDhcvj0rmbDYW2TPBtdcmLmg",
        redirect_uri:
          "https://dev.mechanicsmarketplace.com/app/auth/login-page",
        grant_type: "authorization_code"
      }),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Cookie:
            "SURF=dyKeYgHBZoQIgxwrqGZ8ZQe3tE73mZib; CTK=1gokvu9k8gaj5800; __cf_bm=lBW8dGvA4zOB_V39ozYnwEZjVcvYwUmoJp4smnePzjA-1675741832-0-AWrM90n6i9Y7VWMGxXbWf0nHwfSpUImiTs84G437etlpcBDXtBDayMeFljC9EAgn8pfWN18fzhvhx2NFKhSFQTI="
        }
      }
    );
    return response;
  } catch (e) {
    return;
  }
}

const forgotPasswordApi = data =>
  requests.post(`/api/v2/auth/forgot_password`, {
    ...data,
    callback_url: `${window.location.origin}${
      window.location.pathname
    }#/auth/reset-password`
  });

const resetPasswordApi = data => {
  const { token, password } = data;
  return axios.post(
    `${config.API_ROOT}/api/v2/auth/set_new_password`,
    {
      new_password: password
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
};

const sendOtpApi = data => {
  const { mobileNumber, isSignup, email, userId } = data;
  return requests.post(`/api/v2/user_otp/send`, {
    mobileNumber,
    isSignup,
    email,
    userId
  });
};

const verifyOtpApi = data => {
  return requests.put(`/api/v2/user_otp/verify`, data);
};

const validateUsersApi = data => {
  const { user_name, email, mobile_number, isEmailFormat } = data;
  if (isEmailFormat) {
    return requests.post(`/api/v2/auth/check_exist`, {
      user_name,
      email
    });
  } else {
    return requests.post(`api/v2/auth/check_exist`, {
      user_name,
      mobile_number
    });
  }
};

export {
  registerJobSeekerApi,
  registerBusinessApi,
  loginApi,
  forgotPasswordApi,
  resetPasswordApi,
  registerGeneralApi,
  sendOtpApi,
  verifyOtpApi,
  validateUsersApi,
  login3rdApi,
  loginIndeedApi,
  getCompleteLocation
};
