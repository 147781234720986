import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class JobSeekerRegisterForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "first_name",
                    placeholder: "Enter first name",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "last_name",
                    placeholder: "Enter last name",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "username",
                    placeholder: "Enter email or phone",
                    rules: "required|emailOrPhone",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "password",
                    placeholder: "Enter your password",
                    rules: "required|password",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                            this.$('confirmPassword').validate();
                            this.$('confirmPassword').showErrors();
                        }
                    }
                },
                {
                    name: "confirmPassword",
                    placeholder: "Enter confirm password",
                    type: "password",
                    rules: "required|same:password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "agreeTerm",
                    type: "checkbox",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }
            ]
        };
    }
}
