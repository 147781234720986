import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import FeedbackFormUI from "../components/Navbars/FeedbackFormUI";
import Sidebar from "../components/Sidebar/Sidebar";
import NavPills from "../components/NavPills/NavPills";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import { FormLabel, Radio, FormControlLabel } from "@material-ui/core";
// import routes from "../routes/authRoutes";

import appStyle from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import bgSidebar from "../assets/img/background_sidebar.png";
import logo from "../assets/img/app_logo.png";
import ProfileUpdatedRoute from "../components/Route/ProfileUpdatedRoute";
import { observer, inject } from "mobx-react";
import notificationService from "../services/notificationService";
import TermAndConditions from "../components/Navbars/TermAndConditions";
import PrivacyPolicy from "../components/Navbars/PrivacyPolicy";
import BreachOfBusiness from "../components/Navbars/BreachOfBusiness";
import Error from "../views/Error/Error";

const methods = [
  { name: "By email only", value: "email" },
  { name: "By SMS/text only", value: "sms" },
  { name: "By SMS & email [Default]", value: "both" },
  { name: "No Notifications", value: "none" }
];

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      bgSidebar: bgSidebar,
      color: "orange",
      bgColor: "transparent",
      hasImage: true,
      helpModal: null,
      feedbackModal: null,
      termConditionsModal: null,
      privacyPolicyModal: null,
      breachOfBusinessModal: null,
      remindPendingTransactionModal: null,
      settingNotificationModal: null,
      confirmNoNotificationModal: null
    };
  }

  componentDidMount() {
    this.props.userStore.getCurrentUser();
    if (navigator.platform.indexOf("Win") > -1 && this.refs.mainPanel) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    // console.log("this.refs ", this.refs);
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const hash = window.location.hash.slice(1);
        if (routes[i].regex.test(hash)) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  getRoutes = () => {
    const { routes } = this.props;
    let pages = [];
    if (routes.length > 0) {
      pages = routes.map((route, idx) => {
        if (route.views && route.views.length > 0) {
          return route.views.map((item, key) => {
            if (route.profileUpdated) {
              return (
                <ProfileUpdatedRoute
                  path={item.path}
                  component={item.component}
                  key={key}
                  exact
                />
              );
            } else {
              return (
                <Route
                  path={item.path}
                  component={item.component}
                  exact
                  key={key}
                />
              );
            }
          });
        } else {
          return (
            <Route
              path={route.path}
              component={route.component}
              key={idx}
              exact
            />
          );
        }
      });
    }
    // pages.push(<Route component={Error} />)
    return pages;
  };

  getSidebarRoutes = routes => {
    return routes.filter(prop => prop.showInSidebar);
  };

  createMarkup = text => {
    return { __html: text };
  };

  showHelpModal = idx => {
    return this.setState({
      helpModal: (
        <SweetAlert
          title=""
          onConfirm={this.closeHelpModal}
          onCancel={this.closeHelpModal}
          confirmBtnCssClass={
            this.props.classes.button +
            " " +
            this.props.classes.primary +
            " " +
            this.props.classes.floatRight
          }
          confirmBtnText="Close"
          style={{
            display: "block",
            top: "240px",
            width: "800px",
            left: "calc(50% - 150px)",
            textAlign: "unset"
          }}
        >
          <NavPills
            color="warning"
            active={idx}
            alignCenter
            tabs={[
              {
                tabButton: "Getting Started",
                tabContent: (
                  <div
                    style={{
                      maxHeight: "500px",
                      overflowY: "auto"
                    }}
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.commonStore.contentGettingStarted
                    )}
                  />
                )
              },
              {
                tabButton: "FAQs",
                tabContent: (
                  <div
                    style={{
                      maxHeight: "500px",
                      overflowY: "auto"
                    }}
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.commonStore.contentFAQ
                    )}
                  />
                )
              },
              {
                tabButton: "Contact Us",
                tabContent: (
                  <div
                    style={{
                      maxHeight: "500px",
                      overflowY: "auto"
                    }}
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.props.commonStore.contentContact
                    )}
                  />
                )
              }
            ]}
          />
        </SweetAlert>
      )
    });
  };

  closeHelpModal = () => {
    this.setState({ helpModal: null });
  };

  showFeedbackModal = () => {
    const { feedbackForm } = this.props.commonStore;
    this.setState({
      feedbackModal: (
        <FeedbackFormUI
          onConfirm={this.sendFeedback}
          onCancel={this.closeFeedbackModal}
          feedbackForm={feedbackForm}
        />
      )
    });
  };

  sendFeedback = () => {
    const { feedbackForm, sendFeedback } = this.props.commonStore;
    feedbackForm.submit({
      onSuccess: form => {
        const data = form.values();
        feedbackForm.$("submitting").set(true);
        sendFeedback(data).then(() => {
          notificationService.success("Sent feedback");
          feedbackForm.$("submitting").set(false);
          this.closeFeedbackModal();
        });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  closeFeedbackModal = () => {
    const { feedbackForm } = this.props.commonStore;
    feedbackForm.reset();
    this.setState({
      feedbackModal: null
    });
  };

  showTermConditionsModal = () => {
    this.setState({
      termConditionsModal: (
        <TermAndConditions
          onSave={this.onSaveTermConditions}
          onPrint={this.onPrintTermConditions}
          onCancel={this.closeTermConditionsModal}
          data={this.props.commonStore.contentTermConditions}
        />
      )
    });
  };

  closeTermConditionsModal = () => {
    this.setState({
      termConditionsModal: null
    });
  };

  onSaveTermConditions = () => {};

  onPrintTermConditions = () => {};

  showPrivacyPolicyModal = () => {
    this.setState({
      privacyPolicyModal: (
        <PrivacyPolicy
          onSave={this.onSavePrivacyPolicy}
          onPrint={this.onPrintPrivacyPolicy}
          onCancel={this.closePrivacyPolicyModal}
          data={this.props.commonStore.contentPrivacyPolicy}
        />
      )
    });
  };

  closePrivacyPolicyModal = () => {
    this.setState({
      privacyPolicyModal: null
    });
  };

  onSavePrivacyPolicy = () => {};

  onPrintPrivacyPolicy = () => {};

  showBreachOfBusinessModal = () => {
    this.setState({
      breachOfBusinessModal: (
        <BreachOfBusiness
          onSave={this.onSaveBreachOfBusiness}
          onPrint={this.onPrintBreachOfBusiness}
          onCancel={this.closeBreachOfBusinessModal}
          data={this.props.commonStore.contentBreachOfBusiness}
        />
      )
    });
  };

  closeBreachOfBusinessModal = () => {
    this.setState({
      breachOfBusinessModal: null
    });
  };

  onSaveBreachOfBusiness = () => {};

  onPrintBreachOfBusiness = () => {};

  showRemindPendingTransaction = () => {
    return (
      <SweetAlert
        warning
        title=""
        onConfirm={this.goToTransactionPage}
        onCancel={this.closeRemindPendingTransactionModal}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Go to Transaction"
        cancelBtnText="Cancel"
        showCancel
      >
        You have {this.props.userStore.pendingTransaction} pending transaction.
      </SweetAlert>
    );
  };

  closeRemindPendingTransactionModal = () => {
    this.props.userStore.dismissRemindPendingTransaction();
  };

  closeGuildHintModal = () => {
    this.setState({ submitting: true });
    this.props.userStore.dismissGuildHint();
    this.setState({ submitting: false });
  };

  showGuildHintModal = () => {
    return (
      <SweetAlert
        title=""
        onConfirm={this.dontShowHintAgainModal}
        onCancel={this.closeGuildHintModal}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Don't Show Again"
        cancelBtnText="Close"
        showCancel
      >
        The candidates shown on this screen are proactively seeking employment.
        You can respond to any of them and hire them on a temporary basis. If
        you want to post a job that will be seen by thousands of job seekers in
        our database, go to "Request Employee" and "New Request".
      </SweetAlert>
    );
  };

  dontShowHintAgainModal = () => {
    const { changeNotificationPreferences } = this.props.userStore;
    this.setState({ submitting: true });
    changeNotificationPreferences({ guild_hint: true })
      .then(() => {
        this.setState({ submitting: false });
      })
      .catch(res => {
        this.setState({ submitting: false });
      });
    this.closeGuildHintModal();
  };

  showSettingNotificationModal = () => {
    const { classes } = this.props;
    const { notificationPreferencesForm } = this.props.userStore;
    return (
      <SweetAlert
        warning
        title=""
        onConfirm={this.confirmSettingNotificationModal}
        onCancel={this.closeSettingNotificationModal}
        confirmBtnCssClass={
          this.props.classes.button + " " + this.props.classes.success
        }
        cancelBtnCssClass={
          this.props.classes.button + " " + this.props.classes.danger
        }
        confirmBtnText="Save"
        cancelBtnText="Cancel"
        showCancel
        style={{
          display: "block",
          top: "240px",
          width: "800px",
          left: "calc(50% - 150px)"
        }}
      >
        <GridItem xs={12} className={classes.mb15}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox +
                  " " +
                  classes.labelSettings
                }
              >
                How do you like to receive Notifications about new applications
                and job seekers seeking employment near you?
              </FormLabel>
            </GridItem>
            {methods.map(opt => (
              <GridItem xs={12} sm={3} key={opt.value}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          notificationPreferencesForm.$(
                            "notification_preferences.sending_method"
                          ).value === opt.value
                        }
                        onChange={this.checkChangeNotificationMethod}
                        value={opt.value}
                        name="radio button enabled"
                        aria-label={opt.name}
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot
                        }}
                      />
                    }
                    classes={{
                      label: classes.label + " " + classes.labelSettings
                    }}
                    label={opt.name}
                  />
                </div>
              </GridItem>
            ))}
            <GridItem xs={12} sm={12}>
              <FormLabel
                className={
                  classes.labelLeftHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox +
                  " " +
                  classes.labelSettings
                }
              >
                (You can change these in your Settings later)
              </FormLabel>
            </GridItem>
          </GridContainer>
        </GridItem>
      </SweetAlert>
    );
  };

  checkChangeNotificationMethod = e => {
    if (e.target.value !== "none") {
      this.props.userStore.notificationPreferencesForm
        .$("notification_preferences.sending_method")
        .onChange(e);
    } else {
      this.showConfirmNoNotificationModal();
    }
  };

  closeSettingNotificationModal = () => {
    this.props.userStore.setIsNewRegister(false);
  };

  confirmSettingNotificationModal = () => {
    const {
      notificationPreferencesForm,
      changeNotificationPreferences
    } = this.props.userStore;
    notificationPreferencesForm.submit({
      onSuccess: form => {
        const { notification_preferences } = form.values();
        const { max_distance, sending_method } = notification_preferences;
        this.setState({ submitting: true });
        changeNotificationPreferences({
          notification_preferences: {
            max_distance: +max_distance,
            sending_method
          }
        })
          .then(() => {
            notificationService.success(
              "Change notification preferences successfully"
            );
            this.closeSettingNotificationModal();
            this.setState({ submitting: false });
          })
          .catch(res => {
            const err = res.response;
            this.setState({ submitting: false });
            notificationService.error(err.data.message);
          });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  showConfirmNoNotificationModal = () => {
    this.setState({
      confirmNoNotificationModal: (
        <SweetAlert
          warning
          title=""
          onConfirm={this.onConfirmNoNotification}
          onCancel={this.onCancelConfirmNoNotification}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You will not be notified of any techs and other job seekers in your
          area. Are you sure you want to do this ?
        </SweetAlert>
      )
    });
  };

  onConfirmNoNotification = () => {
    this.props.userStore.notificationPreferencesForm
      .$("notification_preferences.sending_method")
      .set("none");
    this.setState({ confirmNoNotificationModal: null });
  };

  onCancelConfirmNoNotification = () => {
    this.setState({ confirmNoNotificationModal: null });
  };

  goToTransactionPage = () => {
    this.closeRemindPendingTransactionModal();
    this.props.history.push("/transaction-history");
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    const { classes, regex, routes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    // console.log(regex, location.pathname, regex.test(location.pathname));
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={this.getSidebarRoutes(routes)}
          logoText={"Mechanicsmarketplace"}
          logo={logo}
          bgSidebar={this.state.bgSidebar}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          showBreachOfBusinessModal={this.showBreachOfBusinessModal}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            showHelpModal={this.showHelpModal}
            showFeedbackModal={this.showFeedbackModal}
            showTermConditionsModal={this.showTermConditionsModal}
            showPrivacyPolicyModal={this.showPrivacyPolicyModal}
            {...rest}
          />

          <div className={classes.content}>
            <div className={classes.container}>
              {<Switch>{this.getRoutes(routes)}</Switch>}
            </div>
          </div>
        </div>

        {this.state.helpModal}

        {this.state.feedbackModal}

        {this.state.privacyPolicyModal}

        {this.state.termConditionsModal}

        {this.state.breachOfBusinessModal}

        {!this.props.userStore.dismissedRemindPendingTransaction &&
          this.props.userStore.pendingTransaction > 0 &&
          this.showRemindPendingTransaction()}

        {!this.props.userStore.dismissedGuildHint && this.showGuildHintModal()}

        {this.props.userStore.isNewRegister &&
          this.showSettingNotificationModal()}

        {this.state.confirmNoNotificationModal}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(appStyle)(inject("commonStore", "userStore")(observer(Dashboard)))
);
