import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class NumberOfEmployeeSearchForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "initial_payment_per_search",
                    value: 99
                },
                {
                    name: "number_of_employee_search",
                    type: "number",
                    label: "Number of Employee",
                    value: 1,
                    hooks: {
                        onChange: field => {
                            field.validate();
                            this.$("total_initial_charge").set(
                                this.$("initial_payment_per_search").value *
                                    field.value
                            );
                        }
                    },
                    rules: "required|integer|min:1"
                },
                {
                    name: "total_initial_charge",
                    value: 99
                },
                {
                    name: "sales_rep",
                    placeholder: "Select your assigned sales rep",
                    hooks: {
                        onChange: field => {
                            if (field.value === "other") {
                                this.$("other_sales_rep").set("extra", {
                                    show: true
                                });
                            } else {
                                this.$("other_sales_rep").set("extra", {
                                    show: false
                                });
                                this.$("other_sales_rep").reset();
                            }
                        }
                    }
                },
                {
                    name: "other_sales_rep",
                    extra: { show: false },
                },
            ]
        };
    }
}
