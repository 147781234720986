import {
    getTransactionApi,
    getTransactionListApi,
    repayTransactionApi,
    confirmTransactionApi,
    countPendingTransactionApi,
    download,
} from '../dataServices/transactionData.service';

const getTransactionList = (type, status ,pageSize, page, fromDate, toDate) => getTransactionListApi(type, status, pageSize, page, fromDate, toDate);
const saveAsCSV = (type, status ,pageSize, page, fromDate, toDate) => download(type, status, pageSize, page, fromDate, toDate);

const getTransaction = id => getTransactionApi(id);

const repayTransaction = (id, data) => repayTransactionApi(id, data);

const confirmTransaction = (id) => confirmTransactionApi(id);

const countPendingTransaction = () => countPendingTransactionApi();

export {
    getTransactionList,
    getTransaction,
    repayTransaction,
    confirmTransaction,
    countPendingTransaction,
    saveAsCSV,
}