import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import featuresPageStyle from "assets/jss/material-dashboard-pro-react/views/featuresPageStyle.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect } from "react";
import HeaderGeneral from "../layouts/general/components/header/HeaderGeneral";
import registration from "assets/img/background/bg-registration.png";

import Footer from "../components/Footer/Footer.jsx";
import { inject } from "mobx-react";

const styles = theme => ({
    root: {
        flexGrow: 1,
        overflow: "hidden",
        padding: `0 ${theme.spacing.unit * 3}px`
    },
    paper: {
        margin: `${theme.spacing.unit}px auto`,
        padding: theme.spacing.unit * 3,
        borderRadius: "15 !important"
    }
});

const RecruitingServiceSignUp = props => {
    const { classes, history, location, userStore } = props;

    useEffect(() => {
        document.body.style.backgroundColor = "rgb(14 21 25 )";
        return () => {
            document.body.style.backgroundImage = "none";
            document.body.style.backgroundColor = "#eeeeee";
        };
    }, []);

    // useEffect(() => {
    //     localStorage.setItem(
    //         "userIdForFirstTime",
    //         props.userStore.currentUserTW.userId
    //     );
    //     localStorage.setItem("firstTimeToFeature", true);
    // });

    const redirectToTW = () => {
        history.push("/tech-wizard");
    };

    const redirectToCreateBusiness = () => {
        history.push("/create-business-account");
    };
    const logout = () => {
        userStore.logout();
        history.push("/auth/login-page?type=bu");
    }
    return (
        <>
            <HeaderGeneral style={"none"} />
            <div
                className={classes.container}
                style={{
                    marginTop: "150px"
                }}
            >
                <GridContainer justify="center" style={{ marginTop: 75 }}>
                    <GridItem xs={4} sm={12} md={8} className={classes.mrtopItem}>
                    </GridItem>
                    <GridItem xs={4} sm={12} md={8} className={classes.mrtopItem}>
                        <Paper className={classes.paper}>
                            <Grid
                                container
                                wrap="nowrap"
                                spacing={32}
                                alignItems="center"
                                className={classes.itemWrap}
                            >
                                <Grid item className={classes.itemFeature}>
                                    <img src={require("./../assets/img/job_seekers.png")} />
                                </Grid>
                                <Grid
                                    item
                                    style={{ textAlign: "center", position: "absolute", top: 65 }}
                                >
                                    <Typography className={classes.cardTitle}>
                                        Recruiting Services Sign Up
                                    </Typography>
                                    <Typography
                                        style={{ padding: "25px 0" }}
                                        className={classes.cardContent}
                                    >
                                        You logged in as a Job Seeker. Please log out and create a new account as a Business User with different email.
                                    </Typography>
                                    <button onClick={redirectToTW} className={classes.btnContinueJob}>
                                        Keep current account
                                    </button>
                                    <button onClick={logout} className={classes.btnBackBU}>
                                        Back to login
                                    </button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </GridItem>
                    <GridItem xs={4} sm={12} md={8} className={classes.mrtopItem}>
                    </GridItem>
                </GridContainer>
                <div style={{ marginTop: 75, color: "#eeeeee" }}>
                    <Footer style={"borderTopNone"} />
                </div>
            </div>
        </>
    );
};

export default withStyles(featuresPageStyle, styles)(
    inject("userStore")(RecruitingServiceSignUp)
);
