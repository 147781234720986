import React from "react";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import { TablePagination, MenuItem, MenuList } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import NotificationsIcon from "@material-ui/icons/Notifications";

import { withStyles } from "@material-ui/core";

import notificationsPageStyle from "assets/jss/material-dashboard-pro-react/views/notificationsPageStyle.jsx";
import { inject, observer } from "mobx-react";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.props.userNotificationStore.reset();
  }

  componentDidMount() {
    this.getUserNotificationList();
  }

  getUserNotificationList = () => {
    const {
      getUserNotificationList,
      pagination
    } = this.props.userNotificationStore;
    const { pageSize, page } = pagination;
    getUserNotificationList(pageSize, page);
  };

  handleChangePage = (e, page) => {
    this.props.userNotificationStore.changePageOfPagination(page);
    this.getUserNotificationList();
  };

  handleChangePageSize = e => {
    this.props.userNotificationStore.changePageSizeOfPagination(e.target.value);
    this.getUserNotificationList();
  };

  handleClickNotification = notification => () => {
    const link = this.props.userNotificationStore.getNofiticationLink(
      notification
    );
    link && this.props.history.push(link);
  };

  render() {
    const { classes } = this.props;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const {
      userNotificationPagination,
      pagination
    } = this.props.userNotificationStore;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <NotificationsIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Notifications</h4>
            </CardHeader>
            <CardBody>
              {userNotificationPagination.items.length > 0 ? (
                <div>
                  <MenuList role="menu">
                    {userNotificationPagination.items.map(notify => (
                      <MenuItem
                        key={notify.id}
                        onClick={this.handleClickNotification(notify)}
                        className={
                          dropdownItem +
                          " " +
                          classes.notificationDropdownItem +
                          " " +
                          (!notify.read && classes.notRead)
                        }
                      >
                        <GridContainer
                          className={classes.notificationItemWrapper}
                        >
                          <GridItem xs={12}>
                            <div className={classes.notifyTitle}>
                              {notify.title}
                            </div>
                            <div>{notify.body}</div>
                            <span>
                              {moment(notify.date_created).format(
                                "MMM DD YYYY"
                              )}
                            </span>
                          </GridItem>
                        </GridContainer>
                      </MenuItem>
                    ))}
                  </MenuList>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    component="div"
                    count={userNotificationPagination.totalItem}
                    rowsPerPage={pagination.pageSize}
                    page={pagination.page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangePageSize}
                  />
                </div>
              ) : (
                <div className={classes.noNotification}>No notifications.</div>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(notificationsPageStyle)(
  inject("userNotificationStore")(withRouter(observer(Notifications)))
);
