import validatorjs from "validatorjs";
import dvr from "mobx-react-form/lib/validators/DVR";

const rules = {
  telephone: {
    function: value =>
      value.match(/^((\d{3}-\d{3}-\d{4})|(\(\d{3}\) \d{3}-\d{4})|(\d{10}))$/),
    message:
      "The :attribute phone number is not in the following formats: XXX-XXX-XXXX, XXXXXXXXXX, (XXX) XXX-XXXX."
  },
  emailOrPhone: {
    function: value => value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
    message: "This field must be in email format"
  },
  password: {
    function: value =>
      value.match(
        /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*])(?!.*\s)[0-9a-zA-Z!@#$%&*]*\S*$/
      ),
    message: "The password is invalid"
  },
  zipCode: {
    function: value => value.toString().match(/^\d{5}(?:[-\s]\d{4})?$/),
    message: "Zipcode is invalid"
  }
};

export default {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }) => {
      // here we can access the `validatorjs` instance (validator)
      // and we can add the rules using the `register()` method.
      Object.keys(rules).forEach(key =>
        validator.register(key, rules[key].function, rules[key].message)
      );
    }
  })
};
