import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class AddCardForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "name",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "postal_code",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
            ]
        };
    }
}
