import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import { appBreadcrumbs } from "../../constants/app.constant";
import userNotificationStore from "../../stores/userNotificationStore";

import PageLayout from "../../layouts/pageLayout/pageLayout";
import Loading from "../../components/Loading/Loading";

import { ReactComponent as NotiRepoIcon } from "../../assets/img/icons/notification-svgrepo.svg";

import "./notification.css";
import moment from "moment";

const ListNotification = () => {
  const [isLoading, setIsloading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [dataNoti, setDataNoti] = useState([]);

  const itemUnread = dataNoti && dataNoti.filter(item => item.read === false);

  useEffect(() => {
    getUserNotificationList();
  }, []);

  const getUserNotificationList = async () => {
    const { pageSize, page } = userNotificationStore.pagination;
    await userNotificationStore.getUserNotificationList(pageSize, page);
    userNotificationStore.userNotificationPagination &&
      userNotificationStore.userNotificationPagination.items &&
      setDataNoti(userNotificationStore.userNotificationPagination.items);
  };

  const handleItemClick = async (e, id) => {
    e.preventDefault();
    await userNotificationStore.markAsReadUserNotification(id);
    getUserNotificationList();
  };

  const handleChangeTabIndex = name => {
    if (name === "all") {
      setTabIndex(0);
    } else {
      setTabIndex(1);
    }
  };

  const renderTimeNotification = time => {
    if (moment().diff(time, "seconds") < 60) {
      return moment().diff(time, "seconds") + " " + "seconds ago";
    }
    if (moment().diff(time, "minutes") < 60) {
      return moment().diff(time, "minutes") + " " + "minutes ago";
    }
    if (moment().diff(time, "hours") < 24) {
      return moment().diff(time, "hours") + " " + "hours ago";
    }
    if (moment().diff(time, "days") < 7) {
      return moment().diff(time, "days") + " " + "days ago";
    }
    if (moment().diff(time, "weeks") <= 4) {
      return moment().diff(time, "weeks") + " " + "weeks ago";
    }
    if (moment().diff(time, "months") < 12) {
      return moment().diff(time, "months") + " " + "months ago";
    }
    return moment().diff(time, "years") + " " + "years ago";
  };

  return (
    <PageLayout pageTitle={false} sidebar={[]}>
      <div className="createPost">
        <Breadcrumbs items={appBreadcrumbs.listNotification} />
        <div className="titleBar">
          <Typography className="pageSubTitle">Notifications</Typography>
        </div>
        <div className="formNotification">
          <div>
            <div
              className={
                tabIndex === 0
                  ? "list-notification-btn active"
                  : "list-notification-btn"
              }
            >
              <button onClick={() => handleChangeTabIndex("all")}>All</button>
            </div>
            <div
              className={
                tabIndex === 1
                  ? "list-notification-btn active"
                  : "list-notification-btn"
              }
              style={{ marginLeft: 10 }}
            >
              <button onClick={() => handleChangeTabIndex("unread")}>
                Unread
              </button>
            </div>
          </div>
          {tabIndex === 0 ? (
            <div className="filterField">
              {dataNoti ? (
                dataNoti.map((item, key) => (
                  <div
                    key={key}
                    className="formField"
                    onClick={e => !item.read && handleItemClick(e, item.id)}
                  >
                    <div>
                      <NotiRepoIcon />
                    </div>
                    <div className="item-list-notification">
                      {/* <span className="notification-item-title">
                        {item.title}
                      </span>
                      <br /> */}
                      {item.type &&
                      ["USER_QUESTION_FLAG", "USER_ANSWER_FLAG"].includes(
                        item.type
                      ) ? (
                        <>
                          <span className="notification-item-body">
                            {item.title}
                          </span>
                          <br />
                          <span className="notification-item-body">
                            {item.metadata.other.content.length > 45
                              ? `
                        "${item.metadata.other.content.slice(0, 45)}..."`
                              : `"${item.metadata.other.content}"`}
                          </span>
                          <br />
                        </>
                      ) : (
                        <span className="notification-item-body">
                          {item.body}
                        </span>
                      )}
                      <p>{renderTimeNotification(item.date_created)}</p>
                    </div>
                    {!item.read && <div className="dot" />}
                  </div>
                ))
              ) : (
                <div>
                  <p className="no-notification">
                    You don't have any notifications
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="filterField">
              {itemUnread ? (
                itemUnread.map((item, key) => (
                  <div
                    key={key}
                    className="formField"
                    onClick={e => !item.read && handleItemClick(e, item.id)}
                  >
                    <div>
                      <NotiRepoIcon />
                    </div>
                    <div className="item-list-notification">
                      {/* <span className="notification-item-title">
                            {item.title}
                          </span>
                          <br /> */}
                      {item.type &&
                      ["USER_QUESTION_FLAG", "USER_ANSWER_FLAG"].includes(
                        item.type
                      ) ? (
                        <>
                          <span className="notification-item-body">
                            {item.title}
                          </span>
                          <br />
                          <span className="notification-item-body">
                            {item.metadata.other.content.length > 45
                              ? `
                        "${item.metadata.other.content.slice(0, 45)}..."`
                              : `"${item.metadata.other.content}"`}
                          </span>
                          <br />
                        </>
                      ) : (
                        <span className="notification-item-body">
                          {item.body}
                        </span>
                      )}
                      <p>{renderTimeNotification(item.date_created)}</p>
                    </div>
                    <div className="dot" />
                  </div>
                ))
              ) : (
                <div>
                  <p className="no-notification">
                    You don't have any notifications
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default ListNotification;
