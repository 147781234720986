import React, { useState } from "react";
import PostItem from "../postItem/postItem";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  noContent: {
    textAlign: "center",
    fontSize: 18,
    color: blackColor,
    fontWeight: 500,
    margin: "30px 0"
  },
  listPosts: {}
});

class Posts extends React.Component {
  constructor(props) {
    super(props);
  }

  getPosts = () => {
    const { items, isQuestion, classes } = this.props;
    if (items && items.length > 0) {
      return items.map((item, key) => (
        <PostItem
          item={item}
          key={key}
          isQuestion={isQuestion}
          infoQuestion={{
            category: item.tw_category_id && item.tw_category_id.name,
            make: item.tw_make_id && item.tw_make_id.name,
            year: item.year
          }}
        />
      ));
    } else {
      return (
        <Typography className={classes.noContent} color="textPrimary">
          No post
        </Typography>
      );
    }
  };

  render() {
    const { classes } = this.props;
    return <div className={classes.listPosts}>{this.getPosts()}</div>;
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(Posts))
  )
);
