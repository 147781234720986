import React from "react";
import { withRouter } from 'react-router-dom';

// react component for creating dynamic tables
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Tooltip, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { getFieldFromListByKey } from 'services/utils';
import Loading from "components/Loading/Loading";
import { Helmet } from "react-helmet";
import { detailHeader } from "../../variables/constant";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  disableRow: {
    backgroundColor: `rgba(0, 0, 0, 0.3)`,
    cursor: `not-allowed`,
  },
  mb20: {
    marginBottom: '20px'
  },
    tableCell: {
        fontSize: '1rem',
        padding: '4px 20px'
    }
};


const rows = [
  { id: 'listing_id', numeric: false, disablePadding: false, label: 'Listing ID' },
  { id: 'function', numeric: false, disablePadding: false, label: 'Function' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rate' },
  { id: 'start_date', numeric: false, disablePadding: false, label: 'Available' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Date Posted' },
  { id: 'distance', numeric: true, disablePadding: false, label: 'Distance' },
];

// const collapseComponent = (props) => (
//   <td colSpan={4}>
//     <div className={props.className}>
//       {props.children}
//     </div>
//   </td>
// )

class AvailableEmployees extends React.Component {

  constructor(props) {
    super(props);
    this.props.employeesStore.setPageViewing('available');
  }

  componentDidMount() {
    this.handleSort('distance')();
  }

  getAvailableEmployeesList = () => {
    const { getAvailableEmployeesList, pagination } = this.props.employeesStore;
    const { pageSize, page, sort, order, jobType, func } = pagination;
    getAvailableEmployeesList(jobType, func, pageSize, page, sort, order);
  }

  handleChangePage = (e, page) => {
    this.props.employeesStore.changePageOfPagination(page);
    this.getAvailableEmployeesList();
  }

  handleChangePageSize = (e) => {
    this.props.employeesStore.changePageSizeOfPagination(e.target.value);
    this.getAvailableEmployeesList();
  }

  handleSort = sort => () => {
    this.props.employeesStore.changeSortOfPagination(sort);
    this.getAvailableEmployeesList();
  }

  changeFilter = e => {
    this.props.employeesStore.changeJobTypePagination(e.target.value);
    this.getAvailableEmployeesList();
  }

  changeFuncFilter = e => {
    this.props.employeesStore.changeFuncPagination(e.target.value);
    this.getAvailableEmployeesList();
  }

  setCurrentEmployee = emp => () => {
    this.props.history.push(`/employees-available/${emp.id}`);
  }

  render() {
    const { classes } = this.props;
    const { employeesPagination, pagination, isLoading } = this.props.employeesStore;
    const { functions, jobTypes } = this.props.commonStore;
    const { timeZone } = this.props.userStore;

    return (
      <div>
        <Helmet>
            <title>{detailHeader.normalPage.title}</title>
            <meta name='description' content={detailHeader.normalPage.description} />
        </Helmet>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Browse Employees Available</h4>
              </CardHeader>
              <CardBody>
                <GridContainer className={classes.mb20}>
                  <GridItem xs={12} md={6}>
                    <div >
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="filter-select"
                          className={classes.selectLabel}
                        >
                          Select employee type
              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          inputProps={{
                            name: "filterSelect",
                            id: "filter-select"
                          }}
                          value={pagination.jobType}
                          onChange={this.changeFilter}
                        >
                        <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value='all'
                          >
                            All
                            </MenuItem>
                          {jobTypes.map(type =>
                            <MenuItem
                              key={type.value}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              disabled={type.disabled}
                              value={type.value}
                            >
                              {type.name}
                            </MenuItem>)}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <div >
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="filter-select"
                          className={classes.selectLabel}
                        >
                          Select functions
              </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          inputProps={{
                            name: "filterSelect",
                            id: "filter-select"
                          }}
                          value={pagination.func}
                          onChange={this.changeFuncFilter}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value='all'
                          >
                            All
                            </MenuItem>
                          {functions.map(func =>
                            <MenuItem
                              key={func.value}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={func.value}
                            >
                              {func.name}
                            </MenuItem>)}
                        </Select>
                      </FormControl>
                    </div>
                  </GridItem>
                </GridContainer>
                <div style={{ overflow: 'auto' }}>
                  {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                          {rows.map(
                          row => (
                              <TableCell
                              key={row.id}
                              className={classes.tableCell}
                              align={row.numeric ? 'right' : 'left'}
                              padding={row.disablePadding ? 'none' : 'default'}
                              sortDirection={pagination.sort === row.id ? pagination.order : false}
                              >
                              {!row.disableSort ? <Tooltip
                                  title="Sort"
                                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                  enterDelay={300}
                              >
                                  <TableSortLabel
                                  active={pagination.sort === row.id}
                                  direction={pagination.order}
                                  onClick={this.handleSort(row.id)}
                                  >
                                  {row.label}
                                  </TableSortLabel>
                              </Tooltip> : row.label}
                              </TableCell>
                          ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {employeesPagination.items.map(n => {
                        return (
                          <React.Fragment
                            key={n.id}
                          >
                            <Tooltip title=''>
                              <TableRow
                                hover
                                tabIndex={-1}
                                onClick={this.setCurrentEmployee(n)}
                              >
                                <TableCell>{n.listing_id}</TableCell>
                                <TableCell>
                                  {n.function === 'other' ? `Other - ${n.other_function}` : getFieldFromListByKey(functions, 'name', 'value', n.function)}
                                </TableCell>
                                <TableCell>
                                  ${n.rate}/hr
                                </TableCell>
                                <TableCell>
                                  {`${timeZone && moment(n.start_date).tz(timeZone).format('MMM DD YYYY')} to ${timeZone &&moment(n.end_date).tz(timeZone).format('MMM DD YYYY')}`}
                                </TableCell>
                                <TableCell>
                                  {`${timeZone && moment(n.created_at).tz(timeZone).format('MMM DD YYYY')}`}
                                </TableCell>
                                <TableCell align="right">
                                  {Math.ceil(n.distance)} miles
                                </TableCell>
                              </TableRow>
                            </Tooltip>
                            {/* <Collapse
                              in={currentEmployees._id === n._id}
                              timeout="auto"
                              component={collapseComponent}
                              unmountOnExit
                            >
                              <ExpandableEmployeeContent data={currentEmployees} />
                            </Collapse> */}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                    </Table> }
                </div>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  component="div"
                  count={employeesPagination.totalItem}
                  rowsPerPage={pagination.pageSize}
                  page={pagination.page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangePageSize}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles({ ...extendedFormsStyle, ...styles })(inject('employeesStore', 'commonStore', 'userStore')(withRouter(observer(AvailableEmployees))));
