import { Form } from "mobx-react-form";
import plugins from "./plugins";
import * as moment from "moment";
import stores from "stores";
import { getDiffDays } from "services/utils";

export default class HireEmployeeForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "must_bring_tools",
                    type: "radio",
                    value: "no"
                },
                {
                    name: "expect_work_on_weekends",
                    type: "radio",
                    value: "no",
                    hooks: {
                        onChange: field => {
                            const startDate = moment(
                                this.$("start_date").value
                            );
                            const endDate = moment(this.$("end_date").value);
                            const diffDays = getDiffDays(endDate, startDate, field.value === 'yes');
                            if (!Number.isNaN(diffDays)) {
                                this.$("numberOfDays").set(diffDays);
                                this.$("feePlatform").set(
                                    stores.commonStore.getPlatformFee(
                                        this.$("rate").value,
                                        this.$("numberOfDays").value,
                                        this.$("work_day_hours").value
                                    )
                                );
                                this.$("estimatedPay").set(
                                    this.$("rate").value *
                                        this.$("work_day_hours").value *
                                        this.$("numberOfDays").value
                                );
                            }
                            field.validate();
                        }
                    }
                },
                {
                    name: "work_day_hours",
                    value: 8,
                    extra: new Array(24).fill(1).map((value, idx) => idx + 1),
                    hooks: {
                        onChange: field => {
                            const startDate = moment(
                                this.$("start_date").value
                            );
                            const endDate = moment(this.$("end_date").value);
                            const diffDays = getDiffDays(endDate, startDate, this.$('expect_work_on_weekends').value === 'yes');
                            if (!Number.isNaN(diffDays)) {
                                this.$("numberOfDays").set(diffDays);
                                this.$("feePlatform").set(
                                    stores.commonStore.getPlatformFee(
                                        this.$("rate").value,
                                        this.$("numberOfDays").value,
                                        field.value
                                    )
                                );
                                this.$("estimatedPay").set(
                                    this.$("rate").value *
                                        field.value *
                                        this.$("numberOfDays").value
                                );
                            }
                            field.validate();
                        }
                    }
                },
                {
                    name: "start_date",
                    placeholder: "Choose start date",
                    disabled: true,
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            const startDate = moment(field.value);
                            const endDate = moment(this.$("end_date").value);
                            const diffDays = getDiffDays(endDate, startDate, this.$('expect_work_on_weekends').value === 'yes');
                            if (!Number.isNaN(diffDays)) {
                                this.$("numberOfDays").set(diffDays);
                                this.$("feePlatform").set(
                                    stores.commonStore.getPlatformFee(
                                        this.$("rate").value,
                                        this.$("numberOfDays").value,
                                        this.$("work_day_hours").value
                                    )
                                );
                                this.$("estimatedPay").set(
                                    this.$("rate").value *
                                        this.$("work_day_hours").value *
                                        this.$("numberOfDays").value
                                );
                            }
                            field.validate();
                        }
                    }
                },
                {
                    name: "end_date",
                    placeholder: "Choose end date",
                    disabled: true,
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            const endDate = moment(field.value);
                            const startDate = moment(
                                this.$("start_date").value
                            );
                            const diffDays = getDiffDays(endDate, startDate, this.$('expect_work_on_weekends').value === 'yes');
                            if (!Number.isNaN(diffDays)) {
                                this.$("numberOfDays").set(diffDays);
                                this.$("feePlatform").set(
                                    stores.commonStore.getPlatformFee(
                                        this.$("rate").value,
                                        this.$("numberOfDays").value,
                                        this.$("work_day_hours").value
                                    )
                                );
                                this.$("estimatedPay").set(
                                    this.$("rate").value *
                                        this.$("work_day_hours").value *
                                        this.$("numberOfDays").value
                                );
                            }
                            field.validate();
                        }
                    }
                },
                {
                    name: "numberOfDays",
                    value: 0
                },
                {
                    name: "work_start_time",
                    placeholder: "Choose work start time",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "work_start_time_a",
                    value: "AM",
                    extra: ["AM", "PM"]
                },
                {
                    name: "rate",
                    placeholder: "Enter rate",
                    label: "Rate",
                    rules: "required|integer|min:0",
                    disabled: true,
                    hooks: {
                        onChange: field => {
                            this.$("feePlatform").set(
                                stores.commonStore.getPlatformFee(
                                    this.$("rate").value,
                                    this.$("numberOfDays").value,
                                    this.$("work_day_hours").value
                                )
                            );
                            this.$("estimatedPay").set(
                                this.$("rate").value *
                                    this.$("work_day_hours").value *
                                    this.$("numberOfDays").value
                            );
                            field.validate();
                        }
                    }
                },
                {
                    name: "instructions",
                    placeholder: "Enter instructions",
                    rules: "required",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "estimatedPay",
                    type: "number",
                    value: 0
                },
                {
                    name: "feePlatform",
                    type: "number",
                    value: 0
                },
                {
                    name: "submitting",
                    value: false
                }
            ]
        };
    }
}
