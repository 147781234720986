import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import FormHelperText from "@material-ui/core/FormHelperText";

import Button from "components/CustomButtons/Button.jsx";
import { withStyles } from '@material-ui/core';
import PaymentCard from './components/PaymentCard';
import notificationService from '../../../../services/notificationService';
import { observer } from 'mobx-react';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    helperText: {
        color: '#f44336',
        marginTop: '0'
    },
    formField: {
        marginBottom: '10px',
        padding: '0 5px!important'
    },
    gridContainer: {
        width: 'calc(100% + 10px)',
        margin: '0 -5px'
    },
    mra: {
        marginRight: 'auto'
    },
    mla: {
        marginLeft: 'auto'
    }
}

const createOptions = () => {
    return {
        style: {
            base: {
                fontSize: '16px',
                color: '#424770',
                letterSpacing: '0.025em',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#c23d4b',
            },
        }
    }
};

class PaymentInfo extends React.Component {
    cardElementRef;
    expireDateElementRef;
    cvcElementRef;
    cardElementRef;

    state = {
        cardNumberError: '',
        cvcError: '',
        expireDateError: ''
    };

    changePostalCode = e => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            this.setState({
                postalCode: value
            })
        }
    }


    changeNameOnCard = e => {
        const value = e.target.value;
        this.setState({
            nameOnCard: value
        })
    }

    handleChange = (fieldName) => ({ error }) => {
        if (error) {
            this.setState({ [fieldName]: error.message });
        }
    };
    submit = () => {
        const { addCardForm } = this.props;
        const { name, postal_code } = addCardForm.values();
        addCardForm.showErrors();
        if (addCardForm.isValid) {
            const billingDetails = { billing_details: { name: name, address: { postal_code } } };
            this.props.stripe.createPaymentMethod('card', billingDetails).then(({ paymentMethod, error }) => {
                if (paymentMethod) {
                    this.props.addPaymentMethod(paymentMethod.id).then(() => {
                        this.setState({
                            cardNumberError: '',
                            cvcError: '',
                            expireDateError: '',
                        });
                        this.cardElementRef.clear();
                        this.expireDateElementRef.clear();
                        this.cvcElementRef.clear();
                        addCardForm.reset();
                        addCardForm.showErrors(false);
                    });
                }
                if (error) {
                    notificationService.error(error.message);
                }
            });
        } else {
            this.props.stripe.createPaymentMethod('card');
        }
    }

    render() {
        const { classes, cardList, setDefaultCard, deleteCard, isSelectPayment, selectPayment, submitting, addCardForm, previousStep, handlePrevFunc, prevText, showChargeHintText, hidePrevBtn } = this.props;
        return <div>
            <div className={classes.container} >
                {cardList && cardList.length > 0 && <GridItem xs={12} md={6}>
                    <h4>Existing Cards</h4>
                    {cardList.map(card => {
                        return <PaymentCard isSelectPayment={isSelectPayment} data={card} key={card.id} setDefaultCard={setDefaultCard} deleteCard={deleteCard} selectPayment={selectPayment} />
                    })}
                </GridItem>}
                <GridItem xs={12} md={6} style={{ padding: '0!important' }}>
                    <h4>Add Credit Card Information</h4>
                    <GridContainer className={classes.gridContainer}>
                        <GridItem xs={12} md={12} className={classes.formField}>
                            <label>
                                Name on Card
                            <input className={'StripeElement'} placeholder="Jane Doe"  {...addCardForm.$('name').bind()} />
                            </label>
                            {addCardForm.$('name').error && <FormHelperText className={classes.helperText}>This field is required</FormHelperText>}
                        </GridItem>
                        <GridItem xs={12} md={8} className={classes.formField}>
                            <label>
                                Card number
                            <CardNumberElement
                                    onChange={this.handleChange('cardNumberError')}
                                    onReady={(element) => this.cardElementRef = element}
                                    {...createOptions()}
                                />
                            </label>
                            <FormHelperText className={classes.helperText}>
                                {this.state.cardNumberError}
                            </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} md={4} className={classes.formField}>
                            <label>
                                Exp. date
                            <CardExpiryElement
                                    onChange={this.handleChange('expireDateError')}
                                    onReady={(element) => this.expireDateElementRef = element}
                                    {...createOptions()}
                                />
                            </label>
                            <FormHelperText className={classes.helperText}>
                                {this.state.expireDateError}
                            </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} md={6} className={classes.formField}>
                            <label>
                                CVC
                            <CardCVCElement
                                    onChange={this.handleChange('cvcError')}
                                    onReady={(element) => this.cvcElementRef = element}
                                    {...createOptions()}
                                />
                            </label>
                            <FormHelperText className={classes.helperText}>
                                {this.state.cvcError}
                            </FormHelperText>
                        </GridItem>
                        <GridItem xs={12} md={6} className={classes.formField}>
                            <label>
                                Zip code
                            <input className={'StripeElement'} {...addCardForm.$('postal_code').bind()} />
                            </label>
                            {addCardForm.$('postal_code').error && <FormHelperText className={classes.helperText}>This field is required</FormHelperText>}
                        </GridItem>
                    </GridContainer>
                    {showChargeHintText && <div>You will not be charged the platform fee until the employee confirms</div>}
                </GridItem>
            </div>
            <div className={classes.container}>
                {!hidePrevBtn && <Button className={classes.mra} round onClick={handlePrevFunc ? handlePrevFunc : previousStep}>{prevText || 'Previous'}</Button>}
                <Button className={classes.mla} color="primary" round onClick={this.submit} loading={submitting} disabled={submitting}>Add Card</Button>
            </div>
        </div>
    }
}

export default injectStripe(withStyles(styles)(observer(PaymentInfo)));