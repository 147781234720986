import React from 'react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';
import Rating from 'react-rating';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';

const styles = {
    mbt10: {
        marginBottom: '10px',
        marginTop: '10px'
    },
    wrapper: {
        maxHeight: 'calc(80vh - 130px)',
        overflow: 'auto'
    },
    textLeft: {
        textAlign: 'left'
    },
    rateIcon: {
        color: '#F8981F'
    }
}

class Reviews extends React.Component {

    render() {
        const { reviews, handleClose, classes } = this.props;

        return <SweetAlert
            title="Reviews"
            onConfirm={handleClose}
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            confirmBtnCssClass={
                this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
                this.props.classes.button + " " + this.props.classes.danger
            }
            showCancel={false}
        >
            <GridContainer className={classes.wrapper}>
                {reviews && reviews.map(item => <React.Fragment key={item.id}>
                    <GridItem className={classes.mbt10} xs={4}>
                        <div>{item.business_owner.preferences.business_name}</div>
                        <div>
                            <Rating
                                initialRating={item.rating}
                                readonly
                                fractions={2}
                                emptySymbol={<StarBorder  className={classes.rateIcon} />}
                                fullSymbol={<StarRate  className={classes.rateIcon} />}
                            />
                        </div>
                        {moment(item.date_to_publish).format('MMM DD YYYY')}
                    </GridItem>
                    <GridItem className={classes.mbt10} xs={8}>
                        <div className={classes.textLeft}>{item.comment}</div></GridItem>
                </React.Fragment>
                )}
            </GridContainer>
        </SweetAlert >
    }
}

export default withStyles({ ...sweetAlertStyle, ...styles })(observer(Reviews));