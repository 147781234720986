import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Check from "@material-ui/icons/Check";
import { Helmet } from "react-helmet";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { inject, observer } from "mobx-react";
import notificationService from "../services/notificationService";
import { detailHeader } from "../variables/constant";
import { appRole } from "../constants/app.constant";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    // props.authStore.logout();
    this.loginTypeList = ["google"];
    this.isBu = window.location.href.includes("type=bu");
    this.state = {
      cardAnimaton: "cardHidden",
      modal1Open: false,
      emailOrPhone: "",
      password: ""
    };
  }

  componentWillMount() {
    const currentUserIdLocalStorage = localStorage.getItem(
      "userIdForFirstTime"
    );
    const firstTimeToFeatureLocalStorage = localStorage.getItem(
      "firstTimeToFeature"
    );
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "user_general"
    ) {
      currentUserIdLocalStorage ===
        this.props.userStore.currentUserTW.userId.toString() &&
      firstTimeToFeatureLocalStorage
        ? (window.location.href = "/app/#/tech-wizard")
        : (window.location.href = "/app/#/features");
    }
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "business_owner"
    ) {
      window.location.href = "/app/#/employees/employees-available";
    }
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "job_seeker"
    ) {
      window.location.href = "/app/#/tech-wizard";
    }
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  login = async e => {
    e.preventDefault();
    const { loginForm, login } = this.props.authStore;
    const { history } = this.props;
    // if (!loginForm.isValid) {
    //   return loginForm.showErrors(true);
    // }
    loginForm.submit({
      onSuccess: form => {
        const data = form.values();
        login(data, this.isBu)
          .then(res => {
            if (!res) {
              return;
            }
            let oldUserList = JSON.parse(
              localStorage.getItem("userIdForFirstTime")
            );
            if (oldUserList === null) {
              oldUserList = [];
            }
            let isFirstTime = !oldUserList.includes(res.id);
            oldUserList.push(res.id);
            localStorage.setItem(
              "userIdForFirstTime",
              JSON.stringify(oldUserList)
            );

            // Check for preLoginUrl and redirect if it exists
            const preLoginUrl = localStorage.getItem("preLoginUrl");
            if (preLoginUrl) {
              localStorage.removeItem("preLoginUrl"); // Clear preLoginUrl from localStorage
              history.push(preLoginUrl); // Redirect to the saved URL
              return; // Prevent further execution
            }

            // Existing redirection logic
            if (oldUserList.includes(res.id)) {
              history.push("/features");
            }
            if (appRole.business === res.role) {
              if (this.isBu) {
                history.push("/settings?tab=subscription");
                return;
              } else {
                if (isFirstTime) {
                  history.push("/settings?tab=subscription");
                } else {
                  history.push("/employees/employees-available");
                }
                return;
              }
            }
            if (appRole.job_seeker === res.role) {
              if (this.isBu) {
                history.push("/features/recruiting-service-sign-up");
                return;
              } else {
                history.push("/tech-wizard");
                return;
              }
            }
            if (appRole.general === res.role) {
              if (this.isBu) {
                history.push("/create-business-account");
                return;
              }
            }
            if (isFirstTime) {
              history.push("/features");
              return;
            } else {
              history.push("/tech-wizard");
              return;
            }
          })
          .catch(res => {
            const err = res.response;
            if (err) notificationService.error(err.data.message);
          });
      },
      onError: () => {}
    });
  };
  butttonStyle = {
    google: {
      backgroundColor: "red",
      width: "100%",
      height: "40px",
      marginTop: "10px"
    },
    facebook: {
      backgroundColor: "blue",
      width: "100%",
      marginTop: "10px"
    },
    default: {
      backgroundColor: "yellow",
      width: "100%",
      marginTop: "10px"
    }
  };

  render() {
    const { classes } = this.props;
    const { loginForm } = this.props.authStore;
    return (
      <div className={classes.container}>
        <Helmet>
          <title>{detailHeader.loginPage.title}</title>
          <meta
            name="description"
            content={detailHeader.loginPage.description}
          />
        </Helmet>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={this.login}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                >
                  <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      ...loginForm.$("emailOrPhone").bind()
                    }}
                    error={!!loginForm.$("emailOrPhone").error}
                    helpText={loginForm.$("emailOrPhone").error || undefined}
                    labelText="Email"
                  />
                  <CustomInput
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      ...loginForm.$("password").bind()
                    }}
                    error={!!loginForm.$("password").error}
                    helpText={loginForm.$("password").error || undefined}
                    labelText="Password"
                  />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={loginForm.$("rememberMe").onChange}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label="Remember me"
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      className={classes.textRight}
                    >
                      <Link to="/auth/forgot-password">Forgot password ?</Link>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <div>
                    <Button
                      color="primary"
                      simple
                      size="lg"
                      block
                      type="submit"
                    >
                      Let's Go
                    </Button>
                    <div className={classes.bottomText}>
                      First Time User?{" "}
                      <Link
                        className={classes.registerLink}
                        to={
                          this.isBu
                            ? "/auth/register-page/general?type=bu"
                            : "/auth/register-page/general"
                        }
                      >
                        REGISTER HERE
                      </Link>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(
  inject("authStore", "userStore")(observer(LoginPage))
);
