import React from "react";
import ReactDOM from "react-dom";
import { createHashHistory } from "history";
import { Router, Switch } from "react-router-dom";
import { inject, observer, Provider } from "mobx-react";

import { MuiThemeProvider } from "@material-ui/core/styles";

import stores from "./stores";
import theme from "./layouts/theme";

// import BusinessLayout from "./layouts/Business";
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0";
import "app.css";
import './scss/login.scss'
import './scss/error.scss'

import UnauthorizedRoute from "./components/Route/UnauthorizedRoute";
import AuthorizedRoute from "./components/Route/AuthorizedRoute";
import Snackbar from "./components/Snackbar/Snackbar";
import Loading from "./components/Loading/Loading";

window._____APP_STATE_____ = stores;

const hist = createHashHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    console.log("App props ", props);
  }
  componentDidMount() {
    this.props.userStore.getCurrentUser();
    this.props.postsStore.getCategoriesFromApi();
    this.props.postsStore.getMakesFromAPI();
  }

  render() {
    const { currentUser } = this.props.userStore;
    if (this.props.commonStore.appLoaded) {
      const currentNotify = this.props.notificationStore.current;

      const renderLayout = () => {
        if (currentUser) {
          return <AuthorizedRoute />;
        } else {
          return <UnauthorizedRoute />;
        }
      };

      return (
        <>
          {currentNotify && (
            <Snackbar
              place="tr"
              color={currentNotify.color}
              message={currentNotify.message}
              open={currentNotify.display}
              icon={currentNotify.icon}
              closeNotification={this.props.notificationStore.process}
              onClose={this.props.notificationStore.hide}
              onExited={this.props.notificationStore.process}
              close
            />
          )}
          <Router history={hist}>
            <Switch>{renderLayout()}</Switch>
          </Router>
        </>
      );
    }
    return <Loading />;
  }
}

const InjectedApp = inject(
  "notificationStore",
  "userStore",
  "commonStore",
  "postsStore"
)(observer(App));

ReactDOM.render(
  <Provider {...stores}>
    <MuiThemeProvider theme={theme}>
      <InjectedApp />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
