import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import SettingsIcon from "@material-ui/icons/Settings";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import NotificationPreferences from "./components/NotificationPreferences";
import PaymentInfo from "./components/PaymentInfo/PaymentInfo";
import Account from "./components/Account";
import { observer, inject } from "mobx-react";
import notificationService from "../../services/notificationService";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Subscription from "./components/Subscription";
import SubcribeTWDegist from "./components/SubcribeTWDegist";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  header: {
    display: "flex",
    alignItems: "flex-start"
  },
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

class Settings extends React.Component {
  state = {
    deleteCardConfirm: null,
    submitting: false,
    confirmSubscriptionModal: null,
    successfullSubscriptionModal: null,
    confirmNoNotificationModal: null
  };
  componentDidMount() {
    this.props.userStore.getNotificationPreferences();
    this.props.userStore.getPaymentMethods();
    this.props.userStore.getSubscriptionInfo();
  }

  get tab() {
    const params = new URLSearchParams(this.props.location.search);
    const tab = params.get("tab");
    return tab;
  }

  submitChangePassword = e => {
    e.preventDefault();
    const { accountSettingsForm, changePassword } = this.props.userStore;
    if (!accountSettingsForm.isValid) {
      return accountSettingsForm.showErrors(true);
    }
    accountSettingsForm.submit({
      onSuccess: form => {
        const { current_password, new_password } = form.values();
        this.setState({ submitting: true });
        changePassword({ current_password, new_password })
          .then(() => {
            notificationService.success("SETTINGS.CHANGE_PASSWORD_SUCCESS");
            this.setState({ submitting: false });
            accountSettingsForm.reset();
            accountSettingsForm.showErrors(false);
          })
          .catch(res => {
            const err = res.response;
            this.setState({ submitting: false });
            notificationService.error(err.data.message);
          });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  submitNotificationPreferences = e => {
    e.preventDefault();
    const {
      notificationPreferencesForm,
      changeNotificationPreferences
    } = this.props.userStore;
    if (!notificationPreferencesForm.isValid) {
      return notificationPreferencesForm.showErrors(true);
    }
    notificationPreferencesForm.submit({
      onSuccess: form => {
        const { notification_preferences } = form.values();
        const { max_distance, sending_method } = notification_preferences;
        this.setState({ submitting: true });
        changeNotificationPreferences({
          notification_preferences: {
            max_distance: +max_distance,
            sending_method
          }
        })
          .then(() => {
            notificationService.success("SETTINGS.CHANGE_NOTIFICATION_SUCCESS");
            this.setState({ submitting: false });
          })
          .catch(res => {
            const err = res.response;
            this.setState({ submitting: false });
            notificationService.error(err.data.message);
          });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  setDefaultCard = id => {
    this.props.userStore.setDefaultPaymentMethod(id).then(() => {
      notificationService.success("SETTINGS.SET_DEFAULT_CARD_SUCCESS");
      this.props.userStore.getPaymentMethods();
    });
  };

  deleteCard = id => {
    this.setState({
      deleteCardConfirm: (
        <SweetAlert
          warning
          title=""
          onConfirm={this.handleDeleteCard(id)}
          onCancel={this.closeConfirmDeleteModal}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          Are you sure you want do delete this card ?
        </SweetAlert>
      )
    });
  };

  handleDeleteCard = id => () => {
    this.props.userStore.deletePaymentMethod(id).then(() => {
      notificationService.success("SETTINGS.DELETE_CARD_SUCCESS");
      this.closeConfirmDeleteModal();
      this.props.userStore.getPaymentMethods();
    });
  };

  closeConfirmDeleteModal = () => {
    this.setState({ deleteCardConfirm: null });
  };

  addPaymentMethod = id => {
    this.setState({ submitting: true });
    return this.props.userStore.addPaymentMethod(id).then(() => {
      this.setState({ submitting: false });
      notificationService.success("SETTINGS.ADD_CARD_SUCCESS");
      this.props.userStore.getPaymentMethods();
    });
  };

  purchaseSubscription = data => {
    return this.props.userStore.purchaseSubscription(data);
  };

  showConfirmSubscriptionModal = (confirmFn, initAmount) => {
    if (this.props.userStore.numberOfEmployeeSearchForm.isValid) {
      this.setState({
        confirmSubscriptionModal: (
          <SweetAlert
            warning
            title=""
            onConfirm={confirmFn}
            onCancel={this.closeConfirmSubscriptionModal}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Yes"
            cancelBtnText="No"
            showCancel
          >
            Are you sure you want to add a new search and make an initial
            payment of ${initAmount}?
          </SweetAlert>
        )
      });
    }
  };

  closeConfirmSubscriptionModal = () => {
    this.setState({
      confirmSubscriptionModal: null
    });
  };

  showSuccessfullSubscriptionModal = () => {
    this.setState({
      successfullSubscriptionModal: (
        <SweetAlert
          warning
          title=""
          onConfirm={this.closeSuccessfullSubscriptionModal}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          confirmBtnText="Ok"
        >
          Thank you for signing up for our Recruiting Services. We will be in
          touch with you shortly to go over your requirements and start an
          employee search.
        </SweetAlert>
      )
    });
  };

  closeSuccessfullSubscriptionModal = () => {
    this.setState({
      successfullSubscriptionModal: null
    });
  };

  showConfirmNoNotificationModal = () => {
    this.setState({
      confirmNoNotificationModal: (
        <SweetAlert
          warning
          title=""
          onConfirm={this.onConfirmNoNotification}
          onCancel={this.onCancelConfirmNoNotification}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          You will not be notified of any techs and other job seekers in your
          area. Are you sure you want to do this ?
        </SweetAlert>
      )
    });
  };

  onConfirmNoNotification = () => {
    this.props.userStore.notificationPreferencesForm
      .$("notification_preferences.sending_method")
      .set("none");
    this.setState({ confirmNoNotificationModal: null });
  };

  onCancelConfirmNoNotification = () => {
    this.setState({ confirmNoNotificationModal: null });
  };

  render() {
    const { classes } = this.props;
    const {
      accountSettingsForm,
      notificationPreferencesForm,
      toggleShowCurrentPassword,
      toggleShowNewPassword,
      paymentMethods,
      subscriptionInfo,
      availableSubscriptionList,
      getAvailableSubscriptionList,
      getSubscriptionInfo,
      addCardForm,
      toggleShowRetypeNewPassword,
      numberOfEmployeeSearchForm
    } = this.props.userStore;
    const {
      appConfig,
      contentAgreement,
      getContentAgreement,
      salesRep
    } = this.props.commonStore;

    return (
      <div>
        <StripeProvider apiKey={appConfig && appConfig.stripe.api_key}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" icon className={classes.header}>
                  <CardIcon color="primary">
                    <SettingsIcon />
                  </CardIcon>
                  <h4 className={classes.cardIconTitle}>Settings</h4>
                </CardHeader>
                <CardBody>
                  <NavPills
                    color="primary"
                    horizontal={{
                      tabsGrid: {
                        xs: 12,
                        sm: 12,
                        md: 2,
                        style: { minWidth: "230px" }
                      },
                      contentGrid: {
                        xs: 12,
                        sm: 12,
                        md: 10,
                        style: { maxWidth: "calc(100% - 230px)" }
                      }
                    }}
                    active={this.tab === "subscription" ? 3 : 0}
                    tabs={[
                      {
                        tabButton: "Notification Preferences",
                        tabContent: (
                          <NotificationPreferences
                            form={notificationPreferencesForm}
                            submit={this.submitNotificationPreferences}
                            submitting={this.state.submitting}
                            showConfirmNoNotificationModal={
                              this.showConfirmNoNotificationModal
                            }
                          />
                        )
                      },
                      {
                        tabButton: "Payment Methods",
                        tabContent: (
                          <Elements>
                            <PaymentInfo
                              addPaymentMethod={this.addPaymentMethod}
                              cardList={paymentMethods}
                              setDefaultCard={this.setDefaultCard}
                              deleteCard={this.deleteCard}
                              submitting={this.state.submitting}
                              addCardForm={addCardForm}
                              hidePrevBtn={true}
                            />
                          </Elements>
                        )
                      },
                      {
                        tabButton: "Change Password",
                        tabContent: (
                          <Account
                            form={accountSettingsForm}
                            toggleShowCurrentPassword={
                              toggleShowCurrentPassword
                            }
                            toggleShowNewPassword={toggleShowNewPassword}
                            toggleShowRetypeNewPassword={
                              toggleShowRetypeNewPassword
                            }
                            submit={this.submitChangePassword}
                            submitting={this.state.submitting}
                          />
                        )
                      },
                      {
                        tabButton: "Recruiting Services Sign-up",
                        tabContent: (
                          <Elements>
                            <Subscription
                              subscriptionInfo={subscriptionInfo}
                              getAvailableSubscriptionList={
                                getAvailableSubscriptionList
                              }
                              availableSubscriptionList={
                                availableSubscriptionList
                              }
                              addPaymentMethod={this.addPaymentMethod}
                              cardList={paymentMethods}
                              purchaseSubscription={this.purchaseSubscription}
                              getSubscriptionInfo={getSubscriptionInfo}
                              submitting={this.state.submitting}
                              contentAgreement={contentAgreement}
                              getContentAgreement={getContentAgreement}
                              addCardForm={addCardForm}
                              numberOfEmployeeSearchForm={
                                numberOfEmployeeSearchForm
                              }
                              showConfirmSubscriptionModal={
                                this.showConfirmSubscriptionModal
                              }
                              closeConfirmSubscriptionModal={
                                this.closeConfirmSubscriptionModal
                              }
                              showSuccessfullSubscriptionModal={
                                this.showSuccessfullSubscriptionModal
                              }
                              setDefaultCard={this.setDefaultCard}
                              salesRep={salesRep}
                            />
                          </Elements>
                        )
                      },
                      {
                        tabButton: "Subcribe Tech Wizard Digest",
                        tabContent: (
                          <Elements>
                            <SubcribeTWDegist />
                          </Elements>
                        )
                      }
                    ]}
                  />
                  {this.state.deleteCardConfirm}
                  {this.state.confirmSubscriptionModal}
                  {this.state.successfullSubscriptionModal}
                  {this.state.confirmNoNotificationModal}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </StripeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(
  inject("userStore", "commonStore")(observer(Settings))
);
