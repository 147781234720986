import React from 'react';
import { withRouter } from 'react-router-dom';
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Rating from 'react-rating';
import SweetAlert from "react-bootstrap-sweetalert";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Help from "@material-ui/icons/Help";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { withStyles, Tooltip } from '@material-ui/core';

// images
import { inject, observer } from 'mobx-react';
import Loading from 'components/Loading/Loading';
import ForemanProfile from '../Employees/EmployeeDetails/components/ForemanProfile';
import ServiceAdvisorProfile from '../Employees/EmployeeDetails/components/ServiceAdvisorProfile';
import AutoBodyTechnicianProfile from '../Employees/EmployeeDetails/components/AutoBodyTechnicianProfile';
import CollisionServiceAdvisorProfile from '../Employees/EmployeeDetails/components/CollisionServiceAdvisorProfile';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import notificationService from 'services/notificationService';
import { convertDisplayText } from 'services/utils';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { getFieldFromListByKey } from 'services/utils';
import BackPreviousPageButton from 'components/BackPreviousPageButton';
import PaymentModal from "components/PaymentModal";
import Reviews from '../Employees/EmployeeDetails/components/Reviews';

import { getDiffDays } from '../../services/utils';
import html2pdf from "html2pdf.js/src";

const styles = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    container: {
        padding: '10px 20px'
    },
    avatar: {
        marginTop: 0
    },
    ml10: {
        marginLeft: '10px'
    },
    checkboxLabelControl: {
        marginLeft: '5px'
    },
    wrapperPaymentImg: {
        height: '80px'
    },
    paymentImg: {
        height: '100%',
        width: '100%'
    },
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    fullHeight: {
        height: '100%'
    },
    tooltip: {
        zIndex: 20000
    },
    paymentWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    mb30: {
        marginBottom: '30px'
    },
    showReviewBtn: {
        border: 'none',
        padding: '0',
        background: 'none',
        fontSize: '.875rem',
        fontWeight: 'bold',
        marginLeft: '10px',
        '&:hover': {
            textDecoration: 'underline',
            color: '#00acc1',
            cursor: 'pointer'
        }
    },
    rateIcon: {
        color: '#F8981F'
    }
}

class ApplicationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmRejectModal: null,
            confirmAcceptModal: null,
            paymentModal: null,
            reviewsModal: null
        }
    }


    componentDidMount() {
        this.props.applicationsStore.getApplicationById(this.applicationId);
    }

    get applicationId() {
        return this.props.match.params.id;
    }


    acceptApplication = isTempoJob => () => {
        if (this.props.userStore.hasPaymentMethod()) {
            this.handleAcceptApplication(isTempoJob)();
        } else {
            this.setState({
                paymentModal: <PaymentModal addPaymentMethod={this.addPaymentMethod} closePaymentModal={this.closePaymentModal} selectPayment={this.handleAcceptApplication} isTempoJob={isTempoJob} />
            });
        }
    }

    handleAcceptApplication = isTempoJob => () => {
        const { acceptApplication, currentApplication } = this.props.applicationsStore;
        acceptApplication(currentApplication.id).then(({ data, ok }) => {
            this.closeConfirmAcceptModal();
            this.closePaymentModal();
            if (ok) {
                notificationService.success('APPLICATION.PURCHASE_SUCCESS');
                return this.props.history.push('/applications');
            }
            if (data && data.payment_method_id && data.client_secret) {
                this.props.stripe.handleCardPayment(data.client_secret, {
                    payment_method: data.payment_method_id,
                }).then((result) => {
                    if (result.error) {
                        // Display error.message in your UI.
                        notificationService.error(result.error.message);
                    } else {
                        notificationService.success('APPLICATION.PURCHASE_SUCCESS');
                        this.props.history.push('/applications');
                    }
                })
            } else {
                this.setState({
                    paymentModal: <PaymentModal addPaymentMethod={this.addPaymentMethod} closePaymentModal={this.closePaymentModal} selectPayment={this.selectPayment(data.transaction)} isTempoJob={isTempoJob}/>
                });
            }
        });
    }

    addPaymentMethod = (id) => {
        return this.props.userStore.addPaymentMethod(id).then(() => {
            notificationService.success('APPLICATION.ADD_CARD_SUCCESS');
            this.props.userStore.getPaymentMethods();
            return Promise.resolve();
        });
    }

    selectPayment = transactionId => (card) => {
        this.props.transactionStore.repayTransaction(transactionId, { payment_method_id: card.id }).then(() => {
            notificationService.success('APPLICATION.PURCHASE_SUCCESS');
            this.closePaymentModal();
        })
    }

    closePaymentModal = () => {
        this.setState({ paymentModal: null });
    }

    showConfirmAcceptModal = () => {
        const { classes } = this.props;
        const { employee_request } = this.props.applicationsStore.currentApplication;
        const { timeZone } = this.props.userStore;
        const startDateMoment = moment(employee_request.start_date).tz(timeZone);
        const endDateMoment = moment(employee_request.end_date).tz(timeZone);
        const diffDays = getDiffDays(endDateMoment, startDateMoment, employee_request.expect_work_on_weekends);
        const { getPlatformFee } = this.props.commonStore;
        const { getTransactionFee } = this.props.commonStore;
        let platformFee = getPlatformFee(employee_request.rate, diffDays, employee_request.work_day_hours);
        const transactionFee = getTransactionFee();
        const isTempoJob = employee_request.job_type === 'temporary' ? true : false;

        this.setState({
            confirmAcceptModal: <SweetAlert
                title=""
                onConfirm={this.acceptApplication(isTempoJob)}
                onCancel={this.closeConfirmAcceptModal}
                confirmBtnCssClass={
                    classes.button + " " + classes.success
                }
                cancelBtnCssClass={
                    classes.button + " " + classes.danger
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
                style={{width: 'auto !important'}}
            >
                <h3>Want to Contact This Applicant?</h3>
                {
                    isTempoJob ?
                        <GridContainer className={classes.wrapper}>
                            <GridItem xs={12}>
                                <div className={classes.paymentWrapper}>
                                    <label>Estimated Pay to Employee: ${employee_request.rate * employee_request.work_day_hours * diffDays} </label>
                                    <Tooltip classes={{
                                        popper: classes.tooltip
                                    }} title="This is calculated based on an employee working 8hrs/day for the number of days indicated. This amount may vary if the employee works fewer or longer hours per day. You pay the employee directly at your business after he completes his work."><Help style={styles.ml10} /></Tooltip>
                                </div>
                                <div className={classes.paymentWrapper}>
                                    <label>Platform Fee to hire this candidate: ${platformFee} </label>
                                    <Tooltip classes={{
                                        popper: classes.tooltip
                                    }} title="This is the fee we collect to develop and maintain the platform as well as market and solicit candidates to join and get connected with you. Our business can’t survive without it, the same way your business can’t survive by offering free car repairs. We appreciate your support. Note that this fee will be refunded in case of a “No Show” and may be partially refunded if you cancel after the 1st day of work."><Help style={styles.ml10} /></Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer> 
                        :
                        <GridContainer className={classes.wrapper}>
                            <GridItem xs={12}>
                                <div className={classes.paymentWrapper}>
                                    <label>You will be charged a ${transactionFee} transaction fee to see this applicant's contact information and s/he will be notified of your interest in interviewing him/her</label>
                                </div>
                            </GridItem>
                        </GridContainer>
                }
                
            </SweetAlert>
        })
    }

    closeConfirmAcceptModal = () => {
        this.setState({
            confirmAcceptModal: null
        })
    }

    rejectApplication = () => {
        const { rejectApplication, currentApplication } = this.props.applicationsStore;
        rejectApplication(currentApplication.id).then(() => {
            this.closeConfirmRejectModal();
            this.props.history.push('/applications');
            notificationService.success('APPLICATION.REJECTED_APPLICATION');
        });
    }

    showConfirmRejectModal = () => {
        this.setState({
            confirmRejectModal: <SweetAlert
                warning
                title=""
                onConfirm={this.rejectApplication}
                onCancel={this.closeConfirmRejectModal}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
            >
                Are you sure to reject this application ?
      </SweetAlert>
        })
    }

    closeConfirmRejectModal = () => {
        this.setState({
            confirmRejectModal: null
        })
    }


    renderDetailEmployee = (type, emp, index) => {
        const { toolSet } = this.props.commonStore;
        const workTypesMap = {
            automotive_tech: 'Automotive Tech/Mechanic',
            automotive_foreman: 'Automotive Foreman',
            service_advisor: 'Service Advisor/Writer',
            collision_service_advisor: 'Service Mgr',
            body_technician: 'Body Technician',
            poster_helper: 'Porter/Helper',
            other: 'Other',
        };

        let functionName = <GridItem container xs={12}> <h6 className={this.props.classes.label}>{workTypesMap[type]}</h6> </GridItem>;
        let divider;
        if (index > 0) {
            divider = <GridItem xs={12}> <Divider className={this.props.classes.mbt10}></Divider> </GridItem>;
        }
        
        switch (type) {
            case 'automotive_tech':
            case 'automotive_foreman':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <ForemanProfile skills={emp.job_seeker.preferences.skills.automotive_tech_foreman} toolSet={toolSet} />
                    </GridItem>
                </GridContainer>
            case 'body_technician':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <AutoBodyTechnicianProfile skills={emp.job_seeker.preferences.skills.body_technician} />
                    </GridItem>
                </GridContainer>;
            case 'service_manager':
            case 'service_advisor':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <ServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.service_advisor_manager} />
                    </GridItem>
                </GridContainer>
            case 'collision_service_advisor':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <CollisionServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.collision_service_advisor} />
                    </GridItem>
                </GridContainer>;
            case 'poster_helper':
            case 'other':
            default:
                break;
        }
    }

    showReviewsModal = () => {
        const { getReviews } = this.props.employeesStore;
        const { currentApplication } = this.props.applicationsStore;
        getReviews(currentApplication.job_seeker.id).then(res => {
            this.setState({
                reviewsModal: <Reviews handleClose={this.closeReviewsModal} reviews={res.items} />
            })
        });
    }

    closeReviewsModal = () => {
        this.setState({
            reviewsModal: null
        })
    }

    printDocument = (fullName) => {
        const input = document.getElementById('divToPrint');
        const opt = {
            filename: fullName + ' - Profile.pdf'
        };

        html2pdf(input, opt);
    }

    render() {
        const { classes } = this.props;
        const { currentApplication } = this.props.applicationsStore;
        const { functions } = this.props.commonStore;
        const { timeZone } = this.props.userStore;

        if (currentApplication) {
            const { job_seeker } = currentApplication;
            return <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AccountCircle />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Basic Information</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className={classes.container}>
                                <GridItem xs={10}>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <GridContainer>
                                                {/*<GridItem xs={4}>*/}
                                                {/*    <GridContainer className={classes.label}>Full name:</GridContainer>*/}
                                                {/*</GridItem>*/}
                                                {/*<GridItem xs={8}>*/}
                                                {/*    <p className={classes.mbt10}>{`${job_seeker.profile.first_name} ${job_seeker.profile.last_name}`}</p>*/}
                                                {/*</GridItem>*/}
                                                <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Location:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{`${job_seeker.profile.location.city}, ${job_seeker.profile.location.state}, ${job_seeker.profile.location.state_code}`}</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Distance from shop:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{Math.ceil(currentApplication.distance)} miles</p>
                                                </GridItem>
                                                {
                                                    currentApplication.employee_request.job_type === 'temporary' ? 
                                                        <GridItem xs={4}>
                                                            <GridContainer className={classes.label}>Date available:</GridContainer>
                                                        </GridItem>
                                                        :
                                                        <GridItem xs={4}>
                                                        </GridItem>
                                                }
                                                {
                                                    currentApplication.employee_request.job_type === 'temporary' ?
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>{timeZone && moment(currentApplication.employee_request.start_date).tz(timeZone).format('MMM DD YYYY')} to {timeZone && moment(currentApplication.employee_request.end_date).tz(timeZone).format('MMM DD YYYY')}</p>
                                                        </GridItem>
                                                        :
                                                        <GridItem xs={8}>
                                                        </GridItem>
                                                }
                                                
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <GridContainer>
                                                <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Function:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{getFieldFromListByKey(functions, 'name', 'value', currentApplication.employee_request.function)}</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Rate:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>${currentApplication.employee_request.rate}/hr</p>
                                                </GridItem>
                                                <GridItem xs={4}>
                                                    <GridContainer className={classes.label}>Willing to travel:</GridContainer>
                                                </GridItem>
                                                <GridItem xs={8}>
                                                    <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.willing_to_travel} miles</p>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={2}>
                                    <GridContainer className={classes.fullHeight} direction="column" justify="space-between">
                                        <Button color="info" fullWidth onClick={this.showConfirmAcceptModal}>
                                            Reveal/Hire
                                        </Button>
                                        <Button fullWidth color="danger" onClick={this.showConfirmRejectModal}>
                                            Reject
                                        </Button>
                                        <Button
                                            fullWidth color="facebook"
                                            onClick={() => {this.printDocument(currentApplication.job_seeker.profile.first_name)}}>
                                            Download Profile
                                        </Button>
                                    </GridContainer>
                                </GridItem>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <Divider className={classes.mbt10} />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <div className={classes.label}>Listing #:</div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <p className={classes.mbt10}>{currentApplication.listing_id}</p>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <div className={classes.label}>Status:</div>
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <p className={classes.mbt10}>{convertDisplayText(currentApplication.status, '_')}</p>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <Divider className={classes.mbt10} />
                                    </GridItem>
                                </GridContainer>
                                <div id="divToPrint" style={{paddingLeft: '10px', paddingRight: '10px' }}>
                                    <GridContainer>
                                        <GridItem container xs={6}>
                                            <h3 className={classes.label}>Profile</h3>
                                        </GridItem>
                                        <GridItem container xs={6} justify="flex-end" alignItems="center">
                                            <Rating
                                                initialRating={currentApplication.job_seeker.profile.rating}
                                                readonly
                                                fractions={2}
                                                emptySymbol={<StarBorder className={classes.rateIcon} />}
                                                fullSymbol={<StarRate className={classes.rateIcon} />}
                                            /> <b>({currentApplication.job_seeker.profile.rating} / 5) </b>
                                            <button className={classes.showReviewBtn} onClick={this.showReviewsModal}>Reviews ({(currentApplication.job_seeker.profile.rating_raw && currentApplication.job_seeker.profile.rating_raw.count) || 0})</button>
                                        </GridItem>
                                        <GridItem container xs={6} ></GridItem>
                                        <GridItem container xs={6} justify="flex-end" alignItems="center">
                                            <div>
                                                <b>No shows: {currentApplication.job_seeker.preferences.no_shows}</b>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Years of Auto Repair Industry Experience:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.years_of_experience}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Training Courses Completed:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.completed_training_courses.join(', ')}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Highest Level Of Education:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.education_level}</p>
                                        </GridItem>
                                    </GridContainer>
                                    {currentApplication.job_seeker.preferences.work_types.map((type, index) => {
                                        return this.renderDetailEmployee(type, currentApplication, index);
                                    })}
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Other Information:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.other_information}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>DMV record with less than 1 minor infraction:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.clean_dmv_record ? 'Yes' : `No (${currentApplication.job_seeker.preferences.reason_no_dmv_record})`}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer className={classes.mb30}>
                                        <GridItem xs={6}>
                                            <div className={classes.label}>Job Seeker Authorization to conduct background check:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <p className={classes.mbt10}>{currentApplication.job_seeker.preferences.authorization_to_dmv ? 'Yes' : `No`}</p>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                                <GridContainer className={classes.mb30}>
                                    <GridItem xs={12}>
                                        <div className={classes.label}>Resume:</div>
                                        {currentApplication.job_seeker.preferences.resume_photo_truncated ?
                                            <object data={currentApplication.job_seeker.preferences.resume_photo_truncated} type="application/pdf" width="100%" height="1000">
                                                <p>Alternative text - include a link <a href={currentApplication.job_seeker.preferences.resume_photo_truncated}>to the PDF!</a></p>
                                            </object>
                                            :
                                            <div></div>
                                        }
                                    </GridItem>
                                </GridContainer>
                                {this.state.confirmAcceptModal}
                                {this.state.confirmRejectModal}
                                {this.state.paymentModal}
                                {this.state.reviewsModal}
                                <BackPreviousPageButton />
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }
        return <Loading />
    }
}

const EnhancedApplicationDetails = injectStripe(withStyles(styles)(inject('applicationsStore', 'commonStore', 'userStore', 'transactionStore', 'employeesStore')(withRouter((observer(ApplicationDetails))))));

class WrapperApplicationDetails extends React.Component {
    render() {
        const { appConfig } = this.props.commonStore;
        return <StripeProvider apiKey={appConfig && appConfig.stripe.api_key}>
            <Elements><EnhancedApplicationDetails /></Elements>
        </StripeProvider>
    }
}

export default inject('commonStore')(observer(WrapperApplicationDetails));