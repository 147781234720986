import { decorate, observable, action, computed } from "mobx";
import ReviewForm from "./form/review";
import {
    getReviewList,
    getReview,
    createReview,
    updateReview,
    createReply,
    getHiredEmployeeList
} from "../services/domainServices/reviewDomain.service";
import * as _ from "lodash";
import ReplyForm from "./form/reply";

const initialReviewsPagination = {
    page: 1,
    items: [],
    pageSize: 20,
    totalPage: 0,
    totalItem: 0
};

const initialPagination = {
    pageSize: 20,
    page: 0,
    sort: "",
    order: "asc"
};

class ReviewStore {
    reviewsPagination = _.cloneDeep(initialReviewsPagination);
    pageViewing;

    sentPagination = _.cloneDeep(initialPagination);
    receivedPagination = _.cloneDeep(initialPagination);

    hiredEmployeeList = [];
    isLoading = false;
    constructor() {
        this.reviewForm = new ReviewForm();
        this.replyForm = new ReplyForm();
    }

    get pagination() {
        let ret;
        switch (this.pageViewing) {
            case "sent":
                ret = this.sentPagination;
                break;
            case "received":
                ret = this.receivedPagination;
                break;
            default:
                break;
        }
        return ret;
    }

    setPageViewing(page) {
        this.pageViewing = page;
    }

    getReviewsList = (type, pageSize, page) => {
        this.isLoading = true;
        return getReviewList(type, pageSize, page).then(
            action(data => {
                this.isLoading = false;
                this.reviewsPagination = data;
            })
        );
    };

    changePageOfPagination = page => {
        switch (this.pageViewing) {
            case "sent":
                this.sentPagination.page = page;
                break;
            case "received":
                this.receivedPagination.page = page;
                break;
            default:
                break;
        }
    };

    changePageSizeOfPagination = pageSize => {
        switch (this.pageViewing) {
            case "sent":
                this.sentPagination.pageSize = pageSize;
                break;
            case "received":
                this.receivedPagination.pageSize = pageSize;
                break;
            default:
                break;
        }
    };

    getReview = id => {
        return getReview(id);
    };

    createReview = data => {
        return createReview(data);
    };

    updateReview = (id, data) => {
        return updateReview(id, data);
    };

    createReply = (id, data) => {
        return createReply(id, data);
    };

    getHiredEmployeeList = () => {
        return getHiredEmployeeList().then(
            action(data => {
                this.hiredEmployeeList = data;
            })
        );
    };

    resetForm() {
        this.hiredEmployeeList = [];
        this.reviewForm = new ReviewForm();
    }
}

decorate(ReviewStore, {
    reviewsPagination: observable,
    pagination: computed,
    hiredEmployeeList: observable,
    isLoading: observable,
    pageViewing: observable,
    getReviewsList: action,
    getReview: action,
    updateReview: action,
    createReview: action,
    resetForm: action,
    changePageOfPagination: action,
    changeEmpPagination: action,
    changePageSizeOfPagination: action,
    getHiredEmployeeList: action,
    setPageViewing: action
});

export default new ReviewStore();
