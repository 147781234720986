import React from "react";
import { withStyles } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { observer } from "mobx-react";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";


class TermAndConditions extends React.Component {
    createMarkup = (text) => { return { __html: text }; };

    render() {
        const { classes, onCancel, onSave, onPrint, data } = this.props;
        return <SweetAlert
            title=""
            onConfirm={onSave}
            onCancel={onCancel}
            cancelBtnCssClass={
                classes.button + " " + classes.floatRight + " " + classes.primary
            }
            confirmBtnCssClass={
                classes.button + " " + classes.floatRight
            }
            confirmBtnText="Print"
            cancelBtnText="Cancel"
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            showCancel={false}
            showConfirm={false}
        >
            <div className={classes.formWrapper}>
                <h3>{data.name}</h3>
                <div dangerouslySetInnerHTML={this.createMarkup(data.content)}></div>
            </div>
            <div style={{ display: 'flex' }}>
                <Button className={classes.ml10} color="info" round onClick={onSave}>Save</Button>
                <Button className={classes.ml10 + ' ' + classes.mra} color="primary" round onClick={onPrint}>Print</Button>
                <Button className={classes.mr10} round onClick={onCancel}>Cancel</Button>
            </div>
        </SweetAlert>
    }
}

export default withStyles(appStyle)(observer(TermAndConditions));