import { decorate, observable, action } from "mobx";
import * as _ from "lodash";

import appConstants from "../constants/app.constant";
import {
  getPosts,
  getPostsAuth,
  getCategories,
  getMakes,
  getPostById,
  getAnswersByPostId,
  getAnswersByPostIdAuth,
  addAnswer,
  addPost,
  toggleLike,
  updatePost,
  updateAnswer,
  updateViewQuestion,
  uploadImageCreatePost,
  disableQuestion,
  getAnswersById,
  getSeason
} from "../services/domainServices/postDomain.service";
import stores from ".";
import notificationService from "../services/notificationService";

class FilterPost {
  initParams = {
    twSeasonId: "",
    twCategoryId: "",
    twMakeId: "",
    title: "",
    content: "",
    year: "",
    userId: "",
    sort: "created_at",
    order: appConstants.pagination.order,
    page: appConstants.pagination.page,
    page_size: appConstants.pagination.page_size,
    status: "",
    ownerUserId: ""
  };
  params = {};
  totalPost = 0;
  currentPage = 0;
  posts = [];
  season = null;
  recentPosts = [];
  isClearFilter = false;

  currentPostStatus = false;
  currentPost = {};
  currentAnswers = [];
  currentComment = "";
  currentAnswer = "";
  currentPostSeasonId = "";

  formCreatePost = {
    twSeasonId: "",
    twCategoryId: "",
    twMakeId: "",
    year: "",
    content: "",
    title: ""
  };
  formUpdatePost = {
    twCategoryId: "",
    twMakeId: "",
    year: "",
    content: "",
    title: ""
  };

  sorts = [
    {
      value: "created_at",
      label: "Date Created"
    },
    {
      value: "number_answer",
      label: "Number answer"
    }
  ];

  category = {
    active: false,
    name: "category",
    title: "Category",
    subTitle: "All",
    items: []
  };

  year = {
    active: false,
    name: "year",
    title: "Year",
    subTitle: "All",
    items: []
  };

  make = {
    active: false,
    name: "make",
    title: "Make",
    subTitle: "All",
    items: []
  };

  constructor() {
    this.initYears();
    this.params = { ...this.initParams };
  }

  updateUserOwnID = params => {
    const output = { ...params };
    if (stores.userStore.currentUserTW) {
      output.ownerUserId = stores.userStore.currentUserTW.userId;
    }
    return output;
  };

  updateFormCreatepost = data => {
    this.formCreatePost = {
      ...this.formCreatePost,
      ...data
    };
  };
  updateFormUpdatepost = data => {
    this.formUpdatePost = {
      ...this.formUpdatePost,
      ...data
    };
  };

  clearFormCreatepost = () => {
    this.formCreatePost = {
      twSeasonId: "",
      twCategoryId: "",
      twMakeId: "",
      year: "",
      content: "",
      title: ""
    };
  };

  resetSeason = () => {
    this.season = null;
  };

  initYears = () => {
    const now = new Date().getFullYear();
    let index = new Date().getFullYear();
    this.year.items.push({
      checked: false,
      label: "All",
      id: ""
    });
    do {
      this.year.items.push({
        checked: false,
        label: index + "",
        id: index + ""
      });
      index = index - 1;
    } while (index > now - 53);

    for (let i = 6; i >= 0; i--) {
      const itemMore = {
        checked: false,
        label: `${i*10 + 1900}s`,
        id: `${i*10 + 1900}s`
      }
      this.year.items = [...this.year.items, itemMore];
    }
    
    this.year.items.push({
      checked: false,
      label: "None",
      id: "none"
    });
  };

  getParams = () => {
    return this.params;
  };

  updateParams = params => {
    this.params = {
      ...this.params,
      ...params
    };
  };

  resetParams = () => {
    this.params = this.initParams;
    this.category = {
      ...this.category,
      active: false,
      items: this.category.items.map(item => ({ ...item, checked: false }))
    };
    this.year = {
      ...this.year,
      active: false,
      items: this.year.items.map(item => ({ ...item, checked: false }))
    };
    this.make = {
      ...this.make,
      active: false,
      items: this.make.items.map(item => ({ ...item, checked: false }))
    };
    this.currentPage = 0;
    this.totalPost = 0;
  };

  clearParams = () => {
    this.isClearFilter = true;
    this.params = this.initParams;
    this.category = {
      ...this.category,
      items: this.category.items.map(item => ({ ...item, checked: false }))
    };
    this.year = {
      ...this.year,
      items: this.year.items.map(item => ({ ...item, checked: false }))
    };
    this.make = {
      ...this.make,
      items: this.make.items.map(item => ({ ...item, checked: false }))
    };
    this.currentPage = 0;
    this.totalPost = 0;
  };

  setCurrentPage = page => {
    this.currentPage = page;
  };

  getSeasonFromApi = () => {
    return getSeason()
      .then(
        action(res => {
          this.season = res.data.data;
        })
      )
      .catch(err => {
        console.log(err, "error season");
      });
  };

  getPostsFromApi = (params = this.updateUserOwnID(this.params)) => {
    const newparams = { ...params, page: this.currentPage + 1 };
    if (stores.userStore.currentUserTW) {
      return getPostsAuth(newparams)
        .then(
          action(resp => {
            this.posts = resp.data.data;
            this.totalPost = resp.data.totalRecord;
          })
        )
        .catch(err => {
          console.log("err ", err);
        });
    }
    return getPosts(newparams)
      .then(
        action(resp => {
          this.posts = resp.data.data;
          this.totalPost = resp.data.totalRecord;
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  getPostByIdFromApi = id => {
    const params = this.updateUserOwnID(this.params);
    return getPostById(id, params.ownerUserId)
      .then(
        action(resp => {
          if (resp.data && resp.data.data) {
            this.currentPostStatus = true;
            this.currentPost = resp.data.data;
            this.currentPostSeasonId = resp.data.data.tw_season_id;
          }
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  updateCurrentPostStatus = status => {
    this.currentPostStatus = status;
  };

  resetCurrentPost = () => {
    this.currentPost = {};
  };

  getAnswersByPostIdFromApi = id => {
    if (stores.userStore.currentUserTW) {
      return getAnswersByPostIdAuth(id)
        .then(
          action(resp => {
            // console.log("resp ", resp.data);
            this.currentAnswers = resp.data.data;
          })
        )
        .catch(err => {
          if (err) console.log("err ", err);
        });
    } else {
      return getAnswersByPostId(id)
        .then(
          action(resp => {
            // console.log("resp ", resp.data);
            this.currentAnswers = resp.data.data;
          })
        )
        .catch(err => {
          if (err) console.log("err ", err);
        });
    }
  };

  resetCurrentAnswers = () => {
    this.currentAnswers = [];
  };

  getRecentPostsFromApi = (
    params = {
      ...this.params,
      ...{ sort: "" }
    }
  ) => {
    return getPosts(params)
      .then(
        action(resp => {
          this.recentPosts = resp.data.data;
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  getCategories = () => {
    return this.category;
  };

  getCategoriesFromApi = () => {
    return getCategories()
      .then(
        action(resp => {
          this.category = {
            ...this.category,
            items: resp.data.data.map(item => {
              return {
                ...item,
                checked: false,
                label: item.name
              };
            })
          };
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  updateCheckedItems = (items, key) => {
    return items.map((item, index) => ({
      ...item,
      checked: index === key ? true : false
    }));
  };

  toggleFilterItem = (name, key) => {
    this.isClearFilter = false;
    switch (name) {
      case "category":
        this.category.items = this.updateCheckedItems(this.category.items, key);
        this.updateParams({
          twCategoryId:
            this.category.items[key].label === "All"
              ? ""
              : this.category.items[key].id
        });
        break;

      case "year":
        this.year.items = this.updateCheckedItems(this.year.items, key);
        this.updateParams({ year: this.year.items[key].id });
        break;

      case "make":
        this.make.items = this.updateCheckedItems(this.make.items, key);
        this.updateParams({
          twMakeId:
            this.make.items[key].label === "All" ? "" : this.make.items[key].id
        });
        break;

      default:
        this.category.items = this.updateCheckedItems(this.category.items, key);
        this.updateParams({
          twCategoryId:
            this.category.items[key].label === "All"
              ? ""
              : this.category.items[key].id
        });
        break;
    }
  };

  toggle = (name, active) => {
    switch (name) {
      case "value":
        this.category.active = !active;
        break;

      case "year":
        this.year.active = !active;
        break;

      case "make":
        this.make.active = !active;
        break;

      default:
        this.category.active = !active;
        break;
    }
  };

  getMakesFromAPI = () => {
    return getMakes().then(
      action(resp => {
        this.make = {
          ...this.make,
          items: resp.data.data.map(item => {
            return {
              ...item,
              checked: false,
              label: item.name
            };
          })
        };
      })
    );
  };

  getAnswersByIdFromApi = twAnswerId => {
    return getAnswersById(twAnswerId);
  };

  addAnswerFromApi = (twSeasonId, twQuestionId) => {
    console.log("this.comment ", this.currentComment);
    return addAnswer(twSeasonId, twQuestionId, this.currentComment)
      .then(
        action(resp => {
          this.getAnswersByPostIdFromApi(twQuestionId);
          this.getPostByIdFromApi(twQuestionId);
          this.updateCurrentComment("");
          notificationService.success("Thank you for contributing an answer!");
        })
      )
      .catch(err => {
        this.updateCurrentComment("");
        notificationService.success("Something went wrong. Please try again!");
      });
  };
  updateAnswerFromApi = (answerId, questionId) => {
    return updateAnswer(answerId, this.currentAnswer)
      .then(
        action(resp => {
          notificationService.success("Answer Updated");
          this.getAnswersByPostIdFromApi(questionId);
        })
      )
      .catch(err => {
        this.updateCurrentAnswer("");
      });
  };

  addPostFormApi = (twSeasonId, history, formDataUploadImg) => {
    return addPost({
      ...this.formCreatePost,
      ...{ twSeasonId: twSeasonId }
    }).then(
      action(async resp => {
        console.log("resp ", resp);
        const currentPostId = resp.data && resp.data.data && resp.data.data.id;
        if (formDataUploadImg) {
          const resUpload = await this.uploadImageCreatePostFromApi(
            currentPostId,
            formDataUploadImg
          );
          if (resUpload && resUpload.data.statusCode === "80003") {
            await this.disableQuestionFromApi(currentPostId, {
              status: "deleted"
            });
            notificationService.error("Uploaded Image failed");
            history.push(`/tech-wizard`);
            return;
          }
        }
        history.push(`/tech-wizard/post/${currentPostId}`);
        notificationService.success("Posted Successfully");
        this.clearFormCreatepost();
      })
    );
  };
  updatePostFormApi = postId => {
    return updatePost(postId, this.formUpdatePost).then(
      action(async resp => {
        console.log("resp ", resp);
        notificationService.success("Post Updated");
      })
    );
  };

  updatePostFormApiWithImage = (postId, formDataUploadImg) => {
    return updatePost(postId, this.formUpdatePost).then(
      action(async resp => {
        console.log("resp ", resp);
        const resUpload = await this.uploadImageCreatePostFromApi(
          postId,
          formDataUploadImg
        );
        if (resUpload && resUpload.data.statusCode === "80003") {
          notificationService.error("Edit post failed");
        } else {
          notificationService.success("Post Updated");
        }
      })
    );
  };

  updateSort = params => {
    this.updateParams(params);
  };

  updateCurrentComment = comment => {
    this.currentComment = comment;
  };

  updateCurrentAnswer = comment => {
    this.currentAnswer = comment;
  };

  toggleLikeFromApi = (type, body) => {
    return toggleLike(body)
      .then(
        action(async resp => {
          if (type === "question") {
            // this.getPostsFromApi();
            await this.getPostByIdFromApi(body.twQuestionId);
          } else {
            this.getAnswersByPostIdFromApi(body.twQuestionId);
            // const resAsw = await this.getAnswersByIdFromApi(body.twAnswerId);
            // console.log("resAsw", resAsw);
          }
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  updateViewQuestionFromApi = body => {
    return updateViewQuestion(body).then(
      action(() => {
        this.getPostByIdFromApi(body.twQuestionId);
      })
    );
  };

  uploadImageCreatePostFromApi = (postId, formData) => {
    return uploadImageCreatePost(postId, formData);
  };

  disableQuestionFromApi = (postId, body) => {
    return disableQuestion(postId, body);
  };
}

decorate(FilterPost, {
  posts: observable,
  season: observable,
  recentPosts: observable,
  currentPost: observable,
  currentPage: observable,
  currentPostStatus: observable,
  currentAnswers: observable,
  category: observable,
  year: observable,
  make: observable,
  sorts: observable,
  formCreatePost: observable,
  currentComment: observable,
  isClearFilter: observable,
  updateCurrentComment: action,
  updateFormCreatepost: action,
  clearFormCreatepost: action,
  addPostFormApi: action,
  getParams: action,
  updateParams: observable,
  resetParams: action,
  getPostsFromApi: action,
  getSeasonFromApi: action,
  getCategoriesFromApi: action,
  getCategories: action,
  toggleFilterItem: action,
  toggle: action,
  getMakesFromAPI: action,
  clearParams: action,
  resetSeason: action,
  getRecentPostsFromApi: action,
  getPostByIdFromApi: action,
  getAnswersByPostIdFromApi: action,
  resetCurrentPost: action,
  resetCurrentAnswers: action,
  addAnswerFromApi: action,
  toggleLikeFromApi: action,
  updateCurrentPostStatus: action,
  updateViewQuestionFromApi: action,
  setCurrentPage: action
});

export default new FilterPost();
