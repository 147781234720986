import { convertPagingResponse } from "../utils";
import requests from "../http";

// eslint-disable-next-line no-unused-vars
const getApplicationsListApi = (pageSize, page, sort, order) => {
    return requests
        .get(
            `/api/listings/applications?&page=${++page}&page_size=${pageSize}&sort=${sort}&order=${order}`
        )
        .then(data => convertPagingResponse(data));
};

const getApplicationByIdApi = id => {
    return requests.get(`/api/listings/applications/${id}`);
};

const acceptApplicationApi = id => {
    return requests.post(`/api/listings/applications/${id}/accept`);
};

const rejectApplicationApi = id => {
    return requests.post(`/api/listings/applications/${id}/reject`);
};

export {
    getApplicationsListApi,
    acceptApplicationApi,
    rejectApplicationApi,
    getApplicationByIdApi
};
