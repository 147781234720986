import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Button from "../../../components/CustomButtons/Button";
import appConstants from "../../../constants/app.constant";
import { ReactComponent as IconEdit } from "../../../assets/img/icons/icon_edit.svg";
import { ReactComponent as IconRule } from "../../../assets/img/icons/icon_rule.svg";
import { ReactComponent as IconFeedback } from "../../../assets/img/icons/icon_feedback.svg";
import { ReactComponent as IconAvatar } from "../../../assets/img/icons/icon_avatar.svg";

import {
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor,
  noSidePadding,
  hexToRgb,
  successColor
} from "../../../assets/jss/material-dashboard-pro-react";
import { getNumberDateOfRange } from "../../utils/appUtils";

const styles = theme => ({
  welcome: {
    background: whiteColor,
    padding: "16px"
  },
  welcomeTitle: {
    fontSize: 16,
    marginBottom: 12,
    fontWeight: 700
    // fontFamily: "Helvetica Neue"
  },
  welcomeContent: {
    fontSize: 14,
    marginBottom: 12
    // fontFamily: "Helvetica Neue"
  },
  welcomeActions: {},
  welcomeBtn: {
    margin: `6px 0`,
    height: "44px",
    boxShadow: "none"
  },
  postActionBtnLabel: {
    fontSize: 16,
    textTransform: "Capitalize",
    marginLeft: 8,
    fontWeight: 700
  },
  time: {
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: 14
  },
  headTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 12
  },
  title: {
    background: `rgba(${hexToRgb(textActiveColor)}, 0.2)`,
    borderRadius: 5,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "13px",
    color: textActiveColor,
    padding: "3px 7px"
  },
  avatar: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0 0 12px 0",
    marginBottom: 12,
    borderBottom: `1px dashed ${grayColor[8]}`,
    "& h3": {
      color: blackColor,
      fontWeight: 700,
      fontSize: 18,
      margin: "0 0 8px 0",
      lineHeight: "1em",
      fontFamily: "Inter"
    },
    "& p": {
      margin: "0 0 8px 0",
      lineHeight: "1em",
      color: textSecondaryColor,
      fontWeight: "normal",
      fontSize: 14,
      fontFamily: "Inter"
    }
  },
  thumbnail: {
    flex: "0 0 42px",
    marginRight: 12,
    width: 42,
    height: 42,
    overflow: "hidden",
    borderRadius: "50%",
    "& img": {
      width: "100%",
      height: 42
    }
  },
  basicInfo: {
    flex: "1 0 auto"
  },
  award: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  price: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: textSecondaryColor,
    "& span": {
      color: successColor[2],
      fontWeight: 700
    }
  },
  awardDate: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    color: textSecondaryColor
  },
  btnFeedback: {
    ...noSidePadding,
    textTransform: "capitalize",
    fontSize: 16,
    fontWeight: 700,
    "& .btnLabel": {
      marginLeft: 8
    }
  }
});

class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  feedback = () => {
    // console.log("feedback ");
  };

  componentDidMount() {
    if (!this.props.userStore.currentUserTW) {
      this.props.postsStore.getSeasonFromApi();
    }
  }

  componentWillUnmount() {
    this.props.postsStore.resetSeason();
  }

  render() {
    const { classes, history } = this.props;
    const { currentUserTW } = this.props.userStore;
    const { season } = this.props.postsStore;

    const currentDate = moment()
      .utc()
      .format("YYYY-MM-DD")
      .toString();

    const userProfile = () => {
      if (currentUserTW) {
        const { profile, season, rank, point } = currentUserTW;
        const date =
          season && season.end_date
            ? getNumberDateOfRange(currentDate, season.end_date)
            : 0;
        // const prize =
        //   season.prize_pool[0] === "$"
        //     ? season.prize_pool.substring(1)
        //     : season.prize_pool;
        const isSeasonExisted = season && season.end_date && rank;
        return (
          <>
            <div className={classes.headTitle}>
              {isSeasonExisted && rank && (
                <>
                  <Typography className={classes.time} color="textPrimary">
                    Time left: {season ? date : 0} {date > 1 ? "days" : "day"}
                  </Typography>

                  <Typography className={classes.title} color="textPrimary">
                    {rank}
                  </Typography>
                </>
              )}
            </div>

            <div className={classes.avatar}>
              <div className={classes.thumbnail}>
                {currentUserTW.profile.avatar ? (
                  <img src={currentUserTW.profile.avatar} alt="" />
                ) : (
                  <IconAvatar />
                )}
              </div>
              <div className={classes.basicInfo}>
                <h3>{profile.user_name || profile.first_name}</h3>
                {isSeasonExisted && <p>My Score: {point}</p>}
              </div>
            </div>

            {isSeasonExisted && (
              <div className={classes.award}>
                <Typography className={classes.price} color="textPrimary">
                  Prize: <span>{season && season.prize_pool}</span>
                </Typography>
                <Typography className={classes.awardDate} color="textPrimary">
                  Contest End{" "}
                  {moment(season.end_date).format(
                    appConstants.dateTimeFormatNoHM
                  )}
                </Typography>
              </div>
            )}

            <div className="feedback">
              <Button
                className={classNames({
                  [classes.btnFeedback]: true
                })}
                color="primary"
                size={"sm"}
                simple={true}
                onClick={() => {
                  this.props.commonStore.updateModalResponse(true, "feedback");
                }}
                type={"button"}
              >
                <IconFeedback />
                <span className="btnLabel">Feedback</span>
              </Button>
            </div>
          </>
        );
      }
    };

    const notUser = () => {
      const date =
        season && season.end_date
          ? getNumberDateOfRange(currentDate, season.end_date)
          : 0;
      return (
        <>
          <Typography className={classes.welcomeTitle} color="textPrimary">
            Welcome to Tech Wizard
          </Typography>
          <Typography className={classes.welcomeContent} color="textPrimary">
            This is a technical forum and game where you can earn prizes by
            contributing questions and answers to the forum
          </Typography>
          {season && (
            <>
              <div className={classes.headTitle}>
                <Typography className={classes.time} color="textPrimary">
                  Time left: {season ? date : 0} {date > 1 ? "days" : "day"}
                </Typography>
              </div>
              <div className={classes.award}>
                <Typography className={classes.price} color="textPrimary">
                  Prize: <span>{season.prize_pool}</span>
                </Typography>
                <Typography className={classes.awardDate} color="textPrimary">
                  Contest End{" "}
                  {moment(season.end_date).format(
                    appConstants.dateTimeFormatNoHM
                  )}
                </Typography>
              </div>
            </>
          )}
        </>
      );
    };

    return (
      <div className={classes.welcome}>
        {currentUserTW ? <>{userProfile()}</> : <>{notUser()}</>}
        <div className={classes.welcomeActions}>
          <Button
            className={classes.welcomeBtn}
            color="primary"
            size={"sm"}
            fullWidth={true}
            onClick={() => history.push("/tech-wizard/create-post")}
          >
            <IconEdit />
            <span className={classes.postActionBtnLabel}>Ask Question</span>
          </Button>

          <Button
            className={classes.welcomeBtn}
            color="primary"
            size={"sm"}
            fullWidth={true}
            outline={true}
            onClick={() => this.props.commonStore.updateModalGameRules(true)}
          >
            <IconRule />
            <span className={classes.postActionBtnLabel}>Game Rules</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "postsStore", "userStore")(observer(Welcome))
  )
);
