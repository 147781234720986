import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import lockScreenPageStyle from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.jsx";
import { observer, inject } from "mobx-react";
import notificationService from "../services/notificationService";
import config from "config";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      submitted: false,
      isUsernameEmail: false
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
    this.props.authStore.forgotPasswordForm.reset();
    this.props.authStore.forgotPasswordForm.showErrors(false);
  }

  submit = e => {
    e.preventDefault();
    const { forgotPasswordForm, forgotPassword } = this.props.authStore;
    if (!forgotPasswordForm.isValid) {
      return forgotPasswordForm.showErrors(true);
    }
    forgotPasswordForm.submit({
      onSuccess: form => {
        const data = form.values();
        forgotPassword(data)
          .then(() => {
            notificationService.success("FORGOT_PASSWORD.SENT_REQUEST");
            const isUsernameEmail = new RegExp(config.pattern.email).test(
              data && data.username
            );
            this.setState({
              submitted: true,
              isUsernameEmail
            });
          })
          .catch(res => {
            // const err = res.response;
            // notificationService.error(err.data.message);
          });
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { forgotPasswordForm } = this.props.authStore;
    const { submitted } = this.state;
    return (
      <div className={classes.container}>
        <form onSubmit={this.submit}>
          <Card
            profile
            className={
              classes.customCardClass + " " + classes[this.state.cardAnimaton]
            }
          >
            <CardBody profile>
              <h4 className={classes.cardTitle}>Reset your password</h4>
              {!submitted && (
                <div className={classes.notifyText}>
                  Enter your email address and we will send you a link to reset
                  your password.
                </div>
              )}
              {!submitted ? (
                <CustomInput
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    ...forgotPasswordForm.$("username").bind()
                  }}
                  error={!!forgotPasswordForm.$("username").error}
                  helpText={forgotPasswordForm.$("username").error || undefined}
                  labelText="Email"
                />
              ) : (
                <div className={classes.notifyText}>
                  {`Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.`}
                </div>
              )}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <div>
                {!submitted && (
                  <Button color="primary" size="lg" round block type="submit">
                    Submit
                  </Button>
                )}
                <div className={classes.bottomText}>
                  Back to{" "}
                  <Link className={classes.registerLink} to="/auth/login-page">
                    Login
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </form>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(lockScreenPageStyle)(
  inject("authStore")(withRouter(observer(ForgotPassword)))
);
