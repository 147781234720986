import React from 'react';

import SweetAlert from "react-bootstrap-sweetalert";
import Rating from 'react-rating';

import IntegrationReactSelect from "components/CustomSelect/IntegrationReactSelect";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";

const styles = {
    rateIcon: {
        color: '#F8981F',
        fontSize: '40px'
    },
    mb20: {
        marginBottom: '20px'
    },
    ml10: {
        marginLeft: '10px'
    },
    mr10: {
        marginRight: '10px'
    },
    empName: {
        fontWeight: 'bold'
    }
}

class ReviewModalUI extends React.Component {
    render() {
        const { classes, reviewForm, onConfirm, onCancel, hiredEmployeeList, empReadOnly } = this.props;
        return <SweetAlert
            title="Send review"
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmBtnCssClass={
                classes.button + " " + classes.success
            }
            cancelBtnCssClass={
                classes.button + " " + classes.danger
            }
            confirmBtnText="Send"
            cancelBtnText="Cancel"
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            showCancel={false}
            showConfirm={false}
        >
            <div className={classes.formWrapper}>
                <div className={classes.mb20}>
                    {empReadOnly ? <div>
                        <label>Employee:</label> <label className={classes.empName}>{reviewForm.$('receiver').value.label}</label>
                    </div> : <IntegrationReactSelect
                            id="emp-select"
                            label={'Employee *'}
                            error={!!reviewForm.$('receiver').error}
                            helpText={reviewForm.$('receiver').error || undefined}
                            options={hiredEmployeeList}
                            value={reviewForm.$('receiver').value}
                            onChange={reviewForm.$('receiver').onChange}
                            placeholder={'Select employee'} />
                    }
                </div>
                <div>
                    <label>How satisfied were you overall of the employee's skills, performance, punctuality and team work?</label>
                    <div>
                        <Rating
                            initialRating={reviewForm.$('rating').value}
                            emptySymbol={<StarBorder className={classes.rateIcon} />}
                            fullSymbol={<StarRate className={classes.rateIcon} />}
                            onChange={reviewForm.$('rating').onChange}
                        />
                    </div>
                </div>
                <div>
                    <CustomInput
                        labelText='Type your review *'
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={!!reviewForm.$('comment').error}
                        helpText={reviewForm.$('comment').error || undefined}
                        inputProps={
                            {
                                multiline: true,
                                rows: 4,
                                ...reviewForm.$('comment').bind()
                            }
                        }
                    />
                </div>
            </div>
            <div>
                <Button className={classes.mr10} round onClick={onCancel}>Cancel</Button>
                <Button  className={classes.ml10} color="primary" round onClick={onConfirm} loading={reviewForm.$('submitting').value} disabled={reviewForm.$('submitting').value}>Create Review</Button>
            </div>
        </SweetAlert>
    }
}

export default withStyles({ ...sweetAlertStyle, ...styles })(observer(ReviewModalUI));