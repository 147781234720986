import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import Button from "components/CustomButtons/Button.jsx";
import AdminNavbarLinks from "./AdminNavbarLinks";
import Hidden from "@material-ui/core/Hidden";
import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx";

import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import Button from "components/CustomButtons/Button.jsx";
import bestWorkplaceBadge from "../../assets/img/best_workplace_badge_7.png";

function AdminNavbar({ ...props }) {
  const {
    classes,
    color,
    showHelpModal,
    showPrivacyPolicyModal,
    showTermConditionsModal,
    showFeedbackModal
  } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize = classes.sidebarMinimize;
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div
          className={classes.flex}
          style={{ display: "flex", marginLeft: "100px" }}
        >
          {/* <Button href="#" className={classes.title} color="transparent">
            {brandText}
          </Button> */}
          {props.userStore.profileForm.values().best_workplace ? (
            <div className="picture-container">
              <img src={bestWorkplaceBadge} alt="Best Workplace" />
            </div>
          ) : (
            <div className="picture-container" />
          )}
        </div>
        <Hidden smDown implementation="css">
          <div className={sidebarMinimize}>
            {props.miniActive && (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks
            showHelpModal={showHelpModal}
            showFeedbackModal={showFeedbackModal}
            showPrivacyPolicyModal={showPrivacyPolicyModal}
            showTermConditionsModal={showTermConditionsModal}
          />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
