import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import { appBreadcrumbs } from "../../constants/app.constant";
import PageLayout from "../../layouts/pageLayout/pageLayout";
import "./settingAccount.css";
import { Button } from "@material-ui/core";

const styles = () => ({
  pageSubTitle: {
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  titleBar: {
    marginBottom: 16
  }
});

class SettingAccount extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isSubscribe: false
  };

  handleChange = () => event => {
    const { currentUser } = this.props.userStore;
    const idUser = currentUser && currentUser.id;
    this.setState({ isSubscribe: event.target.checked });
    !this.state.isSubscribe
      ? this.props.userStore.updateSubscribeTWDegist(idUser, "Y")
      : this.props.userStore.updateSubscribeTWDegist(idUser, "N");
  };

  componentDidMount() {
    const { currentUser } = this.props.userStore;
    this.setState(
      currentUser &&
        currentUser.user &&
        currentUser.user.tw_digest_subcribed === "Y"
        ? { isSubscribe: true }
        : { isSubscribe: false }
    );
  }

  render() {
    const { classes, history } = this.props;
    const PageSubTitle = () => {
      return (
        <Typography className={classes.pageSubTitle}>
          Account Setting
        </Typography>
      );
    };

    return (
      <PageLayout pageTitle={false} sidebar={[]}>
        <div className={classes.createPost}>
          <Breadcrumbs items={appBreadcrumbs.settingAccount} />
          <div className={classes.titleBar}>
            <PageSubTitle />
          </div>
          <div className="container">
            <Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <p>Subscribe to receive digest emails:</p>
                <div
                  style={{
                    display: "inline",
                    position: "absolute",
                    right: 0
                  }}
                >
                  <Switch
                    checked={this.state.isSubscribe}
                    onChange={this.handleChange()}
                    value="isSubscribe"
                  />
                </div>
              </Grid>
            </Grid>
            <Button
              style={{
                marginTop: 20
              }}
              onClick={() => {
                history.push("/delete-account");
              }}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(SettingAccount))
  )
);
