import { convertPagingResponse } from "../utils";
import requests from "../http";
import * as moment from "moment";

// eslint-disable-next-line no-unused-vars
const getRequestsListApi = (status, pageSize, page, sort, order) => {
  return requests
    .get(
      `/api/listings/request_employees?status=${status}&page=${++page}&page_size=${pageSize}&sort=${sort}&order=${order}`
    )
    .then(data => convertPagingResponse(data));
};
const createRequestApi = data => {
  const dataPost = handleRequestData(data);
  return requests.post(`/api/listings/request_employees`, dataPost);
};

const updateRequestApi = (id, data) => {
  const dataPost = handleRequestData(data);
  return requests.put(`/api/listings/request_employees/${id}`, dataPost);
};

const closeRequestApi = id => {
  return requests.post(`/api/listings/request_employees/${id}/close`);
};

const handleRequestData = data => {
  const {
    autotech_level,
    bodytech_type,
    car_types,
    end_date,
    function: func,
    other_function,
    job_type,
    must_bring_tools,
    rate,
    start_date,
    status,
    work_day_hours,
    work_start_time,
    work_start_time_a,
    expect_work_on_weekends,
    years_of_experience,
    geolocation,
    rate_from,
    rate_to,
    paytype,
    key_benefit_offer,
    additional_comment
  } = data;
  const carTypes = [];
  car_types.forEach(type => {
    if (typeof type === "object") {
      carTypes.push(type.value);
    } else if (typeof type === "string") {
      carTypes.push(type);
    }
  });
  return {
    autotech_level: autotech_level ? autotech_level : undefined,
    bodytech_type: bodytech_type ? bodytech_type : undefined,
    car_types: carTypes,
    function: func,
    other_function,
    job_type,
    rate,
    status,
    years_of_experience,
    geolocation: geolocation
      ? {
          state: geolocation.state || null,
          city: geolocation.city || null
        }
      : null,
    must_bring_tools:
      must_bring_tools === "n/a" ? undefined : must_bring_tools === "yes",
    work_day_hours,
    end_date: moment(end_date)
      .utc()
      .format(),
    start_date: moment(start_date)
      .utc()
      .format(),
    work_start_time: {
      h: moment(work_start_time).isValid()
        ? moment(work_start_time).format("h:mm")
        : "",
      a: work_start_time_a
    },
    expect_work_on_weekends:
      expect_work_on_weekends === "somewk"
        ? null
        : expect_work_on_weekends === "yes",
    rate_from,
    rate_to,
    paytype,
    key_benefit_offer,
    additional_comment
  };
};

// eslint-disable-next-line no-unused-vars
const getRequestByIdApi = id =>
  requests.get(`/api/listings/request_employees/${id}`);

const deleteListingsApi = payload =>
  requests.put(`/api/listings/remove/request_employees`, payload);

export {
  getRequestsListApi,
  createRequestApi,
  updateRequestApi,
  getRequestByIdApi,
  closeRequestApi,
  deleteListingsApi
};
