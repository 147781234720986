import { decorate, observable, action, computed } from "mobx";
import {
    getAvailableEmployeesList,
    getAvailableEmployeeById,
    hireEmployee,
    getPendingEmployeesList,
    getPendingEmployeeById,
    getHiredEmployeesList,
    getHiredEmployeeById,
    reportNoShowEmployee,
    cancelHireEmployee,
    getReviews
} from "../services/domainServices/employeesDomain.service";
import HireEmployeeForm from "./form/hireEmployee";
import * as _ from "lodash";

const initialEmployeesPagination = {
    page: 1,
    items: [],
    pageSize: 20,
    totalPage: 0,
    totalItem: 0
};

const initialPagination = {
    pageSize: 20,
    page: 0,
    sort: "created_at",
    order: "desc",
    jobType: "all",
    func: "all"
};

class EmployeesStore {
    employeesPagination = _.cloneDeep(initialEmployeesPagination);
    pageViewing;

    availablePaginationParams = _.cloneDeep(initialPagination);
    pendingPaginationParams = _.cloneDeep(initialPagination);
    hiredPaginationParams = _.cloneDeep(initialPagination);

    currentEmployee;
    isLoading = false;
    constructor() {
        this.hireEmployeeForm = new HireEmployeeForm();
    }

    get pagination() {
        let ret;
        switch (this.pageViewing) {
            case "available":
                ret = this.availablePaginationParams;
                break;
            case "pending":
                ret = this.pendingPaginationParams;
                break;
            case "hired":
                ret = this.hiredPaginationParams;
                break;
            default:
                break;
        }
        return ret;
    }

    reset() {
        this.currentEmployee = null;
    }

    setPageViewing(page) {
        this.pageViewing = page;
    }

    setCurrentEmployee = emp => {
        this.currentEmployee = emp;
    };

    changePageOfPagination = page => {
        switch (this.pageViewing) {
            case "available":
                this.availablePaginationParams.page = page;
                break;
            case "pending":
                this.pendingPaginationParams.page = page;
                break;
            case "hired":
                this.hiredPaginationParams.page = page;
                break;
            default:
                break;
        }
    };

    changePageSizeOfPagination = pageSize => {
        switch (this.pageViewing) {
            case "available":
                this.availablePaginationParams.pageSize = pageSize;
                break;
            case "pending":
                this.pendingPaginationParams.pageSize = pageSize;
                break;
            case "hired":
                this.hiredPaginationParams.pageSize = pageSize;
                break;
            default:
                break;
        }
    };

    changeJobTypePagination = type => {
        this.availablePaginationParams.jobType = type;
    };

    changeFuncPagination = func => {
        this.availablePaginationParams.func = func;
    };

    changeSortOfPagination = sort => {
        let order = "desc";
        switch (this.pageViewing) {
            case "available":
                if (
                    (this.availablePaginationParams.sort === sort &&
                        this.availablePaginationParams.order === "desc") ||
                    sort === "distance"
                ) {
                    order = "asc";
                }
                this.availablePaginationParams.order = order;
                this.availablePaginationParams.sort = sort;
                break;
            case "pending":
                if (
                    (this.pendingPaginationParams.sort === sort &&
                        this.pendingPaginationParams.order === "desc") ||
                    sort === "distance"
                ) {
                    order = "asc";
                }
                this.pendingPaginationParams.order = order;
                this.pendingPaginationParams.sort = sort;
                break;
            case "hired":
                if (
                    (this.hiredPaginationParams.sort === sort &&
                        this.hiredPaginationParams.order === "desc") ||
                    sort === "distance"
                ) {
                    order = "asc";
                }
                this.hiredPaginationParams.order = order;
                this.hiredPaginationParams.sort = sort;
                break;
            default:
                break;
        }
    };

    getAvailableEmployeesList = (
        jobType,
        func,
        pageSize,
        page,
        sort,
        order
    ) => {
        this.isLoading = true;
        return getAvailableEmployeesList(
            jobType,
            func,
            pageSize,
            page,
            sort,
            order
        ).then(
            action(data => {
                this.isLoading = false;
                this.employeesPagination = data;
            })
        );
    };

    getAvailableEmployeeById = id => {
        return getAvailableEmployeeById(id).then(
            action(data => {
                this.setCurrentEmployee(data);
            })
        );
    };

    getPendingEmployeesList = (pageSize, page, sort, order) => {
        this.isLoading = true;
        return getPendingEmployeesList(pageSize, page, sort, order).then(
            action(data => {
                this.isLoading = false;
                this.employeesPagination = data;
            })
        );
    };

    getPendingEmployeeById = id => {
        return getPendingEmployeeById(id).then(
            action(data => {
                this.setCurrentEmployee(data);
            })
        );
    };

    getHiredEmployeesList = (pageSize, page, sort, order) => {
        this.isLoading = true;
        return getHiredEmployeesList(pageSize, page, sort, order).then(
            action(data => {
                this.isLoading = false;
                this.employeesPagination = data;
            })
        );
    };

    getHiredEmployeeById = id => {
        return getHiredEmployeeById(id).then(
            action(data => {
                this.setCurrentEmployee(data);
            })
        );
    };

    hireEmployee = (id, data) => {
        return hireEmployee(id, data);
    };

    reportNoShowEmployee = id => {
        return reportNoShowEmployee(id);
    };

    cancelHireEmployee = id => {
        return cancelHireEmployee(id);
    };

    getReviews = id => {
        return getReviews(id);
    };
}

decorate(EmployeesStore, {
    employeesPagination: observable,
    pagination: computed,
    currentEmployee: observable,
    isLoading: observable,
    pageViewing: observable,
    changePageOfPagination: action,
    changePageSizeOfPagination: action,
    getAvailableEmployeesList: action,
    changeSortOfPagination: action,
    changeJobTypePagination: action,
    setCurrentEmployee: action,
    getAvailableEmployeeById: action,
    getPendingEmployeeById: action,
    getPendingEmployeesList: action,
    getHiredEmployeesList: action,
    getHiredEmployeeById: action,
    reportNoShowEmployee: action,
    cancelHireEmployee: action,
    reset: action,
    setPageViewing: action
});

export default new EmployeesStore();
