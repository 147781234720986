import { decorate, observable, action } from "mobx";
import * as _ from "lodash";
import {
  getApplicationsList,
  acceptApplication,
  rejectApplication,
  getApplicationById
} from "../services/domainServices/applicationsDomain.service";

const initialApplicationsPagination = {
  page: 1,
  items: [],
  pageSize: 20,
  totalPage: 0,
  totalItem: 0
};

const initialPagination = {
  pageSize: 20,
  page: 0,
  sort: "created_at",
  order: "desc"
};

class ApplicationsStore {
  applicationsPagination = _.cloneDeep(initialApplicationsPagination);

  pagination = _.cloneDeep(initialPagination);
  currentApplication;
  isLoading = false;
  reset() {
    this.applicationsPagination = _.cloneDeep(initialApplicationsPagination);
    this.pagination = _.cloneDeep(initialPagination);
  }

  getApplicationsList = (pageSize, page, sort, order) => {
    this.isLoading = true;
    return getApplicationsList(pageSize, page, sort, order).then(
      action(data => {
        console.log("data ", data);
        this.isLoading = false;
        this.applicationsPagination = data;
      })
    );
  };

  setCurrentApplication = app => {
    this.currentApplication = app;
  };

  getApplicationById = id => {
    return getApplicationById(id).then(
      action(data => {
        this.setCurrentApplication(data);
      })
    );
  };

  changePageOfPagination = page => {
    this.pagination.page = page;
  };

  changePageSizeOfPagination = pageSize => {
    this.pagination.pageSize = pageSize;
  };

  changeSortOfPagination = sort => {
    let order = "desc";

    if (this.pagination.sort === sort && this.pagination.order === "desc") {
      order = "asc";
    }
    this.pagination.order = order;
    this.pagination.sort = sort;
  };

  acceptApplication(id) {
    return acceptApplication(id);
  }

  rejectApplication(id) {
    return rejectApplication(id);
  }
}

decorate(ApplicationsStore, {
  isLoading: observable,
  applicationsPagination: observable,
  pagination: observable,
  currentApplication: observable,
  changePageOfPagination: action,
  changePageSizeOfPagination: action,
  changeSortOfPagination: action,
  reset: action,
  getApplicationsList: action,
  acceptApplication: action,
  rejectApplication: action,
  getApplicationById: action,
  setCurrentApplication: action
});

export default new ApplicationsStore();
