import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withStyles } from '@material-ui/core';
import Rating from 'react-rating';

import FiberManualRecordOutlined from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

const styles = {
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    icon: {
        color: '#ff9800'
    }
};
class CollisionServiceAdvisorProfile extends React.Component {
    render() {
        const { classes, skills } = this.props;
        const { skills_level, software, other_software } = skills;
        return <React.Fragment>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years Of Combined Experience as a Collision Service Advisor/Estimator:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_combined_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years Of Experience as a Body Tech:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_body_tech_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <div className={classes.label}>Skills:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Job Estimation & Pricing:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.job_estimation_and_pricing}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Restoration Job Estimation & Pricing:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.restoration_job_estimation_and_pricing}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Parts Ordering:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.parts_ordering}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Classic Parts Ordering:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.classic_parts_ordering}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Working with Suppliers:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.working_with_suppliers}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Scheduling Jobs & Workload:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.scheduling_jobs}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Interfacing With Operations Team:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.interfacing_with_operations_team}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Filing Insurance Claims:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.filling_insurance_claims}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Negotiating With Insurance Companies:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.negotiating_with_insurance_companies}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Tracking & Managing Customer Satisfaction:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.tracking_managing_customer_satisfaction}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Customer Support & Communication:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.customer_support_and_communication}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Computer Skills:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.computer}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Organization:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.organization}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.label}>Proficiency With Estimation Software:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>CCC:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.ccc}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Mitchell1:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.mitchell}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Shop Monkey:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.shop_monkey}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Web-Est:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.web_est}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Other:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={software.other}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Other Software:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{other_software}</p>
                </GridItem>
            </GridContainer>
        </React.Fragment>;
    }
}

export default withStyles(styles)(CollisionServiceAdvisorProfile);