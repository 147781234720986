import userStore from "./userStore";
import authStore from "./authStore";
import commonStore from "./commonStore";
import notificationStore from "./notificationStore";
import employeesStore from "./employeesStore";
import requestsEmployeeStore from "./requestsEmployeeStore";
import applicationsStore from "./applicationsStore";
import reviewStore from "./reviewStore";
import transactionStore from "./transactionStore";
import userNotificationStore from "./userNotificationStore";
import postsStore from "./postsStore";

export default {
  commonStore,
  userStore,
  notificationStore,
  authStore,
  employeesStore,
  requestsEmployeeStore,
  applicationsStore,
  reviewStore,
  transactionStore,
  userNotificationStore,
  postsStore
};
