import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Helmet } from "react-helmet";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
// import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import notificationService from "services/notificationService";
import { detailHeader } from "../../variables/constant";

class JobSeekerRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null
        };
    }

    handleToggleShowPw = () => {
        this.props.authStore.toggleShowPasswordJobSeeker();
    };

    handleToggleShowConfirmPw = () => {
        this.props.authStore.toggleShowConfirmPasswordJobSeeker();
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    submit = e => {
        e.preventDefault();
        const {
            jobSeekerRegisterForm,
            registerJobSeeker
        } = this.props.authStore;
        if (jobSeekerRegisterForm.$("agreeTerm").value) {
            if (!jobSeekerRegisterForm.isValid) {
                return jobSeekerRegisterForm.showErrors(true);
            }
            // if (jobSeekerRegisterForm.$('password').value !== jobSeekerRegisterForm.$('confirmPassword').value) {
            //   this.setState({
            //     alert: (<SweetAlert
            //       danger
            //       title="Error"
            //       onConfirm={this.hideAlert}
            //       onCancel={this.hideAlert}
            //       confirmBtnCssClass={
            //         this.props.classes.button + " " + this.props.classes.success
            //       }
            //     >
            //       Password and confirm password is not match.
            // </SweetAlert>)
            //   })
            //   return;
            // }
            jobSeekerRegisterForm.submit({
                onSuccess: form => {
                    const data = form.values();
                    registerJobSeeker(data)
                        .then(() => {
                            notificationService.success(
                                "REGISTER.SIGNUP_SUCCESS"
                            );
                        })
                        .catch(res => {
                            const err = res.response;
                            notificationService.error(err.data.message);
                        });
                },
                onError: form => {
                    // eslint-disable-next-line no-console
                    console.log("All form errors", form.errors());
                }
            });
        }
    };

    render() {
        const { classes } = this.props;
        const { jobSeekerRegisterForm } = this.props.authStore;
        return (
            <div className={classes.container}>
                <Helmet>
                    <title>{detailHeader.workPage.title}</title>
                    <meta
                        name="description"
                        content={detailHeader.workPage.description}
                    />
                </Helmet>
                <GridContainer justify="center">
                    {this.state.alert}
                    <GridItem xs={12} sm={12} md={12}>
                        <Card className={classes.cardSignup}>
                            <h2 className={classes.cardTitle}>
                                JOB SEEKER REGISTRATION
                            </h2>
                            <CardBody>
                                <div className={classes.center} />
                                <form
                                    className={classes.form}
                                    onSubmit={this.submit}
                                >
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className:
                                                        classes.customFormControlClasses
                                                }}
                                                inputProps={jobSeekerRegisterForm
                                                    .$("first_name")
                                                    .bind()}
                                                error={
                                                    !!jobSeekerRegisterForm.$(
                                                        "first_name"
                                                    ).error
                                                }
                                                helpText={
                                                    jobSeekerRegisterForm.$(
                                                        "first_name"
                                                    ).error || undefined
                                                }
                                                labelText="First Name"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className:
                                                        classes.customFormControlClasses
                                                }}
                                                inputProps={jobSeekerRegisterForm
                                                    .$("last_name")
                                                    .bind()}
                                                error={
                                                    !!jobSeekerRegisterForm.$(
                                                        "last_name"
                                                    ).error
                                                }
                                                helpText={
                                                    jobSeekerRegisterForm.$(
                                                        "last_name"
                                                    ).error || undefined
                                                }
                                                labelText="Last Name"
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className:
                                                        classes.customFormControlClasses
                                                }}
                                                inputProps={jobSeekerRegisterForm
                                                    .$("username")
                                                    .bind()}
                                                error={
                                                    !!jobSeekerRegisterForm.$(
                                                        "username"
                                                    ).error
                                                }
                                                helpText={
                                                    jobSeekerRegisterForm.$(
                                                        "username"
                                                    ).error || undefined
                                                }
                                                labelText="Email or phone number"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className:
                                                        classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={
                                                                classes.inputAdornment
                                                            }
                                                            onClick={
                                                                this
                                                                    .handleToggleShowPw
                                                            }
                                                        >
                                                            {this.props.authStore.jobSeekerRegisterForm
                                                                .$("password")
                                                                .get("type") ===
                                                            "password" ? (
                                                                <RemoveRedEye
                                                                    className={
                                                                        classes.inputAdornmentIcon
                                                                    }
                                                                />
                                                            ) : (
                                                                <VisibilityOff
                                                                    className={
                                                                        classes.inputAdornmentIcon
                                                                    }
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                    ...jobSeekerRegisterForm
                                                        .$("password")
                                                        .bind()
                                                }}
                                                error={
                                                    !!jobSeekerRegisterForm.$(
                                                        "password"
                                                    ).error
                                                }
                                                helpText={
                                                    jobSeekerRegisterForm.$(
                                                        "password"
                                                    ).error || undefined
                                                }
                                                labelText="Password"
                                            />
                                            <div
                                                className={classes.passwordHint}
                                            >
                                                Password must contain at least 8
                                                characters, one lower case
                                                letter, one upper case letter,
                                                one number and one of the
                                                following symbols: !, @, #, $,
                                                %, &, *
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <CustomInput
                                                formControlProps={{
                                                    fullWidth: true,
                                                    className:
                                                        classes.customFormControlClasses
                                                }}
                                                inputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            className={
                                                                classes.inputAdornment
                                                            }
                                                            onClick={
                                                                this
                                                                    .handleToggleShowConfirmPw
                                                            }
                                                        >
                                                            {this.props.authStore.jobSeekerRegisterForm
                                                                .$(
                                                                    "confirmPassword"
                                                                )
                                                                .get("type") ===
                                                            "password" ? (
                                                                <RemoveRedEye
                                                                    className={
                                                                        classes.inputAdornmentIcon
                                                                    }
                                                                />
                                                            ) : (
                                                                <VisibilityOff
                                                                    className={
                                                                        classes.inputAdornmentIcon
                                                                    }
                                                                />
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                    ...jobSeekerRegisterForm
                                                        .$("confirmPassword")
                                                        .bind()
                                                }}
                                                error={
                                                    !!jobSeekerRegisterForm.$(
                                                        "confirmPassword"
                                                    ).error
                                                }
                                                helpText={
                                                    jobSeekerRegisterForm.$(
                                                        "confirmPassword"
                                                    ).error &&
                                                    jobSeekerRegisterForm.$(
                                                        "confirmPassword"
                                                    ).value !==
                                                        jobSeekerRegisterForm.$(
                                                            "password"
                                                        ).value
                                                        ? "Passwords don't match"
                                                        : jobSeekerRegisterForm.$(
                                                              "confirmPassword"
                                                          ).error || undefined
                                                }
                                                labelText="Confirm password"
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <div className={classes.center}>
                                        <FormControlLabel
                                            classes={{
                                                root:
                                                    classes.checkboxLabelControl,
                                                label: classes.checkboxLabel
                                            }}
                                            control={
                                                <Checkbox
                                                    tabIndex={-1}
                                                    onClick={
                                                        jobSeekerRegisterForm.$(
                                                            "agreeTerm"
                                                        ).onChange
                                                    }
                                                    checkedIcon={
                                                        <Check
                                                            className={
                                                                classes.checkedIcon
                                                            }
                                                        />
                                                    }
                                                    icon={
                                                        <Check
                                                            className={
                                                                classes.uncheckedIcon
                                                            }
                                                        />
                                                    }
                                                    classes={{
                                                        checked:
                                                            classes.checked,
                                                        root: classes.checkRoot
                                                    }}
                                                />
                                            }
                                            label={
                                                <span>
                                                    I Accept Mechanics & Body
                                                    Shops Marketplace{" "}
                                                    <a href="#pablo">
                                                        Terms and Conditions of
                                                        Use
                                                    </a>
                                                    .
                                                </span>
                                            }
                                        />
                                    </div>
                                    <div className={classes.center}>
                                        <Button
                                            round
                                            color="primary"
                                            size="lg"
                                            type="submit"
                                            disabled={
                                                !jobSeekerRegisterForm.$(
                                                    "agreeTerm"
                                                ).value
                                            }
                                        >
                                            Sign up
                                        </Button>
                                        <div className={classes.bottomText}>
                                            Already have an account?{" "}
                                            <Link
                                                className={classes.loginLink}
                                                to="/auth/login-page"
                                            >
                                                Log In
                                            </Link>{" "}
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

JobSeekerRegister.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(
    inject("authStore")(observer(JobSeekerRegister))
);
