import React from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./unsubscribe.css";
import { inject, observer } from "mobx-react";

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdate: true
    };
  }

  handleUnsubscribe = async userid => {
    try {
      const res = await this.props.userStore.updateSubscribeTWDegist(
        userid,
        "N"
      );
      console.log(res);
      if (res && res.statusCode === "0000") {
        this.setState({ statusUpdate: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleRedirectHomePage = () => {
    window.location.href = "/";
  };

  componentDidMount() {}

  render() {
    const { userid, email } = this.props.match.params;
    if (!userid || !email) {
      return <div>404 page not found</div>;
    }
    const { statusUpdate } = this.state;
    return statusUpdate ? (
      <div style={{ marginTop: 330 }}>
        <div className="unsubscribe-box">
          <Grid container className="container">
            <Grid item xs={12} md={12} lg={12}>
              <div className="title">
                <p>Unsubscription</p>
              </div>
              <Grid container className="inner-container">
                <Grid item xs={12} md={12} lg={12}>
                  <p>
                    Please confirm you want to unsubscribe from receiving
                    updates to your questions and other people's questions and
                    answers on Tech Wizard.
                  </p>
                  <div className="btn">
                    <button onClick={() => this.handleRedirectHomePage()}>
                      NEVERMIND
                    </button>
                    <button onClick={() => this.handleUnsubscribe(userid)}>
                      UNSUBSCRIBE
                    </button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    ) : (
      <div style={{ marginTop: 330 }}>
        <div className="unsubscribe-box">
          <Grid container className="container">
            <Grid container className="inner-container">
              <Grid item xs={12} md={12} lg={12}>
                <p>You have unsubscribed from this list.</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default withRouter(inject("userStore")(observer(Unsubscribe)));
