import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { injectStripe } from "react-stripe-elements";
import { Switch } from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";

// core components
import { inject, observer } from "mobx-react";
import pricingSubscriptionPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingSubscriptionPageStyle.jsx";

class SubcribeTWDegist extends React.Component {
  state = {
    isSubscribe: false
  };

  handleChange = name => event => {
    const { currentUser } = this.props.userStore;
    const idUser = currentUser && currentUser.id;
    this.setState({ isSubscribe: event.target.checked });
    !this.state.isSubscribe
      ? this.props.userStore.updateSubscribeTWDegist(idUser, "Y")
      : this.props.userStore.updateSubscribeTWDegist(idUser, "N");
  };

  componentDidMount() {
    const { currentUser } = this.props.userStore;
    this.setState(
      currentUser &&
        currentUser.user &&
        currentUser.user.tw_digest_subcribed === "Y"
        ? { isSubscribe: true }
        : { isSubscribe: false }
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <GridItem
        xs={12}
        sm={12}
        className={classes.justifyContent + " " + classes.dflex}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ margin: 0, padding: "0px 20px" }}>Subscibe:</p>
          <div>
            <Switch
              checked={this.state.isSubscribe}
              onChange={this.handleChange()}
              value="isSubscribe"
            />
          </div>
        </div>
      </GridItem>
    );
  }
}

export default injectStripe(
  withStyles(pricingSubscriptionPageStyle)(
    inject("userStore", "commonStore")(observer(SubcribeTWDegist))
  )
);
