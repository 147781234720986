import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { withStyles } from '@material-ui/core';
import Rating from 'react-rating';

import FiberManualRecordOutlined from "@material-ui/icons/FiberManualRecordOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import { convertDisplayText } from 'services/utils';

const styles = {
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    mb30: {
        marginBottom: '30px'
    },
    icon: {
        color: '#ff9800'
    }
};
class ForemanProfile extends React.Component {
    render() {
        const { classes, skills, toolSet } = this.props;
        let { skills_level, tools } = skills;
        if (!skills_level) skills_level = {};
        let toolDisplay = '--';
        let toolSelected;
        switch (tools.general_tool) {
            case 'specialty_tool':
                toolSelected = toolSet.find(item => item.value === 'specialty_tool');
                if (toolSelected) {
                    toolDisplay = `${toolSelected.name} - ${tools.specialty_tool && tools.specialty_tool.name}`
                }
                break;
            case 'scanner':
                toolSelected = toolSet.find(item => item.value === 'scanner');
                if (toolSelected) {
                    toolDisplay = `${toolSelected.name} - ${tools.scanner}`
                }
                break;
            default:
                toolSelected = toolSet.find(item => item.value === tools.general_tool);
                if (toolSelected) {
                    toolDisplay = toolSelected.name
                }
                break;
        }
        return <React.Fragment>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Years of Automotive Tech/Mechanic Experience:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.years_of_auto_tech_foreman_experience}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <div className={classes.label}>Technician/Mechanic Type:</div>
                </GridItem>
                <GridItem xs={3}>
                    <p className={classes.mbt10}>{convertDisplayText(skills.type_of_technician, '_')}</p>
                </GridItem>
                <GridItem xs={3}>
                    <div className={classes.label}>Level:</div>
                </GridItem>
                <GridItem xs={3}>
                    <p className={classes.mbt10}>{skills.level}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={3}>
                    <div className={classes.label}>Skills:</div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Comprehensive Maintenance Inspection:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.comprehensive_maintenance_inspection}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Maintenance Service:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.maintenance_service}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Brake Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.break_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Tire Service:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.tire_service}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Suspension & Steering:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.suspension_and_steering}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Drive Train & Axles:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.drive_train_and_axles}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Alignment:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.alignment}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Heating & A/C Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.heating_and_ac_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>General Repair & Replace:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.general_repair_and_replace}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={2}></GridItem>
            </GridContainer>
            {/* <GridContainer>
                <GridItem xs={1}></GridItem>
                <GridItem xs={10}><h4>Engine Overhaul</h4></GridItem>
                <GridItem xs={1}></GridItem>
            </GridContainer> */}
            <GridContainer>
                <GridItem xs={2}></GridItem>
                <GridItem xs={8}>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Engine overhaul:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.engine_overhaul}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Engine replacement:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.engine_replacement}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Transmission replacement:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.transmission_replacement}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Transmission Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.transmission_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Diagnostics & Electrical Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.electrical_diagnostics_and_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Diagnostics & Chassis Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.chassis_diagnostics_and_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Advanced Diagnostics:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.advanced_diagnostics}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Smog & Exhaust Repairs:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.smog_and_exhaust_repairs}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Digital Inspections:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.digital_inspection}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Lab Scope Use:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.lab_scope_use}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>Classic Car Restoration/Repair:</p>
                        </GridItem>
                        <GridItem xs={6}>
                            <p className={classes.mbt10}>
                                <Rating
                                    initialRating={skills_level.classic_car_restoration_repair}
                                    readonly
                                    fractions={2}
                                    emptySymbol={<FiberManualRecordOutlined className={classes.icon} />}
                                    fullSymbol={<FiberManualRecord className={classes.icon} />}
                                />
                            </p>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={2}></GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Expertise in Car Types:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.expertise_car_types.join(', ') || '--'}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Expertise in Engine Types:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.expertise_engine_types.join(', ') || '--'}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Certifications:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.certifications.join(', ')}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Manufacturer Certifications:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{skills.manufacturer_certified}</p>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Tools:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{`${toolDisplay}`}</p>
                    {tools && tools.tools_photo && <img alt="Tool" src={tools.tools_photo} style={{ width: '50%', height: '50%' }} />}
                </GridItem>
            </GridContainer>
            {tools.estimated_value && <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Estimated Value of Tools:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>${tools.estimated_value}</p>
                </GridItem>
            </GridContainer>}
            {tools.specialty_tool && <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Specialty Tools:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{tools.specialty_tool}</p>
                </GridItem>
            </GridContainer>}
            {tools.scanner && <GridContainer>
                <GridItem xs={6}>
                    <div className={classes.label}>Scanner:</div>
                </GridItem>
                <GridItem xs={6}>
                    <p className={classes.mbt10}>{tools.scanner}</p>
                </GridItem>
            </GridContainer>}
        </React.Fragment>
    }
}

export default withStyles(styles)(ForemanProfile);