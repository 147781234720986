import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const notificationsPageStyle = theme => ({
    ...customDropdownStyle(theme),
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    }
});

export default notificationsPageStyle;
