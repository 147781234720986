import {
    forgotPasswordApi, loginApi, registerBusinessApi, registerGeneralApi,
    registerJobSeekerApi, resetPasswordApi, validateUsersApi
} from "../dataServices/authData.service";

const registerBusiness = data => {
    return registerBusinessApi(data);
};

const registerJobSeeker = data => {
    return registerJobSeekerApi(data);
};

const registerGeneral = data => {
    return registerGeneralApi(data);
};


const login = (data, isBu) => {
    return loginApi(data, isBu);
};

const forgotPassword = data => {
    return forgotPasswordApi(data);
};

const resetPassword = data => {
    return resetPasswordApi(data);
};

const validateUsers = data => {
    return validateUsersApi(data);
};
export {
    registerBusiness,
    registerJobSeeker,
    login,
    forgotPassword,
    resetPassword,
    registerGeneral,
    validateUsers
};
