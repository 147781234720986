import React from "react";
import ReactPaginate from "react-paginate";
import "./TWPagination.scss";

export const TWPagination = props => {
  const {
    onPageChange,
    page,
    total,
    page_size,
    pageRangeDisplayed,
    previousLabel,
    nextLabel,
    breakLabel
  } = props;

  return (
    <div className="tw-pagination">
      <ReactPaginate
        onPageChange={onPageChange}
        initialPage={0}
        forcePage={page}
        pageCount={Math.ceil(total / page_size)}
        pageRangeDisplayed={
          pageRangeDisplayed < 0 || !pageRangeDisplayed ? 5 : 0
        }
        breakLabel={breakLabel || "..."}
        nextLabel={nextLabel || ">"}
        previousLabel={previousLabel || "<"}
        renderOnZeroPageCount={null}
      />
    </div>
  );
};
