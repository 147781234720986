import React from "react";
import * as moment from "moment-timezone/builds/moment-timezone-with-data";
import { Link, withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import WifiTethering from "@material-ui/icons/WifiTethering";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Loading from "components/Loading/Loading";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { getFieldFromListByKey } from "services/utils";
import notificationService from "../../services/notificationService";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import sweetAlertStyle from "../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  header: {
    display: "flex",
    alignItems: "flex-start"
  },
  newBtn: {
    marginTop: "15px",
    marginLeft: "auto"
  },
  mb20: {
    marginBottom: "20px"
  },
  tableCell: {
    fontSize: "1rem",
    padding: "4px 20px"
  }
};

const rows = [
  { id: "function", numeric: false, disablePadding: false, label: "Function" },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: "Date available"
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: false,
    label: "Date created"
  }
];

class RequestsSaved extends React.Component {
  state = {
    confirmDeleteModal: null
  };
  constructor(props) {
    super(props);
    this.props.requestsEmployeeStore.setPageViewing("saved");
  }

  componentDidMount() {
    this.getRequestsSavedList();
  }

  selectRequest = req => () => {
    this.props.history.push(`/requests/saved/${req.id}`);
  };

  handleChangePage = (e, page) => {
    this.props.requestsEmployeeStore.changePageOfPagination(page);
    this.getRequestsSavedList();
  };

  handleChangePageSize = e => {
    this.props.requestsEmployeeStore.changePageSizeOfPagination(e.target.value);
    this.getRequestsSavedList();
  };

  getRequestsSavedList = () => {
    const {
      getRequestsSavedList,
      pagination
    } = this.props.requestsEmployeeStore;
    const { pageSize, page, sort, order } = pagination;
    getRequestsSavedList(pageSize, page, sort, order);
  };

  handleDeleteListing = () => {
    this.setState({ confirmDeleteModal: null });

    const {
      requestsPagination,
      deleteListings
    } = this.props.requestsEmployeeStore;
    const payload = {
      ids: requestsPagination.items
        .filter(item => item.checked === true)
        .map(item => item.listing_id)
    };
    deleteListings(payload).then(() => {
      notificationService.success(`Deleted`);
      this.getRequestsSavedList();
    });
  };

  closeConfirmModal = () => {
    this.setState({ confirmDeleteModal: null });
  };

  handleAllCheckboxItems = checked => {
    this.props.requestsEmployeeStore.handleAllCheckboxItems(checked);
  };

  handleCheckboxItem = id => {
    this.props.requestsEmployeeStore.handleCheckboxItem(id);
  };

  showConfirmDeleteModal = () => {
    return this.setState({
      confirmDeleteModal: (
        <SweetAlert
          title=""
          onConfirm={this.handleDeleteListing}
          onCancel={this.closeConfirmModal}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.gray
          }
          confirmBtnText="Yes"
          cancelBtnText="No"
          showCancel
        >
          <p>
            Are you sure you want to remove this listing from this page? This
            does not delete or remove your job posting; it only clears your
            workspace.
            <b>THIS FUNCTION CANNOT BE UNDONE.</b>
          </p>
        </SweetAlert>
      )
    });
  };

  render() {
    const { classes } = this.props;
    const {
      requestsPagination,
      pagination,
      isLoading,
      checked
    } = this.props.requestsEmployeeStore;
    const { functions } = this.props.commonStore;
    const { timeZone } = this.props.userStore;

    const disabled =
      requestsPagination.items.filter(item => item.checked === true).length ===
      0;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon className={classes.header}>
              <CardIcon color="primary">
                <WifiTethering />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Requests Saved</h4>
              <div style={{ marginLeft: "auto", marginTop: "15px" }}>
                <Button
                  disabled={
                    disabled || isLoading || requestsPagination.total_item === 0
                  }
                  color={disabled ? "gray" : "danger"}
                  onClick={this.showConfirmDeleteModal}
                  className={classes.ml10}
                >
                  {" "}
                  Delete{" "}
                </Button>
                <Link to={"/request/new"}>
                  <Button color="primary">New Request</Button>
                </Link>
              </div>
            </CardHeader>
            <CardBody>
              <div>
                {isLoading ? (
                  <Loading />
                ) : (
                  <Table aria-labelledby="tableTitle">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: "50px" }}>
                          <Checkbox
                            disabled={
                              isLoading || requestsPagination.total_item === 0
                            }
                            tabIndex={-1}
                            onClick={() =>
                              this.handleAllCheckboxItems(!checked)
                            }
                            checked={checked}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        </TableCell>
                        {rows.map(row => (
                          <TableCell
                            key={row.id}
                            className={classes.tableCell}
                            align={row.numeric ? "right" : "left"}
                            padding={row.disablePadding ? "none" : "default"}
                          >
                            {row.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requestsPagination.items.map(n => {
                        return (
                          <React.Fragment key={n.id}>
                            <TableRow
                              hover
                              tabIndex={-1}
                              onClick={this.selectRequest(n)}
                            >
                              <TableCell>
                                <Checkbox
                                  tabIndex={-1}
                                  onClick={e =>
                                    e.stopPropagation() &
                                    this.handleCheckboxItem(n.id)
                                  }
                                  checked={n.checked}
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                {getFieldFromListByKey(
                                  functions,
                                  "name",
                                  "value",
                                  n.function
                                )}
                              </TableCell>
                              {n.job_type === "temporary" ? (
                                <TableCell>
                                  {timeZone &&
                                    moment(n.start_date).format(
                                      "MMM DD YYYY"
                                    )}{" "}
                                  to{" "}
                                  {timeZone &&
                                    moment(n.end_date).format("MMM DD YYYY")}
                                </TableCell>
                              ) : (
                                <TableCell />
                              )}
                              <TableCell>
                                {timeZone &&
                                  moment(n.created_at).format("MMM DD YYYY")}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </div>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={requestsPagination.totalItem}
                rowsPerPage={pagination.pageSize}
                page={pagination.page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangePageSize}
              />
            </CardBody>
          </Card>
        </GridItem>
        {this.state.confirmDeleteModal}
      </GridContainer>
    );
  }
}

export default withStyles({ ...styles, ...extendedFormsStyle })(
  inject("requestsEmployeeStore", "commonStore", "userStore")(
    withRouter(observer(RequestsSaved))
  )
);
