import React from 'react';

import SweetAlert from "react-bootstrap-sweetalert";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";

const styles = {
    rateIcon: {
        color: '#F8981F',
        fontSize: '40px'
    },
    mb20: {
        marginBottom: '20px'
    },
    ml10: {
        marginLeft: '10px'
    },
    mr10: {
        marginRight: '10px'
    },
    empName: {
        fontWeight: 'bold'
    }
}

class ReplyModalUI extends React.Component {
    render() {
        const { classes, replyForm, onConfirm, onCancel } = this.props;
        return <SweetAlert
            title="Reply"
            onConfirm={onConfirm}
            onCancel={onCancel}
            confirmBtnCssClass={
                classes.button + " " + classes.success
            }
            cancelBtnCssClass={
                classes.button + " " + classes.danger
            }
            confirmBtnText="Send"
            cancelBtnText="Cancel"
            style={{ display: "block", top: "240px", width: '800px', left: 'calc(50% - 150px)' }}
            showCancel={false}
            showConfirm={false}
        >
            <div className={classes.formWrapper}>
                <div>
                    <CustomInput
                        labelText='Type your reply *'
                        formControlProps={{
                            fullWidth: true
                        }}
                        error={!!replyForm.$('comment').error}
                        helpText={replyForm.$('comment').error || undefined}
                        inputProps={
                            {
                                multiline: true,
                                rows: 4,
                                ...replyForm.$('comment').bind()
                            }
                        }
                    />
                </div>
            </div>
            <div>
                <Button className={classes.mr10} round onClick={onCancel}>Cancel</Button>
                <Button  className={classes.ml10} color="primary" round onClick={onConfirm} loading={replyForm.$('submitting').value} disabled={replyForm.$('submitting').value}>Reply</Button>
            </div>
        </SweetAlert>
    }
}

export default withStyles({ ...sweetAlertStyle, ...styles })(observer(ReplyModalUI));