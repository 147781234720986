import React from "react";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import unauthRoutes from "../../routes/unauthRoutes";
import GeneralLayout from "../../layouts/general/generalLayout";
import AuthLayout from "../../layouts/AuthLayout";

class UnauthorizedRoute extends React.Component {
  componentDidMount() {
    // console.log("this.props ", this.props);
  }

  render() {
    const { pathname } = this.props.location;
    const { userStore } = this.props;

    if (
      (pathname.includes("features") ||
        pathname.includes("create-business-account") ||
        pathname.includes("delete-account")) &&
      !userStore.currentUser
    ) {
      localStorage.setItem("preLoginUrl", pathname);
      window.location.href = "/app/#/auth/login-page";
    }

    return unauthRoutes.map((prop, key) => {
      if (prop.layout === "general") {
        return <GeneralLayout {...prop} key={key} />;
      } else {
        return <AuthLayout {...prop} key={key} />;
      }
    });
  }
}

export default inject("userStore")(observer(UnauthorizedRoute));
