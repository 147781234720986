import { withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import React from "react";

import zoomImgIcon from "../../../assets/img/zoomImageIcon.png";

import { PostImageItemSlider } from "../postImageItemSlider/postImageItemSlider";

const styles = theme => ({
  containerImageItems: {
    display: "flex",
    margin: "20px 0"
  },
  imageItem: {
    position: "relative",
    width: "10rem",
    height: "10rem",
    marginRight: 10,
    "&:hover": {
      "& $overlayHover": {
        opacity: 0.3
      },
      "& $zoomIconWrap": {
        display: "block"
      }
    }
  },
  imageContent: {
    borderRadius: "2px",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  overlayHover: {
    position: "absolute",
    width: "100%",
    background: "black",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    borderRadius: "2px",
    transition: ".5s ease"
  },
  zoomIconWrap: {
    position: "absolute",
    display: "none",
    top: 0,
    right: 5
  },
  zoomIcon: {
    width: 20,
    height: 20
  }
});

class PostImageItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowModalImageSlider: false,
      imageClicked: ""
    };
  }

  handleShowModalImageSlider = indexImage => {
    this.setState({
      isShowModalImageSlider: true,
      imageClicked: indexImage
    });
  };

  handleCloseModalImageSlider = () => {
    this.setState({
      isShowModalImageSlider: false
    });
  };

  render() {
    const { classes, listImagesPost } = this.props;
    const { isShowModalImageSlider, imageClicked } = this.state;
    return (
      <div className={classes.containerImageItems}>
        {listImagesPost &&
          listImagesPost.map((img, index) => (
            <div
              key={index}
              className={classes.imageItem}
              onClick={() => this.handleShowModalImageSlider(index)}
            >
              <img className={classes.imageContent} src={img} alt="" />
              <div className={classes.overlayHover} />
              <div className={classes.zoomIconWrap}>
                <img src={zoomImgIcon} className={classes.zoomIcon} alt="" />
              </div>
            </div>
          ))}
        {isShowModalImageSlider && (
          <PostImageItemSlider
            onClose={() => this.handleCloseModalImageSlider()}
            listImageSlider={listImagesPost}
            indexImageStart={imageClicked}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(observer(PostImageItem));
