import React from "react";
import { withRouter } from "react-router-dom";
import { Elements, StripeProvider } from "react-stripe-elements";

import WifiTethering from "@material-ui/icons/WifiTethering";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import notificationService from "services/notificationService";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import RequestFormUI from "./components/RequestFormUI";
import BackPreviousPageButton from "../../components/BackPreviousPageButton";
import { checkParagraphNotContainsStrings } from "services/utils";
import config from "config";
import { injectStripe } from "react-stripe-elements";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  ml10: {
    marginLeft: "10px"
  },
  mr10: {
    marginRight: "10px"
  },
  helperText: {
    color: "#f44336",
    marginTop: "0"
  },
  formField: {
    marginBottom: "10px",
    padding: "0 5px!important"
  },
  gridContainer: {
    width: "calc(100% + 10px)",
    margin: "0 -5px"
  }
};

class NewRequest extends React.Component {
  cardElementRef;
  expireDateElementRef;
  cvcElementRef;
  cardElementRef;

  constructor(props) {
    super(props);
    this.state = {
      warningModal: null,
      confirmSendRequestModal: null,
      confirmMakePaymentModal: null,
      addPaymentModal: null,
      submittingSend: false,
      submittingSave: false,
      cardNumberError: "",
      cvcError: "",
      expireDateError: "",
      keyOffers: [],
      location: {
        city: '',
        state: ''
      }
    };
    this.props.requestsEmployeeStore.reset();
  }

  closeWarningModal = () => {
    this.setState({
      warningModal: null
    });
  };

  checkValidRequest = type => () => {
    const { requestForm } = this.props.requestsEmployeeStore;
    requestForm.$("status").set(type);
    const isTemporaryJob = requestForm.$("job_type").value === "temporary";
    if (isTemporaryJob) {
      requestForm.has("rate_from") && requestForm.del("rate_from");
      requestForm.has("rate_to") && requestForm.del("rate_to");
    } else {
      requestForm.has("rate") && requestForm.del("rate");
      requestForm.has("start_date") && requestForm.del("start_date");
      requestForm.has("end_date") && requestForm.del("end_date");
      requestForm.has("work_start_time") && requestForm.del("work_start_time");
    }
    requestForm.validate();
    requestForm.showErrors(true);
    const { currentUser } = this.props.userStore;
    const { preferences } = currentUser;
    const { business_name } = preferences;
    const invalidStrings = [
      config.pattern.url,
      config.pattern.phone,
      config.pattern.email,
      business_name
    ];

    const isValid = checkParagraphNotContainsStrings(
      requestForm.$("additional_comment").value,
      invalidStrings
    );

    const requestHandler =
      type === "saved"
        ? this.saveRequest
        : this.showConfirmSendRequestModal(isTemporaryJob);

    if (!isValid) {
      this.setState({
        warningModal: (
          <SweetAlert
            warning
            title=""
            onConfirm={this.closeWarningModal}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Ok"
          >
            Providing business or contact information prior to hiring an
            employee is a breach of our Terms & Conditions of use. Repeated
            attempts may result in your account being suspended.
          </SweetAlert>
        )
      });
    } else {
      requestHandler();
    }
  };

  handleUpdateKeyOffer = key => {
    const { keyOffers } = this.state;
    if (keyOffers.includes(key)) {
      this.setState({
        keyOffers: keyOffers.filter(item => item !== key)
      });
      return;
    }
    this.setState({ keyOffers: [...keyOffers, key] });
  };

  sendRequest = () => {
    this.closeWarningModal();
    const { requestForm, createRequest } = this.props.requestsEmployeeStore;
    requestForm.submit({
      onSuccess: form => {
        const data = {
          ...form.values(),
          key_benefit_offer: this.state.keyOffers
        };
        data.geolocation.city = this.state.location.city
        data.geolocation.state = this.state.location.state
        this.setState({ submittingSend: true });
        createRequest(data).then(
          () => {
            this.setState({ submittingSend: false });
            notificationService.success("REQUEST.SENT_REQUEST");
            this.props.history.push("/request/sent");
          },
          () => {
            this.setState({ submittingSend: false });
          }
        );
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
        notificationService.error(
          "Some fields are missing or have errors. Please correct them and try again."
        );
      }
    });
  };

  saveRequest = () => {
    this.closeWarningModal();
    const { requestForm, createRequest } = this.props.requestsEmployeeStore;
    requestForm.submit({
      onSuccess: form => {
        const data = {
          ...form.values(),
          key_benefit_offer: this.state.keyOffers
        };
        data.geolocation.city = this.state.location.city
        data.geolocation.state = this.state.location.state
        this.setState({ submittingSave: true });
        createRequest(data).then(
          () => {
            notificationService.success("REQUEST.SAVED_REQUEST");
            this.setState({ submittingSave: false });
            this.props.history.push("/request/saved");
          },
          () => {
            this.setState({ submittingSave: false });
          }
        );
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  showConfirmSendRequestModal = isTemporaryJob => () => {
    if (this.props.requestsEmployeeStore.requestForm.hasError) {
      return;
    }
    this.closeWarningModal();
    const { getTransactionFee } = this.props.commonStore;
    const transactionFee = getTransactionFee();
    const { getHiredFee } = this.props.commonStore;
    const hiredFee = getHiredFee();

    this.setState({
      confirmSendRequestModal: (
        <SweetAlert
          title=""
          onConfirm={this.confirmSendRequest}
          onCancel={this.closeSendRequestModal}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.danger
          }
          confirmBtnText="Confirm Request"
          cancelBtnText="Go Back"
          showCancel
          style={{
            display: "block",
            top: "240px",
            width: "800px",
            left: "calc(50% - 150px)"
          }}
        >
          {isTemporaryJob ? (
            <div style={{ textAlign: "left" }}>
              If a job seeker applies to your job and you decide to hire
              him/her, you will be charged a small transaction fee (typically
              $25-$99) to cover the cost to maintain the platform as well as
              market and solicit candidates to join and get connected with you.
              We appreciate your support. Note that this fee will be refunded in
              case of a “No Show” and may be partially refunded if you cancel
              after the 1st day of work. You will be informed of the exact
              amount after you receive applicants and you are ready to make a
              hire.
            </div>
          ) : (
            <div style={{ textAlign: "left" }}>
              Posting this employee request is free. If a job seeker applies to
              your job and you like his/her profile and wish to interview
              him/her or bring him/her in for a trial period, you will be
              charged a [${transactionFee}] non-refundable transaction fee to
              cover the cost of maintaining the platform as well as market and
              solicit candidates to the platform. We appreciate your support. If
              you decide to hire the employee, you agree to pay us a Hire fee of
              [${hiredFee}] within one week of the employee's start date, which
              will be charged to your credit card on file. If the employee
              doesn't show up or doesn't work out in his 1st 5 days of permanent
              employment, the fee will be waived.{" "}
            </div>
          )}
        </SweetAlert>
      )
    });
  };

  confirmSendRequest = () => {
    this.closeSendRequestModal();
    this.sendRequest();
  };

  closeSendRequestModal = () => {
    this.setState({
      confirmSendRequestModal: null
    });
  };

  handleChange = fieldName => ({ error }) => {
    if (error) {
      this.setState({ [fieldName]: error.message });
    }
  };
  handleUpdateLocation = ({ city, state }) => {
    this.setState({
      location: {
        city: city,
        state: state
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { requestForm } = this.props.requestsEmployeeStore;
    const {
      carTypes,
      workProcesses,
      autotechLevels,
      jobTypes,
      functions,
      bodyTechTypes,
      benefitOffer = []
    } = this.props.commonStore;
    const { currentUser } = this.props.userStore;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <WifiTethering />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>New Request</h4>
            </CardHeader>
            <CardBody>
              <RequestFormUI
                requestForm={requestForm}
                carTypes={carTypes}
                profileCarTypes={currentUser.preferences.car_types}
                workProcesses={workProcesses}
                autotechLevels={autotechLevels}
                jobTypes={jobTypes}
                functions={functions}
                bodyTechTypes={bodyTechTypes}
                benefitOffer={benefitOffer}
                handleUpdateKeyOffer={this.handleUpdateKeyOffer}
                handleUpdateLocation={this.handleUpdateLocation}
                location={this.state.location}
                editMode={true}
              />
              {this.state.warningModal}
              {this.state.confirmSendRequestModal}
              {this.state.confirmMakePaymentModal}
              {this.state.addPaymentModal}
            </CardBody>
            <CardFooter className={classes.justifyContentSpaceBetween}>
              <BackPreviousPageButton />
              <div>
                <Button
                  color="primary"
                  className={classes.mr10}
                  onClick={this.checkValidRequest("sent")}
                  loading={this.state.submittingSend}
                  disabled={
                    this.state.submittingSend || this.state.submittingSave
                  }
                >
                  Post Job
                </Button>
                <Button
                  color="info"
                  className={classes.ml10}
                  onClick={this.checkValidRequest("saved")}
                  loading={this.state.submittingSave}
                  disabled={
                    this.state.submittingSend || this.state.submittingSave
                  }
                >
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const EnhancedNewRequest = injectStripe(
  withStyles(styles)(
    inject("requestsEmployeeStore", "commonStore", "userStore")(
      withRouter(observer(NewRequest))
    )
  )
);

class WrapperNewRequest extends React.Component {
  render() {
    const { appConfig } = this.props.commonStore;
    return (
      <StripeProvider apiKey={appConfig && appConfig.stripe.api_key}>
        <Elements>
          <EnhancedNewRequest />
        </Elements>
      </StripeProvider>
    );
  }
}

export default inject("commonStore")(observer(WrapperNewRequest));
