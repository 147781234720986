import {
    getSubscriptionInfoApi,
    getAvailableSubscriptionListApi,
    purchaseSubscriptionApi
} from "../dataServices/subscriptionData.service";

const getSubscriptionInfo = () => getSubscriptionInfoApi();

const getAvailableSubscriptionList = () => getAvailableSubscriptionListApi();

const purchaseSubscription = data => purchaseSubscriptionApi(data);

export {
    getSubscriptionInfo,
    getAvailableSubscriptionList,
    purchaseSubscription
};
