import HeaderGeneral from "./components/header/HeaderGeneral";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import { observer, inject } from "mobx-react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import ModalRequireLogin from "../../common/components/modals/modalRequireLogin";
import ModalResponse from "../../common/components/modals/modalResponse";
import ModalGameRules from "../../common/components/modals/modalGameRules";

const styles = theme => ({
  wrapper: {}
});

class GeneralLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  getRoutes = () => {
    const { path, component } = this.props;
    const { views } = this.props;
    if (views && views.lenght > 0) {
      return views.map((item, key) => (
        <Route path={item.path} component={item.component} exact key={key} />
      ));
    } else {
      return <Route path={path} component={component} exact />;
    }
  };

  render() {
    const { classes, regex, location, path } = this.props;
    const {
      openModalResponse,
      modalResponseType,
      openModalRequireLogin,
      openModalGameRules
    } = this.props.commonStore;

    if (regex.test(location.pathname)) {
      return (
        <div className={classes.wrapper}>
          {path === "/tech-wizard" && <HeaderGeneral />}

          <Switch>{this.getRoutes()}</Switch>

          <ModalRequireLogin open={openModalRequireLogin} />

          <ModalGameRules open={openModalGameRules} />

          {modalResponseType === "feedback" && (
            <ModalResponse open={openModalResponse} type="feedback" />
          )}

          {modalResponseType === "report" && (
            <ModalResponse open={openModalResponse} type="report" />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(GeneralLayout))
  )
);
