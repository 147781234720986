import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import notificationService from '../../services/notificationService';

class ProfileUpdatedRoute extends React.Component {
  render() {
    const { userStore, ...restProps } = this.props;
    if (userStore.currentUser && userStore.currentUser.info_fully_updated) {
      return <Route {...restProps} />;
    }
    notificationService.warning('You must update your profile first');
    return <Redirect to={'/update-profile'} />;
  }
}

export default inject('userStore')(observer(ProfileUpdatedRoute));