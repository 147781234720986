import React from "react";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

import { image, container } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
  mainContent: {},
  container,
  bodyGrid: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginTop: 105
  },
  bodyContent: {
    width: "60%",
    flex: "1 0"
  },
  sidebar: {
    flex: `0 0 370px`,
    marginLeft: 34
  },
  sidebarSection: {
    marginBottom: 20
  }
});

class PageLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      body,
      sidebar,
      pageTitle,
      classes,
      regex,
      path,
      location,
      children,
      ...rest
    } = this.props;

    return (
      <div className={classes.mainContent}>
        {pageTitle && pageTitle}
        <div className={classes.container}>
          <div className={classes.bodyGrid}>
            <div className={classes.bodyContent}>{children}</div>
            {sidebar && sidebar.length > 0 && (
              <div className={classes.sidebar}>
                {sidebar.map((item, key) => {
                  return (
                    <section className={classes.sidebarSection} key={key}>
                      {item}
                    </section>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(inject("commonStore", "userStore")(observer(PageLayout)))
);
