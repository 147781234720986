import React from "react";
import { withRouter } from 'react-router-dom';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';
import Datetime from "react-datetime";

// react component for creating dynamic tables
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { getFieldFromListByKey } from 'services/utils';
import notificationService from "services/notificationService";
import PaymentModal from "components/PaymentModal";

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    disableRow: {
        backgroundColor: `rgba(0, 0, 0, 0.3)`,
        cursor: `not-allowed`,
    },
    mb20: {
        marginBottom: '20px'
    }
};


const rows = [
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Created at', disableSort: true },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type', disableSort: true },
    { id: 'transaction_no', numeric: false, disablePadding: false, label: 'Transaction No.', disableSort: true },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status', disableSort: true },
    { id: 'employee_name', numeric: false, disablePadding: false, label: 'Employee name', disableSort: true },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Amount', disableSort: true },
    { id: 'action', numeric: false, disablePadding: false, label: '' },
];

const types = [
    { name: 'Subscription', value: 'subscription' },
    { name: 'Payment', value: 'payment' },
    { name: 'Refund', value: 'refund' }]

const statuses = [
    { name: 'Succeeded', value: 'succeeded', status: ['succeeded'] },
    { name: 'Pending', value: 'pending', status: ['payment_intent_unexpected_state', 'requires_action', 'requires_payment_method', 'requires_confirmation'] },
    { name: 'Failed', value: 'failed', status: ['canceled'] }
]

class TransactionList extends React.Component {
    state = {
        paymentModal: null
    }
    constructor(props) {
        super(props);
        this.props.transactionStore.reset();
    }

    componentDidMount() {
        this.props.userStore.getPaymentMethods();
        this.getTransactionList();
    }

    getTransactionList = () => {
        const { getTransactionList, pagination } = this.props.transactionStore;
        const { pageSize, page, type, status, fromDate, toDate } = pagination;
        getTransactionList(type, status, pageSize, page, fromDate, toDate);
    }

    handleChangePage = (e, page) => {
        this.props.transactionStore.changePageOfPagination(page);
        this.getTransactionList();
    }

    handleChangePageSize = (e) => {
        this.props.transactionStore.changePageSizeOfPagination(e.target.value);
        this.getTransactionList();
    }

    handleSort = sort => () => {
        this.props.transactionStore.changeSortOfPagination(sort);
        this.getTransactionList();
    }

    changeTypeFilter = e => {
        this.props.transactionStore.changeTypePagination(e.target.value);
        this.getTransactionList();
    }

    changeStatusFilter = e => {
        this.props.transactionStore.changeStatusPagination(e.target.value);
        this.getTransactionList();
    }

    changeToDateFilter = e => {
        this.props.transactionStore.changeToDatePagination(e);
        this.getTransactionList();
    }

    changeFromDateFilter = e => {
        this.props.transactionStore.changeFromDatePagination(e);
        this.getTransactionList();
    }

    payTransaction = transaction => () => {
        if (transaction.stripe && transaction.stripe.payment_intent_id && transaction.stripe.client_secret) {
            this.props.stripe.handleCardPayment(transaction.stripe.client_secret, {
                payment_method: transaction.stripe.payment_intent_id,
            }).then((result) => {
                if (result.error) {
                    // Display error.message in your UI.
                    notificationService.error(result.error.message);
                } else {
                    this.props.transactionStore.confirmTransaction(transaction.id).then(() => {
                        notificationService.success('TRANSACTION.PURCHASE_SUCCESS');
                        this.getTransactionList();
                    })
                }
            })
        } else {
            this.setState({
                paymentModal: <PaymentModal addPaymentMethod={this.addPaymentMethod} closePaymentModal={this.closePaymentModal} selectPayment={this.selectPayment(transaction.id)} />
            });
        }
    }

    addPaymentMethod = (id) => {
        return this.props.userStore.addPaymentMethod(id).then(() => {
            notificationService.success('TRANSACTION.ADD_CARD_SUCCESS');
            this.props.userStore.getPaymentMethods();
            return Promise.resolve();
        });
    }

    selectPayment = transactionId => (card) => {
        this.props.transactionStore.repayTransaction(transactionId, { payment_method_id: card.id }).then(() => {
            notificationService.success('TRANSACTION.PURCHASE_SUCCESS');
            this.closePaymentModal();
            this.getTransactionList();
        })
    }

    closePaymentModal = () => {
        this.setState({ paymentModal: null });
    }


    validToDate = (currentDate) => {
        const { fromDate } = this.props.transactionStore.pagination;
        if (fromDate) {
            const fromDateMoment = moment(fromDate);
            return currentDate.isAfter(fromDateMoment);
        }
        return true;
    };

    validFromDate = (currentDate) => {
        const { toDate } = this.props.transactionStore.pagination;
        if (toDate) {
            const toDateMoment = moment(toDate);
            return currentDate.isBefore(toDateMoment);
        }
        return true;
    };

    saveAsCSV = () => {
        const { download, pagination } = this.props.transactionStore;
        const { pageSize, page, type, status, fromDate, toDate } = pagination;
        download(type, status, pageSize, page, fromDate, toDate);
    }

    getTotalMount = (items = []) => {
        let t = 0;
        items.forEach(item => {
            t += item.amount;
        });
        return t;
    }

    render() {
        const { classes } = this.props;
        const { transactionPagination, pagination, isLoading } = this.props.transactionStore;
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <Assignment />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Transactions History</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className={classes.mb20}>
                                <GridItem xs={12} md={6}>
                                    <div >
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="filter-select"
                                                className={classes.selectLabel}
                                            >
                                                Select type
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                inputProps={{
                                                    name: "filterSelect",
                                                    id: "filter-select"
                                                }}
                                                value={pagination.type}
                                                onChange={this.changeTypeFilter}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value='all'
                                                >
                                                    All
                                                </MenuItem>
                                                {types.map(type =>
                                                    <MenuItem
                                                        key={type.value}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value={type.value}
                                                    >
                                                        {type.name}
                                                    </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <div >
                                        <FormControl
                                            fullWidth
                                            className={classes.selectFormControl}
                                        >
                                            <InputLabel
                                                htmlFor="filter-select"
                                                className={classes.selectLabel}
                                            >
                                                Select status
                                            </InputLabel>
                                            <Select
                                                MenuProps={{
                                                    className: classes.selectMenu
                                                }}
                                                classes={{
                                                    select: classes.select
                                                }}
                                                inputProps={{
                                                    name: "filterSelect",
                                                    id: "filter-select"
                                                }}
                                                value={pagination.status}
                                                onChange={this.changeStatusFilter}
                                            >
                                                <MenuItem
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected
                                                    }}
                                                    value='all'
                                                >
                                                    All
                                                </MenuItem>
                                                {statuses.map(stat =>
                                                    <MenuItem
                                                        key={stat.value}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected
                                                        }}
                                                        value={stat.value}
                                                    >
                                                        {stat.name}
                                                    </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <div className={classes.dateTimeContainer}>
                                        <Datetime
                                            value={pagination.fromDate}
                                            onChange={this.changeFromDateFilter}
                                            isValidDate={this.validFromDate}
                                            timeFormat={false}
                                            renderInput={(props) => <CustomInput
                                                labelText='From date'
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={
                                                    props
                                                }
                                            />}
                                        />
                                    </div>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <div className={classes.dateTimeContainer}>
                                        <Datetime
                                            value={pagination.toDate}
                                            onChange={this.changeToDateFilter}
                                            isValidDate={this.validToDate}
                                            timeFormat={false}
                                            renderInput={(props) => <CustomInput
                                                labelText='To date'
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={
                                                    props
                                                }
                                            />}
                                        />
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <div style={{ position: 'relative' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" color="primary" onClick={this.saveAsCSV}>Download</Button>
                                </div>
                                <div style={{ position: 'absolute', bottom: -40, left: 20 }}>
                                    <span>Total transactions: <strong>{transactionPagination.totalItem}</strong></span>
                                    <span style={{marginLeft: 50}}>Total Amount: <strong>${this.getTotalMount(transactionPagination.items)}</strong></span>
                                </div>
                                {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle">
                                    <TableHead>
                                        <TableRow>
                                            {rows.map(
                                                row => (
                                                    <TableCell
                                                        key={row.id}
                                                        align={row.numeric ? 'right' : 'left'}
                                                        padding={row.disablePadding ? 'none' : 'default'}
                                                        sortDirection={pagination.sort === row.id ? pagination.order : false}
                                                    >
                                                        {!row.disableSort ? <Tooltip
                                                            title="Sort"
                                                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                            enterDelay={300}
                                                        >
                                                            <TableSortLabel
                                                                active={pagination.sort === row.id}
                                                                direction={pagination.order}
                                                                onClick={this.handleSort(row.id)}
                                                            >
                                                                {row.label}
                                                            </TableSortLabel>
                                                        </Tooltip> : row.label}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactionPagination.items.map(n => {
                                            return (
                                                <React.Fragment
                                                    key={n.id}
                                                >
                                                    <Tooltip title=''>
                                                        <TableRow
                                                            hover
                                                            tabIndex={-1}
                                                        >
                                                            <TableCell>
                                                                {moment(n.created_at).format('MMM DD YYYY')}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    getFieldFromListByKey(types, 'name', 'value', n.type)
                                                                }
                                                            </TableCell>
                                                            <TableCell>{`${n.transaction_no}`}</TableCell>
                                                            <TableCell>
                                                                {
                                                                    statuses.find(stat => stat.status.includes(n.status)) && statuses.find(stat => stat.status.includes(n.status)).name
                                                                }
                                                            </TableCell>
                                                            <TableCell>{`${n.metadata.employee_name || ''}`}</TableCell>
                                                            <TableCell>{`$${n.amount}`}</TableCell>
                                                            <TableCell>
                                                                {n.status !== 'succeeded' && <Button color="primary" size='sm' onClick={this.payTransaction(n)}>Pay</Button>}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        })}
                                    </TableBody>
                                </Table>}
                                {this.state.paymentModal}
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[20, 50, 100]}
                                component="div"
                                count={transactionPagination.totalItem}
                                rowsPerPage={pagination.pageSize}
                                page={pagination.page}
                                backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangePageSize}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

const EnhancedTransactionList = injectStripe(withStyles({ ...extendedFormsStyle, ...styles })(inject('transactionStore', 'commonStore', 'userStore')(withRouter(observer(TransactionList)))));

class WrapperTransactionList extends React.Component {
    render() {
        const { appConfig } = this.props.commonStore;
        return <StripeProvider apiKey={appConfig && appConfig.stripe.api_key}>
            <Elements><EnhancedTransactionList /></Elements>
        </StripeProvider>
    }
}

export default inject('commonStore')(observer(WrapperTransactionList));