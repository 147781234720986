import { convertPagingResponse } from "../utils";
import requests from "../http";
import moment from "moment";
import stores from 'stores';

const getAvailableEmployeesListApi = (jobType, func, pageSize, page, sort, order) => {
    return requests
        .get(
            `/api/listings/employees/available?job_type=${jobType === "all" ? "" : jobType
            }&page=${++page}&page_size=${pageSize}&order=${order}${sort ? `&sort=${sort}` : ''}${func !== 'all' ? `&function=${func}` : ''}`
        )
        .then(data => convertPagingResponse(data));
};

const getPendingEmployeeByIdApi = id => {
    return requests.get(`/api/listings/employees/pending/${id}`);
};

const getPendingEmployeesListApi = (pageSize, page, sort, order) => {
    return requests
        .get(
            `/api/listings/employees/pending?page=${++page}&page_size=${pageSize}&order=${order}&sort=${sort}`
        )
        .then(data => convertPagingResponse(data));
};

const getAvailableEmployeeByIdApi = id => {
    return requests.get(`/api/listings/employees/available/${id}`);
};


const getHiredEmployeesListApi = (pageSize, page, sort, order) => {
    return requests
        .get(
            `/api/listings/employees/hired?page=${++page}&page_size=${pageSize}&order=${order}&sort=${sort}`
        )
        .then(data => convertPagingResponse(data));
};

const getHiredEmployeeByIdApi = id => {
    return requests.get(`/api/listings/employees/hired/${id}`);
};

const cancelHireEmployeeApi = id => {
    return requests.post(`/api/listings/employees/${id}/cancel_hire`);
};


const reportNoShowEmployeeApi = id => {
    return requests.get(`/api/listings/employees/hired/${id}/report_no_show`);
};

const hireEmployeeApi = (id, data) => {
    const {
        end_date,
        start_date,
        work_start_time,
        work_start_time_a,
        additional_comment,
        must_bring_tools,
        rate,
        expect_work_on_weekends,
        work_day_hours
    } = data;
    const localTimezone = moment.tz.guess();
    const userTimezone = stores.userStore.currentUser && stores.userStore.currentUser.profile && stores.userStore.currentUser.profile.location && stores.userStore.currentUser.profile.location.time_zone && stores.userStore.currentUser.profile.location.time_zone.value;

    const dataPost = {
        must_bring_tools: must_bring_tools === 'yes',
        additional_comment,
        rate,
        end_date: moment(end_date).tz(localTimezone).subtract(userTimezone, 'h').toISOString(),
        start_date: moment(start_date).tz(localTimezone).subtract(userTimezone, 'h').toISOString(),
        work_start_time: {
            h: moment(work_start_time).isValid()
                ? moment(work_start_time).format("h:mm")
                : "",
            a: work_start_time_a
        },
        expect_work_on_weekends: expect_work_on_weekends === 'yes',
        work_day_hours
    };
    return requests.post(`/api/listings/employees/${id}/hire`, dataPost);
};

const getReviewsApi = (id) => {
    return requests.get(`/api/reviews/view/job_seeker/${id}?page_size=100`);
}

export {
    getAvailableEmployeesListApi,
    getAvailableEmployeeByIdApi,
    getPendingEmployeesListApi,
    getPendingEmployeeByIdApi,
    getHiredEmployeesListApi,
    getHiredEmployeeByIdApi,
    reportNoShowEmployeeApi,
    cancelHireEmployeeApi,
    hireEmployeeApi,
    getReviewsApi
};
