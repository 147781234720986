export const detailHeader = {
    normalPage: {
        title: 'Employment Solutions & Recruiting App for auto mechanics jobs & more',
        description: 'We are automotive recruiters & your resource for automotive employment & mechanic job information. Job seekers use our mobile App to find auto technicians jobs.'
    },
    loginPage: {
        title: 'Mechanics Marketplace Login',
        description: 'Login page for automotive repair shops, body shops, trucking companies and automotive dealerships'
    },
    registerPage: {
        title: 'Mechanics Marketplace Sign up',
        description: 'General registration page for Mechanics & Body Shops Marketplace'
    },
    businessPage: {
        title: 'Mechanics Marketplace Business Sign up',
        description: 'Sign up page for automotive repair shops, body shops, trucking companies and automotive dealerships'
    },
    workPage: {
        title: 'Mechanics Marketplace Job Seeker Sign up',
        description: 'Sign up page for job seekers in automotive industry and automotive professionals'
    }
};


export const statusCode = {
    success: "0000",
}
