import BusinessRegisterForm from "./form/businessRegister";
import JobSeekerRegisterForm from "./form/jobSeekerRegister";
import GeneralRegisterForm from "./form/generalRegister";
import { action, decorate, observable } from "mobx";
import LoginForm from "./form/login";
import {
  forgotPassword,
  login,
  registerBusiness,
  registerGeneral,
  registerJobSeeker,
  resetPassword,
  validateUsers
} from "../services/domainServices/authDomain.service";
import stores from ".";
import ForgotPasswordForm from "./form/forgotPassword";
import ResetPasswordForm from "./form/resetPasswordRegister";
import {
  sendOtpApi,
  verifyOtpApi
} from "../services/dataServices/authData.service";
import { statusCode } from "../variables/constant";

class AuthStore {
  userCredential = "";

  constructor() {
    this.businessRegisterForm = new BusinessRegisterForm();
    this.jobSeekerRegisterForm = new JobSeekerRegisterForm();
    this.loginForm = new LoginForm();
    this.forgotPasswordForm = new ForgotPasswordForm();
    this.resetPasswordForm = new ResetPasswordForm();
    this.generalRegisterForm = new GeneralRegisterForm();
    this.userCredential = "";
  }

  toggleShowPasswordBusiness() {
    if (this.businessRegisterForm.$("password").get("type") === "text") {
      this.businessRegisterForm.$("password").set("type", "password");
    } else {
      this.businessRegisterForm.$("password").set("type", "text");
    }
  }

  toggleShowConfirmPasswordBusiness() {
    if (this.businessRegisterForm.$("confirmPassword").get("type") === "text") {
      this.businessRegisterForm.$("confirmPassword").set("type", "password");
    } else {
      this.businessRegisterForm.$("confirmPassword").set("type", "text");
    }
  }

  toggleShowPasswordJobSeeker() {
    if (this.jobSeekerRegisterForm.$("password").get("type") === "text") {
      this.jobSeekerRegisterForm.$("password").set("type", "password");
    } else {
      this.jobSeekerRegisterForm.$("password").set("type", "text");
    }
  }

  toggleShowPasswordGeneral() {
    if (this.generalRegisterForm.$("password").get("type") === "text") {
      this.generalRegisterForm.$("password").set("type", "password");
    } else {
      this.generalRegisterForm.$("password").set("type", "text");
    }
  }

  toggleShowConfirmPasswordJobSeeker() {
    if (
      this.jobSeekerRegisterForm.$("confirmPassword").get("type") === "text"
    ) {
      this.jobSeekerRegisterForm.$("confirmPassword").set("type", "password");
    } else {
      this.jobSeekerRegisterForm.$("confirmPassword").set("type", "text");
    }
  }

  toggleShowConfirmPasswordGeneral() {
    if (this.generalRegisterForm.$("confirmPassword").get("type") === "text") {
      this.generalRegisterForm.$("confirmPassword").set("type", "password");
    } else {
      this.generalRegisterForm.$("confirmPassword").set("type", "text");
    }
  }

  toggleShowResetPassword() {
    if (this.resetPasswordForm.$("password").get("type") === "text") {
      this.resetPasswordForm.$("password").set("type", "password");
    } else {
      this.resetPasswordForm.$("password").set("type", "text");
    }
  }

  toggleShowConfirmResetPassword() {
    if (this.resetPasswordForm.$("confirmPassword").get("type") === "text") {
      this.resetPasswordForm.$("confirmPassword").set("type", "password");
    } else {
      this.resetPasswordForm.$("confirmPassword").set("type", "text");
    }
  }

  login = (cred, isBu) => {
    return login(cred, isBu)
      .then(
        action(res => {
          if (res && res.data) {
            this.userCredential = res.data;
            stores.commonStore.setAppLoading();
            stores.userStore.setUserToken(res.data, cred.rememberMe);
            stores.userStore.getCurrentUser();
            return res.data;
          }
        })
      )
      .catch(err => {
        console.log("login err ", err);
      });
  };



  forgotPassword(data) {
    return forgotPassword(data);
  }

  resetPassword(data) {
    return resetPassword(data);
  }

  sendOtp(data) {
    return sendOtpApi(data).then(res => res.data);
  }

  verifyOtp(data) {
    return verifyOtpApi(data).then(res => res.data);
  }

  registerJobSeeker(newUser) {
    return registerJobSeeker(newUser).then(
      action(res => {
        stores.commonStore.setAppLoading();
        stores.userStore.setUserToken(res.data, true);
        stores.userStore.getCurrentUser();
      })
    );
  }

  registerBusiness(newUser) {
    return registerBusiness(newUser).then(
      action(res => {
        stores.commonStore.setAppLoading();
        stores.userStore.setUserToken(res.data, true);
        stores.userStore.setIsNewRegister(true);
        stores.userStore.getCurrentUser();
      })
    );
  }

  registerGeneral(newUser) {
    return registerGeneral(newUser).then(
      action(res => {
        if (res.status === statusCode.success) {
          stores.commonStore.setAppLoading();
          stores.userStore.setUserToken(res.data, true);
          stores.userStore.getCurrentUser();
        }
        return res;
      })
    );
  }

  validateUsers(data) {
    return validateUsers(data).then(res => res.data);
  }

  logout() {
    this.userCredential = null;
    stores.userStore.logout();
  }
}

decorate(AuthStore, {
  businessRegisterForm: observable,
  userCredential: observable,
  jobSeekerRegisterForm: observable,
  forgotPasswordForm: observable,
  resetPasswordForm: observable,
  toggleShowPasswordBusiness: action,
  toggleShowConfirmPasswordBusiness: action,
  toggleShowConfirmPasswordJobSeeker: action,
  toggleShowPasswordJobSeeker: action,
  toggleShowPasswordGeneral: action,
  toggleShowResetPassword: action,
  toggleShowConfirmResetPassword: action,
  login: action,
  forgotPassword: action,
  resetPassword: action,
  registerBusiness: action,
  registerJobSeeker: action,
  logout: action,
  registerGeneral: action,
  toggleShowConfirmPasswordGeneral: action,
  sendOtp: action,
  verifyOtp: action,
  validateUsers: action
});

export default new AuthStore();
