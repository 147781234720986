import requests from "../http";
import {
  getPostsAPI,
  getPostsAuthAPI,
  getCategoriesAPI,
  getMakesAPI,
  getPostsByIdAPI,
  getAnswersByPostIdAPI,
  addAnswerAPI,
  addPostAPI,
  toggleLikeAPI,
  getAnswersByPostIdAuthAPI,
  updatePostAPI,
  updateAnswerAPI,
  updateViewQuestionAPI,
  uploadImageCreatePostApi,
  disableQuestionAPI,
  getAnswersByIdAPI,
  getSeasonAPI
} from "../dataServices/postData.service";

const getPosts = params => {
  return getPostsAPI(params);
};

const getPostById = (id, ownerUserId) => {
  return getPostsByIdAPI(id, ownerUserId);
};

const getAnswersById = id => {
  return getAnswersByIdAPI(id);
};
const getAnswersByPostId = id => {
  return getAnswersByPostIdAPI(id);
};

const getAnswersByPostIdAuth = id => {
  return getAnswersByPostIdAuthAPI(id);
};

const getCategories = params => {
  return getCategoriesAPI(params);
};

const getMakes = params => {
  return getMakesAPI(params);
};

const addAnswer = (twSeasonId, twQuestionId, content) => {
  return addAnswerAPI(twSeasonId, twQuestionId, content);
};

const updateAnswer = (answerId, content) => {
  return updateAnswerAPI(answerId, content);
};

const addPost = body => {
  return addPostAPI(body);
};

const updatePost = (postId, body) => {
  return updatePostAPI(postId, body);
};

const toggleLike = body => {
  return toggleLikeAPI(body);
};

const getPostsAuth = body => {
  return getPostsAuthAPI(body);
};
const updateViewQuestion = body => {
  return updateViewQuestionAPI(body);
};

const uploadImageCreatePost = (postId, formData) => {
  return uploadImageCreatePostApi(postId, formData);
};

const disableQuestion = (postId, body) => {
  return disableQuestionAPI(postId, body);
};

const getSeason = () => {
  return getSeasonAPI();
};

export {
  getPosts,
  getPostsAuth,
  getCategories,
  getMakes,
  getPostById,
  getAnswersByPostId,
  getAnswersByPostIdAuth,
  addAnswer,
  updateAnswer,
  addPost,
  updatePost,
  toggleLike,
  updateViewQuestion,
  uploadImageCreatePost,
  disableQuestion,
  getAnswersById,
  getSeason
};
