import requests from "../http";

const getStateApi = () => requests.get("/api/static/location/states");
const getCountiesByStateApi = state =>
  requests.get(`/api/static/location/counties?state=${state}`);
const getCitiesByStateApi = state =>
  requests.get(`/api/static/location/cities?state=${state}`);
const getFunctionsApi = () => requests.get("/api/static/common_list/functions");
const getWorkProcessesApi = () =>
  requests.get("/api/static/common_list/work_process");
const getJobTypesApi = () => requests.get("/api/static/common_list/job_types");
const getCarTypesApi = () => requests.get("/api/static/common_list/car_types");
const getAutotechLevelsApi = () =>
  requests.get("/api/static/common_list/autotech_levels");
const getBodytechTypessApi = () =>
  requests.get("/api/static/common_list/bodytech_types");
const getBusinessTypesApi = () =>
  requests.get("/api/static/common_list/business_types");
const getEducationTypesApi = () =>
  requests.get("/api/static/common_list/education_levels");
const getToolSetApi = () => requests.get("/api/static/common_list/tool_set");
const getKeyBenefitOfferApi = () =>
  requests.get("/api/static/common_list/key_benefit_offer");
const getAppConfigApi = () => requests.get("/api/static/app_config");
const getSalesRepApi = () => requests.get("/api/static/sales_rep");
const getContentGettingStartedApi = () =>
  requests.get("/api/static/page_contents/getting_started_business");
const getContentFAQApi = () =>
  requests.get("/api/static/page_contents/faqs_business");
const getContentContactApi = () =>
  requests.get("/api/static/page_contents/contact_us");
const getContentTermConditionsApi = () =>
  requests.get("/api/static/page_contents/terms_and_conditions");
const getContentPrivacyPolicyApi = () =>
  requests.get("/api/static/page_contents/privacy_policy");
const getContentBreachOfBusinessApi = () =>
  requests.get("/api/static/page_contents/breach_of_business_contract_terms");

const getContentAgreementApi = code =>
  requests.get(`/api/static/agreements/${code}`);

const sendFeedbackApi = data => {
  delete data.submitting;
  return requests.post("/api/feedback", {
    ...data,
    can_contact: data.can_contact === "yes"
  });
};

const sendFormFeedbackApi = body =>
  requests.post("/api/v2/general_feedback/create", body);

const sendFormReportApi = body => requests.post("/api/v2/tw_flag/create", body);

const getGameRuleApi = () =>
  requests.get(`/api/static/page_contents/tw-game-rules`);

export {
  sendFormFeedbackApi,
  sendFormReportApi,
  getStateApi,
  getCountiesByStateApi,
  getCitiesByStateApi,
  getBodytechTypessApi,
  getFunctionsApi,
  getJobTypesApi,
  getCarTypesApi,
  getBusinessTypesApi,
  getEducationTypesApi,
  getWorkProcessesApi,
  getAutotechLevelsApi,
  getToolSetApi,
  getAppConfigApi,
  getContentGettingStartedApi,
  getContentFAQApi,
  getContentContactApi,
  getContentTermConditionsApi,
  getContentPrivacyPolicyApi,
  getContentBreachOfBusinessApi,
  getContentAgreementApi,
  sendFeedbackApi,
  getSalesRepApi,
  getKeyBenefitOfferApi,
  getGameRuleApi
};
