import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Button from "../../../components/CustomButtons/Button";
import appConstants from "../../../constants/app.constant";
import { ReactComponent as IconCloseGrey } from "../../../assets/img/icons/icon_close_grey.svg";

import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor,
  noSidePadding,
  hexToRgb,
  successColor
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 630,
    maxWidth: "100%",
    transform: `translate(-50%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
    overflow: "auto",
    maxHeight: "90vh"
  },
  closeIcon: {
    position: "absolute",
    top: 13,
    right: 16,
    width: 18,
    height: 18,
    textAlign: "center",
    cursor: "pointer"
  },
  modalTitle: {
    margin: "10px auto 10px",
    fontFamily: "Inter",
    fontSize: 19,
    lineHeight: "26px",
    fontWeight: 700,
    color: blackColor
  },
  modalContent: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    color: blackColor,
    margin: "10px auto 16px",
    "& p": {
      marginBottom: 5
    }
  }
});

class ModalGameRules extends React.Component {
  constructor(props) {
    super(props);
    this.props.commonStore.getGameRuleFromApi();
  }

  render() {
    const { classes, open, history } = this.props;
    const { gameRules } = this.props.commonStore;

    return (
      <div className={classes.modalRequireLogin}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={() => this.props.commonStore.updateModalGameRules(false)}
        >
          <div className={classes.paper}>
            <div
              className={classes.closeIcon}
              onClick={() => this.props.commonStore.updateModalGameRules(false)}
            >
              <IconCloseGrey />
            </div>
            <Typography className={classes.modalTitle}>Game Rules</Typography>
            <div
              className={classes.modalContent}
              dangerouslySetInnerHTML={{
                __html: gameRules
              }}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(ModalGameRules))
  )
);
