import React from "react";
import { withRouter } from "react-router-dom";

import WifiTethering from "@material-ui/icons/WifiTethering";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import notificationService from "services/notificationService";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";

import { withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import RequestFormUI from "./components/RequestFormUI";
import BackPreviousPageButton from "../../components/BackPreviousPageButton";
import { checkParagraphNotContainsStrings } from "services/utils";
import config from "config";

const styles = {
  ...sweetAlertStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  justifyContentSpaceBetween: {
    justifyContent: "space-between"
  },
  ml10: {
    marginLeft: "10px"
  },
  mr10: {
    marginRight: "10px"
  }
};

class EditRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      warningModal: null,
      submittingSend: false,
      submittingSave: false,
      keyOffers: []
    };
    this.props.requestsEmployeeStore.reset();
  }

  get requestId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    this.props.requestsEmployeeStore.getRequestById(this.requestId).then(() => {
      const { requestForm, selectedRequest } = this.props.requestsEmployeeStore;
      const fields = [
        "function",
        "other_function",
        "autotech_level",
        "bodytech_type",
        "job_type",
        "years_of_experience",
        "geolocation.state",
        "geolocation.city",
        "car_types",
        "must_bring_tools",
        "additional_comment",
        "rate_from",
        "rate_to",
        "paytype",
        "expect_work_on_weekends",
        "start_date",
        "end_date",
        "work_start_time",
        "work_start_time_a",
        "work_day_hours",
        "rate"
      ];
      fields.forEach(field => {
        if (requestForm.$(field)) {
          requestForm.$(field).set("disabled", true);
        }
      });
      if (selectedRequest.key_benefit_offer) {
        this.setState({
          keyOffers: selectedRequest.key_benefit_offer
        });
      }
    });
  }

  componentWillUnmount() {
    this.props.requestsEmployeeStore.reset();
  }

  enableEditMode = () => {
    this.setState({
      editMode: true
    });
    const { requestForm } = this.props.requestsEmployeeStore;
    const fields = [
      "function",
      "other_function",
      "autotech_level",
      "bodytech_type",
      "job_type",
      "years_of_experience",
      "geolocation.state",
      "geolocation.city",
      "car_types",
      "must_bring_tools",
      "additional_comment",
      "rate_from",
      "rate_to",
      "paytype",
      "expect_work_on_weekends",
      "start_date",
      "end_date",
      "work_start_time",
      "work_start_time_a",
      "rate",
      "work_day_hours"
    ];
    fields.forEach(field => {
      if (requestForm.$(field)) {
        requestForm.$(field).set("disabled", false);
      }
    });
  };

  closeWarningModal = () => {
    this.setState({
      warningModal: null
    });
  };

  checkValidRequest = type => () => {
    const { requestForm } = this.props.requestsEmployeeStore;
    requestForm.$("status").set(type);
    if (requestForm.$("job_type").value === "temporary") {
      requestForm.has("rate_from") && requestForm.del("rate_from");
      requestForm.has("rate_to") && requestForm.del("rate_to");
      requestForm.has("paytype") && requestForm.del("paytype");
    } else {
      requestForm.has("rate") && requestForm.del("rate");
      requestForm.has("start_date") && requestForm.del("start_date");
      requestForm.has("end_date") && requestForm.del("end_date");
      requestForm.has("work_start_time") && requestForm.del("work_start_time");
    }
    requestForm.validate();
    requestForm.showErrors();
    const { currentUser } = this.props.userStore;
    const { preferences } = currentUser;
    const { business_name } = preferences;
    const invalidStrings = [
      config.pattern.url,
      config.pattern.phone,
      config.pattern.email,
      business_name
    ];
    const isValid = checkParagraphNotContainsStrings(
      requestForm.$("additional_comment").value,
      invalidStrings
    );

    const requestHandler =
      type === "saved" ? this.saveRequest : this.sendRequest;

    if (!isValid) {
      this.setState({
        warningModal: (
          <SweetAlert
            warning
            title=""
            onConfirm={this.closeWarningModal}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText="Ok"
          >
            Providing business or contact information prior to hiring an
            employee is a breach of our Terms & Conditions of use. Repeated
            attempts may result in your account being suspended.
          </SweetAlert>
        )
      });
    } else {
      requestHandler();
    }
  };

  handleUpdateKeyOffer = key => {
    const { keyOffers } = this.state;
    if (keyOffers.includes(key)) {
      this.setState({
        keyOffers: keyOffers.filter(item => item !== key)
      });
      return;
    }
    this.setState({ keyOffers: [...keyOffers, key] });
  };

  sendRequest = () => {
    this.closeWarningModal();
    const { requestForm, updateRequest } = this.props.requestsEmployeeStore;
    requestForm.submit({
      onSuccess: form => {
        const data = {
          ...form.values(),
          key_benefit_offer: this.state.keyOffers
        };
        if (data.numberOfDays === 0) {
          return notificationService.error(
            "REQUEST.NUMBER_WORK_DAY_MUST_GREATER_THAN_ZERO"
          );
        }
        if(this.state.location){
          data.geolocation.city = this.state.location.city
          data.geolocation.state = this.state.location.state
        }
        this.setState({ submittingSend: true });
        updateRequest(this.requestId, data).then(
          () => {
            notificationService.success("REQUEST.SENT_REQUEST");
            this.setState({ submittingSend: false });
            this.props.history.push("/request/sent");
          },
          () => {
            this.setState({ submittingSend: false });
          }
        );
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  saveRequest = () => {
    this.closeWarningModal();
    const { requestForm, updateRequest } = this.props.requestsEmployeeStore;
    requestForm.submit({
      onSuccess: form => {
        const data = {
          ...form.values(),
          key_benefit_offer: this.state.keyOffers
        };
        if (data.numberOfDays === 0) {
          return notificationService.error(
            "REQUEST.NUMBER_WORK_DAY_MUST_GREATER_THAN_ZERO"
          );
        }
        if(this.state.location){
          data.geolocation.city = this.state.location.city
          data.geolocation.state = this.state.location.state
        }
        this.setState({ submittingSave: true });
        updateRequest(this.requestId, data).then(
          () => {
            notificationService.success("REQUEST.SAVED_REQUEST");
            this.setState({ submittingSave: false });
            this.props.history.push("/request/saved");
          },
          () => {
            this.setState({ submittingSave: false });
          }
        );
      },
      onError: form => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    });
  };

  closeRequest = () => {
    const { requestForm, closeRequest } = this.props.requestsEmployeeStore;
    closeRequest(requestForm.$("id").value).then(() => {
      notificationService.success("REQUEST.CLOSED_REQUEST");
      this.props.history.push("/request/sent");
    });
  };

  get isSentRequest() {
    return this.props.history.location.pathname.includes("sent");
  }
  handleUpdateLocation = ({ city, state }) => {
    this.setState({
      location: {
        city: city,
        state: state
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { requestForm, selectedRequest } = this.props.requestsEmployeeStore;
    const {
      carTypes,
      workProcesses,
      autotechLevels,
      jobTypes,
      functions,
      bodyTechTypes,
      benefitOffer
    } = this.props.commonStore;
    const { currentUser } = this.props.userStore;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <WifiTethering />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Requests {this.isSentRequest ? "Sent" : "Saved"}
              </h4>
            </CardHeader>
            <CardBody>
              {selectedRequest.id && (
                <RequestFormUI
                  requestForm={requestForm}
                  carTypes={carTypes}
                  profileCarTypes={currentUser.preferences.car_types}
                  editMode={this.state.editMode}
                  workProcesses={workProcesses}
                  bodyTechTypes={bodyTechTypes}
                  autotechLevels={autotechLevels}
                  jobTypes={jobTypes}
                  functions={functions}
                  benefitOffer={benefitOffer}
                  handleUpdateKeyOffer={this.handleUpdateKeyOffer}
                  selectedKeyOffers={selectedRequest.key_benefit_offer}
                  location={this.state.location}
                  handleUpdateLocation={this.handleUpdateLocation}
                />
              )}
              {this.state.warningModal}
            </CardBody>
            {selectedRequest.id && (
              <CardFooter className={classes.justifyContentSpaceBetween}>
                <BackPreviousPageButton />
                {selectedRequest.status === "sent" ? (
                  <Button color="primary" onClick={this.closeRequest}>
                    Cancel request
                  </Button>
                ) : this.state.editMode ? (
                  <div>
                    <Button
                      color="primary"
                      className={classes.mr10}
                      onClick={this.checkValidRequest("sent")}
                      loading={this.state.submittingSend}
                      disabled={
                        this.state.submittingSend || this.state.submittingSave
                      }
                    >
                      Post Job
                    </Button>
                    <Button
                      color="info"
                      className={classes.ml10}
                      onClick={this.checkValidRequest("saved")}
                      loading={this.state.submittingSave}
                      disabled={
                        this.state.submittingSend || this.state.submittingSave
                      }
                    >
                      Save
                    </Button>
                  </div>
                ) : !this.isSentRequest ? (
                  <Button color="primary" onClick={this.enableEditMode}>
                    {" "}
                    Edit & Send new{" "}
                  </Button>
                ) : (
                  ""
                )
                // (<Button color="primary" disabled={true}> Repost </Button>) TODO NEW FEATURE
                }
              </CardFooter>
            )}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(
  inject("requestsEmployeeStore", "commonStore", "userStore")(
    withRouter(observer(EditRequest))
  )
);
