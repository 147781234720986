import {
  getStateApi,
  getCountiesByStateApi,
  getCitiesByStateApi,
  getFunctionsApi,
  getJobTypesApi,
  getCarTypesApi,
  getBusinessTypesApi,
  getEducationTypesApi,
  getWorkProcessesApi,
  getAutotechLevelsApi,
  getToolSetApi,
  getBodytechTypessApi,
  getAppConfigApi,
  getContentGettingStartedApi,
  getContentFAQApi,
  getContentContactApi,
  sendFeedbackApi,
  getSalesRepApi,
  getContentTermConditionsApi,
  getContentPrivacyPolicyApi,
  getContentBreachOfBusinessApi,
  getContentAgreementApi,
  getKeyBenefitOfferApi,
  sendFormFeedbackApi,
  sendFormReportApi,
  getGameRuleApi
} from "../dataServices/commonData.service";

const getState = () => getStateApi();

const getCountiesByState = state => getCountiesByStateApi(state);

const getCitiesByState = state => getCitiesByStateApi(state);

const getFunctions = () => getFunctionsApi();

const getWorkProcesses = () => getWorkProcessesApi();

const getJobTypes = () => getJobTypesApi();

const getCarTypes = () => getCarTypesApi();

const getBusinessTypes = () => getBusinessTypesApi();

const getEducationTypes = () => getEducationTypesApi();

const getAutotechLevels = () => getAutotechLevelsApi();

const getToolSet = () => getToolSetApi();

const getBenefitOffer = () => getKeyBenefitOfferApi();

const getBodytechTypes = () => getBodytechTypessApi();

const getAppConfig = () => getAppConfigApi();

const getContentGettingStarted = () => getContentGettingStartedApi();

const getContentFAQ = () => getContentFAQApi();

const getContentBreachOfBusiness = () => getContentBreachOfBusinessApi();

const getContentPrivacyPolicy = () => getContentPrivacyPolicyApi();

const getContentTermConditions = () => getContentTermConditionsApi();

const getContentContact = () => getContentContactApi();

const getContentAgreement = code => getContentAgreementApi(code);

const sendFeedback = data => sendFeedbackApi(data);

const getSalesRep = () => getSalesRepApi();

const sendFeedbackFromApi = data => sendFormFeedbackApi(data);

const sendReportFromApi = data => sendFormReportApi(data);

const getGameRule = () => getGameRuleApi();

export {
  sendFeedbackFromApi,
  sendReportFromApi,
  getState,
  getCountiesByState,
  getCitiesByState,
  getFunctions,
  getWorkProcesses,
  getJobTypes,
  getCarTypes,
  getBusinessTypes,
  getEducationTypes,
  getAutotechLevels,
  getToolSet,
  getBodytechTypes,
  getAppConfig,
  getContentGettingStarted,
  getContentFAQ,
  getContentContact,
  getContentBreachOfBusiness,
  getContentPrivacyPolicy,
  getContentTermConditions,
  getContentAgreement,
  sendFeedback,
  getSalesRep,
  getBenefitOffer,
  getGameRule
};
