import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import classNames from "classnames";

import Button from "../../../components/CustomButtons/Button";
import { ReactComponent as IconChecked } from "../../../assets/img/icons/icon_filter_checked.svg";

import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  filterItem: {
    background: "transparent",
    marginTop: -1
  },
  title: {
    fontSize: 16,
    fontWeight: 700
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400
  },
  summaryTitle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  expansionPanelExpanded: {
    borderRadius: `0 !important`,
    background: "#F7F7F7",
    boxShadow: "none",
    border: `1px solid ${grayColor[8]}`
  },
  expansionPanelDetails: {
    padding: `10px 10px`,
    maxHeight: 200,
    overflow: "auto",
    "& ul": {
      margin: 0,
      padding: 0,
      width: "100%"
    },
    "& li, & a": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: `5px 10px`,
      cursor: "pointer",
      userSelect: "none",
      fontSize: 16,
      fontWeight: 400
    },
    "& li:hover": {
      color: primaryColor[0]
    }
  },
  expansionPanelSummary: {
    background: whiteColor
  }
});

class FilterItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "--Select--" };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    const { isClearFilter } = this.props.postsStore;
    if (prevState.name !== "--Select--") {
      if (isClearFilter) {
        this.setState({ name: "--Select--" });
      }
    }
  }

  handleChange = () => {};

  toggle = (name, active) => {
    this.props.postsStore.toggle(name, active);
  };

  subTitle = subTitle => {
    return <span>{subTitle}</span>;
  };

  onItemFilterClick = (name, key, url, label) => {
    const { history } = this.props;
    this.props.postsStore.toggleFilterItem(name, key);
    this.setState({ name: label });
    if (url) history.push(url);
  };

  getListItem = (items, name, url) => {
    if (items && items.length > 0) {
      return items.map((item, key) => (
        <li
          key={key}
          onClick={() => this.onItemFilterClick(name, key, url, item.label)}
        >
          <span>{item.label}</span>
          {item.checked && (
            <span className="checkStatus">
              <IconChecked />
            </span>
          )}
        </li>
      ));
    }
  };

  render() {
    const { redirectUrl, classes, filterItem } = this.props;
    const { name } = this.state;

    return (
      <div className={classes.filterItem}>
        <ExpansionPanel
          expanded={filterItem.active}
          onChange={this.handleChange()}
          className={classes.expansionPanelExpanded}
        >
          <ExpansionPanelSummary
            onClick={e => this.toggle(filterItem.name, filterItem.active)}
            expandIcon={<ExpandMore />}
            className={classes.expansionPanelSummary}
          >
            <div className={classes.summaryTitle}>
              <Typography className={classes.title} color="textPrimary">
                {filterItem.title}
              </Typography>
              <Typography className={classes.subTitle} color="textSecondary">
                {name}
              </Typography>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <ul>
              {this.getListItem(filterItem.items, filterItem.name, redirectUrl)}
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

FilterItem.propTypes = {
  redirectUrl: PropTypes.string,
  filterItem: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(FilterItem))
  )
);
