import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import uploadImageIcon from "../../assets/img/uploadImage.png";
import deleteIcon from "../../assets/img/deleteIcon.png";

import { FormHelperText, MenuItem } from "@material-ui/core";
import Breadcrumbs from "../../common/components/breadcrumbs/breadcrumbs";
import Loading from "components/Loading/Loading";
import { appBreadcrumbs } from "../../constants/app.constant";
import Button from "../../components/CustomButtons/Button";
import PageLayout from "../../layouts/pageLayout/pageLayout";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FilledInput from "@material-ui/core/FilledInput";
import InputLabel from "@material-ui/core/InputLabel";
import {
  container,
  primaryColor,
  whiteColor,
  selectDropdown,
  blackColor
} from "../../assets/jss/material-dashboard-pro-react";
import { ReactComponent as IconDropdown } from "../../assets/img/icons/icon_dropdown_select.svg";

import Compress from "compress.js";
import notificationService from "../../services/notificationService";
import { ModalContent } from "../../common/components/modals/modalImageUpload";

const MAXIMUM_FILE_SIZE = 1048576;

const styles = theme => ({
  container,
  pageSubTitle: {
    fontSize: 16,
    lineHeight: "19px",
    fontWeight: "bold",
    color: "#000"
  },
  titleBar: {
    marginBottom: 16
  },
  contentField: {
    paddingTop: 12
  },
  createPost: {
    width: 700,
    maxWidth: "100%",
    margin: "0 auto"
  },
  formCreatePost: {
    background: whiteColor,
    padding: 24,
    marginBottom: 30
  },
  filterField: {
    paddingBottom: 8,
    marginBottom: 16,
    borderBottom: `1px solid #EEEEEE`
  },
  formField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  formFieldLabel: {
    flex: "0 0 80px",
    marginRight: 30,
    marginBottom: 16,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    color: blackColor
  },
  formFieldInput: {
    flex: "1 0 auto",
    marginBottom: 16,
    "& > div": {
      width: "100%"
    }
  },
  select: selectDropdown.select,
  label: selectDropdown.label,
  titleText: {
    width: "100%",
    height: "79px",
    padding: "16px",
    margin: "0 0 10px 0",
    fontFamily: "Inter",
    fontSize: 16,
    lineHeight: "1.5em",
    border: `1px solid #ECECEC`,
    resize: "none",
    overflow: "auto"
  },
  contentText: {
    width: "100%",
    height: "236px",
    padding: "16px",
    margin: "0 0 10px 0",
    fontFamily: "Inter",
    fontSize: 16,
    lineHeight: "1.5em",
    border: `1px solid #ECECEC`,
    resize: "none",
    overflow: "auto"
  },
  formActions: {
    display: "flex",
    alignItem: "center",
    justifyContent: "flex-end"
  },
  listItem: {
    padding: 0
  },
  item: {
    "&:hover": {
      background: "none",
      color: primaryColor[0]
    }
  },
  flx: { display: "flex" },
  //style uploadFile
  descUploadImg: {
    marginLeft: 10,
    fontWeight: 300
  },
  inputFile: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: " absolute",
    zIndex: "-1"
  },
  inputFileLabel: {
    width: "100%",
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 12px",
    border: "2px dotted #ECECEC",
    flexDirection: "column"
  },
  uploadIcon: {
    width: 48,
    height: 48
  },
  uploadFileContent: {
    fontWeight: 400,
    fontSize: 16,
    color: "#6A6E77"
  },
  uploadFileDesc: {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: 14
  },
  //style itemUpload
  itemUploadWrap: {
    width: "50%",
    border: "1px solid #ECECEC",
    display: "inline-block"
  },

  itemUploadImg: {
    display: "flex",
    padding: "15px 10px",
    height: 80,
    alignItems: "center",
    position: "relative"
  },
  itemImg: {
    width: 50,
    height: 50,
    cursor: "pointer",
    objectFit: "cover"
  },
  itemUploadDesc: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 15,
    fontSize: 14
  },
  itemUploadName: {
    fontWeight: 400,
    color: "#1890FF"
  },
  iconDelete: {
    position: "absolute",
    right: 10,
    width: 18,
    cursor: "pointer"
  }
});

const compress = new Compress();

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      twCategoryId: "",
      year: "",
      twMakeId: "",
      title: "",
      content: "",
      imageFilesPreview: [],
      oldImageFilesFromAPI: [],
      imageFilesUpload: [],
      showModalImageUpload: false,
      urlImageSelected: "",
      isChangeFileImageUpload: false,
      categoryError: false,
      makeError: false,
      yearError: false
    };
  }

  componentDidMount() {
    this.getDataPost();
  }

  getDataPost = async () => {
    this.setState({
      isLoading: true
    });
    const { id } = this.props.match.params;
    await this.props.postsStore.getPostByIdFromApi(id);
    console.log(
      "this.props.postsStore.currentPost",
      this.props.postsStore.currentPost
    );
    this.setState({ title: this.props.postsStore.currentPost.title });
    this.setState({ content: this.props.postsStore.currentPost.content });
    this.setState({ year: this.props.postsStore.currentPost.year || "" });
    this.setState({
      twCategoryId:
        (this.props.postsStore.currentPost &&
          this.props.postsStore.currentPost.tw_category_id &&
          this.props.postsStore.currentPost.tw_category_id.id) ||
        ""
    });
    this.setState({
      twMakeId:
        (this.props.postsStore.currentPost &&
          this.props.postsStore.currentPost.tw_make_id &&
          this.props.postsStore.currentPost.tw_make_id.id) ||
        ""
    });
    this.setState({
      imageFilesPreview: [...this.props.postsStore.currentPost.images]
    });
    this.setState({
      oldImageFilesFromAPI: [...this.props.postsStore.currentPost.images]
    });
    this.props.postsStore.updateFormUpdatepost({
      twCategoryId: this.state.twCategoryId,
      twMakeId: this.state.twMakeId,
      year: this.state.year,
      content: this.state.content,
      title: this.state.title
    });
    this.setState({
      isLoading: false
    });
  };

  handleBackClick = e => {
    e.preventDefault();
    this.props.history.goBack();
  };

  handleChange = (e, name) => {
    name === "twCategoryId" &&
      e.target.value &&
      this.setState({ categoryError: false });
    name === "twMakeId" &&
      e.target.value &&
      this.setState({ makeError: false });
    name === "year" && e.target.value && this.setState({ yearError: false });
    this.setState({ [name]: e.target.value });
    this.props.postsStore.updateFormUpdatepost({ [name]: e.target.value });
  };

  componentWillUnmount() {
    // this.props.postsStore.clearFormCreatepost();
  }

  onSubmit = async e => {
    e.preventDefault();

    const {
      imageFilesUpload,
      oldImageFilesFromAPI,
      isChangeFileImageUpload
    } = this.state;

    const {
      twCategoryId,
      twMakeId,
      year
    } = this.props.postsStore.formUpdatePost;

    const { id } = this.props.match.params;
    !twCategoryId && this.setState({ categoryError: true });
    !twMakeId && this.setState({ makeError: true });
    !year && this.setState({ yearError: true });
    const postId =
      this.props.postsStore &&
      this.props.postsStore.currentPost &&
      this.props.postsStore.currentPost.id;

    if (twCategoryId && twMakeId && year) {
      let formData;
      formData = new FormData();
      this.setState({
        isLoading: true
      });
      if (imageFilesUpload.length > 0) {
        for (const image of imageFilesUpload) {
          formData.append("files", image);
        }
      }
      if (oldImageFilesFromAPI.length > 0) {
        for (const oldImage of oldImageFilesFromAPI) {
          formData.append("keepFiles", oldImage);
        }
      }
      isChangeFileImageUpload
        ? await this.props.postsStore.updatePostFormApiWithImage(id, formData)
        : await this.props.postsStore.updatePostFormApi(id);
      await this.props.postsStore.getPostsFromApi();
      this.setState({
        isLoading: false
      });
      this.props.history.push(`/tech-wizard/post/${postId}`);
    }
  };

  handleCompressFileImage = fileImg => {
    const fileExceedSizeList = [];
    fileImg.forEach(item => {
      if (item.size > MAXIMUM_FILE_SIZE) {
        fileExceedSizeList.push(item);
      } else {
        this.setState(prevState => ({
          imageFilesUpload: [...prevState.imageFilesUpload, item]
        }));
      }
    });

    if (fileExceedSizeList.length > 0) {
      compress
        .compress(fileExceedSizeList, {
          size: 1, // the max size in MB, defaults to 2MB
          resize: true, // defaults to true, set false if you do not want to resize the image width and height
          rotate: false, // See the rotation section below,
          quality: 1, // the quality of the image, max is 1,
          maxWidth: 1000, // the max width of the output image, defaults to 1920px
          maxHeight: 1000 // the max height of the output image, defaults to 1920px
        })
        .then(data => {
          // returns an array of compressed images
          console.log(data);
          const fileType = data.map((item, i) => {
            const endFile = Compress.convertBase64ToFile(item.data, item.ext);
            const fileConverted = new File(
              [endFile],
              fileExceedSizeList[i].name,
              {
                type: endFile.type
              }
            );
            return fileConverted;
          });
          this.setState(prevState => ({
            imageFilesUpload: [...prevState.imageFilesUpload, ...fileType]
          }));
        });
    }
  };

  handleOnChangeUploadImages = async e => {
    const { imageFilesPreview } = this.state;
    const fileImg = [...e.target.files];
    const selectedFiles = [];
    const lengthFileImg = fileImg.length;
    const lengthImageFilesPreview = imageFilesPreview.length;
    this.setState({
      isChangeFileImageUpload: true
    });
    //validate upload image format
    for (let i = 0; i < lengthFileImg; i++) {
      if (!fileImg[i].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
        notificationService.error(
          "Only files with the following extensions are allowed: png jpg jpeg"
        );
        return;
      }
      if (lengthFileImg > 5 || lengthImageFilesPreview + lengthFileImg > 5) {
        notificationService.error("Can't upload more than 5 images");
        return;
      }
      selectedFiles.push({
        urlImage: URL.createObjectURL(fileImg[i]),
        files: fileImg[i]
      });
      this.setState({
        imageFilesPreview: [...imageFilesPreview, ...selectedFiles]
      });
    }
    this.handleCompressFileImage(fileImg);
  };

  handleDeleteUploadImages = imageIndex => {
    const {
      imageFilesPreview,
      imageFilesUpload,
      oldImageFilesFromAPI
    } = this.state;
    this.setState({
      isChangeFileImageUpload: true
    });
    imageFilesPreview.splice(imageIndex, 1);
    imageFilesUpload.splice(imageIndex, 1);

    oldImageFilesFromAPI.splice(imageIndex, 1);
    this.setState({
      imageFilesPreview: [...imageFilesPreview],
      imageFilesUpload: [...imageFilesUpload],
      oldImageFilesFromAPI: [...oldImageFilesFromAPI]
    });
  };

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isChangeFileImageUpload: true
    });
    const dt = e.dataTransfer;
    const { files } = dt;
    this.handleFiles(files);
  };

  handleFiles = async files => {
    const { imageFilesPreview } = this.state;
    const fileImg = [...files];
    const lengthImageFilesPreview = imageFilesPreview.length;
    const lengthFileImg = fileImg.length;

    for (let i = 0; i < lengthFileImg; i++) {
      if (!files[i].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
        notificationService.error(
          "Only files with the following extensions are allowed: png jpg jpeg"
        );
        return;
      }
      if (lengthFileImg > 5 || lengthImageFilesPreview + lengthFileImg > 5) {
        notificationService.error("Can't upload more than 5 images");
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onloadend = () => {
        this.setState(prevState => ({
          imageFilesPreview: [
            ...prevState.imageFilesPreview,
            { urlImage: reader.result, files: files[i] }
          ]
        }));
      };
    }
    this.handleCompressFileImage(fileImg);
  };

  handleShowModalUploadImages = urlImage => {
    this.setState({
      showModalImageUpload: true,
      urlImageSelected: urlImage
    });
  };

  handleCloseModalUploadImages = () => {
    this.setState({
      showModalImageUpload: false
    });
  };

  renderFormatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  renderNameFileImage = img => {
    const nameImg = img.split("-");
    return nameImg[nameImg.length - 1];
  };

  render() {
    const { classes } = this.props;
    const { category, year, make } = this.props.postsStore;
    const {
      imageFilesPreview,
      showModalImageUpload,
      urlImageSelected,
      categoryError,
      yearError,
      makeError
    } = this.state;

    const PageSubTitle = () => {
      return (
        <Typography className={classes.pageSubTitle}>Edit post</Typography>
      );
    };

    const IconDrop = () => {
      return (
        <IconDropdown
          style={{
            position: "absolute",
            right: 20,
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none"
          }}
        />
      );
    };

    const checkValidform = () => {
      if (this.state.title && this.state.content) {
        return true;
      }
      return false;
    };

    return (
      <PageLayout pageTitle={false} sidebar={[]}>
        <div className={classes.createPost}>
          <Breadcrumbs items={appBreadcrumbs.editPost} />
          <div className={classes.titleBar}>
            <PageSubTitle />
          </div>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <div
              className={classes.formCreatePost}
              onDragEnter={e => this.handleDrag(e)}
              onDragLeave={e => this.handleDrag(e)}
              onDragOver={e => this.handleDrag(e)}
              onDrop={e => this.handleDrag(e)}
            >
              <form onSubmit={e => this.onSubmit(e)}>
                <div className={classes.filterField}>
                  <div className={classes.formField}>
                    <div className={classes.formFieldLabel}>Category</div>
                    <div className={classes.formFieldInput}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                        error={categoryError}
                      >
                        <InputLabel htmlFor="make" className={classes.label}>
                          Choose a category
                        </InputLabel>
                        <Select
                          value={this.state.twCategoryId}
                          className={classes.select}
                          onChange={e => this.handleChange(e, "twCategoryId")}
                          IconComponent={IconDrop}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          input={<FilledInput name="category" id="category" />}
                        >
                          {category.items.length > 0 &&
                            category.items.map((item, key) => {
                              return (
                                <MenuItem
                                  className={classes.item}
                                  key={key}
                                  value={item.id}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {categoryError && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.formField}>
                    <div className={classes.formFieldLabel}>Year</div>
                    <div className={classes.formFieldInput}>
                      <FormControl
                        variant="filled"
                        className={classes.label}
                        error={yearError}
                      >
                        <InputLabel htmlFor="year" className={classes.label}>
                          Choose a year
                        </InputLabel>
                        <Select
                          value={this.state.year}
                          className={classes.select}
                          onChange={e => this.handleChange(e, "year")}
                          IconComponent={IconDrop}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          input={<FilledInput name="year" id="year" />}
                        >
                          {year.items.length > 0 &&
                            year.items.map((item, key) => {
                              return (
                                <MenuItem
                                  className={classes.item}
                                  key={key}
                                  value={item.label}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {yearError && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.formField}>
                    <div className={classes.formFieldLabel}>Make</div>
                    <div className={classes.formFieldInput}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                        error={makeError}
                      >
                        <InputLabel htmlFor="make" className={classes.label}>
                          Choose a make
                        </InputLabel>
                        <Select
                          className={classes.select}
                          value={this.state.twMakeId}
                          color={"primary"}
                          onChange={e => this.handleChange(e, "twMakeId")}
                          IconComponent={IconDrop}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                          input={<FilledInput name="make" id="make" />}
                        >
                          {make.items.length > 0 &&
                            make.items.map((item, key) => {
                              return (
                                <MenuItem
                                  className={classes.item}
                                  key={key}
                                  value={item.id}
                                >
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        {makeError && (
                          <FormHelperText>
                            This field is required
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className={classes.contentField}>
                  <div className={classes.formFieldLabel}>Title</div>
                  <textarea
                    defaultValue={this.state.title}
                    className={classes.titleText}
                    placeholder="Ask Brief Question..."
                    onChange={e => this.handleChange(e, "title")}
                  />
                </div>
                <div className={classes.contentField}>
                  <div className={classes.formFieldLabel}>Your question</div>
                  <textarea
                    defaultValue={this.state.content}
                    className={classes.contentText}
                    placeholder="Provide specific details..."
                    onChange={e => this.handleChange(e, "content")}
                  />
                </div>
                <div className={classes.contentField}>
                  <div>
                    <div className={classes.formFieldLabel}>
                      Upload your images
                      <span className={classes.descUploadImg}>
                        {"(you can upload up to 5 files)"}
                      </span>
                    </div>
                  </div>
                  <div onDrop={e => this.handleDrop(e)}>
                    <input
                      type="file"
                      name="file-input"
                      id="file-input"
                      multiple
                      class={classes.inputFile}
                      onChange={this.handleOnChangeUploadImages}
                    />
                    <label class={classes.inputFileLabel} for="file-input">
                      <img
                        className={classes.uploadIcon}
                        src={uploadImageIcon}
                      />
                      <p className={classes.uploadFileContent}>
                        Drag and drop or{" "}
                        <span
                          style={{
                            color: "#F8971C"
                          }}
                        >
                          browse
                        </span>{" "}
                        to choose a file
                      </p>
                      <p className={classes.uploadFileDesc}>
                        JPG, JPEG or PNG, file size no more than 3MB
                      </p>
                    </label>
                  </div>
                </div>
                <div className={classes.contentField}>
                  {imageFilesPreview &&
                    imageFilesPreview.map((img, index) => (
                      <div key={index} className={classes.itemUploadWrap}>
                        <div className={classes.itemUploadImg}>
                          <img
                            className={classes.itemImg}
                            src={img.urlImage || img}
                            alt=""
                            onClick={() =>
                              this.handleShowModalUploadImages(
                                img.urlImage || img
                              )
                            }
                          />
                          <div className={classes.itemUploadDesc}>
                            {/* <span className={classes.itemUploadName}>
                              {img.files
                                ? img.files.name
                                : this.renderNameFileImage(img)}
                            </span> */}
                            <span>
                              {img.files
                                ? this.renderFormatBytes(img.files.size)
                                : ""}
                            </span>
                          </div>
                          <img
                            className={classes.iconDelete}
                            src={deleteIcon}
                            alt=""
                            onClick={() => this.handleDeleteUploadImages(index)}
                          />
                        </div>
                      </div>
                    ))}
                </div>
                <div className={classes.formActions}>
                  <Button
                    type="submit"
                    color={"info"}
                    style={{ marginRight: "10px" }}
                    onClick={e => this.handleBackClick(e)}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    color={"primary"}
                    disabled={!checkValidform()}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          )}
          {showModalImageUpload && (
            <ModalContent onClose={() => this.handleCloseModalUploadImages()}>
              <img
                // className={classes.itemImg}
                src={urlImageSelected}
                alt=""
                width="100%"
                height="auto"
              />
            </ModalContent>
          )}
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(EditPost))
  )
);
