import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Rating from 'react-rating';
import SweetAlert from "react-bootstrap-sweetalert";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";
import Help from "@material-ui/icons/Help";
import AccountCircle from "@material-ui/icons/AccountCircle";
import CardAvatar from "components/Card/CardAvatar.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

// images
import { inject, observer } from 'mobx-react';
import Loading from '../../../components/Loading/Loading';
import ForemanProfile from './components/ForemanProfile';
import ServiceAdvisorProfile from './components/ServiceAdvisorProfile';
import AutoBodyTechnicianProfile from './components/AutoBodyTechnicianProfile';
import CollisionServiceAdvisorProfile from './components/CollisionServiceAdvisorProfile';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import notificationService from '../../../services/notificationService';
import { convertDisplayText } from 'services/utils';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import BackPreviousPageButton from '../../../components/BackPreviousPageButton';
import ReviewModalUI from '../../Reviews/components/ReviewModalUI';
import { getDiffDays } from '../../../services/utils';
import defaultImage from "assets/img/default-avatar.png";
import Reviews from './components/Reviews';
import html2pdf from "html2pdf.js/src";

const styles = {
    ...sweetAlertStyle,
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    container: {
        padding: '10px 20px'
    },
    avatar: {
        marginTop: 0
    },
    ml10: {
        marginLeft: '10px'
    },
    mt15: {
        marginTop: '15px'
    },
    checkboxLabelControl: {
        marginLeft: '5px'
    },
    wrapperPaymentImg: {
        height: '80px'
    },
    paymentImg: {
        height: '100%',
        width: '100%'
    },
    label: {
        fontWeight: 'bold',
        marginTop: '10px',
        marginBottom: '10px',
    },
    mbt10: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    mb30: {
        marginBottom: '30px'
    },
    showReviewBtn: {
        border: 'none',
        padding: '0',
        background: 'none',
        fontSize: '.875rem',
        fontWeight: 'bold',
        marginLeft: '10px',
        '&:hover': {
            textDecoration: 'underline',
            color: '#00acc1',
            cursor: 'pointer'
        }
    },
    rateIcon: {
        color: '#F8981F'
    }
}

class HiredEmployeeDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmNoShowModal: null,
            reviewModal: null,
            reviewsListModal: null
        }
        this.props.employeesStore.reset();
    }

    componentDidMount() {
        this.props.employeesStore.getHiredEmployeeById(this.employeeId);
    }

    get employeeId() {
        return this.props.match.params.id;
    }

    showConfirmNoShowModal = () => {
        this.setState({
            confirmNoShowModal: <SweetAlert
                warning
                title=""
                onConfirm={this.confirmNoShow}
                onCancel={this.closeConfirmNoShowModal}
                confirmBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.success
                }
                cancelBtnCssClass={
                    this.props.classes.button + " " + this.props.classes.danger
                }
                confirmBtnText="Yes"
                cancelBtnText="No"
                showCancel
            >
                Are you sure the employee did not show up on his 1st day? Did you try contacting him? Press “Yes” to report “No Show” and receive a refund on your fee paid. Don’t forget to provide a review on the candidate.
      </SweetAlert>
        })
    }

    closeConfirmNoShowModal = () => {
        this.setState({
            confirmNoShowModal: null
        })
    }

    confirmNoShow = () => {
        const { reportNoShowEmployee, currentEmployee } = this.props.employeesStore;
        reportNoShowEmployee(currentEmployee.id).then(() => {
            this.closeConfirmNoShowModal();
            notificationService.success('EMPLOYEE.REPORTED');
            this.props.history.push('/employees-hired');
        })
    }

    renderDetailEmployee = (type, emp, index) => {
        const { toolSet } = this.props.commonStore;
        const workTypesMap = {
            automotive_tech: 'Automotive Tech/Mechanic',
            automotive_foreman: 'Automotive Foreman',
            service_advisor: 'Service Advisor/Writer',
            collision_service_advisor: 'Service Mgr',
            body_technician: 'Body Technician',
            poster_helper: 'Porter/Helper',
            other: 'Other',
        };

        let functionName = <GridItem container xs={12}> <h6 className={this.props.classes.label}>{workTypesMap[type]}</h6> </GridItem>;
        let divider;
        if (index > 0) {
            divider = <GridItem xs={12}> <Divider className={this.props.classes.mbt10}></Divider> </GridItem>;
        }
        
        switch (type) {
            case 'automotive_tech':
            case 'automotive_foreman':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <ForemanProfile skills={emp.job_seeker.preferences.skills.automotive_tech_foreman} toolSet={toolSet} />
                    </GridItem>
                </GridContainer>
            case 'body_technician':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <AutoBodyTechnicianProfile skills={emp.job_seeker.preferences.skills.body_technician} />
                    </GridItem>
                </GridContainer>;
            case 'service_manager':
            case 'service_advisor':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <ServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.service_advisor_manager} />
                    </GridItem>
                </GridContainer>
            case 'collision_service_advisor':
                return <GridContainer key={type}>
                    {divider}
                    {functionName}
                    <GridItem container xs={12}>
                        <CollisionServiceAdvisorProfile skills={emp.job_seeker.preferences.skills.collision_service_advisor} />
                    </GridItem>
                </GridContainer>;
            case 'poster_helper':
            case 'other':
            default:
                break;
        }
    }

    hideReviewModal = () => {
        this.props.reviewStore.reviewForm.reset();
        this.props.reviewStore.reviewForm.showErrors(false);
        this.setState({
            reviewModal: null
        })
    }

    submitReview = () => {
        const { reviewForm, createReview } = this.props.reviewStore;
        reviewForm.submit({
            onSuccess: (form) => {
                const data = form.values();
                createReview(data).then(() => {
                    notificationService.success('EMPLOYEE.SENT_REVIEW');
                    this.hideReviewModal();
                }).catch(() => {
                    this.hideReviewModal();
                });
            },
            onError: (form) => {
                // eslint-disable-next-line no-console
                console.log("All form errors", form.errors());
            }
        })
    }

    showReviewModal = () => {
        const { reviewForm } = this.props.reviewStore;
        const { currentEmployee } = this.props.employeesStore;
        reviewForm.set({ receiver: { value: currentEmployee.job_seeker.id, label: `${currentEmployee.job_seeker.profile.first_name} ${currentEmployee.job_seeker.profile.last_name}` }, listing_id: currentEmployee.listing_id });
        this.setState({
            reviewModal: <ReviewModalUI reviewForm={reviewForm} onConfirm={this.submitReview} onCancel={this.hideReviewModal} empReadOnly={true} />
        })
    }

    showReviewsModal = () => {
        const { getReviews, currentEmployee } = this.props.employeesStore;
        getReviews(currentEmployee.job_seeker.id).then(res => {
            this.setState({
                reviewsListModal: <Reviews handleClose={this.closeReviewsModal} reviews={res.items} />
            })
        });
    }

    closeReviewsModal = () => {
        this.setState({
            reviewsListModal: null
        })
    }

    printDocument = (fullName) => {
        const input = document.getElementById('divToPrint');
        const opt = {
            filename: fullName + ' - Profile.pdf'
        };

        html2pdf(input, opt);
    }

    render() {
        const { classes } = this.props;
        const { currentEmployee } = this.props.employeesStore;
        const { timeZone } = this.props.userStore;

        if (currentEmployee) {
            const { job_seeker, details } = currentEmployee;
            const endDate = moment(details.end_date).tz(timeZone);
            const startDate = moment(details.start_date).tz(timeZone);
            const overStartDate = moment().isSameOrAfter(startDate);
            const validTimeToReview = moment().isSameOrAfter(endDate.add(1, 'days'));
            const timeDiff = getDiffDays(endDate, startDate, currentEmployee.details.expect_work_on_weekends);
            const { getPlatformFee } = this.props.commonStore;
            const overTimeReview = moment().isAfter(endDate.add(7, 'days'));
            let platformFee = getPlatformFee(currentEmployee.details.rate, timeDiff, currentEmployee.details.work_day_hours || 8);
            let estimatedPay = currentEmployee.details.rate * (currentEmployee.details.work_day_hours || 8) * timeDiff;

            const { getHiredFee } = this.props.commonStore;
            const hiredFee = getHiredFee();
            const isTempoJob = job_seeker.job_type === 'temporary' ? true : false;
            
            return <GridContainer>
                <GridItem xs={12}>
                    <div className={classes.mt15}>
                        <BackPreviousPageButton />
                    </div>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AccountCircle />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>Personal Information</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className={classes.container}>
                                <GridItem xs={10}>
                                    <GridContainer>
                                        <GridItem xs={2}>
                                            <GridContainer>
                                                <CardAvatar profile className={classes.avatar}>
                                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                                        <img src={(job_seeker.profile && job_seeker.profile.avatar) || defaultImage} alt="employee avatar" />
                                                    </a>
                                                </CardAvatar>
                                            </GridContainer>
                                        </GridItem>
                                        <GridItem xs={10}>
                                            <GridContainer>
                                                <GridItem xs={3}>
                                                    <div className={classes.label}>Job Seeker Id:</div>
                                                </GridItem>
                                                <GridItem xs={9}>
                                                    <p className={classes.mbt10}>{job_seeker.job_seeker_id}</p>
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    <div className={classes.label}>Name:</div>
                                                </GridItem>
                                                <GridItem xs={9}>
                                                    <p className={classes.mbt10}>{`${job_seeker.profile.first_name} ${job_seeker.profile.last_name}`}</p>
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    <div className={classes.label}>Phone Number:</div>
                                                </GridItem>
                                                <GridItem xs={9}>
                                                    <p className={classes.mbt10}>{job_seeker.profile.mobile_number}</p>
                                                </GridItem>
                                                <GridItem xs={3}>
                                                    <div className={classes.label}>City, State ZIP:</div>
                                                </GridItem>
                                                <GridItem xs={9}>
                                                    <p className={classes.mbt10}>{`${job_seeker.profile.location.city}, ${job_seeker.profile.location.state} ${job_seeker.profile.location.zip_code}`}</p>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <Divider className={classes.mbt10} />
                                        </GridItem>
                                        <GridItem xs={10}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <GridContainer>
                                                        <GridItem xs={4}>
                                                            <div className={classes.label}>Listing #:</div>
                                                        </GridItem>
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>{currentEmployee.listing_id}</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={6}>
                                                    <GridContainer>
                                                        <GridItem xs={4}>
                                                            <div className={classes.label}>Status:</div>
                                                        </GridItem>
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>{convertDisplayText(currentEmployee.status, '_')}</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                        {/* <GridItem xs={10}>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    <GridContainer>
                                                        <GridItem xs={4}>
                                                            <div className={classes.label}>Confirmation #:</div>
                                                        </GridItem>
                                                        <GridItem xs={8}>
                                                            <p className={classes.mbt10}>--</p>
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem> */}
                                        <GridItem xs={12}>
                                            <Divider className={classes.mbt10} />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        { isTempoJob ?
                                            <GridItem xs={6}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h5>Estimated Pay to Employee: ${estimatedPay} </h5>
                                                    <Tooltip title="This is calculated based on an employee working 8hrs/day for the number of days indicated. This amount may vary if the employee works fewer or longer hours per day. You pay the employee directly at your business after he completes his work."><Help style={styles.ml10} /></Tooltip>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h5>Platform Fee to hire this candidate: ${platformFee} </h5>
                                                    <Tooltip title="This is the fee we collect to develop and maintain the platform as well as market and solicit candidates to join and get connected with you. Our business can’t survive without it, the same way your business can’t survive by offering free car repairs. We appreciate your support. Note that this fee will be refunded in case of a “No Show” and may be partially refunded if you cancel after the 1st day of work."><Help style={styles.ml10} /></Tooltip>
                                                </div>
                                            </GridItem> 
                                            :
                                            <GridItem xs={6}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <h5>Hire Fee to hire this candidate: ${hiredFee} </h5>
                                                </div>
                                            </GridItem>
                                        }
                                        
                                        <GridItem xs={6}>
                                            <GridContainer>
                                                <GridItem container justify="flex-end">
                                                    {currentEmployee.status !== 'reported_not_showed' &&
                                                        <React.Fragment>
                                                            {overStartDate ? <Button color="rose" onClick={this.showConfirmNoShowModal}>
                                                                No Show
                                                        </Button> : <Button disabled={true}>
                                                                    No Show
                                                        </Button>}
                                                        </React.Fragment>}
                                                    {
                                                        overTimeReview ?
                                                            <Tooltip title="Reviews must be submitted within 1 week of job completion.">
                                                                <div>
                                                                    <Button disabled={true} className={classes.ml10}>
                                                                        Review
                                                            </Button>
                                                                </div>
                                                            </Tooltip> :
                                                            <React.Fragment>
                                                                {
                                                                    validTimeToReview ?
                                                                        <Button className={classes.ml10} onClick={this.showReviewModal} color="info">
                                                                            Review
                                                            </Button> :
                                                                        <Tooltip title="Reviews can only be submitted after 1 day of job completion.">
                                                                            <div>
                                                                                <Button className={classes.ml10} disabled={true}>
                                                                                    Review
                                                            </Button>
                                                                            </div>
                                                                        </Tooltip>
                                                                }
                                                            </React.Fragment>}
                                                    <Button
                                                        className={classes.ml10} color="facebook"
                                                        onClick={() => {this.printDocument(job_seeker.profile.first_name)}}>
                                                        Download Profile
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                    
                                    <div id="divToPrint" style={{paddingLeft: '10px', paddingRight: '10px' }}>
                                        <GridContainer>
                                            <GridItem container xs={6}>
                                                <h3 className={classes.label}>Profile</h3>
                                            </GridItem>
                                            <GridItem container xs={6} justify="flex-end" alignItems="center">
                                                <Rating
                                                    initialRating={currentEmployee.job_seeker.profile.rating}
                                                    readonly
                                                    fractions={2}
                                                    emptySymbol={<StarBorder className={classes.rateIcon} />}
                                                    fullSymbol={<StarRate className={classes.rateIcon} />}
                                                /> <b>({currentEmployee.job_seeker.profile.rating} / 5) </b><button className={classes.showReviewBtn} onClick={this.showReviewsModal}>Reviews ({(currentEmployee.job_seeker.profile.rating_raw && currentEmployee.job_seeker.profile.rating_raw.count) || 0})</button>
                                            </GridItem>
                                            <GridItem container xs={6} ></GridItem>
                                            <GridItem container xs={6} justify="flex-end" alignItems="center">
                                                <div>
                                                    <b>No shows: {currentEmployee.job_seeker.preferences.no_shows}</b>
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>Years of Auto Repair Industry Experience:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.years_of_experience}</p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>Training Courses Completed:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.completed_training_courses.join(', ')}</p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>Highest Level Of Education:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.education_level}</p>
                                            </GridItem>
                                        </GridContainer>

                                        {currentEmployee.job_seeker.preferences.work_types.map((type, index) => {
                                            return this.renderDetailEmployee(type, currentEmployee, index);
                                        })}
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>Other Information:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.other_information}</p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>DMV record with less than 1 minor infraction:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.clean_dmv_record ? 'Yes' : `No (${currentEmployee.job_seeker.preferences.reason_no_dmv_record})`}</p>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer className={classes.mb30}>
                                            <GridItem xs={6}>
                                                <div className={classes.label}>Job Seeker Authorization to conduct background check:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <p className={classes.mbt10}>{currentEmployee.job_seeker.preferences.authorization_to_dmv ? 'Yes' : `No`}</p>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                    <GridContainer className={classes.mb30}>
                                        <GridItem xs={12}>
                                            <div className={classes.label}>Resume:</div>
                                            {currentEmployee.job_seeker.preferences.resume_photo ?
                                                <object data={currentEmployee.job_seeker.preferences.resume_photo} type="application/pdf" width="100%" height="1000">
                                                    <p>Alternative text - include a link <a href={currentEmployee.job_seeker.preferences.resume_photo}>to the PDF!</a></p>
                                                </object>
                                                :
                                                <div></div>
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {this.state.confirmNoShowModal}
                                {this.state.reviewModal}
                                {this.state.reviewsListModal}
                                <BackPreviousPageButton />
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        }
        return <Loading />
    }
}

export default withStyles(styles)(inject('employeesStore', 'reviewStore', 'commonStore', 'userStore')(observer(HiredEmployeeDetails)));