import React from "react";
import { observer, inject } from "mobx-react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  image,
  container,
  primaryColor,
  textActiveColor,
  noSidePadding
} from "../../../assets/jss/material-dashboard-pro-react";

const styles = theme => ({
  container,
  breadcrumbs: {
    margin: `${theme.spacing.unit * 2}px 0`,
    "& ul": {
      padding: 0,
      margin: 0
    },
    "& li": {
      display: "inline-block",
      marginRight: 8,
      fontFamily: '"Inter"',
      fontWeight: 400,
      fontSize: 16,
      cursor: "pointer",
      "&::after": {
        content: '"/"',
        marginLeft: 10
      },
      "&:last-child": {
        "&:after": {
          content: '""'
        }
      }
    }
  },
  active: {
    color: primaryColor[0],
    cursor: "default !important"
  }
});
class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
  }

  toPage = url => {
    const { history } = this.props;
    if (url) history.push(url);
  };

  render() {
    const { items, classes } = this.props;
    return (
      <div className={classes.breadcrumbs}>
        <ul>
          {items.map((item, key) => (
            <li
              key={key}
              className={classNames({
                [classes.active]: item.active
              })}
              onClick={() => this.toPage(item.url)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(inject("commonStore", "userStore")(observer(Breadcrumbs)))
);
