import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import notificationService from "services/notificationService";
import IntegrationReactSelect from "../../components/CustomSelect/IntegrationReactSelect";
import { getCompleteLocation } from "../../services/dataServices/authData.service";
import { detailHeader } from "../../variables/constant";
import _ from "lodash";
class GeneralRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      address: "",
      timer: null,
      stateReactSelect: [],
      location: { city: null, state: null },
      isErrorLocation: false,
      isErrorAlias: false
    };
  }

  componentWillMount() {
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "user_general"
    ) {
      window.location.href = "/app/#/features";
    }
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "business_owner"
    ) {
      window.location.href = "/app/#/employees/employees-available";
    }
    if (
      this.props.userStore &&
      this.props.userStore.currentUserTW &&
      this.props.userStore.currentUserTW.role === "job_seeker"
    ) {
      window.location.href = "/app/#/tech-wizard";
    }
  }

  handleToggleShowPw = () => {
    this.props.authStore.toggleShowPasswordGeneral();
  };

  handleToggleShowConfirmPw = () => {
    this.props.authStore.toggleShowConfirmPasswordGeneral();
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  isEmailFormat = data => {
    if (data.includes("@")) {
      return true;
    }
    return false;
  };

  isValidLocation = () => {
    let isValid = true;
    if (!this.state.location.city || !this.state.location.state) {
      isValid = false;
    }
    this.setState({ isErrorLocation: !isValid });
    return isValid;
  };

  isValidAlias = () => {
    const aliasValue = this.props.authStore.generalRegisterForm
      .$("user_name")
      .value.trim();
    let isValid = true;
    this.setState({ isErrorAlias: false });
    if (aliasValue && !/^[A-Za-z0-9]{4,20}$/.test(aliasValue)) {
      isValid = false;
    }
    this.setState({ isErrorAlias: !isValid });
    return isValid;
  };

  submit = e => {
    e.preventDefault();
    const {
      generalRegisterForm,
      registerGeneral,
      sendOtp
    } = this.props.authStore;
    const { history } = this.props;
    if (generalRegisterForm.$("agreeTerm").value) {
      const isValidLocation = this.isValidLocation();
      const isValidAlias = this.isValidAlias();
      if (!generalRegisterForm.isValid || !isValidLocation || !isValidAlias) {
        return generalRegisterForm.showErrors(true);
      }
      generalRegisterForm.submit({
        onSuccess: form => {
          const data = form.values();
          // const location = data.location.value.split(",").reverse();
          let request = {
            user_name: data && data.user_name,
            first_name: data && data.first_name,
            last_name: data && data.last_name,
            password: data && data.password,
            city: this.state.location.city,
            state: this.state.location.state
          };
          if (this.isEmailFormat(data.emailOrPhone)) {
            request = {
              ...request,
              email: data.emailOrPhone
            };
          } else {
            request = {
              ...request,
              mobile_number: data.emailOrPhone
            };
          }

          registerGeneral(request)
            .then(res => {
              const userId = res && res.data && res.data.userId;
              localStorage.setItem("user", JSON.stringify(res && res.data));
              localStorage.setItem("userId", userId);
              sendOtp({
                mobileNumber: res && res.data && res.data.mobileNumber,
                email: res && res.data && res.data.email
              }).then(sendOtpRes => {
                localStorage.setItem("otpEntryId", sendOtpRes.otpEntryId);
              });
              history.push({
                pathname: "/auth/verify-otp"
              });
            })
            .catch(res => {
              notificationService.error(res);
            });
        },
        onError: form => {
          // eslint-disable-next-line no-console
          console.log("All form errors", form.errors());
        }
      });
    }
  };
  searchLocation = _.debounce(async function(e) {
    if (e.trim() === "") {
      this.setState({ stateReactSelect: [] });
      return;
    }
    const res = await getCompleteLocation(e);
    const options = _.get(res.data, "data", []).map(e => {
      return { value: e, label: e };
    });
    this.setState({ stateReactSelect: options });
  }, 750);
  render() {
    const { classes } = this.props;
    const { generalRegisterForm } = this.props.authStore;
    const cityLocation = {
      label: this.state.location.city,
      value: this.state.location.city
    };
    const stateLocation = {
      label: this.state.location.state,
      value: this.state.location.state
    };
    return (
      <div className={classes.container}>
        <Helmet>
          <title>
            {detailHeader &&
              detailHeader.workPage &&
              detailHeader.workPage.title &&
              detailHeader.workPage.title}
          </title>
          <meta
            name="description"
            content={
              detailHeader &&
              detailHeader.workPage &&
              detailHeader.workPage.description &&
              detailHeader.workPage.description
            }
          />
        </Helmet>
        <GridContainer justify="center">
          {this.state.alert}
          <GridItem xs={12} sm={12} md={12}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>USER REGISTRATION</h2>
              <CardBody>
                <div className={classes.center} />
                <form
                  className={classes.form}
                  onSubmit={this.submit}
                  autoComplete="off"
                >
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          pattern: "[a-zA-z0-9]{1,20}",
                          ...generalRegisterForm.$("user_name").bind()
                        }}
                        error={
                          !!generalRegisterForm.$("user_name").error ||
                          this.state.isErrorAlias
                        }
                        helpText={
                          generalRegisterForm.$("user_name").error || undefined
                        }
                        labelText="Alias"
                      />
                      {this.state.isErrorAlias && (
                        <p className={classes.errorFieldInput}>
                          Alias cannot have spaces.
                        </p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={generalRegisterForm.$("first_name").bind()}
                        error={!!generalRegisterForm.$("first_name").error}
                        helpText={
                          generalRegisterForm.$("first_name").error || undefined
                        }
                        labelText="First Name"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={generalRegisterForm.$("last_name").bind()}
                        error={!!generalRegisterForm.$("last_name").error}
                        helpText={
                          generalRegisterForm.$("last_name").error || undefined
                        }
                        labelText="Last Name"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    {/* <GridItem xs={12} sm={12} md={6}>
                      <IntegrationReactSelect
                        label={"Location*"}
                        id="state-select"
                        error={!!generalRegisterForm.$("location").error}
                        helpText={
                          generalRegisterForm.$("location").error || undefined
                        }
                        options={this.state.stateReactSelect}
                        value={generalRegisterForm.$("location").value}
                        onChange={generalRegisterForm.$("location").onChange}
                        placeholder={"City, State or ZIP Code"}
                        handleInputChange={e => {
                          this.searchLocation(e);
                        }}
                      />
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <IntegrationReactSelect
                        label={"City*"}
                        id="city-select"
                        error={this.state.isErrorLocation}
                        helpText={
                          generalRegisterForm.$("city").error || undefined
                        }
                        // options={profileForm.$('location.city').extra}
                        options={this.state.stateReactSelect}
                        // value={profileForm.$('city').value}
                        value={this.state.location.city ? cityLocation : null}
                        onChange={e => {
                          let city;
                          let state;
                          if (!e) {
                            city = null;
                            state = null;
                          } else {
                            const locationList = e.value.split(",").reverse();
                            city = locationList[1].trim();
                            state = locationList[0].trim();
                          }
                          this.setState({
                            location: {
                              city: city,
                              state: state
                            },
                            isErrorLocation: false
                          });
                        }}
                        placeholder={"Select City"}
                        handleInputChange={e => {
                          this.searchLocation(e);
                        }}
                      />
                      {this.state.isErrorLocation && (
                        <p className={classes.errorField}>
                          The field is required.
                        </p>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <IntegrationReactSelect
                        label={"State*"}
                        id="state-select"
                        error={this.state.isErrorLocation}
                        helpText={
                          generalRegisterForm.$("state").error || undefined
                        }
                        options={this.state.stateReactSelect}
                        value={this.state.location.state ? stateLocation : null}
                        onChange={e => {
                          let city;
                          let state;
                          if (!e) {
                            city = null;
                            state = null;
                          } else {
                            const locationList = e.value.split(",").reverse();
                            city = locationList[1].trim();
                            state = locationList[0].trim();
                          }
                          this.setState({
                            location: {
                              city: city,
                              state: state
                            },
                            isErrorLocation: false
                          });
                        }}
                        handleInputChange={e => {
                          this.searchLocation(e);
                        }}
                        placeholder={"Select State"}
                      />
                      {this.state.isErrorLocation && (
                        <p className={classes.errorField}>
                          The field is required.
                        </p>
                      )}
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={generalRegisterForm
                          .$("emailOrPhone")
                          .bind()}
                        error={!!generalRegisterForm.$("emailOrPhone").error}
                        helpText={
                          generalRegisterForm.$("emailOrPhone").error ||
                          undefined
                        }
                        labelText="Email"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                              onClick={this.handleToggleShowPw}
                            >
                              {this.props.authStore.generalRegisterForm
                                .$("password")
                                .get("type") === "password" ? (
                                <RemoveRedEye
                                  className={classes.inputAdornmentIcon}
                                />
                              ) : (
                                <VisibilityOff
                                  className={classes.inputAdornmentIcon}
                                />
                              )}
                            </InputAdornment>
                          ),
                          ...generalRegisterForm.$("password").bind()
                        }}
                        error={!!generalRegisterForm.$("password").error}
                        helpText={
                          generalRegisterForm.$("password").error || undefined
                        }
                        labelText="Password"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                              onClick={this.handleToggleShowConfirmPw}
                            >
                              {this.props.authStore.generalRegisterForm
                                .$("confirmPassword")
                                .get("type") === "password" ? (
                                <RemoveRedEye
                                  className={classes.inputAdornmentIcon}
                                />
                              ) : (
                                <VisibilityOff
                                  className={classes.inputAdornmentIcon}
                                />
                              )}
                            </InputAdornment>
                          ),
                          ...generalRegisterForm.$("confirmPassword").bind()
                        }}
                        error={!!generalRegisterForm.$("confirmPassword").error}
                        helpText={
                          generalRegisterForm.$("confirmPassword").error &&
                          (generalRegisterForm.$("confirmPassword").value !==
                            generalRegisterForm.$("confirmPassword").value) !==
                            generalRegisterForm.$("password").value
                            ? "Passwords don't match"
                            : generalRegisterForm.$("confirmPassword").error ||
                              undefined
                        }
                        labelText="Confirm password"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem xs={8} sm={8} md={8}>
                      <FormControlLabel
                        classes={{
                          root: classes.checkboxLabelControl,
                          label: classes.checkboxLabel
                        }}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            onClick={
                              generalRegisterForm.$("agreeTerm").onChange
                            }
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        label={
                          <span>
                            I Accept Mechanics & Body Shops Marketplace{" "}
                            <a href="#pablo">Terms and Conditions of Use</a>.
                          </span>
                        }
                      />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <div className={classes.passwordHint}>
                        Password must contain at least 8 characters, one lower
                        case letter, one upper case letter, one number and one
                        of the following symbols: !, @, #, $, %, &, *
                      </div>
                    </GridItem>
                  </GridContainer>
                  <div className={classes.center}>
                    <Button
                      round
                      color="primary"
                      size="lg"
                      type="submit"
                      disabled={
                        !generalRegisterForm.$("agreeTerm").value ||
                        !!generalRegisterForm.$("emailOrPhone").error
                      }
                    >
                      Sign up
                    </Button>
                    <div className={classes.bottomText}>
                      Already have an account?{" "}
                      <Link className={classes.loginLink} to="/auth/login-page">
                        Log In
                      </Link>{" "}
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

GeneralRegister.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(
  inject("authStore", "userStore")(observer(GeneralRegister))
);
