import React from "react";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import {
  image,
  container,
  primaryColor,
  grayColor,
  textPrimaryColor,
  whiteColor,
  textSecondaryColor
} from "../../../assets/jss/material-dashboard-pro-react";

import Button from "../../../components/CustomButtons/Button";
import { ReactComponent as IconSearch } from "../../../assets/img/icons/icon_search.svg";

const styles = theme => ({
  searchForm: {
    height: 44,
    ["@media(max-width: 980px)"]: {
      margin: "10px 0 !important"
    }
  },
  searchFormGroup: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "100%"
  },
  input: {
    flex: "1 0 auto",
    height: "100%",
    position: "relative"
  },
  btnSubmit: {
    flex: "0 0 120px"
  },
  inputText: {
    height: "100%",
    padding: "8px 8px 8px 44px",
    fontSize: 16,
    fontWeight: 500,
    color: textSecondaryColor,
    border: `1px solid ${grayColor[8]}`,
    fontFamily: "Inter",
    width: "100%"
  },
  iconSearch: {
    position: "absolute",
    top: "50%",
    left: 16,
    transform: "translateY(-50%)"
  },
  btnSubmit: {
    height: "100%",
    margin: 0,
    borderRadius: 0
  }
});

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: ""
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  toPostDetail = id => {
    const { history } = this.props;
    history.push(`/tech-wizard/post/${id}`);
  };

  onSubmit = e => {
    e.preventDefault();
    const { history } = this.props;
    this.props.postsStore.updateParams({
      title: this.state.textSearch,
      page: 1
    });
    this.props.postsStore.setCurrentPage(0);
    this.props.postsStore.getPostsFromApi();
    history.push("/tech-wizard/posts");
  };

  onChange = e => {
    this.setState({ textSearch: e.target.value });
  };

  render() {
    const { classes } = this.props;
    const { textSearch } = this.state;
    const { title } = this.props.postsStore.getParams();
    return (
      <div className={classes.searchForm}>
        <form
          onSubmit={e => this.onSubmit(e)}
          className={classes.searchFormGroup}
        >
          <div className={classes.input}>
            <IconSearch className={classes.iconSearch} />
            <input
              type="text"
              placeholder="Search question..."
              className={classes.inputText}
              defaultValue={
                this.props.history.location.pathname === "/tech-wizard"
                  ? textSearch
                  : title
              }
              onChange={e => this.onChange(e)}
            />
          </div>
          <Button
            className={classNames({
              [classes.btnSubmit]: true
            })}
            color="primary"
            size={"sm"}
            // onClick={() => this.onSubmit()}
            type={"submit"}
          >
            <span className={classes.btnSubmitLabel}>Search</span>
          </Button>
        </form>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(SearchForm))
  )
);
