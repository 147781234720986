import requests from "../http";

const updateProfileApi = ({ profile, preferences }) => {
  if (typeof profile.location.city === "object") {
    profile.location.city = profile.location.city.value;
  }
  if (typeof profile.location.county === "object") {
    profile.location.county = profile.location.county.value;
  }
  if (typeof profile.location.state === "object") {
    profile.location.state = profile.location.state.value;
  }
  const carTypes = [];
  preferences.car_types.forEach(type => {
    if (typeof type === "object") {
      carTypes.push(type.value);
    } else if (typeof type === "string") {
      carTypes.push(type);
    }
  });
  preferences.car_types = carTypes;
  const otherCarTypes = [];
  preferences.other_car_types.forEach(type => {
    if (typeof type === "object") {
      otherCarTypes.push(type.value);
    } else if (typeof type === "string") {
      otherCarTypes.push(type);
    }
  });
  preferences.other_car_types = otherCarTypes;
  const bodyData = {
    profile,
    preferences
  };
  return requests.put("/api/account/info", bodyData);
};
const updateAccountToBusinessApi = ({ profile, preferences }) => {
  if (typeof profile.location.city === "object") {
    profile.location.city = profile.location.city.value;
  }
  if (typeof profile.location.county === "object") {
    profile.location.county = profile.location.county.value;
  }
  if (typeof profile.location.state === "object") {
    profile.location.state = profile.location.state.value;
  }
  const carTypes = [];
  preferences.car_types.forEach(type => {
    if (typeof type === "object") {
      carTypes.push(type.value);
    } else if (typeof type === "string") {
      carTypes.push(type);
    }
  });
  preferences.car_types = carTypes;
  const otherCarTypes = [];
  preferences.other_car_types.forEach(type => {
    if (typeof type === "object") {
      otherCarTypes.push(type.value);
    } else if (typeof type === "string") {
      otherCarTypes.push(type);
    }
  });
  preferences.other_car_types = otherCarTypes;
  const bodyData = {
    profile,
    preferences,
    role: "business_owner"
  };
  return requests.put("/api/v2/user_general/update_info", bodyData);
};

const getProfileApi = () => {
  return requests.get("/api/account/info");
};

const getUserTWProfileAPI = () => {
  return requests.get("/api/account/user_tw");
};

const getUserTWInfoAPI = data => {
  return requests.post("/api/account/user_tw", data);
};

const uploadAvatarApi = file => {
  return requests.sendWithFiles("/api/upload", "post", null, [
    { name: "avatar", files: [file] }
  ]);
};

const changePasswordApi = data => {
  return requests.post("/api/account/change_password", data);
};

const changeNotificationPreferencesApi = data => {
  return requests.put("/api/account/settings", data);
};

const getNotificationPreferencesApi = () => {
  return requests.get("/api/account/settings");
};

const getPaymentMethodsApi = () => {
  return requests.get("/api/account/settings/payment_methods");
};

const addPaymentMethodApi = data => {
  return requests.post("/api/account/settings/payment_methods", data);
};

const deletePaymentMethodApi = id => {
  return requests.del(`/api/account/settings/payment_methods/${id}`);
};

const setDefaultPaymentMethodApi = id => {
  return requests.post(
    `/api/account/settings/payment_methods/${id}/set_default`
  );
};

const getStatisticalSummaryApi = () => {
  return Promise.all([
    requests.get("/api/listings/employees/pending/count"),
    requests.get("/api/listings/applications/count")
  ]).then(([pending, applications]) => {
    return Promise.resolve({
      pending: pending.count,
      applications: applications.count
    });
  });
};

const updateSubscribeTWDegistApi = (id, params) => {
  return requests.get(`/api/v2/tw_digest/${id}/${params}`);
};

const updateGUProfileApi = (id, body) =>
  requests.put(`/api/v2/user_general/update_info?userId=${id}`, body);

const deleteAccountApi = params => {
  return requests.put(`/api/v2/user_general/delete_owner`, params);
};

export {
  updateProfileApi,
  updateAccountToBusinessApi,
  getProfileApi,
  uploadAvatarApi,
  changePasswordApi,
  changeNotificationPreferencesApi,
  getNotificationPreferencesApi,
  getPaymentMethodsApi,
  addPaymentMethodApi,
  deletePaymentMethodApi,
  setDefaultPaymentMethodApi,
  getStatisticalSummaryApi,
  getUserTWProfileAPI,
  getUserTWInfoAPI,
  updateSubscribeTWDegistApi,
  updateGUProfileApi,
  deleteAccountApi
};
