import { Form } from "mobx-react-form";
import plugins from "./plugins";
import stores from "..";

export default class ProfileForm extends Form {
  plugins() {
    return plugins;
  }

  setup() {
    return {
      fields: [
        {
          name: "avatar",
          handlers: {
            onChange: field => file => {
              field.set(file || "");
            }
          }
        },
        {
          name: "best_workplace",
          handlers: {
            onChange: field => file => {
              field.set(file || "");
            }
          }
        },
        {
          name: "first_name",
          placeholder: "Enter first name",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "last_name",
          placeholder: "Enter last name",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "user_name",
          placeholder: "Enter user name",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "email",
          placeholder: "Enter your email",
          rules: "required|email",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "mobile_number",
          placeholder: "Enter your mobile phone",
          // rules: "required|telephone",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "location",
          label: "Location",
          fields: [
            {
              name: "state",
              // rules: "required",
              placeholder: "Select State",
              handlers: {
                onChange: field => value => {
                  field.set(value || "");
                }
              },
              hooks: {
                onChange: field => {
                  if (!field.value) {
                    this.$("location.state").reset();
                  }
                  this.$("location.city").set("extra", []);
                  if (this.$("location.city").value) {
                    this.$("location.city").reset();
                  }

                  const { value } = field;
                  if (value && value.value) {
                    stores.commonStore
                      .getCitiesByState(value.value)
                      .then(data => {
                        this.$("location.city").set(
                          "extra",
                          data.map(item => ({
                            label: item,
                            value: item
                          }))
                        );
                      });
                  }
                  field.validate();
                }
              }
            },
            {
              name: "city",
              // rules: "required",
              placeholder: "Select City",
              handlers: {
                onChange: field => value => {
                  field.set(value || "");
                  // if (value.value === "Other") {
                  //   this.$("location.other_city").set("extra", {
                  //     show: true
                  //   });
                  // } else {
                  //   this.$("location.other_city").set("extra", {
                  //     show: false
                  //   });
                  //   this.$("location.other_city").reset();
                  // }
                }
              },
              hooks: {
                onChange: field => {
                  field.validate();
                }
              },
              extra: []
            },
            {
              name: "other_city",
              placeholder: "Type city",
              extra: { show: false }
            },
            {
              name: "address_line1",
              placeholder: "Enter your address",
              // rules: "required",
              hooks: {
                onChange: field => {
                  field.validate();
                }
              }
            },
            {
              name: "zip_code",
              placeholder: "Enter your postal code",
              rules: "zipCode",
              hooks: {
                onChange: field => {
                  field.validate();
                }
              }
            }
          ]
        },
        {
          name: "sales_rep",
          placeholder: "Select your assigned sales rep",
          hooks: {
            onChange: field => {
              if (field.value === "other") {
                this.$("other_sales_rep").set("extra", {
                  show: true
                });
              } else {
                this.$("other_sales_rep").set("extra", {
                  show: false
                });
                this.$("other_sales_rep").reset();
              }
            }
          }
        },
        {
          name: "other_sales_rep",
          extra: { show: false }
        }
      ]
    };
  }
}
