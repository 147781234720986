import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import moment from "moment";
import PropTypes from "prop-types";
import appConstants from "../../../constants/app.constant";
import Button from "../../../components/CustomButtons/Button";
import {
  Popper,
  Hidden,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem
} from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import avatar from "../../../assets/img/avatar-in-post.png";
import { ReactComponent as IconLike } from "../../../assets/img/icons/icon_like.svg";
import { ReactComponent as IconLikeSolid } from "../../../assets/img/icons/icon_like_solid.svg";
import { ReactComponent as IconView } from "../../../assets/img/icons/icon_view.svg";
import { ReactComponent as IconComment } from "../../../assets/img/icons/icon_comment.svg";
import { ReactComponent as IconShare } from "../../../assets/img/icons/icon_share.svg";
import { ReactComponent as IconFlag } from "../../../assets/img/icons/icon_flag.svg";
import { ReactComponent as IconEdit } from "../../../assets/img/icons/icon_edit.svg";
import config from "../../../config";
import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor,
  noSidePadding
} from "../../../assets/jss/material-dashboard-pro-react";
import PostImageItem from "../postImageItem/postImageItem";

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${color} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.95em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${color} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${color} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.95em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${color}`
      }
    }
  };
}

const styles = theme => ({
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: "absolute",
    fontSize: 6,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  infoQuestionWrap: {
    marginBottom: 15
  },
  infoQuestionItem: {
    color: "#0e5d6b",
    fontSize: 12,
    fontWeight: 400,
    padding: "5px 10px",
    backgroundColor: "#5dc1d3",
    borderRadius: 10,
    marginRight: 10
  },
  postItem: {
    marginBottom: 12,
    background: whiteColor,
    border: `1px solid ${grayColor[8]}`,
    cursor: "pointer",
    "&:last-child": {
      border: "none !important"
    }
  },
  isQuestionPostItem: {
    border: "none",
    borderBottom: `1px solid ${grayColor[8]}`
  },
  postContent: {
    padding: "16px 20px 8px"
  },
  isQuestionContent: {
    padding: "8px 0 8px"
  },
  account: {
    boxShadow: "none"
  },
  avatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    marginRight: 10,
    overflow: "hidden"
  },
  username: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "17px",
    margin: 0
  },
  titleText: {
    width: "100%",
    height: "40px",
    padding: "16px",
    margin: "10px 0 10px 0",
    fontFamily: "Inter",
    fontSize: 16,
    lineHeight: "1.5em",
    border: `1px solid #ECECEC`,
    borderRadius: "4px",
    resize: "none",
    overflow: "auto",
    backgroundColor: "#f2f2f29c"
  },
  wrapEditText: {
    display: "flex",
    justifyContent: "end"
  },
  btnEditText: {
    border: "none",
    background: "none",
    cursor: "pointer",
    fontSize: 11,
    color: "#6565c6",
    "&:hover": {
      color: "blue",
      textDecoration: "underline"
    },
    "&:disabled": {
      cursor: "not-allowed"
    }
  },
  postOn: {
    fontSize: 14
  },
  postTitle: {
    margin: "12px 0 0 0",
    fontSize: 18,
    fontWeight: "500"
  },
  postQuestion: {
    margin: "12px 0 0 0",
    fontSize: 15,
    cursor: "pointer"
  },
  postActions: {
    margin: "0 -1.125rem",
    padding: "0 20px 8px"
  },
  isQuestionAction: {
    margin: "0 -34px"
  },
  postActionBtns: {
    margin: "0 10px 0 0",
    color: `${textSecondaryColor[0]} !important`
  },
  active: {
    color: `${textActiveColor[0]} !important`
  },
  postActionBtnLabel: {
    marginLeft: 8,
    textTransform: "Capitalize",
    color: "inherit",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "17px",
    marginBottom: "-2px"
  },
  socialShareWrapper: {
    background: whiteColor,
    padding: "16px 16px 8px 16px"
  },
  dropdownItem: {
    ...noSidePadding,
    "&:hover": {
      background: "transparent"
    }
  },
  socialShareLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 18,
    "& input": {
      height: 42,
      padding: 12,
      color: textActiveColor,
      borderRadius: "5px 0 0 5px",
      border: "1px solid #E9EAEB",
      background: whiteColor,
      fontFamily: "Inter",
      fontSize: 16,
      fontWeight: 400
    },
    "& button": {
      height: 42,
      margin: 0,
      borderRadius: "0 5px 5px 0",
      fontSize: 16,
      fontWeight: 700,
      textTransform: "capitalize"
    }
  },
  socialShareLabel: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#969798"
  },
  socialBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  socialLabel: {
    fontSize: 16,
    fontWeight: 400,
    color: blackColor,
    marginLeft: 9,
    fontFamily: "Inter"
  },
  btnFloatRight: {
    float: "right",
    margin: "0 !important"
  },
  ["@media(max-width: 1200px)"]: {
    btnFloatRight: {
      float: "none"
    }
  }
});

class PostItem extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    openTooltip: false,
    arrowRef: null,
    isEditAnswer: false,
    disableEditAnswer: false,
    likeStatusQuestion: false,
    likeCountQuestion: 0
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { item } = this.props;
    this.setState({
      likeStatusQuestion: item.likeStatus,
      likeCountQuestion: item.likeCount
    });
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  toPostDetail = id => {
    const { history, match } = this.props;
    const paramsId = match.params.id;
    if (id && id !== paramsId) history.push(`/tech-wizard/post/${id}`);
  };

  handleClick = event => {
    const { currentTarget } = event;
    this.setState(state => ({
      anchorEl: currentTarget,
      open: !state.open
    }));
  };

  onCopy = id => {
    const copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleTooltipClose = () => {
    this.setState({ openTooltip: false });
  };

  handleTooltipOpen = () => {
    this.setState({ openTooltip: true });
  };

  handleEditQuestionClick = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    const { history } = this.props;
    history.push(`/tech-wizard/edit-post/${id}`);
  };

  handleOpenEditAnswer = e => {
    e.preventDefault();
    this.setState({ isEditAnswer: true });
  };
  handleCloseEditAnswer = e => {
    e.preventDefault();
    this.setState({ isEditAnswer: false });
  };
  handleChangeEditAnswer = e => {
    if (e.target.value.trim().length > 0) {
      this.props.postsStore.updateCurrentAnswer(e.target.value);
      this.setState({ disableEditAnswer: false });
    } else {
      this.setState({ disableEditAnswer: true });
    }
  };
  handleEditAnswerClick = (e, anwerID, questionId) => {
    e.preventDefault();
    this.props.postsStore.updateAnswerFromApi(anwerID, questionId);
    this.setState({ isEditAnswer: false });
  };

  handleLikeClick = async e => {
    const { item, isQuestion } = this.props;
    const { currentUserTW } = this.props.userStore;
    e.stopPropagation();
    if (currentUserTW) {
      let body = {};
      if (isQuestion) {
        body = {
          twSeasonId: item.tw_season_id,
          twQuestionId: item.id,
          twAnswerId: ""
        };
      } else {
        body = {
          twSeasonId: item.tw_season_id,
          twQuestionId: item.tw_question_id,
          twAnswerId: item.id
        };
      }
      await this.props.postsStore.toggleLikeFromApi(
        isQuestion ? "question" : "answer",
        body
      );
      const { currentPost, currentAnswer } = this.props.postsStore;
      console.log("this.currentAnswer", currentAnswer);
      this.setState({
        likeStatusQuestion: currentPost.likeStatus,
        likeCountQuestion: currentPost.likeCount
      });
    } else {
      this.props.commonStore.updateModalRequireLogin(true);
    }
  };

  renderTitleContentPost = (content, type) => {
    const { path } = this.props.match;
    if (path === "/tech-wizard" || path === "/tech-wizard/posts") {
      if (content && content.length > 250) {
        return (
          <>
            {content.slice(0, 250)}
            {type === "content" ? (
              <a onClick={this.toPostDetail()}>...Readmore</a>
            ) : (
              "..."
            )}
          </>
        );
      }
    }
    return content;
  };

  render() {
    const { classes, item, isQuestion, infoQuestion } = this.props;
    const {
      anchorEl,
      open,
      isEditAnswer,
      disableEditAnswer,
      likeStatusQuestion,
      likeCountQuestion
    } = this.state;
    const id = open ? "simple-popper" : null;
    const { currentUserTW } = this.props.userStore;

    return (
      <div
        className={classNames({
          [classes.postItem]: true,
          [classes.isQuestionPostItem]: isQuestion ? false : true
        })}
        onClick={e => {
          e.stopPropagation();
          if (isQuestion) this.toPostDetail(item.id);
        }}
      >
        <div
          className={classNames({
            [classes.postContent]: true,
            [classes.isQuestionContent]: isQuestion ? false : true
          })}
        >
          <Grid container alignItems={"center"} justify={"space-between"}>
            {infoQuestion && (
              <Grid item xs={12}>
                <div className={classes.infoQuestionWrap}>
                  {infoQuestion.category && (
                    <span className={classes.infoQuestionItem}>
                      {infoQuestion.category}
                    </span>
                  )}
                  {infoQuestion.make && (
                    <span className={classes.infoQuestionItem}>
                      {infoQuestion.make}
                    </span>
                  )}
                  {infoQuestion.year && (
                    <span className={classes.infoQuestionItem}>
                      {infoQuestion.year}
                    </span>
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <div className={classes.account}>
                <Grid container alignItems={"center"} justify={"flex-start"}>
                  {isQuestion ? (
                    <>
                      {item.user_id && item.user_id.avatar ? (
                        <img
                          src={item.user_id.avatar}
                          alt=""
                          className={classes.avatar}
                        />
                      ) : (
                        <img src={avatar} alt="" className={classes.avatar} />
                      )}
                      <Typography
                        className={classes.username}
                        color="textPrimary"
                        gutterBottom
                      >
                        {item.user_id.user_name ||
                          item.user_id.first_name ||
                          "Anonymous"}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {item.user_id && item.user_id.avatar ? (
                        <img
                          src={item.user_id.avatar}
                          alt=""
                          className={classes.avatar}
                        />
                      ) : (
                        <img src={avatar} alt="" className={classes.avatar} />
                      )}
                      <Typography
                        className={classes.username}
                        color="textPrimary"
                        gutterBottom
                      >
                        {item.user_id.user_name || item.user_id.first_name}
                      </Typography>
                    </>
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container alignItems={"center"} justify={"flex-end"}>
                <Typography
                  className={classes.postOn}
                  color="textSecondary"
                  gutterBottom
                >
                  Posted:{" "}
                  {moment(item.created_at).format(appConstants.dateTimeFormat)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {isQuestion && (
            <Typography className={classes.postTitle} color="textPrimary">
              {this.renderTitleContentPost(item.title, "title")}
            </Typography>
          )}
          {isQuestion ? (
            <>
              <Typography className={classes.postQuestion} color="textPrimary">
                {this.renderTitleContentPost(item.content, "content")}
              </Typography>
              {item.images && <PostImageItem listImagesPost={item.images} />}
            </>
          ) : isEditAnswer ? (
            <div className={classes.contentField}>
              <input
                type="text"
                defaultValue={item.content}
                className={classes.titleText}
                placeholder="Enter your answer..."
                onChange={e => this.handleChangeEditAnswer(e)}
              />
              <div className={classes.wrapEditText}>
                <button
                  className={classes.btnEditText}
                  onClick={e => this.handleCloseEditAnswer(e)}
                >
                  Cancel
                </button>
                <button
                  disabled={disableEditAnswer}
                  className={classes.btnEditText}
                  onClick={e =>
                    this.handleEditAnswerClick(e, item.id, item.tw_question_id)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          ) : (
            <Typography className={classes.postQuestion} color="textPrimary">
              {item.content}
            </Typography>
          )}
        </div>

        <div
          className={classNames({
            [classes.postActions]: true,
            [classes.isQuestionAction]: isQuestion ? false : true
          })}
        >
          <Button
            className={classNames({
              [classes.postActionBtns]: true,
              [classes.active]: item.likeStatus ? true : false
            })}
            color="primary"
            size={"sm"}
            simple={true}
            onClick={e => this.handleLikeClick(e)}
          >
            {(isQuestion ? (
              likeStatusQuestion
            ) : (
              item.likeStatus
            )) ? (
              <IconLikeSolid />
            ) : (
              <IconLike />
            )}
            <span className={classes.postActionBtnLabel}>
              {isQuestion ? likeCountQuestion : item.likeCount} Like
            </span>
          </Button>

          <Button
            className={classes.postActionBtns}
            color="primary"
            size={"sm"}
            simple={true}
            aria-describedby={id}
            onClick={e => {
              e.stopPropagation();
              if (currentUserTW) {
                this.handleClick(e);
              } else {
                this.props.commonStore.updateModalRequireLogin(true);
              }
            }}
          >
            <IconShare />
            <span className={classes.postActionBtnLabel}>Share</span>
          </Button>

          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            transition
            placement={"bottom-end"}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <div className={classes.socialShareWrapper}>
                      <div className={classes.socialShareLink}>
                        <input
                          type="text"
                          disabled
                          id={item.id}
                          defaultValue={`${config.API_ROOT}/signup?refCode=${
                            currentUserTW ? currentUserTW.job_seeker_id : ""
                          }`}
                        />

                        <ClickAwayListener
                          onClickAway={this.handleTooltipClose}
                        >
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true
                              }}
                              onClose={this.handleTooltipClose}
                              open={this.state.openTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied"
                            >
                              <Button
                                onClick={() => {
                                  this.onCopy(item.id);
                                  this.handleTooltipOpen();
                                }}
                                color={"primary"}
                                size={"sm"}
                                className={classes.btnCopy}
                              >
                                Copy
                              </Button>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                      <Typography className={classes.socialShareLabel}>
                        Share via socail media
                      </Typography>
                      <MenuList role="menu" className={classes.menuSocials}>
                        <MenuItem
                          onClick={this.handleClose}
                          className={classes.dropdownItem}
                        >
                          <FacebookShareButton
                            className={classes.socialBtn}
                            quote={item.title}
                            url={`${config.API_ROOT}/signup?refCode=${
                              currentUserTW ? currentUserTW.job_seeker_id : ""
                            }`}
                          >
                            <FacebookIcon size={32} round={true} />{" "}
                            <span className={classes.socialLabel}>
                              Facebook
                            </span>
                          </FacebookShareButton>
                        </MenuItem>
                        <MenuItem
                          onClick={this.handleClose}
                          className={classes.dropdownItem}
                        >
                          <TwitterShareButton
                            className={classes.socialBtn}
                            title={item.title}
                            url={`${config.API_ROOT}/signup?refCode=${
                              currentUserTW ? currentUserTW.job_seeker_id : ""
                            }`}
                          >
                            <TwitterIcon size={32} round={true} />{" "}
                            <span className={classes.socialLabel}>Twitter</span>
                          </TwitterShareButton>
                        </MenuItem>
                      </MenuList>
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button
            className={classes.postActionBtns}
            color="primary"
            size={"sm"}
            simple={true}
            onClick={e => {
              e.stopPropagation();
              if (currentUserTW) {
                const body = {};
                if (isQuestion) {
                  body.twSeasonId = item.tw_season_id;
                  body.twQuestionId = item.id;
                  body.twAnswerId = "";
                } else {
                  body.twSeasonId = item.tw_season_id;
                  body.twQuestionId = item.tw_question_id;
                  body.twAnswerId = item.id;
                }
                this.props.commonStore.updateModalResponseData(body);
                this.props.commonStore.updateModalResponse(true, "report");
              } else {
                this.props.commonStore.updateModalRequireLogin(true);
              }
            }}
          >
            <Tooltip
              title={
                <React.Fragment>
                  Report inappropriate content
                  <span className={classes.arrow} ref={this.handleArrowRef} />
                </React.Fragment>
              }
              classes={{ popper: classes.arrowPopper }}
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    arrow: {
                      enabled: Boolean(this.state.arrowRef),
                      element: this.state.arrowRef
                    }
                  }
                }
              }}
            >
              <div>
                <IconFlag />
                <span className={classes.postActionBtnLabel}>Flag</span>
              </div>
            </Tooltip>
          </Button>
          {currentUserTW && currentUserTW.userId === item.user_id.id && (
            <Button
              className={classes.postActionBtns}
              color="primary"
              size={"sm"}
              simple={true}
              onClick={e =>
                isQuestion
                  ? this.handleEditQuestionClick(e, item.id)
                  : this.handleOpenEditAnswer(e)
              }
            >
              <IconEdit />
              <span className={classes.postActionBtnLabel}>Edit</span>
            </Button>
          )}

          <div className={classes.btnFloatRight}>
            {isQuestion && (
              <>
                <Button
                  className={classes.postActionBtns}
                  color="primary"
                  size={"sm"}
                  simple={true}
                  onClick={() => {
                    this.toPostDetail(item.id);
                  }}
                >
                  <IconComment />
                  <span className={classes.postActionBtnLabel}>
                    {item.answerCount ? item.answerCount : 0} Answers
                  </span>
                </Button>
                <Button
                  className={classes.postActionBtns}
                  color="primary"
                  size={"sm"}
                  simple={true}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <IconView />
                  <span className={classes.postActionBtnLabel}>
                    {item.viewCount || 0} Views
                  </span>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PostItem.propTypes = {
  isQuestion: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore", "postsStore")(observer(PostItem))
  )
);
