import { decorate, observable, action } from "mobx";
import {
  getState,
  getFunctions,
  getJobTypes,
  getCarTypes,
  getBusinessTypes,
  getEducationTypes,
  getCitiesByState,
  getCountiesByState,
  getWorkProcesses,
  getAutotechLevels,
  getToolSet,
  getBodytechTypes,
  getAppConfig,
  getContentGettingStarted,
  getContentFAQ,
  getContentContact,
  sendFeedback,
  getSalesRep,
  getContentTermConditions,
  getContentPrivacyPolicy,
  getContentBreachOfBusiness,
  getContentAgreement,
  getBenefitOffer,
  sendFeedbackFromApi,
  sendReportFromApi,
  getGameRule
} from "../services/domainServices/commonDomain.service";
import notificationService from "../services/notificationService";
import FeedbackForm from "./form/feedback";

class CommonStore {
  appLoaded = false;
  appConfig;
  state = [];
  cities = [];
  counties = [];
  functions = [];
  workProcesses = [];
  jobTypes = [];
  carTypes = [];
  businessTypes = [];
  educationTypes = [];
  autotechLevels = [];
  toolSet = [];
  bodyTechTypes = [];
  salesRep = [];
  benefitOffer = [];

  contentGettingStarted;
  contentFAQ;
  contentContact;
  contentPrivacyPolicy;
  contentTermConditions;
  contentBreachOfBusiness;
  contentAgreement;
  openModalRequireLogin = false;
  openModalGameRules = false;
  openModalResponse = false;
  modalResponseType = "";
  modalResponse = {
    title: "",
    content: "",
    twSeasonId: "",
    twQuestionId: "",
    twAnswerId: ""
  };
  gameRules = "";

  constructor() {
    this.feedbackForm = new FeedbackForm();

    // getState().then(
    //   action(data => {
    //     this.state = data;
    //   })
    // );

    getFunctions().then(
      action(data => {
        this.functions = data;
      })
    );

    getJobTypes().then(
      action(data => {
        this.jobTypes = data;
      })
    );

    getCarTypes().then(
      action(data => {
        this.carTypes = [...data, "Other"];
      })
    );

    getBusinessTypes().then(
      action(data => {
        this.businessTypes = [...data, "Other"];
      })
    );
    getEducationTypes().then(
      action(data => {
        this.educationTypes = data;
      })
    );

    getWorkProcesses().then(
      action(data => {
        this.workProcesses = data;
      })
    );

    getAutotechLevels().then(
      action(data => {
        this.autotechLevels = data;
      })
    );

    getToolSet().then(
      action(data => {
        this.toolSet = data;
      })
    );

    getBenefitOffer().then(
      action(data => {
        this.benefitOffer = data;
      })
    );

    getBodytechTypes().then(
      action(data => {
        this.bodyTechTypes = data;
      })
    );

    getAppConfig().then(
      action(data => {
        this.appConfig = data;
      })
    );

    getSalesRep().then(
      action(data => {
        this.salesRep = [
          ...data,
          {
            code: "other",
            name: "Other"
          }
        ];
      })
    );

    this.getContentContact();
    this.getContentFAQ();
    this.getContentGettingStarted();
    this.getContentBreachOfBusiness();
    this.getContentPrivacyPolicy();
    this.getContentTermConditions();
  }

  getCitiesByState = state =>
    getCitiesByState(state).then(
      action(data => {
        this.cities = data;
        return data;
      })
    );

  getCountiesByState = state =>
    getCountiesByState(state).then(
      action(data => {
        this.counties = data;
        return data;
      })
    );

  getContentGettingStarted = () => {
    return getContentGettingStarted().then(
      action(data => {
        this.contentGettingStarted = data.content;
      })
    );
  };

  getContentFAQ = () => {
    return getContentFAQ().then(
      action(data => {
        this.contentFAQ = data.content;
      })
    );
  };

  getContentContact = () => {
    return getContentContact().then(
      action(data => {
        this.contentContact = data.content;
      })
    );
  };

  getContentTermConditions = () => {
    return getContentTermConditions().then(
      action(data => {
        this.contentTermConditions = data;
      })
    );
  };

  getContentPrivacyPolicy = () => {
    return getContentPrivacyPolicy().then(
      action(data => {
        this.contentPrivacyPolicy = data;
      })
    );
  };

  getContentBreachOfBusiness = () => {
    return getContentBreachOfBusiness().then(
      action(data => {
        this.contentBreachOfBusiness = data;
      })
    );
  };

  getContentAgreement = code => {
    return getContentAgreement(code).then(
      action(data => {
        this.contentAgreement = data;
      })
    );
  };

  setAppLoaded() {
    this.appLoaded = true;
  }

  setAppLoading() {
    this.appLoaded = false;
  }

  getPlatformFee = (rate, timeDiff, workingHour) => {
    let platformFee =
      this.appConfig &&
      this.appConfig.platform_fee &&
      (rate * workingHour * timeDiff * this.appConfig.platform_fee.rate) / 100;
    if (
      this.appConfig &&
      this.appConfig.platform_fee.min !== undefined &&
      this.appConfig.platform_fee.min !== null &&
      platformFee < this.appConfig.platform_fee.min
    ) {
      platformFee = this.appConfig.platform_fee.min;
    }
    if (
      this.appConfig &&
      this.appConfig.platform_fee.max !== undefined &&
      this.appConfig.platform_fee.max !== null &&
      platformFee > this.appConfig.platform_fee.max
    ) {
      platformFee = this.appConfig.platform_fee.max;
    }
    return platformFee;
  };

  getTransactionFee = () => {
    let transactionFee = this.appConfig.transaction_fee.rate;
    if (
      this.appConfig &&
      this.appConfig.transaction_fee.min !== undefined &&
      this.appConfig.transaction_fee.min !== null &&
      transactionFee < this.appConfig.transaction_fee.min
    ) {
      transactionFee = this.appConfig.transaction_fee.min;
    }
    if (
      this.appConfig &&
      this.appConfig.transaction_fee.max !== undefined &&
      this.appConfig.transaction_fee.max !== null &&
      transactionFee > this.appConfig.transaction_fee.max
    ) {
      transactionFee = this.appConfig.transaction_fee.max;
    }
    return transactionFee;
  };

  getHiredFee = () => {
    let hiredFee = this.appConfig.hire_fee.rate;
    if (
      this.appConfig &&
      this.appConfig.hire_fee.min !== undefined &&
      this.appConfig.hire_fee.min !== null &&
      hiredFee < this.appConfig.hire_fee.min
    ) {
      hiredFee = this.appConfig.hire_fee.min;
    }
    if (
      this.appConfig &&
      this.appConfig.hire_fee.max !== undefined &&
      this.appConfig.hire_fee.max !== null &&
      hiredFee > this.appConfig.hire_fee.max
    ) {
      hiredFee = this.appConfig.hire_fee.max;
    }
    return hiredFee;
  };

  addCity(city) {
    this.cities.push(city);
  }

  updateModalRequireLogin = status => {
    this.openModalRequireLogin = status;
  };

  updateModalResponse = (status, type) => {
    this.openModalResponse = status;
    this.modalResponseType = type;
  };

  updateModalGameRules = status => {
    this.openModalGameRules = status;
  };

  updateModalResponseData = data => {
    this.modalResponse = {
      ...this.modalResponse,
      ...data
    };
  };

  resetModalResponse = () => {
    this.modalResponse = {
      twSeasonId: "",
      twQuestionId: "",
      twAnswerId: "",
      title: "",
      content: ""
    };
  };

  sendFeedback = () => {
    sendFeedbackFromApi({
      title: this.modalResponse.title,
      content: this.modalResponse.content
    })
      .then(
        action(resp => {
          this.updateModalResponse(false, "");
          this.resetModalResponse();
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  sendReport = data => {
    sendReportFromApi({
      ...this.modalResponse,
      ...data
    })
      .then(
        action(resp => {
          this.updateModalResponse(false, "");
          this.resetModalResponse();
          notificationService.success("Reported Successfully!");
        })
      )
      .catch(err => {
        console.log("err ", err);
      });
  };

  getGameRuleFromApi = () =>
    getGameRule().then(
      action(resp => {
        this.gameRules = resp.content;
      })
    );
}

decorate(CommonStore, {
  sendFeedback: observable,
  sendReport: observable,
  modalResponse: observable,
  appLoaded: observable,
  state: observable,
  cities: observable,
  counties: observable,
  functions: observable,
  jobTypes: observable,
  carTypes: observable,
  businessTypes: observable,
  educationTypes: observable,
  toolSet: observable,
  bodyTechTypes: observable,
  salesRep: observable,
  appConfig: observable,
  contentContact: observable,
  contentFAQ: observable,
  contentGettingStarted: observable,
  contentBreachOfBusiness: observable,
  contentPrivacyPolicy: observable,
  contentTermConditions: observable,
  contentAgreement: observable,
  feedbackForm: observable,
  openModalRequireLogin: observable,
  openModalResponse: observable,
  modalResponseType: observable,
  openModalGameRules: observable,
  gameRules: observable,
  setAppLoaded: action,
  setAppLoading: action,
  getCitiesByState: action,
  getCountiesByState: action,
  getContentContact: action,
  getContentFAQ: action,
  getContentGettingStarted: action,
  getContentBreachOfBusiness: action,
  getContentPrivacyPolicy: action,
  getContentTermConditions: action,
  getContentAgreement: action,
  addCity: action,
  benefitOffer: observable,
  updateModalRequireLogin: action,
  updateModalResponse: action,
  updateModalGameRules: action,
  updateModalResponseData: action,
  getGameRuleFromApi: action
});

export default new CommonStore();
