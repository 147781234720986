import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Button from "../../../components/CustomButtons/Button";
import { ReactComponent as IconCloseGrey } from "../../../assets/img/icons/icon_close_grey.svg";

import {
  image,
  container,
  primaryColor,
  textPrimaryColor,
  textSecondaryColor,
  textActiveColor,
  whiteColor,
  blackColor,
  grayColor,
  noSidePadding,
  hexToRgb,
  successColor
} from "../../../assets/jss/material-dashboard-pro-react";
import { formatCurrency, getNumberDateOfRange } from "../../utils/appUtils";

const styles = theme => ({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 555,
    maxWidth: "100%",
    transform: `translate(-60%, -50%)`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 4}px`,
    outline: "none"
  },
  closeIcon: {
    position: "absolute",
    top: 13,
    right: 16,
    width: 18,
    height: 18,
    textAlign: "center",
    cursor: "pointer"
  },
  modalTitle: {
    margin: "10px auto 10px",
    fontFamily: "Inter",
    fontSize: 19,
    lineHeight: "26px",
    fontWeight: 700,
    color: blackColor,
    marginBottom: 18
  },
  modalContent: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 16,
    color: blackColor,
    margin: "10px auto 16px",
    "& p": {
      marginBottom: 5
    }
  },
  formSubmit: {
    textAlign: "center",

    "& input, & textarea": {
      padding: "10px 16px",
      border: "1px solid #969798",
      color: "#969798",
      fontSize: 16,
      fontWeight: "normal",
      fontFamily: "Inter",
      borderRadius: 10,
      width: "100%",
      marginBottom: 12
    },
    "& textarea": {
      minHeight: 215,
      overflow: "auto",
      resize: "none"
    },
    "& button": {
      width: 215,
      maxWidth: "334px",
      overflow: "auto",
      borderRadius: 10
    }
  }
});

class ModalResponse extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.commonStore.updateModalResponseData({
      title: "",
      content: ""
    });
  }

  onSubmit = e => {
    e.preventDefault();
    const { type } = this.props;
    if (type === "feedback") {
      this.props.commonStore.sendFeedback();
    } else {
      this.props.commonStore.sendReport({});
    }
  };

  render() {
    const { classes, open, type } = this.props;
    const { title, content } = this.props.commonStore.modalResponse;

    const isValid = () => {
      return title && content ? true : false;
    };

    return (
      <div className={classes.modalResponse}>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={() => this.props.commonStore.updateModalResponse(false, "")}
        >
          <div className={classes.paper}>
            <div
              className={classes.closeIcon}
              onClick={() => {
                this.props.commonStore.updateModalResponse(false, "");
                this.props.commonStore.resetModalResponse();
              }}
            >
              <IconCloseGrey />
            </div>

            <Typography className={classes.modalTitle}>
              {type === "feedback" ? "Feedback" : "Report"}
            </Typography>

            <div className={classes.modalContent}>
              <form onSubmit={this.onSubmit} className={classes.formSubmit}>
                <input
                  type="text"
                  placeholder="Title"
                  onChange={e =>
                    this.props.commonStore.updateModalResponseData({
                      title: e.target.value
                    })
                  }
                />
                <textarea
                  placeholder="Write content"
                  onChange={e =>
                    this.props.commonStore.updateModalResponseData({
                      content: e.target.value
                    })
                  }
                />
                <Button type="submit" color="primary" disabled={!isValid()}>
                  Send
                </Button>
              </form>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(
    inject("commonStore", "userStore")(observer(ModalResponse))
  )
);
