import { Form } from "mobx-react-form";
import plugins from "./plugins";

export default class AccountSettingsForm extends Form {
    plugins() {
        return plugins;
    }

    setup() {
        return {
            fields: [
                {
                    name: "new_password",
                    label: 'new password',
                    placeholder: "Enter your new password",
                    rules: "required|password",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "retype_new_password",
                    label: 'retype new password',
                    placeholder: "Retype your new password",
                    rules: "required|same:new_password",
                    type: "password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                },
                {
                    name: "current_password",
                    label: 'password',
                    placeholder: "Enter current password",
                    type: "password",
                    rules: "required|password",
                    hooks: {
                        onChange: field => {
                            field.validate();
                        }
                    }
                }
            ]
        };
    }
}
