import { Form } from "mobx-react-form";
import stores from "..";
import plugins from "./plugins";

export default class GeneralRegisterForm extends Form {
  plugins() {
    return plugins;
  }

  setup() {
    return {
      fields: [
        {
          name: "user_name",
          placeholder: "Enter alias",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "first_name",
          placeholder: "Enter first name",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "last_name",
          placeholder: "Enter last name",
          rules: "required",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "emailOrPhone",
          placeholder: "Enter your email",
          rules: "required|emailOrPhone",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "password",
          placeholder: "Enter your password",
          rules: "required|password",
          type: "password",
          hooks: {
            onChange: field => {
              field.validate();
              this.$("confirmPassword").validate();
              this.$("confirmPassword").showErrors();
            }
          }
        },
        {
          name: "confirmPassword",
          placeholder: "Enter confirm password",
          type: "password",
          rules: "required|same:password",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "agreeTerm",
          type: "checkbox",
          hooks: {
            onChange: field => {
              field.validate();
            }
          }
        },
        {
          name: "state",
          // rules: "required",
          placeholder: "Select State",
          handlers: {
            onChange: field => value => {
              field.set(value);
            }
          }
          // hooks: {
          //   onChange: field => {
          //     if (!field.value) {
          //       this.$("state").reset();
          //     }
          //     this.$("city").set("extra", []);
          //     if (this.$("city").value) {
          //       this.$("city").reset();
          //     }

          //     const { value } = field;
          //     if (value && value.value) {
          //       stores.commonStore.getCitiesByState(value.value).then(data => {
          //         this.$("city").set(
          //           "extra",
          //           data.map(item => ({
          //             label: item,
          //             value: item
          //           }))
          //         );
          //       });
          //     }
          //     field.validate();
          //   }
          // }
        },
        {
          name: "city",
          // rules: "required",
          placeholder: "Select City",
          handlers: {
            onChange: field => value => {
              field.set(value);
            }
          },
          // hooks: {
          //   onChange: field => {
          //     field.validate();
          //   }
          // },
          extra: []
        }
        // {
        //   name: "location",
        //   rules: "required",
        //   placeholder: "City, State or Zip Code",
        //   handlers: {
        //     onChange: field => value => {
        //       field.set(value);
        //     }
        //   },
        //   hooks: {
        //     onChange: field => {
        //       field.validate();
        //     }
        //   },
        //   extra: []
        // }
      ]
    };
  }
}
