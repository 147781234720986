import {
  getRequestsListApi,
  createRequestApi,
  updateRequestApi,
  getRequestByIdApi,
  closeRequestApi,
  deleteListingsApi
} from "../dataServices/requestsData.service";

const getRequestsList = (status, pageSize, page, sort, order) => {
  return getRequestsListApi(status, pageSize, page, sort, order);
};

const createRequest = request => {
  return createRequestApi(request);
};

const updateRequest = (id, request) => {
  return updateRequestApi(id, request);
};

const closeRequest = id => {
  return closeRequestApi(id);
};

const getRequestById = id => {
  return getRequestByIdApi(id);
};

const deleteListings = payload => deleteListingsApi(payload);

export {
  getRequestsList,
  createRequest,
  updateRequest,
  getRequestById,
  closeRequest,
  deleteListings
};
