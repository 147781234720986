import React from "react";
import classNames from "classnames";
import Select, { createFilter } from "react-select";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

const useStyles = {
  root: {
    flexGrow: 1,
    marginTop: 10
  },
  input: {
    display: "flex",
    padding: 0
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  chip: {
    margin: "6px 3px"
  },
  chipFocused: {
    backgroundColor: "yellow"
  },
  noOptionsMessage: {
    padding: "10px"
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: 0,
    left: 0,
    right: 0
  },
  divider: {
    height: 0
  }
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        classes: { underline: props.selectProps.classes.underline },
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

const selectStyles = {
  root: {
    fontSize: "1.4rem"
  },
  ...customInputStyle,
  ...useStyles,
  input: {
    font: "inherit",
    fontSize: "1.4rem",
    display: "flex",
    padding: 0
  },
  underline: {
    "&:before": {
      borderBottom: ["1px solid #D2D2D2", "!important"]
    },
    "&:after": {
      borderBottomColor: "#F8981F"
    },
    "&:hover:before": {
      borderBottomColor: ["#D2D2D2", "!important"]
    }
  }
};

class IntegrationReactSelect extends React.Component {
  render() {
    const {
      id,
      classes,
      value,
      options,
      label,
      onChange,
      placeholder,
      helpText,
      error,
      multiple,
      filterConfig,
      isClearable = true,
      handleInputChange,
      defaultValue,
      editMode
    } = this.props;
    let convertedValue;
    if (!multiple) {
      let singleValue;
      if (typeof value === "string") {
        singleValue = options.find(item => item.value === value);
      } else {
        singleValue = value;
      }
      convertedValue = singleValue;
    } else {
      let multiValue = [];
      if (value) {
        value.forEach(valueItem => {
          if (typeof valueItem === "string") {
            const selectedItem = options.find(item => item.value === valueItem);
            if (selectedItem) {
              multiValue.push(selectedItem);
            }
          } else {
            multiValue.push(valueItem);
          }
        });
      }
      convertedValue = multiValue;
    }
    var helpTextClasses = classNames({
      [classes.labelRootError]: error
    });

    const labelClasses = classNames({
      [" " + classes.labelRootError]: error
    });
    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            id={id}
            textFieldProps={{
              label: label,
              InputLabelProps: {
                shrink: true,
                className: labelClasses
              }
            }}
            classes={classes}
            options={options}
            components={components}
            value={convertedValue}
            defaultValue={defaultValue}
            onChange={onChange}
            onInputChange={handleInputChange}
            placeholder={placeholder}
            isMulti={multiple}
            filterOption={filterConfig ? createFilter(filterConfig) : undefined}
            isClearable={isClearable}
            isSearchable={editMode}
            onFocus={e => {
              if (e.target.autocomplete) {
                e.target.autocomplete = "nope";
              }
            }}
          />
          {helpText !== undefined ? (
            <FormHelperText id={id + "-text"} className={helpTextClasses}>
              {helpText}
            </FormHelperText>
          ) : null}
        </NoSsr>
      </div>
    );
  }
}

export default withStyles(selectStyles)(IntegrationReactSelect);
