import React from "react";
import { withRouter } from 'react-router-dom';
import Rating from 'react-rating';

// react component for creating dynamic tables
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Tooltip } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment-timezone/builds/moment-timezone-with-data';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import StarRate from "@material-ui/icons/StarRate";
import StarBorder from "@material-ui/icons/StarBorder";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Loading from "components/Loading/Loading";
import Button from "components/CustomButtons/Button.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import ReplyModalUI from "./components/ReplyModalUI";
import notificationService from "../../services/notificationService";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  newBtn: {
    marginTop: '15px',
    marginLeft: 'auto'
  },
  mb20: {
    marginBottom: '20px'
  },
  formWrapper: {
    padding: '20px'
  },
  tableCell: {
      fontSize: '1rem',
      padding: '4px 20px'
  },
  actionsCol: {
    width: '100px'
  },
  rateIcon: {
    color: '#F8981F',
    fontSize: '20px'
  }
};


const rows = [
  { id: 'job_seeker', numeric: false, disablePadding: false, label: 'Job Seeker' },
  { id: 'rate', numeric: false, disablePadding: false, label: 'Rating' },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Review' },
  { id: 'reply', numeric: false, disablePadding: false, label: 'Reply' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Updated' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

class ReceivedReviews extends React.Component {

  constructor(props) {
    super(props);
    this.props.reviewStore.setPageViewing('received');
    this.props.reviewStore.resetForm();
    this.state = {
      replyModal: null
    }
  }

  componentDidMount() {
    this.getReviewsList();
    this.props.reviewStore.getHiredEmployeeList();
  }

  getReviewsList = () => {
    const { getReviewsList, pagination } = this.props.reviewStore;
    const { pageSize, page } = pagination;
    getReviewsList('receiver', pageSize, page);
  }

  handleChangePage = (e, page) => {
    this.props.reviewStore.changePageOfPagination(page);
    this.getReviewsList();
  }

  handleChangePageSize = (e) => {
    this.props.reviewStore.changePageSizeOfPagination(e.target.value);
    this.getReviewsList();
  }

  showReplyModal = (review) => () => {
    const { replyForm } = this.props.reviewStore;
    this.setState({
      replyModal: <ReplyModalUI replyForm={replyForm} onConfirm={this.submitReview(review)} onCancel={this.hideReplyModal} />
    })
  }

  submitReview = (review) => () => {
    const { replyForm, createReply } = this.props.reviewStore;
    replyForm.submit({
      onSuccess: (form) => {
        const data = form.values();
        replyForm.$('submitting').set(true);
        createReply(review.id, data).then(() => {
          notificationService.success('REVIEW.SENT_REPLY');
          replyForm.$('submitting').set(false);
          this.getReviewsList();
          this.hideReplyModal();
        }).catch(() => {
          this.hideReplyModal();
          replyForm.$('submitting').set(false);
        });
      },
      onError: (form) => {
        // eslint-disable-next-line no-console
        console.log("All form errors", form.errors());
      }
    })
  }


  hideReplyModal = () => {
    this.props.reviewStore.replyForm.reset();
    this.props.reviewStore.replyForm.showErrors(false);
    this.setState({
      replyModal: null
    })
  }

  render() {
    const { classes } = this.props;
    const { reviewsPagination, pagination, isLoading } = this.props.reviewStore;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon className={classes.header}>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Received Reviews</h4>
            </CardHeader>
            <CardBody>
              <div>
              {isLoading ? <Loading /> : <Table aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                        {rows.map(
                        row => (
                            <TableCell
                            key={row.id}
                            className={row.id === 'action' ? classes.actionsCol : classes.tableCell}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={pagination.sort === row.id ? pagination.order : false}
                            >
                            {row.label}
                            </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviewsPagination.items.map(n => {
                      return (
                        <React.Fragment
                          key={n.id}
                        >
                          <Tooltip title=''>
                            <TableRow
                              hover
                              tabIndex={-1}
                            >
                              <TableCell
                                className={classes.tableCell}
                              >
                                {n.job_seeker && n.job_seeker.profile && `${n.job_seeker.profile.first_name} ${n.job_seeker.profile.last_name}`}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                <Rating
                                  initialRating={n.rating}
                                  emptySymbol={<StarBorder className={classes.rateIcon} />}
                                  fullSymbol={<StarRate className={classes.rateIcon} />}
                                  readonly
                                />
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {n.comment}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {n.reply && n.reply.comment}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {moment(n.updated_at).format('MMM DD YYYY')}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                {moment(n.created_at).format('MMM DD YYYY')}
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                              >
                                <Button color="primary" size='sm' disabled={n.reply} onClick={this.showReplyModal(n)}>Reply</Button>
                              </TableCell>
                            </TableRow>
                          </Tooltip>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>}
              </div>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={reviewsPagination.totalItem}
                rowsPerPage={pagination.pageSize}
                page={pagination.page}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangePageSize}
              />
              {this.state.replyModal}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(inject('reviewStore', 'commonStore', 'userStore')(withRouter(observer(ReceivedReviews))));
